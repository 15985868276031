import React from 'react';
import { Box, DialogContent, Fab, makeStyles, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
    width: '100%',
  },
  fab: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
  },
  heroImage: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
  checklistContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignSelf: 'stretch',
  },
  checklistRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
  },
}));

export default function PartnerImported({ partnerName, propertyCount, changeToPropertyValues, openVideoDialog }) {
  const classes = useStyles();
  return (
    <DialogContent>
      <Box mx="auto" textAlign="center" mb={2}>
        <Box style={{ position: 'relative' }} mb={1}>
          <img className={classes.heroImage} src="./rei_hub_properties_intro_hero.webp" alt="Properties" />
          <Fab color="secondary" className={classes.fab} onClick={openVideoDialog}>
            <PlayCircleFilledIcon color="white" fontSize="24px" />
          </Fab>
        </Box>
        <Box mx="auto" textAlign="center" mb={2}>
          <Typography variant="h6" gutterBottom>
            {`Your ${propertyCount} ${partnerName} ${pluralize('property', propertyCount)} are ready in REI Hub!`}
          </Typography>
        </Box>
        <div className={classes.checklistContainer}>
          <div className={classes.checklistRow}>
            <CheckCircle color="primary" />
            <Typography variant="body1">REI Hub categorizes transactions by property</Typography>
          </div>
          <div className={classes.checklistRow}>
            <CheckCircle color="primary" />
            <Typography variant="body1">View financial summaries and details in the dashboard</Typography>
          </div>
          <div className={classes.checklistRow}>
            <CheckCircle color="primary" />
            <Typography variant="body1">{`Properties added in ${partnerName} sync automatically`}</Typography>
          </div>
        </div>
        <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
          <Button hasFullWidth variant="contained" onClick={changeToPropertyValues}>
            Continue
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
}

PartnerImported.propTypes = {
  partnerName: PropTypes.string.isRequired,
  propertyCount: PropTypes.number.isRequired,
  changeToPropertyValues: PropTypes.func.isRequired,
  openVideoDialog: PropTypes.func.isRequired,
};
