import React, { createRef, useState } from 'react';
import { Box, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import { AddCircleOutline, AttachFile, CloseOutlined } from '@material-ui/icons';
import { Widget } from '@uploadcare/react-widget';
import PropTypes from 'prop-types';

import Button from '~/components/Button';

const useStyles = makeStyles((theme) => ({
  notesAndAttachmentsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  iconButton: {
    color: theme.palette.primary.main,
    gap: theme.spacing(2),
  },
  notesAndAttachmentsIcon: {
    paddingRight: theme.spacing(1),
    fontSize: '30px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  notesFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  notesField: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(33% - 8px)',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
  },
  hideWidget: {
    display: 'none',
  },
  uploadCareContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.main,
    gap: theme.spacing(2),
  },
}));

export default function QuickBookRowNotesAndAttachment({ journal, onChange }) {
  const uploadcareWidgetRef = createRef();
  const classes = useStyles();

  const [isDescriptionSectionDisplayed, setIsDescriptionSectionDisplayed] = useState(false);

  const handleUploaderChange = (file) => {
    onChange({
      ...journal,
      attachmentURL: file ? file.cdnUrl : '',
    });
  };

  return (
    <>
      {isDescriptionSectionDisplayed ? (
        <Box className={classes.notesFieldContainer}>
          <TextField
            className={classes.notesField}
            label="Additional Notes"
            variant="outlined"
            size="small"
            multiline
            minRows={3}
            value={journal.notes}
            onChange={(event) => {
              onChange({ ...journal, notes: event.target.value });
            }}
          />
        </Box>
      ) : null}
      <Box className={classes.notesAndAttachmentsContainer}>
        {!isDescriptionSectionDisplayed ? (
          <Button variant="text" className={classes.iconButton} onClick={() => setIsDescriptionSectionDisplayed(true)}>
            <AddCircleOutline className={classes.notesAndAttachmentsIcon} />
            <Typography variant="subtitle2">Add Notes</Typography>
          </Button>
        ) : null}
        <Box className={classes.uploadCareContainer}>
          <div className={journal.attachmentURL ? null : classes.hideWidget}>
            <Widget
              ref={uploadcareWidgetRef}
              multiple
              tabs="file url camera"
              value={journal.attachmentURL}
              onChange={(file) => handleUploaderChange(file)}
            />
          </div>
          {!journal.attachmentURL ? (
            <Button
              className={classes.iconButton}
              variant="text"
              onClick={() => {
                uploadcareWidgetRef.current.openDialog();
              }}
            >
              <AttachFile className={classes.notesAndAttachmentsIcon} />
              <Typography variant="subtitle2">Add Attachment</Typography>
            </Button>
          ) : (
            <Tooltip title="Remove all attachments" placement="bottom" arrow>
              <CloseOutlined
                className={classes.notesAndAttachmentsIcon}
                onClick={() => {
                  handleUploaderChange();
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  );
}

QuickBookRowNotesAndAttachment.propTypes = {
  journal: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
