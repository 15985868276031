import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import { OfflineBoltOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  // reverse the negative margins from page header for proper spacing
  buttonGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
});

class ActionButtons extends React.PureComponent {
  render() {
    const { actionButtons, classes, className } = this.props;

    return (
      <ButtonGroup className={`noprint ${classes.buttonGroup} ${className}`} aria-label="action buttons">
        {actionButtons.map((button, index) => {
          const buttonProps = {
            variant: 'outlined',
            className: button.class,
          };
          if (button.link) {
            buttonProps.component = Link;
            buttonProps.to = button.link;
          } else if (button.action) {
            buttonProps.onClick = button.action;
          }

          if (button.class === 'filter') {
            buttonProps.startIcon = <FilterListIcon />;
          } else if (button.class === 'add') {
            buttonProps.startIcon = <AddIcon />;
          } else if (button.class === 'edit') {
            buttonProps.startIcon = <EditIcon />;
          } else if (button.class === 'delete') {
            buttonProps.startIcon = <DeleteOutlineIcon />;
          } else if (button.class === 'cancel') {
            buttonProps.startIcon = <CloseOutlinedIcon />;
          } else if (button.class === 'export') {
            buttonProps.startIcon = <GetAppIcon />;
          } else if (button.class === 'lighting') {
            buttonProps.startIcon = <OfflineBoltOutlined />;
          }

          return (
            <Button {...buttonProps} key={index}>
              {button.text}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}

ActionButtons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(ActionButtons);
