import React, { useState } from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import PropTypes from 'prop-types';

import CardBase from '../../components/CardBase';
import CardDateFilterMenu from '../../components/CardDateFilterMenu';
import LinkBase from '../../components/LinkBase';
import CashflowGraph from '../graphs/CashflowGraph';

export default function GraphsCard({ view, id }) {
  GraphsCard.defaultProps = {
    id: null,
  };
  GraphsCard.propTypes = {
    view: PropTypes.string.isRequired,
    id: PropTypes.string,
  };

  const [filter, setFilter] = useState({
    startDate: moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    filterText: 'Last 12 Months',
  });
  const [tabValue, setTabValue] = useState('Cashflow');

  const [stale, setStale] = useState(true);

  const getCashflowReportLink = () => {
    const baseLink = `/reports/operating-cash-flow-by-period?startDate=${filter.startDate}&endDate=${filter.endDate}&period=month`;
    switch (view) {
      case 'portfolio':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={baseLink}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      case 'property':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={`${baseLink}&propertyId=${id}`}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      case 'unit':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={`${baseLink}&unitId=${id}`}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      case 'subportfolio':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={`${baseLink}&entityId=${id}`}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      default:
        return null;
    }
  };

  const getNOIReportLink = () => {
    const baseLink = `/reports/net-operating-income-by-period?startDate=${filter.startDate}&endDate=${filter.endDate}&period=month`;
    switch (view) {
      case 'portfolio':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={baseLink}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      case 'property':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={`${baseLink}&propertyId=${id}`}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      case 'unit':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={`${baseLink}&unitId=${id}`}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      case 'subportfolio':
        return (
          <Box display="flex" flexDirection="column" justifyContent="felx-end">
            <LinkBase to={`${baseLink}&entityId=${id}`}>
              <Typography variant="body2">View Report</Typography>
            </LinkBase>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={8}>
      <CardBase>
        <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
          <Box pb={2} display="flex" flexDirection="row">
            <Box paddingRight={2}>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={(_event, newValue) => {
                  setTabValue(newValue);
                  setStale(true);
                }}
              >
                <Tab label="Cashflow" value="Cashflow" />
                <Tab label="Net Operating Income" value="NOI" />
              </Tabs>
            </Box>
            <Box flexGrow={1} />
            <CardDateFilterMenu setFilter={setFilter} setStale={setStale} />
          </Box>
          <CashflowGraph stale={stale} setStale={setStale} view={view} id={id} filter={filter} tabValue={tabValue} />
          {tabValue === 'Cashflow' && getCashflowReportLink()}
          {tabValue === 'NOI' && getNOIReportLink()}
        </Box>
      </CardBase>
    </Grid>
  );
}
