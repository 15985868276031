import React from 'react';
import NumberFormat from 'react-number-format';
import { ButtonBase } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../../contexts/PersonContext';
import { create, find, get, remove } from '../../feathersWrapper';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleTextFieldChange,
  handleTransactionScopeChange,
  handleUploaderChange,
} from '../../functions/InputHandlers';
import { setInitialJournalScope, setJournalScope } from '../../functions/JournalFunctions';
import { testMatchingRule } from '../../functions/RegExpFunctions';
import { getBalanceSheetAccountOptions, nameLabel } from '../Autocomplete/Library';
import { getManualBasicJournal } from '../SearchSelect/TransactionTypeOptions';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  red: {
    color: theme.palette.error.main,
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddTransferDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { yodleeTransaction, ruleId } = this.props;
    const { organizationName, organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      autobookResult: null,
      matchingTransaction: null,
      bookMatchingTransaction: false,
      rule: {
        createRule: false,
        matchDescription: false,
        matchAmount: false,
        descriptionContains: '',
        autobookMatchingTransactions: true,
        applyToAllAccounts: false,
      },
      displayNotesSection: false,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Transfer';
    journal.organizationId = organizationId;
    journal.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };

    if (ruleId) {
      const rule = await get(this, 'imported-transaction-rules', ruleId);
      if (rule.type === 'Transfer To') {
        journal.debitLines[0].accountIdSelect = rule.destinationAccount;
      } else {
        journal.creditLines[0].accountIdSelect = rule.destinationAccount;
      }
      journal.unitId = rule.unitId;
      journal.propertyId = rule.propertyId;
      journal.entityId = rule.entityId;
      await setInitialJournalScope(journal, this);
    }

    if (yodleeTransaction) {
      if (yodleeTransaction.yodleeType === 'CREDIT') {
        journal.debitLines[0].accountIdSelect = yodleeTransaction.account;
        journal.debitLines[0].yodleeTransactionId = yodleeTransaction.id;
      } else if (yodleeTransaction.yodleeType === 'DEBIT') {
        journal.creditLines[0].accountIdSelect = yodleeTransaction.account;
        journal.creditLines[0].yodleeTransactionId = yodleeTransaction.id;
      }
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;
      journal.amount = yodleeTransaction.amount;
      await this.findInitialMatchingTransaction(journal, initialState);
    }

    initialState.journal = journal;

    initialState.balanceSheetAccountOptions = await getBalanceSheetAccountOptions(this, {
      id: {
        $ne: yodleeTransaction.account.id,
      },
    });

    this.setState(initialState);
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  // Attempts to find a matching transaction for imported transfers
  // based on transaction amount and date
  findInitialMatchingTransaction = async (journal, initialState) => {
    let transactionType;
    if (journal.creditLines[0].yodleeTransactionId) {
      transactionType = 'CREDIT';
    } else if (journal.debitLines[0].yodleeTransactionId) {
      transactionType = 'DEBIT';
    }

    const query = {
      organizationId: journal.organizationId,
      amount: journal.amount,
      yodleeType: transactionType,
      date: {
        $lte: moment(journal.date).add(3, 'days').format('YYYY-MM-DD'),
        $gte: moment(journal.date).subtract(3, 'days').format('YYYY-MM-DD'),
      },
      journalId: null,
      split: { $or: [false, null] },
      ignored: { $or: [false, null] },
      pending: false,
      deleted: false,
    };

    const result = await find(this, 'yodlee-transactions', { query });

    if (result.data.length === 1) {
      initialState.bookMatchingTransaction = true;
      [initialState.matchingTransaction] = result.data;
      if (transactionType === 'CREDIT') {
        journal.debitLines[0].accountIdSelect = result.data[0].account;
      } else {
        journal.creditLines[0].accountIdSelect = result.data[0].account;
      }
    }
  };

  // Attempts to find a matching transaction for the transfer
  // based on transaction amount, date, and account selected in the transfer to/from dropdown
  findMatchingTransaction = (name, component) => async (event, value) => {
    handleAutocompleteChange(name, component)(event, value);
    const { organizationId } = this.context;
    const { journal } = this.state;

    let transactionType;
    if (journal.creditLines[0].yodleeTransactionId) {
      transactionType = 'CREDIT';
    } else if (journal.debitLines[0].yodleeTransactionId) {
      transactionType = 'DEBIT';
    }

    if (!transactionType || !value || !value.id) {
      this.setState({ matchingTransaction: null });
      return;
    }

    const query = {
      organizationId,
      accountId: value.id,
      amount: journal.amount,
      yodleeType: transactionType,
      date: {
        $lte: moment(journal.date).add(3, 'days').format('YYYY-MM-DD'),
        $gte: moment(journal.date).subtract(3, 'days').format('YYYY-MM-DD'),
      },
      journalId: null,
      split: { $or: [false, null] },
      ignored: { $or: [false, null] },
      pending: false,
      deleted: false,
    };

    const result = await find(this, 'yodlee-transactions', { query });

    if (result.data.length === 1) {
      this.setState({ bookMatchingTransaction: true, matchingTransaction: result.data[0] });
    } else {
      this.setState({ matchingTransaction: null });
    }
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const { submitting, journal, rule, bookMatchingTransaction, matchingTransaction } = this.state;

    const { onAddTransaction, yodleeTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    journal.creditLines[0].credit = journal.amount;
    journal.debitLines[0].debit = journal.amount;
    journal.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    if (!journal.amount) {
      this.setState({ error: { message: 'Please enter a non-zero amount for this transaction' } });
      return;
    }

    // Find matching transaction if applicable
    if (matchingTransaction && bookMatchingTransaction) {
      if (matchingTransaction.yodleeType === 'CREDIT') {
        journal.debitLines[0].yodleeTransactionId = matchingTransaction.id;
      } else if (matchingTransaction.yodleeType === 'DEBIT') {
        journal.creditLines[0].yodleeTransactionId = matchingTransaction.id;
      }
    }

    if (rule.createRule) {
      if (!rule.matchDescription && !rule.matchAmount) {
        this.setState({
          error: {
            message: 'Please select a description or amount to create a matching rule',
          },
        });
        return;
      }
      if (rule.matchDescription) {
        if (!testMatchingRule(rule.descriptionContains, yodleeTransaction.description)) {
          this.setState({
            error: {
              message: `The imported transaction description does not contain the matching rule text.
              Please update the rule so it is contained within the text: ${yodleeTransaction.description}`,
            },
          });
          return;
        }
      }
    }

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);
    setJournalScope(journal);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then(async (journalResult) => {
        if (rule.createRule) {
          const ruleType =
            journal.debitLines[0].yodleeTransactionId === yodleeTransaction.id ? 'Transfer From' : 'Transfer To';
          const ruleSubmit = {
            type: ruleType,
            organizationId,
            entityId: journal.entityId,
            propertyId: journal.propertyId,
            unitId: journal.unitId,
            destinationAccountId:
              journal.debitLines[0].yodleeTransactionId === yodleeTransaction.id
                ? journal.creditLines[0].accountId
                : journal.debitLines[0].accountId,
            transactionAmount: rule.matchAmount ? journal.amount : null,
            descriptionContains: rule.matchDescription ? rule.descriptionContains : '',
            autobookMatchingTransactions: rule.autobookMatchingTransactions,
            analysisAccountId: rule.applyToAllAccounts ? null : yodleeTransaction.account.id,
          };

          await create(this, 'imported-transaction-rules', ruleSubmit, true)
            .then(async (ruleResult) => {
              if (ruleSubmit.autobookMatchingTransactions) {
                const automationResult = await create(this, 'automations', {
                  organizationId: ruleSubmit.organizationId,
                  automationName: 'bookRuleMatching',
                  ruleId: ruleResult.id,
                });
                onAddTransaction(journalResult);
                if (automationResult.newTransactions) {
                  this.setState({
                    autobookResult: {
                      ruleId: ruleResult.id,
                      autobookedTransactionCount: automationResult.newTransactions,
                    },
                  });
                } else {
                  this.closeDialog();
                }
              } else {
                onAddTransaction(journalResult);
                this.closeDialog();
              }
            })
            .catch((error) => {
              remove(this, 'journals', journalResult.id, true);
              this.setState({ error });
              this.setState({ submitting: false });
            });
        } else {
          onAddTransaction(journalResult);
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { yodleeTransaction, ruleId, classes } = this.props;
    const {
      loading,
      error,
      journal,
      balanceSheetAccountOptions,
      rule,
      autobookResult,
      bookMatchingTransaction,
      matchingTransaction,
      displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    if (autobookResult) {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Rule Created</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {`Your rule was created. ${autobookResult.autobookedTransactionCount} additional transactions matched the template and were booked automatically.`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">Add Transfer</DialogTitle>
        <DialogContent>
          {yodleeTransaction && (
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction
              </FormLabel>
              <Typography>
                {moment(yodleeTransaction.date).format('M/D/YYYY')}
                <Box px={2} component="span">
                  |
                </Box>
                <NumberFormat
                  displayType="text"
                  value={yodleeTransaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                />
                <Box px={2} component="span">
                  |
                </Box>
                {yodleeTransaction.account.name}
              </Typography>
            </FormControl>
          )}
          {!yodleeTransaction && (
            <>
              <KeyboardDatePicker
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={journal.date}
                onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>Amount</InputLabel>
                <NumberFormat
                  value={journal.amount}
                  required
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={handleNumberFormatChange('nested_journal_amount', this)}
                  customInput={Input}
                />
              </FormControl>
            </>
          )}
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          {!journal.debitLines[0].yodleeTransactionId && (
            <Autocomplete
              options={balanceSheetAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.debitLines[0].accountIdSelect}
              onChange={this.findMatchingTransaction('nested_journal_debitLines_0_accountIdSelect', this)}
              // onInputChange={(event, value) => this.findMatchingTransaction(event, value)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Transferred To Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          )}
          {!journal.creditLines[0].yodleeTransactionId && (
            <Autocomplete
              options={balanceSheetAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.creditLines[0].accountIdSelect}
              onChange={this.findMatchingTransaction('nested_journal_creditLines_0_accountIdSelect', this)}
              // onInputChange={(event, value) => this.findMatchingTransaction(event, value)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Transferred From Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          )}
          {matchingTransaction && (
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <FormControl margin="dense">
                <Typography variant="body2">
                  We found a matching transaction. Check this box to book it as well.
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bookMatchingTransaction}
                      onChange={handleCheckboxChange(this)}
                      name="nested_bookMatchingTransaction"
                    />
                  }
                  label={`${moment(matchingTransaction.date).format('M/D/YYYY')} - ${matchingTransaction.description}`}
                />
              </FormControl>
            </Box>
          )}
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          {yodleeTransaction && !ruleId && (
            <FormControl margin="dense">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rule.createRule}
                    onChange={handleCheckboxChange(this)}
                    name="nested_rule_createRule"
                  />
                }
                label="Create a new transaction matching rule"
              />
            </FormControl>
          )}
          {rule.createRule && (
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.matchDescription}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_matchDescription"
                    />
                  }
                  label="Match by description"
                />
              </FormControl>
              {rule.matchDescription && (
                <>
                  <Typography variant="body2">
                    {`Enter a matching phrase contained in the imported description.
                      The imported description was: ${yodleeTransaction.description}`}
                  </Typography>
                  <TextField
                    label="Description Contains"
                    fullWidth
                    required
                    margin="dense"
                    InputProps={{
                      value: rule.descriptionContains,
                      name: 'nested_rule_descriptionContains',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                </>
              )}
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.matchAmount}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_matchAmount"
                    />
                  }
                  label="Match by amount"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.autobookMatchingTransactions}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_autobookMatchingTransactions"
                    />
                  }
                  label="Automatically book matching transactions"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.applyToAllAccounts}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_applyToAllAccounts"
                    />
                  }
                  label="Apply this rule to all accounts"
                />
              </FormControl>
              {!rule.applyToAllAccounts && (
                <Typography variant="body2">
                  {`This rule will be applied only to transactions in ${yodleeTransaction.account.name}`}
                </Typography>
              )}
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Transfer
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddTransferDialogContent.contextType = PersonContext;

AddTransferDialogContent.defaultProps = {
  yodleeTransaction: null,
  unitId: null,
  propertyId: null,
  bankAccountId: null,
  ruleId: null,
};

AddTransferDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  bankAccountId: PropTypes.node,
  ruleId: PropTypes.node,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(styles)(AddTransferDialogContent);
