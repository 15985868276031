import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import moment from 'moment';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';

import { PersonContext } from '../contexts/PersonContext';
import client from '../feathers';
import { create, find, get, patch } from '../feathersWrapper';
import {
  asyncHandleChange,
  handleCheckboxChange,
  handleNumberFormatChange,
  handleTextFieldChange,
  handleToggleButtonChange,
} from '../functions/InputHandlers';
import { testMatchingRule } from '../functions/RegExpFunctions';
import { sumProperty } from '../functions/SumFunctions';
import history from '../history';

import {
  bankCreditTransactionTypes,
  bankDebitTransactionTypes,
  cardCreditTransactionTypes,
  cardDebitTransactionTypes,
  loanCreditTransactionTypes,
  loanDebitTransactionTypes,
} from './SearchSelect/TransactionTypeOptions';
import AddAirbnbDialogContent from './TransactionTemplates/AddAirbnbDialogContent';
import AddCreditCardPaymentDialogContent from './TransactionTemplates/AddCreditCardPaymentDialogContent';
import AddExpenseDialogContent from './TransactionTemplates/AddExpenseDialogContent';
import AddFixedAssetDialogContent from './TransactionTemplates/AddFixedAssetDialogContent';
import AddLoanPaymentDialogContent from './TransactionTemplates/AddLoanPaymentDialogContent';
import AddNetIncomeDialogContent from './TransactionTemplates/AddNetIncomeDialogContent';
import AddPartnerDepositDialogContent from './TransactionTemplates/AddPartnerDepositDialogContent';
import AddRefundDialogContent from './TransactionTemplates/AddRefundDialogContent';
import AddRevenueDialogContent from './TransactionTemplates/AddRevenueDialogContent';
import AddTransferDialogContent from './TransactionTemplates/AddTransferDialogContent';
import AddVrboDialogContent from './TransactionTemplates/AddVrboDialogContent';
import AddYodleeTransactionDialogContent from './TransactionTemplates/AddYodleeTransactionDialogContent';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  red: {
    color: theme.palette.error.main,
  },
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
  aButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddImportedTransactionDialog extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { yodleeTransactionId } = this.props;
    const { organizationId } = this.context;

    let hasMatchOptions = false;
    let loanTemplateMatch = [];
    let importedTransactionRuleMatch = [];
    const yodleeTransaction = await get(this, 'yodlee-transactions', yodleeTransactionId);
    const ownerFundsResult = await find(this, 'accounts', { query: { organizationId, default: 'ownerFunds' } });
    if (yodleeTransaction.journalLineId) {
      this.onAddTransaction();
      this.setState({ loading: false, key: 'alreadyBooked' });
      return;
    }

    const incomeTemplateMatch = [];
    let showAirbnbMessage = false;
    let showVRBOMessage = false;

    const transactionTypes = this.getTransactionTypes(yodleeTransaction);

    const amount = {};
    if (yodleeTransaction.yodleeType === 'DEBIT') {
      amount.credit = yodleeTransaction.amount;
    } else {
      amount.debit = yodleeTransaction.amount;
    }

    // find potential matching transactions
    const journalLineMatchQurey = {
      organizationId,
      accountId: yodleeTransaction.accountId,
      yodleeTransactionId: null,
      ...amount,
      startDate: moment(yodleeTransaction.date).subtract(21, 'days').format('YYYY-MM-DD'),
      endDate: moment(yodleeTransaction.date).add(5, 'days').format('YYYY-MM-DD'),
    };
    const journalLineMatchPromise = find(this, 'journal-lines', { query: journalLineMatchQurey });

    // find potential owner fund transactions
    const ownerFundsMatchQurey = {
      organizationId,
      accountId: ownerFundsResult.data[0].id,
      yodleeTransactionId: null,
      ...amount,
      startDate: moment(yodleeTransaction.date).subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment(yodleeTransaction.date).add(7, 'days').format('YYYY-MM-DD'),
    };
    const ownerFundsMatchPromise = find(this, 'journal-lines', { query: ownerFundsMatchQurey });

    if (['Bank', 'Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(yodleeTransaction.account.type2)) {
      // find potential matching loan templates
      const loanTemplateMatchQurey = {
        organizationId,
        mortgagePaymentAmount: yodleeTransaction.amount,
        type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
      };

      if (yodleeTransaction.account.type2 === 'Bank') {
        loanTemplateMatchQurey.mortgagePaymentAccountId = yodleeTransaction.accountId;
      } else {
        loanTemplateMatchQurey.id = yodleeTransaction.accountId;
      }

      // search if not positive bank account flow
      if (!(yodleeTransaction.account.type2 === 'Bank' && yodleeTransaction.yodleeType === 'CREDIT')) {
        const loanTemplateSearch = await find(this, 'accounts', { query: loanTemplateMatchQurey });
        loanTemplateMatch = loanTemplateSearch.data;
      }
    }

    if (['Bank', 'Credit Card'].includes(yodleeTransaction.account.type2) && yodleeTransaction.yodleeType === 'DEBIT') {
      // find potential matching templates
      const importedTransactionRuleMatchCriteria = {
        reportName: 'importedTransactionRuleMatch',
        organizationId,
        description: yodleeTransaction.description,
        type: ['Expense', 'Fixed Asset Purchase', 'Transfer To', 'Split'],
        transactionAmount: yodleeTransaction.amount,
        analysisAccountId: yodleeTransaction.account.id,
        // destinationAccountId: { $ne: yodleeTransaction.accountId },
      };

      importedTransactionRuleMatch = await create(this, 'reports', importedTransactionRuleMatchCriteria);
    }

    if (yodleeTransaction.account.type2 === 'Bank' && yodleeTransaction.yodleeType === 'CREDIT') {
      // check for Airbnb
      if (yodleeTransaction.description && yodleeTransaction.description.toLowerCase().includes('airbnb')) {
        incomeTemplateMatch.push({
          name: 'Airbnb',
          type: 'Net Income',
          matched: false,
        });
        const airbnbAccountResult = await find(this, 'accounts', {
          query: {
            organizationId,
            default: 'airbnb',
          },
        });
        showAirbnbMessage = airbnbAccountResult.data[0].inactive;
      }
      // check for VRBO
      if (yodleeTransaction.description && yodleeTransaction.description.toLowerCase().includes('vrbo')) {
        incomeTemplateMatch.push({
          name: 'VRBO',
          type: 'Net Income',
          matched: false,
        });
        const vrboAccountResult = await find(this, 'accounts', {
          query: {
            organizationId,
            default: 'vrbo',
          },
        });
        showVRBOMessage = vrboAccountResult.data[0].inactive;
      }
      // find potential matching templates
      const importedTransactionRuleMatchCriteria = {
        reportName: 'importedTransactionRuleMatch',
        organizationId,
        description: yodleeTransaction.description,
        type: ['Revenue', 'Transfer From', 'Split'],
        transactionAmount: yodleeTransaction.amount,
        analysisAccountId: yodleeTransaction.account.id,
        // destinationAccountId: { $ne: yodleeTransaction.accountId },
      };

      importedTransactionRuleMatch = await create(this, 'reports', importedTransactionRuleMatchCriteria);
    }

    const journalSearch = await journalLineMatchPromise;
    const ownerFundsSearch = await ownerFundsMatchPromise;

    journalSearch.data.forEach((match) => {
      match.matched = false;
    });
    ownerFundsSearch.data.forEach((match) => {
      match.matched = false;
    });
    loanTemplateMatch.forEach((match) => {
      match.matched = false;
    });
    importedTransactionRuleMatch.forEach((match) => {
      match.matched = false;
    });
    incomeTemplateMatch.forEach((match) => {
      match.matched = false;
    });

    let inputMethod = 'new';
    if (journalSearch.data.length) {
      if (journalSearch.data.length === 1) {
        journalSearch.data[0].matched = true;
      }
      inputMethod = 'match';
    } else if (ownerFundsSearch.data.length) {
      if (ownerFundsSearch.data.length === 1) {
        ownerFundsSearch.data[0].matched = true;
      }
      inputMethod = 'match';
    } else if (loanTemplateMatch.length) {
      if (loanTemplateMatch.length === 1) {
        loanTemplateMatch[0].matched = true;
      }
      inputMethod = 'match';
    } else if (importedTransactionRuleMatch.length) {
      if (importedTransactionRuleMatch.length === 1) {
        importedTransactionRuleMatch[0].matched = true;
      }
      inputMethod = 'match';
    } else if (incomeTemplateMatch.length) {
      if (incomeTemplateMatch.length === 1) {
        incomeTemplateMatch[0].matched = true;
      }
      inputMethod = 'match';
    }
    if (yodleeTransaction.amount === 0) {
      inputMethod = 'ignore';
    }
    if (inputMethod === 'match') {
      hasMatchOptions = true;
    }

    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      helpMessage: '',
      yodleeTransaction,
      key: 'selectTransactionType',
      transactionTypeSelect: null,
      inputMethod,
      hasMatchOptions,
      transactionTypes,
      split: [this.getSplitLine(), this.getSplitLine()],
      journalMatch: journalSearch.data,
      ownerFundsMatch: ownerFundsSearch.data,
      loanTemplateMatch,
      importedTransactionRuleMatch,
      incomeTemplateMatch,
      showAirbnbMessage,
      showVRBOMessage,
      loanAccountId: null,
      loanAccountName: null,
      ruleId: null,
      splitRule: {
        type: 'Split',
        createRule: false,
        matchAmount: true,
        matchDescription: false,
        descriptionContains: '',
        autobookMatchingTransactions: true,
        applyToAllAccounts: false,
      },
      ignoreRule: {
        type: 'Ignore',
        createRule: false,
        matchAmount: false,
        matchDescription: false,
        descriptionContains: '',
        autobookMatchingTransactions: true,
        applyToAllAccounts: false,
      },
    };

    this.setState(initialState);
  };

  getSplitLine = () => ({ key: generateHash(), amount: null });

  getDialogContent = (key) => {
    const { classes } = this.props;
    const {
      error,
      yodleeTransaction,
      inputMethod,
      transactionTypes,
      split,
      journalMatch,
      ownerFundsMatch,
      hasMatchOptions,
      loanTemplateMatch,
      importedTransactionRuleMatch,
      incomeTemplateMatch,
      showAirbnbMessage,
      showVRBOMessage,
      helpMessage,
      splitRule,
      ignoreRule,
      autobookedTransactionCount,
    } = this.state;

    switch (key) {
      case 'selectTransactionType':
        return (
          <form onSubmit={this.submitSelectTransactionType}>
            <DialogTitle id="alert-dialog-title">
              Book Transaction
              {!yodleeTransaction.splitParentId && (
                <IconButton
                  className={classes.deleteIconButton}
                  aria-label="delete"
                  onClick={() => this.setState({ key: 'confirmDelete' })}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              <Box position="absolute" top={20} right={16} component="span">
                <Button
                  onClick={() => this.setState({ key: 'getHelp' })}
                  variant="outlined"
                  size="small"
                  color="secondary"
                >
                  Help
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Transaction
                </FormLabel>
                <Typography>
                  {moment(yodleeTransaction.date).format('M/D/YYYY')}
                  <Box px={2} component="span">
                    |
                  </Box>
                  <NumberFormat
                    displayType="text"
                    value={yodleeTransaction.amount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                  />
                  <Box px={2} component="span">
                    |
                  </Box>
                  {yodleeTransaction.account.name}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel required shrink style={{ position: 'relative' }} component={InputLabel}>
                  Action
                </FormLabel>
                <input
                  tabIndex={-1}
                  onChange={() => {}}
                  autoComplete="off"
                  style={{ position: 'absolute', opacity: 0, height: 0 }}
                  value={inputMethod !== null ? inputMethod : ''}
                  required
                />
                <ToggleButtonGroup
                  value={inputMethod}
                  onChange={handleToggleButtonChange('inputMethod', this)}
                  exclusive
                >
                  <ToggleButton value="new">Create</ToggleButton>
                  {hasMatchOptions && <ToggleButton value="match">Match</ToggleButton>}
                  <ToggleButton value="split">Split</ToggleButton>
                  <ToggleButton value="ignore">Ignore</ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
              {inputMethod === 'new' && (
                <Box marginTop={1}>
                  <ButtonGroup
                    color="secondary"
                    orientation="vertical"
                    variant="outlined"
                    size="large"
                    fullWidth
                    aria-label="transaction type button group"
                  >
                    {transactionTypes.map((type) => (
                      <Button
                        key={type.label}
                        onClick={() =>
                          this.setState({
                            key: 'newTransaction',
                            transactionTypeSelect: type,
                          })
                        }
                      >
                        {type.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              )}
              {inputMethod === 'match' && (
                <>
                  {(journalMatch.length > 0 || ownerFundsMatch.length > 0) && (
                    <FormControl margin="dense" fullWidth>
                      <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                        Match Existing Transactions
                      </FormLabel>
                      {journalMatch.length > 0 && (
                        <FormGroup>
                          {journalMatch.map((match, index) => (
                            <FormControlLabel
                              key={match.id}
                              control={
                                <Checkbox
                                  checked={match.matched}
                                  name={`nested_journalMatch_${index}_matched`}
                                  onChange={handleCheckboxChange(this)}
                                />
                              }
                              label={
                                <>
                                  {match.journal.description}
                                  <Box color="text.secondary">
                                    {moment(match.journal.date).format('M/D/YYYY')}
                                    <Box px={2} component="span">
                                      |
                                    </Box>
                                    <NumberFormat
                                      displayType="text"
                                      value={match.credit ? match.credit : match.debit}
                                      thousandSeparator
                                      prefix="$"
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  </Box>
                                </>
                              }
                            />
                          ))}
                        </FormGroup>
                      )}
                      {ownerFundsMatch.length > 0 && (
                        <FormGroup>
                          {ownerFundsMatch.map((match, index) => (
                            <FormControlLabel
                              key={match.id}
                              control={
                                <Checkbox
                                  checked={match.matched}
                                  name={`nested_ownerFundsMatch_${index}_matched`}
                                  onChange={handleCheckboxChange(this)}
                                />
                              }
                              label={
                                <>
                                  {match.journal.description}
                                  <Box color="text.secondary">
                                    {moment(match.journal.date).format('M/D/YYYY')}
                                    <Box px={2} component="span">
                                      |
                                    </Box>
                                    <NumberFormat
                                      displayType="text"
                                      value={match.credit ? match.credit : match.debit}
                                      thousandSeparator
                                      prefix="$"
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                    <Box px={2} component="span">
                                      |
                                    </Box>
                                    Owner Funds
                                  </Box>
                                </>
                              }
                            />
                          ))}
                        </FormGroup>
                      )}
                    </FormControl>
                  )}
                  {(loanTemplateMatch.length > 0 ||
                    importedTransactionRuleMatch.length > 0 ||
                    incomeTemplateMatch.length > 0) && (
                    <FormControl margin="dense" fullWidth>
                      <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                        Create From Template
                      </FormLabel>
                      <FormGroup>
                        {loanTemplateMatch.map((match, index) => (
                          <FormControlLabel
                            key={match.id}
                            control={
                              <Checkbox
                                checked={match.matched}
                                name={`nested_loanTemplateMatch_${index}_matched`}
                                onChange={handleCheckboxChange(this)}
                              />
                            }
                            label={`Loan Payment: ${match.name}${match.property ? ` (${match.property.address1})` : ''}`}
                          />
                        ))}
                        {importedTransactionRuleMatch.map((match, index) => (
                          <FormControlLabel
                            key={match.id}
                            control={
                              <Checkbox
                                checked={match.matched}
                                name={`nested_importedTransactionRuleMatch_${index}_matched`}
                                onChange={handleCheckboxChange(this)}
                              />
                            }
                            label={
                              <>
                                {`${match.type}: ${match.destinationAccount ? match.destinationAccount.name : ''} `}
                                {match.descriptionContains !== '' && `(${match.descriptionContains}) `}
                                {match.transactionAmount !== null && (
                                  <NumberFormat
                                    displayType="text"
                                    value={match.transactionAmount}
                                    thousandSeparator
                                    prefix="($"
                                    suffix=")"
                                    decimalScale={2}
                                    fixedDecimalScale
                                  />
                                )}
                              </>
                            }
                          />
                        ))}
                        {incomeTemplateMatch.map((match, index) => (
                          <FormControlLabel
                            key={match.name}
                            control={
                              <Checkbox
                                checked={match.matched}
                                name={`nested_incomeTemplateMatch_${index}_matched`}
                                onChange={handleCheckboxChange(this)}
                              />
                            }
                            label={`${match.type}:  ${match.name}`}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                  {showAirbnbMessage && (
                    <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                      <Typography variant="body2">
                        {'You can upload your Airbnb transactions to REI Hub. '}
                        <ButtonBase component="span" className={classes.aButton} onClick={this.activateAirbnb}>
                          Get started
                        </ButtonBase>
                      </Typography>
                    </Box>
                  )}
                  {showVRBOMessage && (
                    <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                      <Typography variant="body2">
                        {'You can upload your VRBO transactions to REI Hub. '}
                        <ButtonBase component="span" className={classes.aButton} onClick={this.activateVRBO}>
                          Get started
                        </ButtonBase>
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              {inputMethod === 'split' && (
                <>
                  <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2">Transactions</Typography>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {split.map((splitLine, index) => (
                          <TableRow key={splitLine.key}>
                            <TableCell>{`Transaction ${index + 1}`}</TableCell>
                            <TableCell className={classes.amountCell}>
                              <FormControl margin="dense" fullWidth>
                                <InputLabel required>Amount</InputLabel>
                                <NumberFormat
                                  value={split[index].amount}
                                  thousandSeparator
                                  required
                                  prefix="$"
                                  decimalScale={2}
                                  onValueChange={handleNumberFormatChange(`nested_split_${index}_amount`, this)}
                                  customInput={Input}
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>
                            <Button onClick={this.addSplitLine} color="primary">
                              Add Line
                            </Button>
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              displayType="text"
                              value={sumProperty(split, 'amount')}
                              thousandSeparator
                              prefix="Total: $"
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Box>
                  <Box>
                    <FormControl margin="dense">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={splitRule.createRule}
                            onChange={handleCheckboxChange(this)}
                            name="nested_splitRule_createRule"
                          />
                        }
                        label="Create a new transaction matching rule"
                      />
                    </FormControl>
                    {splitRule.createRule && (
                      <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                        <FormControl margin="none" fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox checked={splitRule.matchAmount} disabled name="nested_splitRule_matchAmount" />
                            }
                            label="Match by amount"
                          />
                        </FormControl>
                        <FormControl margin="none" fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={splitRule.matchDescription}
                                onChange={handleCheckboxChange(this)}
                                name="nested_splitRule_matchDescription"
                              />
                            }
                            label="Match by description"
                          />
                        </FormControl>
                        {splitRule.matchDescription && (
                          <>
                            <Typography variant="body2">
                              {`Enter a matching phrase contained in the imported description.
                      The imported description was: ${yodleeTransaction.description}`}
                            </Typography>
                            <TextField
                              label="Description Contains"
                              fullWidth
                              required
                              margin="dense"
                              InputProps={{
                                value: splitRule.descriptionContains,
                                name: 'nested_splitRule_descriptionContains',
                                onChange: handleTextFieldChange(this),
                              }}
                            />
                          </>
                        )}
                        <FormControl margin="none" fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={splitRule.autobookMatchingTransactions}
                                onChange={handleCheckboxChange(this)}
                                name="nested_splitRule_autobookMatchingTransactions"
                              />
                            }
                            label="Automatically book matching transactions"
                          />
                        </FormControl>
                        <FormControl margin="none" fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={splitRule.applyToAllAccounts}
                                onChange={handleCheckboxChange(this)}
                                name="nested_splitRule_applyToAllAccounts"
                              />
                            }
                            label="Apply this rule to all accounts"
                          />
                        </FormControl>
                        {!splitRule.applyToAllAccounts && (
                          <Typography variant="body2">
                            {`This rule will be applied only to transactions
                        in ${yodleeTransaction.account.name}`}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {inputMethod === 'ignore' && (
                <Box>
                  <FormControl margin="dense">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ignoreRule.createRule}
                          onChange={handleCheckboxChange(this)}
                          name="nested_ignoreRule_createRule"
                        />
                      }
                      label="Create a new transaction matching rule"
                    />
                  </FormControl>
                  {ignoreRule.createRule && (
                    <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                      <FormControl margin="none" fullWidth>
                        <FormControlLabel
                          control={<Checkbox checked={ignoreRule.matchAmount} name="nested_ignoreRule_matchAmount" />}
                          onChange={handleCheckboxChange(this)}
                          label="Match by amount"
                        />
                      </FormControl>
                      <FormControl margin="none" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ignoreRule.matchDescription}
                              onChange={handleCheckboxChange(this)}
                              name="nested_ignoreRule_matchDescription"
                            />
                          }
                          label="Match by description"
                        />
                      </FormControl>
                      {ignoreRule.matchDescription && (
                        <>
                          <Typography variant="body2">
                            {`Enter a matching phrase contained in the imported description.
                          The imported description was: ${yodleeTransaction.description}`}
                          </Typography>
                          <TextField
                            label="Description Contains"
                            fullWidth
                            required
                            margin="dense"
                            InputProps={{
                              value: ignoreRule.descriptionContains,
                              name: 'nested_ignoreRule_descriptionContains',
                              onChange: handleTextFieldChange(this),
                            }}
                          />
                        </>
                      )}
                      <FormControl margin="none" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ignoreRule.autobookMatchingTransactions}
                              onChange={handleCheckboxChange(this)}
                              name="nested_ignoreRule_autobookMatchingTransactions"
                            />
                          }
                          label="Automatically book matching transactions"
                        />
                      </FormControl>
                      <FormControl margin="none" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ignoreRule.applyToAllAccounts}
                              onChange={handleCheckboxChange(this)}
                              name="nested_ignoreRule_applyToAllAccounts"
                            />
                          }
                          label="Apply this rule to all accounts"
                        />
                      </FormControl>
                      {!ignoreRule.applyToAllAccounts && (
                        <Typography variant="body2">
                          {`This rule will be applied only to transactions
                        in ${yodleeTransaction.account.name}`}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              {inputMethod && inputMethod !== 'new' && (
                <Button type="submit" variant="contained" color="primary" disableElevation>
                  Save Transaction
                </Button>
              )}
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        );
      case 'autoBookResult':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Rule Created</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Your rule was created. ${autobookedTransactionCount} additional transactions
                  matched the template and were ${inputMethod === split ? 'split' : 'ignored'}
                  automatically.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        );
      case 'newTransaction':
        return this.getTransactionDialogContent();
      case 'confirmDelete':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Transaction?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">This transaction will be removed.</DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteTransaction} className={classes.deleteConfirmationButton}>
                Delete Transaction
              </Button>
            </DialogActions>
          </>
        );
      case 'getHelp':
        return (
          <form onSubmit={this.getHelp}>
            <DialogTitle id="alert-dialog-title">Get Help</DialogTitle>
            <DialogContent>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Transaction
                </FormLabel>
                <Typography>
                  {moment(yodleeTransaction.date).format('M/D/YYYY')}
                  <Box px={2} component="span">
                    |
                  </Box>
                  <NumberFormat
                    displayType="text"
                    value={yodleeTransaction.amount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                  />
                  <Box px={2} component="span">
                    |
                  </Box>
                  {yodleeTransaction.account.name}
                </Typography>
              </FormControl>
              <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} my={2}>
                <Typography variant="body1">
                  {`In a sentence or two, please tell us what this transaction is for
                    or how we can assist you.`}
                </Typography>
              </Box>
              <TextField
                label="Description"
                helperText="We typically respond within one business day"
                fullWidth
                multiline
                required
                rows="4"
                variant="filled"
                color="secondary"
                margin="dense"
                InputProps={{
                  value: helpMessage,
                  name: 'helpMessage',
                  onChange: handleTextFieldChange(this),
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Submit
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        );
      case 'helpSubmitted':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Get Help</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Thank you. We have received your message and will be in touch soon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      case 'alreadyBooked':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Transaction Booked</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This transaction has already been booked
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  };

  resetTransactionType = (type) => {
    this.setState({
      transactionTypeSelect: { label: type, value: type },
    });
  };

  getTransactionDialogContent = () => {
    const { transactionTypeSelect, yodleeTransaction, loanAccountId, loanAccountName, ruleId } = this.state;
    switch (transactionTypeSelect.value) {
      case 'Expense':
        return (
          <AddExpenseDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            propertyId={yodleeTransaction.account.propertyId}
            ruleId={ruleId}
            resetTransactionType={this.resetTransactionType}
          />
        );
      case 'Revenue':
        return (
          <AddRevenueDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            propertyId={yodleeTransaction.account.propertyId}
            ruleId={ruleId}
            resetTransactionType={this.resetTransactionType}
          />
        );
      case 'Net Income':
        return (
          <AddNetIncomeDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            propertyId={yodleeTransaction.account.propertyId}
          />
        );
      case 'Loan Payment':
        return (
          <AddLoanPaymentDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            loanAccountId={loanAccountId}
            loanAccountName={loanAccountName}
          />
        );
      case 'Fixed Asset Purchase':
        return (
          <AddFixedAssetDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            ruleId={ruleId}
            resetTransactionType={this.resetTransactionType}
          />
        );
      case 'Airbnb':
        return (
          <AddAirbnbDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            propertyId={yodleeTransaction.account.propertyId}
          />
        );
      case 'VRBO':
        return (
          <AddVrboDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            propertyId={yodleeTransaction.account.propertyId}
          />
        );
      case 'Partner Deposit':
        return (
          <AddPartnerDepositDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
          />
        );
      case 'Refund':
        return (
          <AddRefundDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            propertyId={yodleeTransaction.account.propertyId}
          />
        );
      case 'Transfer':
        return (
          <AddTransferDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            ruleId={ruleId}
          />
        );
      case 'Transfer To':
        return (
          <AddTransferDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            ruleId={ruleId}
          />
        );
      case 'Transfer From':
        return (
          <AddTransferDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            ruleId={ruleId}
          />
        );
      case 'Credit Card Payment':
        return (
          <AddCreditCardPaymentDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            yodleeTransaction={yodleeTransaction}
            ruleId={ruleId}
          />
        );
      default:
        return (
          <AddYodleeTransactionDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            transactionTypeSelect={transactionTypeSelect}
            yodleeTransaction={yodleeTransaction}
          />
        );
    }
  };

  getTransactionTypes = (yodleeTransaction) => {
    const { partnerName } = this.context;
    if (yodleeTransaction.account.type === 'Asset') {
      if (yodleeTransaction.yodleeType === 'DEBIT') {
        return bankDebitTransactionTypes;
      }
      if (partnerName) {
        const partnerBankCreditTransactionTypes = [...bankCreditTransactionTypes];
        partnerBankCreditTransactionTypes.unshift({
          label: `${partnerName} Deposit`,
          value: 'Partner Deposit',
        });
        return partnerBankCreditTransactionTypes;
      }
      return bankCreditTransactionTypes;
    }
    if (
      yodleeTransaction.account.type === 'Liability' &&
      ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(yodleeTransaction.account.type2)
    ) {
      if (yodleeTransaction.yodleeType === 'CREDIT') {
        return loanCreditTransactionTypes;
      }
      return loanDebitTransactionTypes;
    }
    if (yodleeTransaction.account.type === 'Liability' && yodleeTransaction.account.type2 === 'Credit Card') {
      if (yodleeTransaction.yodleeType === 'CREDIT') {
        return cardCreditTransactionTypes;
      }
      return cardDebitTransactionTypes;
    }
    return [];
  };

  activateAirbnb = async (event) => {
    event.preventDefault();
    const { setContextState, airbnbAccountId } = this.context;

    await patch(this, 'accounts', airbnbAccountId, {
      inactive: false,
    });
    setContextState({ airbnbAccountActive: true });
    const airbnbUrl = `/accounts/airbnb/${airbnbAccountId}`;
    history.push(airbnbUrl);
  };

  activateVRBO = async (event) => {
    event.preventDefault();
    const { setContextState, vrboAccountId } = this.context;

    await patch(this, 'accounts', vrboAccountId, {
      inactive: false,
    });
    setContextState({ vrboAccountActive: true });
    const vrboUrl = `/accounts/vrbo/${vrboAccountId}`;
    history.push(vrboUrl);
  };

  addSplitLine = () => {
    const { split } = this.state;
    this.setState({ split: split.concat([this.getSplitLine()]) });
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  onAddTransaction = () => {
    const { onAddTransaction } = this.props;
    onAddTransaction();
  };

  submitSelectTransactionType = (event) => {
    event.preventDefault();
    const { inputMethod } = this.state;
    if (inputMethod === 'match') {
      return this.matchYodleeTransaction();
    }
    if (inputMethod === 'split') {
      return this.splitYodleeTransaction();
    }
    if (inputMethod === 'ignore') {
      return this.ignoreYodleeTransaction();
    }
    return null;
  };

  matchYodleeTransaction = async () => {
    const { onAddTransaction } = this.props;
    const {
      submitting,
      yodleeTransaction,
      journalMatch,
      ownerFundsMatch,
      loanTemplateMatch,
      importedTransactionRuleMatch,
      incomeTemplateMatch,
    } = this.state;

    if (submitting) {
      return;
    }

    let amountSelected = 0;
    journalMatch.forEach((match) => {
      if (match.matched) {
        amountSelected += match.credit;
        amountSelected += match.debit;
      }
    });
    ownerFundsMatch.forEach((match) => {
      if (match.matched) {
        amountSelected += match.credit;
        amountSelected += match.debit;
      }
    });

    // if no matched selected, check template matches
    if (amountSelected === 0) {
      let loanAccountId = null;
      let rule = null;
      let incomeTemplateName = null;
      let loanAccountName = null;

      // check loan templates
      loanTemplateMatch.forEach((template) => {
        if (template.matched) {
          loanAccountId = template.id;
          loanAccountName = template.name;
        }
      });
      if (loanAccountId) {
        this.setState({
          loanAccountId,
          loanAccountName,
          key: 'newTransaction',
          transactionTypeSelect: { label: 'Loan Payment', value: 'Loan Payment' },
        });
        return;
      }

      // check rules
      importedTransactionRuleMatch.forEach((template) => {
        if (template.matched) {
          rule = template;
        }
      });
      if (rule) {
        if (rule.type === 'Split') {
          const split = rule.splitAmounts.map((amount) => {
            const splitLine = this.getSplitLine();
            splitLine.amount = amount;
            return splitLine;
          });
          await asyncHandleChange('split', split, this);
          this.splitYodleeTransaction();
          return;
        }
        this.setState({
          ruleId: rule.id,
          key: 'newTransaction',
          transactionTypeSelect: { label: rule.type, value: rule.type },
        });
        return;
      }

      // check templates
      incomeTemplateMatch.forEach((template) => {
        if (template.matched) {
          incomeTemplateName = template.name;
        }
      });
      if (incomeTemplateName) {
        this.setState({
          key: 'newTransaction',
          transactionTypeSelect: { label: incomeTemplateName, value: incomeTemplateName },
        });
        return;
      }
    }

    if (amountSelected !== yodleeTransaction.amount) {
      this.setState({ error: { message: 'Please select a match or template.' } });
      return;
    }
    this.setState({ submitting: true });

    const journalLinesPromises = [];
    journalMatch.forEach((match) => {
      if (match.matched) {
        journalLinesPromises.push(
          patch(this, 'journal-lines', match.id, {
            yodleeTransactionId: yodleeTransaction.id,
          }),
        );
        journalLinesPromises.push(
          patch(this, 'yodlee-transactions', yodleeTransaction.id, {
            journalId: match.journalId,
            journalLineId: match.id,
          }),
        );
      }
    });
    ownerFundsMatch.forEach((match) => {
      if (match.matched) {
        journalLinesPromises.push(
          patch(this, 'journal-lines', match.id, {
            accountId: yodleeTransaction.accountId,
            yodleeTransactionId: yodleeTransaction.id,
          }),
        );
        journalLinesPromises.push(
          patch(this, 'yodlee-transactions', yodleeTransaction.id, {
            journalId: match.journalId,
            journalLineId: match.id,
          }),
        );
      }
    });
    await Promise.all(journalLinesPromises);
    this.closeDialog();
    onAddTransaction();
  };

  splitYodleeTransaction = async () => {
    const { onAddTransaction } = this.props;
    const { submitting, yodleeTransaction, split, splitRule } = this.state;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    if (sumProperty(split, 'amount') === (-yodleeTransaction.amount).toFixed(2)) {
      split.forEach((line) => {
        line.amount = -line.amount;
      });
    }

    if (splitRule.createRule) {
      if (splitRule.matchDescription) {
        if (!testMatchingRule(splitRule.descriptionContains, yodleeTransaction.description)) {
          this.setState({
            error: {
              message: `The imported transaction description does not contain the matching rule text.
              Please update the rule so it is contained within the text: ${yodleeTransaction.description}`,
            },
          });
          return;
        }
      }
    }

    if (sumProperty(split, 'amount') !== yodleeTransaction.amount.toFixed(2)) {
      this.setState({ error: { message: 'The split amount must total the original transaction amount' } });
      return;
    }

    this.setState({ submitting: true });

    const yodleeTransactionsService = client.service('yodlee-transactions');
    const splitPromises = [];
    // const splitNewTransactionIds = [];

    split.forEach((line) => {
      const newTransaction = {
        ...yodleeTransaction,
        amount: Math.abs(line.amount),
        splitParentId: yodleeTransaction.splitParentId ? yodleeTransaction.splitParentId : yodleeTransaction.id,
      };
      delete newTransaction.id;
      delete newTransaction.createdAt;
      if (line.amount < 0) {
        newTransaction.yodleeType = newTransaction.yodleeType === 'DEBIT' ? 'CREDIT' : 'DEBIT';
      }
      splitPromises.push(
        yodleeTransactionsService.create(newTransaction),
        /* .then((result) => {
          splitNewTransactionIds.push(result.id);
        }), */
      );
    });

    await Promise.all(splitPromises);

    if (yodleeTransaction.splitParentId) {
      await yodleeTransactionsService
        .remove(yodleeTransaction.id)
        .then((result) => {
          this.closeDialog();
          onAddTransaction(result);
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    } else {
      await yodleeTransactionsService
        .patch(yodleeTransaction.id, { split: true })
        .then(async (result) => {
          if (splitRule.createRule) {
            const splitAmounts = split.map((line) => line.amount);
            const ruleSubmit = { ...splitRule };
            ruleSubmit.splitAmounts = splitAmounts;
            ruleSubmit.organizationId = organizationId;
            ruleSubmit.transactionAmount = yodleeTransaction.amount;
            if (!splitRule.applyToAllAccounts) {
              ruleSubmit.analysisAccountId = yodleeTransaction.account.id;
            }

            await create(this, 'imported-transaction-rules', ruleSubmit, true).then(async (splitResult) => {
              if (splitRule.autobookMatchingTransactions) {
                const automationResult = await create(this, 'automations', {
                  organizationId,
                  automationName: 'splitRuleMatching',
                  ruleId: splitResult.id,
                });
                onAddTransaction(splitResult);
                this.setState({
                  key: 'autoBookResult',
                  autobookedTransactionCount: automationResult.newTransactions,
                });
              } else {
                this.closeDialog();
                onAddTransaction(splitResult);
              }
            });
          } else {
            this.closeDialog();
            onAddTransaction(result);
          }
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    }
  };

  ignoreYodleeTransaction = async () => {
    const { organizationId } = this.context;
    const { onAddTransaction } = this.props;
    const { submitting, yodleeTransaction, ignoreRule } = this.state;

    if (submitting) {
      return;
    }

    if (ignoreRule.createRule) {
      if (ignoreRule.matchDescription) {
        if (!testMatchingRule(ignoreRule.descriptionContains, yodleeTransaction.description)) {
          this.setState({
            error: {
              message: `The imported transaction description does not contain the matching rule text.
              Please update the rule so it is contained within the text: ${yodleeTransaction.description}`,
            },
          });
          return;
        }
      }

      if (!ignoreRule.matchAmount && !ignoreRule.matchDescription) {
        this.setState({ error: { message: 'Either the description or amount must be set to create a rule' } });
        return;
      }
    }

    this.setState({ submitting: true });

    const yodleeTransactionsService = client.service('yodlee-transactions');
    await yodleeTransactionsService.patch(yodleeTransaction.id, { ignored: true }).then(async (result) => {
      if (ignoreRule.createRule) {
        const ruleSubmit = { ...ignoreRule };
        ruleSubmit.organizationId = organizationId;
        if (ignoreRule.matchAmount) {
          ruleSubmit.transactionAmount = yodleeTransaction.amount;
        }
        if (!ignoreRule.applyToAllAccounts) {
          ruleSubmit.analysisAccountId = yodleeTransaction.account.id;
        }

        await create(this, 'imported-transaction-rules', ruleSubmit, true)
          .then(async (ignoreResult) => {
            if (ignoreRule.autobookMatchingTransactions) {
              const automationResult = await create(this, 'automations', {
                organizationId,
                automationName: 'ignoreRuleMatching',
                ruleId: ignoreResult.id,
              });
              onAddTransaction(ignoreResult);
              this.setState({
                key: 'autoBookResult',
                autobookedTransactionCount: automationResult.newTransactions,
              });
            } else {
              this.closeDialog();
              onAddTransaction(result);
            }
          })
          .catch((error) => {
            this.setState({ error });
            this.setState({ submitting: false });
          });
      } else {
        this.closeDialog();
        onAddTransaction(result);
      }
    });
  };

  deleteTransaction = () => {
    const { onAddTransaction } = this.props;
    const { submitting, yodleeTransaction } = this.state;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    patch(this, 'yodlee-transactions', yodleeTransaction.id, { deleted: true })
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  getHelp = async (event) => {
    event.preventDefault();

    const { submitting, yodleeTransaction, helpMessage } = this.state;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const message = `${helpMessage}
    (Transaction Details:
    ${moment(yodleeTransaction.date).format('M/D/YYYY')}, ${yodleeTransaction.amount}, ${yodleeTransaction.account.name},
    ${yodleeTransaction.description})`;

    this.setState({ submitting: true });

    await create(this, 'contact-us', {
      organizationId,
      personContext: this.context,
      subject: 'Transaction Help',
      message,
    });
    this.setState({ key: 'helpSubmitted' });
  };

  render() {
    const { isOpen } = this.props;
    const { loading, key } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEnforceFocus
        onClose={this.closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addTransactionDialog"
      >
        {!loading && this.getDialogContent(key)}
      </Dialog>
    );
  }
}

AddImportedTransactionDialog.contextType = PersonContext;

AddImportedTransactionDialog.defaultProps = {
  yodleeTransactionId: null,
};

AddImportedTransactionDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  yodleeTransactionId: PropTypes.node,
};

export default withStyles(styles)(AddImportedTransactionDialog);
