import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { find, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';

class CashFlowsByProperty extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }
    const properties = await find(this, 'properties', {
      query: {
        organizationId,
        $limit: 200,
        $sort: {
          address1: 1,
          id: 1,
        },
      },
    });

    const reportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
    });

    // launch async calls
    // get totals and prep value array and display toggle
    const accountsPromises = [create(this, 'reports', reportFilter)];
    // get data for each property
    properties.data.forEach((property) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...reportFilter,
          propertyId: property.id,
        }),
      );
    });

    // get unassigned data
    accountsPromises.push(
      create(this, 'reports', {
        ...reportFilter,
        propertyId: null,
      }),
    );

    // resolve promises
    const accountsResults = await Promise.all(accountsPromises);

    const accountsObject = {
      operations: {
        accounts: [],
        revenues: {
          accounts: [],
        },
        expenses: {
          accounts: [],
        },
        creditCards: {
          accounts: [],
        },
        escrows: {
          accounts: [],
        },
        securityDeposits: {
          accounts: [],
        },
        accountsReceivable: {
          accounts: [],
        },
        accountsPayable: {
          accounts: [],
        },
        prepaidExpenses: {
          accounts: [],
        },
        deferredRevenues: {
          accounts: [],
        },
      },
      investing: {
        accounts: [],
        revenues: {
          accounts: [],
        },
        fixedAssets: {
          accounts: [],
        },
        deposits: {
          accounts: [],
        },
        otherAssets: {
          accounts: [],
        },
        otherLiabilities: {
          accounts: [],
        },
      },
      financing: {
        accounts: [],
        loans: {
          accounts: [],
        },
        equity: {
          accounts: [],
        },
      },
      allIncluded: {
        accounts: [],
      },
      cash: {
        accounts: [],
        bank: {
          accounts: [],
        },
        propertyManagement: {
          accounts: [],
        },
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    accountsResults[0].forEach((account) => {
      /* eslint-disable no-param-reassign */
      account.values = [];
      account.display = account.netCredits !== 0;
      account.subaccounts = [];
      /* eslint-enable no-param-reassign */
      if (['Bank', 'Property Management'].includes(account.type2)) {
        if (account.type2 === 'Bank') {
          accountsObject.cash.bank.accounts.push(account);
        } else {
          accountsObject.cash.propertyManagement.accounts.push(account);
        }
        accountsObject.cash.accounts.push(account);
      } else {
        if (account.type2 === 'Depreciation Expense' || account.type2 === 'Accumulated Depreciation') {
          accountsObject.investing.fixedAssets.accounts.push(account);
          accountsObject.investing.accounts.push(account);
        } else if (account.type === 'Revenue') {
          if (account.type2 === 'Investing Revenue') {
            accountsObject.investing.revenues.accounts.push(account);
            accountsObject.investing.accounts.push(account);
          } else {
            accountsObject.operations.revenues.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          }
        } else if (account.type === 'Expense') {
          accountsObject.operations.expenses.accounts.push(account);
          accountsObject.operations.accounts.push(account);
        } else if (account.type === 'Asset') {
          if (account.type2 === 'Escrow') {
            accountsObject.operations.escrows.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (account.type2 === 'Accounts Receivable') {
            accountsObject.operations.accountsReceivable.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (account.type2 === 'Prepaid Expense') {
            accountsObject.operations.prepaidExpenses.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (account.type2 === 'Fixed Asset') {
            accountsObject.investing.fixedAssets.accounts.push(account);
            accountsObject.investing.accounts.push(account);
          } else if (account.type2 === 'Deposit Asset') {
            accountsObject.investing.deposits.accounts.push(account);
            accountsObject.investing.accounts.push(account);
          } else {
            accountsObject.investing.otherAssets.accounts.push(account);
            accountsObject.investing.accounts.push(account);
          }
        } else if (account.type === 'Liability') {
          if (account.type2 === 'Credit Card') {
            accountsObject.operations.creditCards.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (account.type2 === 'Security Deposits') {
            accountsObject.operations.securityDeposits.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (account.type2 === 'Accounts Payable') {
            accountsObject.operations.accountsPayable.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (account.type2 === 'Deferred Revenue') {
            accountsObject.operations.deferredRevenues.accounts.push(account);
            accountsObject.operations.accounts.push(account);
          } else if (['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(account.type2)) {
            accountsObject.financing.loans.accounts.push(account);
            accountsObject.financing.accounts.push(account);
          } else {
            accountsObject.investing.otherLiabilities.accounts.push(account);
            accountsObject.investing.accounts.push(account);
          }
        } else if (account.type === 'Equity') {
          accountsObject.financing.equity.accounts.push(account);
          accountsObject.financing.accounts.push(account);
        }
        accountsObject.allIncluded.accounts.push(account);
      }
      accountsObject.byId[account.id] = account;
    });

    accountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    // populate values array
    for (let i = 0; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        if (['Bank', 'Property Management'].includes(account.type2)) {
          accountsResults[0][index].values.push(-account.netCredits);
        } else {
          accountsResults[0][index].values.push(account.netCredits);
        }
        if (account.netCredits !== 0) {
          accountsResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const headers = properties.data.map((property) => property.address1);
    headers.unshift('Total');
    headers.push('None Assigned');

    await asyncSetState(
      {
        loading: false,
        headers,
        accountsObject,
      },
      this,
    );
    return { success: true };
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, headers, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        headers,
        exportOptions,
        reportName: 'Cash Flows By Property',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'cash-flows-by-property.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountsObject, headers, loading, filter, filterDialog, downloadDialog } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Cash Flows By Property"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          required={['dateRange']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine values={headers} bold />
                  <FinancialAccountLine label="Cash Flows from Operating" overline bold />
                  <FinancialAccountLine
                    label="Operating Revenues"
                    values={this.sumColumnValues(accountsObject.operations.revenues.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Cash Expenses"
                    values={this.sumColumnValues(accountsObject.operations.expenses.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Credit Card Balances"
                    values={this.sumColumnValues(accountsObject.operations.creditCards.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Escrow Account Balances"
                    values={this.sumColumnValues(accountsObject.operations.escrows.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Security Deposits"
                    values={this.sumColumnValues(accountsObject.operations.securityDeposits.accounts)}
                    indent={1}
                  />
                  {accountsObject.operations.accountsReceivable.accounts.length > 0 && (
                    <FinancialAccountLine
                      label="Accounts Receivable"
                      values={this.sumColumnValues(accountsObject.operations.accountsReceivable.accounts)}
                      indent={1}
                    />
                  )}
                  {accountsObject.operations.accountsPayable.accounts.length > 0 && (
                    <FinancialAccountLine
                      label="Accounts Payable"
                      values={this.sumColumnValues(accountsObject.operations.accountsPayable.accounts)}
                      indent={1}
                    />
                  )}
                  {accountsObject.operations.prepaidExpenses.accounts.length > 0 && (
                    <FinancialAccountLine
                      label="Prepaid Expenses"
                      values={this.sumColumnValues(accountsObject.operations.prepaidExpenses.accounts)}
                      indent={1}
                    />
                  )}
                  {accountsObject.operations.deferredRevenues.accounts.length > 0 && (
                    <FinancialAccountLine
                      label="Deferred Revenues"
                      values={this.sumColumnValues(accountsObject.operations.deferredRevenues.accounts)}
                      indent={1}
                    />
                  )}
                  <FinancialAccountLine
                    label="Net Cash Flows From Operating"
                    values={this.sumColumnValues(accountsObject.operations.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine label="Cash Flows from Investing" overline bold />
                  <FinancialAccountLine
                    label="Investing Revenues"
                    values={this.sumColumnValues(accountsObject.investing.revenues.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Fixed Assets"
                    values={this.sumColumnValues(accountsObject.investing.fixedAssets.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Deposits"
                    values={this.sumColumnValues(accountsObject.investing.deposits.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Other Assets"
                    values={this.sumColumnValues(accountsObject.investing.otherAssets.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Other Liabilities"
                    values={this.sumColumnValues(accountsObject.investing.otherLiabilities.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Net Cash Flows From Investing"
                    values={this.sumColumnValues(accountsObject.investing.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine label="Cash Flows from Financing" overline bold />
                  {accountsObject.financing.loans.accounts.length > 0 && (
                    <>
                      <FinancialAccountLine label="Loans" bold indent={1} />
                      {accountsObject.financing.loans.accounts.map((account) => (
                        <FinancialAccountLine
                          key={account.id}
                          accountId={account.id}
                          label={account.name}
                          values={account.values}
                          display={account.display && !account.parentAccountId}
                          subaccounts={account.subaccounts}
                          indent={2}
                          startDate={filter.startDate}
                          endDate={filter.endDate}
                        />
                      ))}
                      <FinancialAccountLine
                        label="Total Loans"
                        values={this.sumColumnValues(accountsObject.financing.loans.accounts)}
                        sumline
                        bold
                        indent={1}
                      />
                    </>
                  )}
                  {accountsObject.financing.equity.accounts.length > 0 && (
                    <>
                      <FinancialAccountLine label="Equity" bold indent={1} />
                      {accountsObject.financing.equity.accounts.map((account) => (
                        <FinancialAccountLine
                          key={account.id}
                          accountId={account.id}
                          label={account.name}
                          values={account.values}
                          display={account.display && !account.parentAccountId}
                          subaccounts={account.subaccounts}
                          indent={2}
                          startDate={filter.startDate}
                          endDate={filter.endDate}
                        />
                      ))}
                      <FinancialAccountLine
                        label="Total Equity"
                        values={this.sumColumnValues(accountsObject.financing.equity.accounts)}
                        sumline
                        bold
                        indent={1}
                      />
                    </>
                  )}
                  <FinancialAccountLine
                    label="Net Cash Flows From Financing"
                    values={this.sumColumnValues(accountsObject.financing.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine
                    label="Net Cash Flows"
                    values={this.sumColumnValues(accountsObject.allIncluded.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />

                  <FinancialAccountLine label="Cash Accounts" overline bold />
                  {accountsObject.cash.bank.accounts.length > 0 && (
                    <>
                      <FinancialAccountLine label="Bank Accounts" bold indent={1} />
                      {accountsObject.cash.bank.accounts.map((account) => (
                        <FinancialAccountLine
                          key={account.id}
                          accountId={account.id}
                          label={account.name}
                          values={account.values}
                          display={account.display && !account.parentAccountId}
                          subaccounts={account.subaccounts}
                          indent={2}
                          startDate={filter.startDate}
                          endDate={filter.endDate}
                        />
                      ))}
                      <FinancialAccountLine
                        label="Total Bank Accounts"
                        values={this.sumColumnValues(accountsObject.cash.bank.accounts)}
                        sumline
                        bold
                        indent={1}
                      />
                    </>
                  )}
                  {accountsObject.cash.propertyManagement.accounts.length > 0 && (
                    <>
                      <FinancialAccountLine label="Property Management" bold indent={1} />
                      {accountsObject.cash.propertyManagement.accounts.map((account) => (
                        <FinancialAccountLine
                          key={account.id}
                          accountId={account.id}
                          label={account.name}
                          values={account.values}
                          display={account.display && !account.parentAccountId}
                          subaccounts={account.subaccounts}
                          indent={2}
                          startDate={filter.startDate}
                          endDate={filter.endDate}
                        />
                      ))}
                      <FinancialAccountLine
                        label="Total Property Management"
                        values={this.sumColumnValues(accountsObject.cash.propertyManagement.accounts)}
                        sumline
                        bold
                        indent={1}
                      />
                    </>
                  )}
                  <FinancialAccountLine
                    label="Net Cash Accounts"
                    values={this.sumColumnValues(accountsObject.cash.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

CashFlowsByProperty.contextType = PersonContext;

CashFlowsByProperty.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CashFlowsByProperty;
