import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import PropTypes from 'prop-types';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { tooltipFormatter } from '~/functions/ChartFunctions';

import CardBase from '../../components/CardBase';
import CardDateFilterMenu from '../../components/CardDateFilterMenu';
// import LinkBase from '../../components/LinkBase';
import ErrorCard from '../../components/ErrorCard';
// import { Link as RouterLink } from 'react-router-dom';
import { PersonContext } from '../../contexts/PersonContext';
import { create } from '../../feathersWrapper';

const styles = (theme) => ({
  durationLabel: {
    color: theme.palette.action.active,
  },
});

class ExpensesCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: {
        startDate: moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
        filterText: 'Last 12 Months',
      },
      stale: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const { stale } = this.state;
    if (stale) {
      this.getData();
      this.setState({ stale: false });
    }
  }

  getData = async () => {
    const { view, id } = this.props;
    const { organizationId, basis } = this.context;
    const { filter } = this.state;
    const query = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      type: 'Expense',
      startDate: filter.startDate,
      endDate: filter.endDate,
    };

    if (view === 'property') {
      query.propertyId = id;
    } else if (view === 'unit') {
      query.unitId = id;
    } else if (view === 'subportfolio') {
      query.entityId = id;
    }

    const expenseAccountsPromise = create(this, 'reports', query);
    const expenseAccounts = await expenseAccountsPromise;
    let totalExpenses = 0;

    expenseAccounts.sort((a, b) => a.netCredits - b.netCredits);
    const expenseGraphData = [];
    expenseAccounts.forEach((account) => {
      totalExpenses -= account.netCredits;
      if (expenseGraphData.length < 4) {
        if (account.netCredits !== 0) {
          expenseGraphData.push({
            name: account.name,
            amount: -account.netCredits,
          });
          if (expenseGraphData.length === 3) {
            expenseGraphData.push({
              name: 'Other',
              amount: 0.0,
            });
          }
        }
      } else {
        expenseGraphData[3].amount -= account.netCredits;
      }
    });

    this.setState({
      totalExpenses,
      expenseGraphData,
      loading: false,
    });
  };

  render() {
    const { loading, error, totalExpenses, expenseGraphData, filter } = this.state;
    const { classes } = this.props;
    // const { theme, view, id } = this.props;

    const expenseColors = ['#4F7CAC', '#8EDCE6', '#8884D8', '#CCC'];

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Expenses
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    if (error) {
      return <ErrorCard error={error.message} />;
    }
    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Box display="flex" flexDirection="row">
              <Box>
                <Typography variant="h6" gutterBottom>
                  Expenses
                </Typography>
              </Box>
              <Box flexGrow={1} />
              <CardDateFilterMenu
                setFilter={(newValue) => {
                  this.setState({ filter: newValue });
                }}
                setStale={(newValue) => {
                  this.setState({ stale: newValue });
                }}
              />
            </Box>
            <Typography variant="h4">
              <NumberFormat
                displayType="text"
                value={totalExpenses}
                thousandSeparator
                prefix="$"
                decimalScale={0}
                fixedDecimalScale
              />
              <Box marginLeft={1} component="span">
                <Typography variant="overline" component="span" className={classes.durationLabel}>
                  {filter.filterText}
                </Typography>
              </Box>
            </Typography>
            {expenseGraphData.length > 0 && (
              <ResponsiveContainer width="100%" height={275}>
                <PieChart
                  margin={{
                    top: 20,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <Pie
                    cx="50%"
                    dataKey="amount"
                    data={expenseGraphData}
                    fill="#8884d8"
                    label={false}
                    innerRadius="70%"
                    outerRadius="100%"
                    isAnimationActive={false}
                  >
                    {expenseGraphData.map((entry, index) => (
                      <Cell key={`cell-${entry.name}`} fill={expenseColors[index % expenseColors.length]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value, name, props) =>
                      tooltipFormatter(value, name, {
                        ...props,
                        shouldShowDefaultColor: true,
                      })
                    }
                  />
                  <Legend
                    verticalAlign="bottom"
                    wrapperStyle={{
                      paddingTop: 20,
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

ExpensesCard.contextType = PersonContext;

ExpensesCard.defaultProps = {
  id: null,
};

ExpensesCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  view: PropTypes.string.isRequired,
  id: PropTypes.node,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(ExpensesCard);
