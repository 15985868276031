import { getCreditJournalLine, getDebitJournalLine } from '~/components/SearchSelect/TransactionTypeOptions';
import { get } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';

/**
 *
 * @param {object} journalData: { journal: journal object, organizationId: organizationId from
 * context, direction: 'cash in' or 'cash out', transactionId: yodlee transaction id, type:
 * transaction type string, bookAsRefund: boolean to book as refund if amount is negative }
 * @returns journal object for submit
 */
export const setUpJournalForSubmit = (journalData) => {
  const journalSubmit = { ...journalData.journal };
  if (journalSubmit.journalScopeSelect) {
    setJournalScope(journalSubmit);
  }
  journalSubmit.organizationId = journalData.organizationId;
  journalSubmit.debitLines[0].debit = journalSubmit.amount;
  journalSubmit.creditLines[0].credit = journalSubmit.amount;
  if (journalData.direction === 'cash out') {
    journalSubmit.debitLines[0].accountId = journalSubmit.debitLines[0].accountIdSelect.id;
    journalSubmit.creditLines[0].accountId = journalSubmit.creditLines[0].accountIdSelect.id;
    journalSubmit.creditLines[0].yodleeTransactionId = journalData.transactionId;
  } else if (journalData.direction === 'cash in') {
    journalSubmit.creditLines[0].accountId = journalSubmit.creditLines[0].accountIdSelect.id;
    journalSubmit.debitLines[0].accountId = journalSubmit.debitLines[0].accountIdSelect.id;
    journalSubmit.debitLines[0].yodleeTransactionId = journalData.transactionId;
  }
  journalSubmit.journalLines = journalSubmit.debitLines.concat(journalSubmit.creditLines);

  journalSubmit.vendorId = journalSubmit.vendorIdSelect ? journalSubmit.vendorIdSelect.id : null;
  journalSubmit.type = journalData.type;

  if (journalData.bookAsRefund) {
    if (journalSubmit.amount < 0) {
      // book as a refund
      journalSubmit.type = journalData.direction === 'cash out' ? 'Refund' : 'Refund Issued';
      const newJournalLines = [];
      journalSubmit.journalLines.forEach((line) => {
        if (line.type === 'credit') {
          const newLine = getDebitJournalLine();
          newLine.debit = -line.credit;
          newLine.accountId = line.accountId;
          newJournalLines.push(newLine);
        } else {
          const newLine = getCreditJournalLine();
          newLine.credit = -line.debit;
          newLine.accountId = line.accountId;
          newJournalLines.push(newLine);
        }
      });
      journalSubmit.journalLines = newJournalLines;
    }
  }

  return journalSubmit;
};

export const setUpRuleJournalScope = async (match, journalObject, organizationName) => {
  if (match.entityId && !match.propertyId) {
    const entity = await get('entities', match.entityId);
    journalObject.entityIdSelect = entity;
    journalObject.journalScopeSelect = { name: 'Sub-Portfolio', id: 'Sub-Portfolio' };
  } else if (match.propertyId) {
    const property = await get('properties', match.propertyId);
    journalObject.propertyIdSelect = property;
    if (property.unitSelection === 'multi' && !match.unitId) {
      journalObject.unitIdSelect = { name: 'All Units', id: 'All' };
    } else if (match.unitId) {
      const unit = await get('units', match.unitId);
      journalObject.unitIdSelect = unit;
    }
    journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
  } else {
    journalObject.journalScopeSelect = {
      name: `${organizationName} Portfolio`,
      id: `${organizationName} Portfolio`,
    };
  }
  return journalObject;
};
