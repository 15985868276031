import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '~/components/Button';
import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { PersonContext } from '~/contexts/PersonContext';
import { patch } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  setupCompleteImage: {
    height: 'auto',
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '300px',
  },
}));

export default function SetupComplete() {
  const { organizationId, partnerName, setIsTourVisible, setIsScheduleCallDialogVisible, setOrganizationId } =
    useContext(PersonContext);
  const { userOnboarding } = useOnboardingContext();
  const tracking = useSegmentTrack();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  const finishOnboarding = async () => {
    await patch('organizations', organizationId, {
      showWelcome: false,
    });
    await setOrganizationId(organizationId);

    // set the threshold for the number of units to offer an onboarding call
    // onboarding calls must also not be disabled by the boolean in the dashboard layout
    const hasEnoughUnitsForOnboardingCall = userOnboarding?.totalUnits >= 0;
    const isNotRentRedi = partnerName !== 'RentRedi';
    const isOnboardingCallTestGroup = organizationId % 3 === 0;

    setIsTourVisible(true);
    if (hasEnoughUnitsForOnboardingCall && isOnboardingCallTestGroup && isNotRentRedi) {
      setIsScheduleCallDialogVisible(true);
    }

    history.push('/properties');
  };

  return (
    <DialogContent>
      <Box mx="auto" textAlign="center" mb={2}>
        <img alt="A happy user on a laptop" src="/onboarding-success.webp" className={classes.setupCompleteImage} />
        <Typography variant={isSmallScreen ? 'h6' : 'h5'}>Your portfolio setup is complete!</Typography>
      </Box>
      <Box mb={1} textAlign="center">
        <Typography variant="body1">
          {`Now we'll guide you around REI Hub, the tool that'll make tracking your rental property finances
                easier than ever before.`}
        </Typography>
      </Box>
      <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
        <Button
          hasFullWidth
          variant="contained"
          onClick={() => {
            tracking('sounds_good clicked', { location: 'Onboarding - Complete' });
            finishOnboarding();
          }}
        >
          Sounds good
        </Button>
      </Box>
    </DialogContent>
  );
}
