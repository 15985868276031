import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';

class OperatingExpenseRatio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        entityId: null,
        propertyId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const query = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const accountsObject = {
      revenues: {
        accounts: [],
      },
      expenses: {
        accounts: [],
      },
      allIncluded: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };
    // launch async calls
    // let accounts = await create(this, 'reports', query)
    await create(this, 'reports', query).then((result) => {
      /* eslint-disable no-param-reassign */
      result.forEach((account) => {
        account.display = account.netCredits !== 0;
        account.subaccounts = [];
        account.values = [account.netCredits];
        if (
          account.type2 === 'Investing Revenue' ||
          account.type2 === 'Interest Expense' ||
          account.type2 === 'Depreciation Expense'
        ) {
          accountsObject.excluded.accounts.push(account);
        } else if (account.type === 'Expense') {
          accountsObject.expenses.accounts.push(account);
          accountsObject.allIncluded.accounts.push(account);
          account.values = [-account.netCredits];
        } else if (account.type === 'Revenue') {
          accountsObject.revenues.accounts.push(account);
          accountsObject.allIncluded.accounts.push(account);
        }
        accountsObject.byId[account.id] = account;
      });
      /* eslint-enable no-param-reassign */
      return result;
    });

    const headers = [null];

    await asyncSetState(
      {
        loading: false,
        headers,
        accountsObject,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  /* exportPdf = async () => {
    const { organizationId, accountingFirmId } = this.context;
    const { location } = this.props;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-pdf`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        jwt: localStorage.getItem('feathers-jwt'),
        organizationId,
        accountingFirmId,
        page: `${location.pathname}${location.search}`,
      }),
    })
      .then(async (resp) => {
        const blob = new Blob([await resp.blob()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'net-income.pdf';
        document.body.appendChild(a);
        a.click();
      });
  }

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        const blob = new Blob(
          [await resp.blob()],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'net-operating-income.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  } */

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    // {
    //   text: 'Export',
    //   action: (() => this.setState({ downloadDialog: true })),
    //   class: 'export'
    // },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const { accountsObject, filter, filterDialog, loading, downloadDialog } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Operating Expense Ratio"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" maxWidth="600px">
                  <FinancialAccountLine label="Operating Income" underline overline bold />
                  <FinancialAccountLine
                    label="Operating Expenses"
                    values={this.sumColumnValues(accountsObject.expenses.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine
                    label="Operating Revenues"
                    values={this.sumColumnValues(accountsObject.revenues.accounts)}
                    indent={1}
                    marginBottom
                  />
                  <FinancialAccountLine
                    label="Operating Expense Ratio"
                    values={
                      this.sumColumnValues(accountsObject.revenues.accounts) > 0
                        ? [
                            (this.sumColumnValues(accountsObject.expenses.accounts) * 100) /
                              this.sumColumnValues(accountsObject.revenues.accounts),
                          ]
                        : ['N/A']
                    }
                    prefix=""
                    suffix="%"
                    underline
                    overline
                    bold
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

OperatingExpenseRatio.contextType = PersonContext;

OperatingExpenseRatio.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OperatingExpenseRatio;
