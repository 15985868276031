import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../../contexts/PersonContext';
import { create, get, remove } from '../../feathersWrapper';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleTextFieldChange,
  handleTransactionScopeChange,
  handleUploaderChange,
} from '../../functions/InputHandlers';
import { setInitialJournalScope, setJournalScope } from '../../functions/JournalFunctions';
import { testMatchingRule } from '../../functions/RegExpFunctions';
import AddVendorDialog from '../AddVendorDialog';
import { getAccountOptions, getPaymentAccountOptions, getVendorOptions, nameLabel } from '../Autocomplete/Library';
import {
  getCreditJournalLine,
  getDebitJournalLine,
  getManualBasicJournal,
} from '../SearchSelect/TransactionTypeOptions';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  red: {
    color: theme.palette.error.main,
  },
  aButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddExpenseDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { yodleeTransaction } = this.props;
    const { propertyId, unitId, propertyManagerId, bankAccountId, ruleId } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      addVendorDialog: false,
      showCapitalizationBox: false,
      autobookResult: false,
      rule: {
        createRule: false,
        matchDescription: false,
        matchAmount: false,
        descriptionContains: '',
        autobookMatchingTransactions: true,
        applyToAllAccounts: false,
      },
      dislpayNotesSection: false,
      error: null,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Expense';
    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

    if (yodleeTransaction) {
      journal.creditLines[0].accountIdSelect = yodleeTransaction.account;
      journal.creditLines[0].yodleeTransactionId = yodleeTransaction.id;
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;
      journal.amount = yodleeTransaction.amount;
    }

    if (ruleId) {
      const rule = await get(this, 'imported-transaction-rules', ruleId);
      journal.debitLines[0].accountIdSelect = rule.destinationAccount;
      journal.unitId = rule.unitId;
      journal.propertyId = rule.propertyId;
      journal.entityId = rule.entityId;
      await setInitialJournalScope(journal, this);
      if (rule.vendor) {
        journal.vendorIdSelect = rule.vendor;
      }
    } else if (unitId) {
      const unit = await get(this, 'units', unitId);
      journal.propertyIdSelect = unit.property;
      journal.unitIdSelect = unit;
    } else if (propertyId) {
      const property = await get(this, 'properties', propertyId);
      journal.propertyIdSelect = property;
    } else if (propertyManagerId) {
      const propertyManager = await get(this, 'property-managers', propertyManagerId);
      journal.creditLines[0].accountIdSelect = propertyManager.account;
    } else if (bankAccountId) {
      const account = await get(this, 'accounts', bankAccountId);
      journal.creditLines[0].accountIdSelect = account;
      if (account.property) {
        journal.propertyIdSelect = account.property;
      }
    }

    initialState.journal = journal;
    initialState.expenseAccountOptions = await getAccountOptions(this, {
      $or: [{ type: 'Expense' }, { type2: 'Sales and Use Tax' }],
    });
    initialState.paymentAccountOptions = await getPaymentAccountOptions(this);
    initialState.vendorOptions = await getVendorOptions(this, true);

    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const { submitting, journal, rule } = this.state;

    const { onAddTransaction, yodleeTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    if (!journal.amount) {
      this.setState({ error: { message: 'Please enter a non-zero amount for this transaction' } });
      return;
    }

    if (journal.debitLines[0].accountIdSelect.type2 === 'Depreciation Expense') {
      if (yodleeTransaction) {
        this.setState({
          error: {
            message: `Depreciation is not a cash expense and should not be entered from the import
                      feed.`,
          },
        });
      } else {
        this.setState({
          error: {
            message: `Depreciation is not a cash expense and should be entered using the Depreciation
                      transaction type. Alternatively, use the Fixed Asset Purchase transaction type if you made
                      capital expenditures that will be depreciated in the future.`,
          },
        });
      }
      return;
    }

    if (rule.createRule) {
      if (!rule.matchDescription && !rule.matchAmount) {
        this.setState({
          error: {
            message: 'Please select a description or amount to create a matching rule',
          },
        });
        return;
      }
      if (rule.matchDescription) {
        if (!testMatchingRule(rule.descriptionContains, yodleeTransaction.description)) {
          this.setState({
            error: {
              message: `The imported transaction description does not contain the matching rule text.
              Please update the rule so it is contained within the text: ${yodleeTransaction.description}`,
            },
          });
          return;
        }
      }
    }

    this.setState({ submitting: true });

    setJournalScope(journal);

    journal.organizationId = organizationId;
    journal.debitLines[0].debit = journal.amount;
    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;
    journal.creditLines[0].credit = journal.amount;
    journal.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
    journal.vendorId = journal.vendorIdSelect ? journal.vendorIdSelect.id : null;
    journal.type = 'Expense';
    journal.journalLines = journal.debitLines.concat(journal.creditLines);
    if (journal.amount < 0) {
      // book as a refund
      journal.type = 'Refund';
      const newJournalLines = [];
      journal.journalLines.forEach((line) => {
        if (line.type === 'credit') {
          const newLine = getDebitJournalLine();
          newLine.debit = -line.credit;
          newLine.accountId = line.accountId;
          newJournalLines.push(newLine);
        } else {
          const newLine = getCreditJournalLine();
          newLine.credit = -line.debit;
          newLine.accountId = line.accountId;
          newJournalLines.push(newLine);
        }
      });
      journal.journalLines = newJournalLines;
    }

    // errors will be displayed within the dialog rather than throwing to the error boundary
    create(this, 'journals', journal, true)
      .then(async (journalResult) => {
        if (rule.createRule) {
          const ruleSubmit = {
            type: 'Expense',
            organizationId,
            destinationAccountId: journal.debitLines[0].accountId,
            entityId: journal.entityId,
            propertyId: journal.propertyId,
            unitId: journal.unitId,
            vendorId: journal.vendorId,
            transactionAmount: rule.matchAmount ? journal.amount : null,
            descriptionContains: rule.matchDescription ? rule.descriptionContains : '',
            autobookMatchingTransactions: rule.autobookMatchingTransactions,
            analysisAccountId: rule.applyToAllAccounts ? null : yodleeTransaction.account.id,
          };

          await create(this, 'imported-transaction-rules', ruleSubmit, true)
            .then(async (ruleResult) => {
              if (ruleSubmit.autobookMatchingTransactions) {
                const automationResult = await create(this, 'automations', {
                  organizationId: ruleSubmit.organizationId,
                  automationName: 'bookRuleMatching',
                  ruleId: ruleResult.id,
                });
                onAddTransaction(journalResult);
                if (automationResult.newTransactions) {
                  this.setState({
                    autobookResult: true,
                    autobookedTransactionCount: automationResult.newTransactions,
                  });
                } else {
                  this.closeDialog();
                }
              } else {
                onAddTransaction(journalResult);
                this.closeDialog();
              }
            })
            .catch((error) => {
              remove(this, 'journals', journalResult.id, true);
              this.setState({ error });
              this.setState({ submitting: false });
            });
        } else {
          onAddTransaction(journalResult);
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  getDepreciationTypeWarning = () => {
    const { yodleeTransaction, resetTransactionType, classes } = this.props;

    if (yodleeTransaction) {
      return (
        <Typography>
          {`Depreciation is not a cash expense and should not be entered from the import feed. If you made
            capital expenditures that will be depreciated in the future use the `}
          <ButtonBase
            component="span"
            className={classes.aButton}
            onClick={() => {
              resetTransactionType('Fixed Asset Purchase');
            }}
          >
            Fixed Asset Purchase transaction type.
          </ButtonBase>
        </Typography>
      );
    }
    return (
      <Typography variant="body2">
        {'Depreciation is not a cash expense and should be entered in REI Hub using the '}
        <ButtonBase
          component="span"
          className={classes.aButton}
          onClick={() => {
            resetTransactionType('Depreciation');
          }}
        >
          Depreciation transaction type.
        </ButtonBase>
        {' If you made capital expenditures that will be depreciated in the future record them using the '}
        <ButtonBase
          component="span"
          className={classes.aButton}
          onClick={() => {
            resetTransactionType('Fixed Asset Purchase');
          }}
        >
          Fixed Asset Purchase transaction type.
        </ButtonBase>
      </Typography>
    );
  };

  render() {
    const { classes, ruleId, yodleeTransaction, resetTransactionType } = this.props;
    const { vendorTracking } = this.context;
    const {
      loading,
      error,
      journal,
      addVendorDialog,
      rule,
      expenseAccountOptions,
      paymentAccountOptions,
      vendorOptions,
      showCapitalizationBox,
      autobookResult,
      autobookedTransactionCount,
      displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    if (addVendorDialog) {
      return (
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={() => this.setState({ addVendorDialog: false })}
          onAddVendor={async (newValue) => {
            await asyncHandleChange('vendorOptions', await getVendorOptions(this, true), this);
            await asyncHandleChange('nested_journal_vendorIdSelect', await get(this, 'vendors', newValue.id), this);
          }}
        />
      );
    }

    if (autobookResult) {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Rule Created</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {`Your rule was created. ${autobookedTransactionCount} additional transactions matched the template and were booked automatically.`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">Add Expense</DialogTitle>
        <DialogContent>
          {yodleeTransaction && (
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction
              </FormLabel>
              <Typography>
                {moment(yodleeTransaction.date).format('M/D/YYYY')}
                <Box px={2} component="span">
                  |
                </Box>
                <NumberFormat
                  displayType="text"
                  value={yodleeTransaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                />
                <Box px={2} component="span">
                  |
                </Box>
                {yodleeTransaction.account.name}
              </Typography>
            </FormControl>
          )}
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel required>Amount</InputLabel>
            <NumberFormat
              value={journal.amount}
              required
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={handleNumberFormatChange('nested_journal_amount', this)}
              customInput={Input}
            />
            {journal.amount > 2500 && !showCapitalizationBox && (
              <FormHelperText>
                <Typography variant="inherit">
                  {'You may need to capitalize expenses over $2,500. '}
                  <ButtonBase
                    component="span"
                    className={classes.aButton}
                    onClick={() => {
                      this.setState({
                        showCapitalizationBox: true,
                      });
                    }}
                  >
                    Learn More
                  </ButtonBase>
                </Typography>
              </FormHelperText>
            )}
            {journal.amount < 0 && (
              <FormHelperText>
                <Typography color="error" variant="inherit">
                  {`Entering a negative number will create the transaction as a refund received.
                      Use a positive number if you would like to enter an expense.`}
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
          {showCapitalizationBox === true && (
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <Typography variant="body2">
                {`The IRS generally requires you to capitalize items over $2,500 which improve or restore your property.
                  This includes renovations and new or replacement tangible property, such as appliances. `}
                <ButtonBase
                  component="span"
                  className={classes.aButton}
                  onClick={() => {
                    resetTransactionType('Fixed Asset Purchase');
                  }}
                >
                  Use a fixed asset purchase
                </ButtonBase>
                {' to record these transactions in REI Hub.'}
              </Typography>
            </Box>
          )}
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          <Autocomplete
            options={expenseAccountOptions}
            getOptionLabel={nameLabel}
            value={journal.debitLines[0].accountIdSelect}
            onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Expense Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          {journal.debitLines[0].accountIdSelect &&
            journal.debitLines[0].accountIdSelect.type2 === 'Depreciation Expense' && (
              <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                {this.getDepreciationTypeWarning()}
              </Box>
            )}
          {!yodleeTransaction && (
            <Autocomplete
              options={paymentAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.creditLines[0].accountIdSelect}
              onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Payment Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          )}
          {vendorTracking && (
            <Autocomplete
              options={vendorOptions}
              getOptionLabel={nameLabel}
              value={journal.vendorIdSelect}
              onChange={handleAutocompleteChange('nested_journal_vendorIdSelect', this, () =>
                this.setState({ addVendorDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Vendor (optional)"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
          )}
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          {yodleeTransaction && !ruleId && (
            <FormControl margin="dense">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rule.createRule}
                    onChange={handleCheckboxChange(this)}
                    name="nested_rule_createRule"
                  />
                }
                label="Create a new transaction matching rule"
              />
            </FormControl>
          )}
          {rule.createRule && (
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.matchDescription}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_matchDescription"
                    />
                  }
                  label="Match by description"
                />
              </FormControl>
              {rule.matchDescription && (
                <>
                  <Typography variant="body2">
                    {`Enter a matching phrase contained in the imported description.
                        The imported description was: ${yodleeTransaction.description}`}
                  </Typography>
                  <TextField
                    label="Description Contains"
                    fullWidth
                    required
                    margin="dense"
                    InputProps={{
                      value: rule.descriptionContains,
                      name: 'nested_rule_descriptionContains',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                </>
              )}
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.matchAmount}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_matchAmount"
                    />
                  }
                  label="Match by amount"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.autobookMatchingTransactions}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_autobookMatchingTransactions"
                    />
                  }
                  label="Automatically book matching transactions"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rule.applyToAllAccounts}
                      onChange={handleCheckboxChange(this)}
                      name="nested_rule_applyToAllAccounts"
                    />
                  }
                  label="Apply this rule to all accounts"
                />
              </FormControl>
              {!rule.applyToAllAccounts && (
                <Typography variant="body2">
                  {`This rule will be applied only to transactions in ${yodleeTransaction.account.name}`}
                </Typography>
              )}
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Expense
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddExpenseDialogContent.contextType = PersonContext;

AddExpenseDialogContent.defaultProps = {
  yodleeTransaction: null,
  unitId: null,
  propertyId: null,
  propertyManagerId: null,
  bankAccountId: null,
  ruleId: null,
  resetTransactionType: () => {},
};

AddExpenseDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  propertyManagerId: PropTypes.node,
  bankAccountId: PropTypes.node,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
  ruleId: PropTypes.node,
  resetTransactionType: PropTypes.func,
};

export default withStyles(styles)(AddExpenseDialogContent);
