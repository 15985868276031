import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { PersonContext } from '~/contexts/PersonContext';
import { patch } from '~/feathersFunctionalWrapper';
import history from '~/history';

import useEntitiesMatchQuery from './hooks/useEntitiesMatchQuery';
import useMatchState from './hooks/useMatchState';
import useSelectedTransactionTypeEffects from './hooks/useSelectedTransactionTypeEffects';
import useTransactionTypesState from './hooks/useTransactionTypesState';
import { QuickBookRowContext } from './context';
import QuickBookRow from './QuickBookRow';

export default function QuickBookRowProvider({
  colCount,
  transaction,
  onAddTransaction,
  onCancelTransactionBookingClicked,
  removeQuickSelectLine,
}) {
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
  const [isGetHelpDialogOpen, setIsGetHelpDialogOpen] = useState(false);
  const [isAirbnbMessageVisible, setIsAirbnbMessageVisible] = useState(false);
  const [isVRBOMessageVisible, setIsVRBOMessageVisible] = useState(false);

  const { airbnbAccountId, organizationId, partnerName, settings, vrboAccountId, setContextState } =
    useContext(PersonContext);
  const { data, isLoading } = useEntitiesMatchQuery({
    organizationId,
    shouldHideSuggestRule: settings?.shouldHideSuggestRule,
    transaction,
    setIsAirbnbMessageVisible,
    setIsAlreadyBooked,
    setIsVRBOMessageVisible,
  });
  const matchState = useMatchState({
    descriptionMatches: data?.descriptionMatches,
    importedTransactionRuleMatch: data?.importedTransactionRuleMatch,
    incomeTemplateMatch: data?.incomeTemplateMatch,
    journalLinesMatch: data?.journalLinesMatch,
    loanTemplateMatch: data?.loanTemplateMatch,
    ownerFundsMatch: data?.ownerFundsMatch,
  });
  const transactionTypes = useTransactionTypesState({
    accountType: transaction.account.type,
    accountType2: transaction.account.type2,
    partnerName,
    yodleeType: transaction.yodleeType,
  });

  const onTransactionAction = useCallback(
    (shouldAddRule, journal, descriptionMatches) => {
      removeQuickSelectLine(transaction);
      onAddTransaction(shouldAddRule, journal, descriptionMatches);
    },
    [transaction, onAddTransaction, removeQuickSelectLine],
  );

  const onTransactionTypeChange = useCallback((transactionTypeSelect) => {
    setSelectedTransactionType(transactionTypeSelect);
  }, []);

  const onGetHelp = useCallback(() => {
    setIsGetHelpDialogOpen(true);
  }, []);

  const activateAirbnb = useCallback(async () => {
    await patch('accounts', airbnbAccountId, {
      inactive: false,
    });
    setContextState({ airbnbAccountActive: true });
    const airbnbUrl = `/accounts/airbnb/${airbnbAccountId}`;
    history.push(airbnbUrl);
  }, [airbnbAccountId, setContextState]);

  const activateVRBO = useCallback(async () => {
    await patch('accounts', vrboAccountId, {
      inactive: false,
    });
    setContextState({ vrboAccountActive: true });
    const vrboUrl = `/accounts/vrbo/${vrboAccountId}`;
    history.push(vrboUrl);
  }, [vrboAccountId, setContextState]);

  useSelectedTransactionTypeEffects({
    matchState,
    partnerName,
    selectedTransactionType,
    transactionAmount: transaction.amount,
    transactionTypes,
    setSelectedTransactionType,
  });

  const contextValue = useMemo(
    () => ({
      colCount,
      isAirbnbMessageVisible,
      isAlreadyBooked,
      isGetHelpDialogOpen,
      isLoading,
      isVRBOMessageVisible,
      matchState,
      selectedTransactionType,
      transaction,
      transactionTypes,
      activateAirbnb,
      activateVRBO,
      onCancelTransactionBookingClicked,
      onGetHelp,
      onTransactionAction,
      onTransactionTypeChange,
      setIsAirbnbMessageVisible,
      setIsGetHelpDialogOpen,
      setIsVRBOMessageVisible,
    }),
    [
      colCount,
      isAirbnbMessageVisible,
      isAlreadyBooked,
      isGetHelpDialogOpen,
      isLoading,
      isVRBOMessageVisible,
      matchState,
      selectedTransactionType,
      transaction,
      transactionTypes,
      activateAirbnb,
      activateVRBO,
      onCancelTransactionBookingClicked,
      onGetHelp,
      onTransactionAction,
      onTransactionTypeChange,
    ],
  );

  return (
    <QuickBookRowContext.Provider value={contextValue}>
      <QuickBookRow />
    </QuickBookRowContext.Provider>
  );
}

QuickBookRowProvider.propTypes = {
  colCount: PropTypes.number,
  transaction: PropTypes.object.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  onCancelTransactionBookingClicked: PropTypes.func,
  removeQuickSelectLine: PropTypes.func,
};
