import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import history from '../history';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';
import ViewEnableSubportfoliosDialog from '../components/ViewEnableSubportfoliosDialog';

class OperatingCashFlowBySubportfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      viewEnableSubportfoliosDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      additionalCriteria: [],
    };
  }

  async componentDidMount() {
    const { multiEntity } = this.context;

    if (!multiEntity) {
      this.setState({ viewEnableSubportfoliosDialog: true });
      return;
    }

    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const incomeQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const principalQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
      journalType: ['Loan Payment'],
    });

    const escrowQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Escrow'],
    });

    const entities = await find(this, 'entities', {
      query: {
        organizationId,
        $limit: 100,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    const accountsObject = {
      operatingRevenues: {
        accounts: [],
      },
      operatingExpenses: {
        accounts: [],
      },
      noi: {
        accounts: [],
      },
      interestExpenses: {
        accounts: [],
      },
      principalPaydown: {
        accounts: [],
      },
      debtService: {
        accounts: [],
      },
      escrows: {
        accounts: [],
      },
      allIncluded: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    const incomePromises = [
      await create(this, 'reports', incomeQuery).then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.display = false;
          account.subaccounts = [];
          account.values = [];
          if (account.type2 === 'Investing Revenue' || account.type2 === 'Depreciation Expense') {
            accountsObject.excluded.accounts.push(account);
          } else if (account.type2 === 'Interest Expense') {
            accountsObject.interestExpenses.accounts.push(account);
            accountsObject.debtService.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);
          } else if (account.type === 'Expense') {
            accountsObject.operatingExpenses.accounts.push(account);
            accountsObject.noi.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);
          } else if (account.type === 'Revenue') {
            accountsObject.operatingRevenues.accounts.push(account);
            accountsObject.noi.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);
          }
          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      }),
    ];

    const principalPromises = [
      await create(this, 'reports', principalQuery).then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.display = false;
          account.subaccounts = [];
          account.values = [];

          accountsObject.principalPaydown.accounts.push(account);
          accountsObject.debtService.accounts.push(account);
          accountsObject.allIncluded.accounts.push(account);

          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      }),
    ];

    const escrowPromises = [
      await create(this, 'reports', escrowQuery).then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.display = false;
          account.subaccounts = [];
          account.values = [];
          account.contributions = [];
          account.withdrawals = [];

          accountsObject.escrows.accounts.push(account);
          accountsObject.allIncluded.accounts.push(account);

          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      }),
    ];

    entities.data.forEach((entity) => {
      incomePromises.push(
        create(this, 'reports', {
          ...incomeQuery,
          entityId: entity.id,
        }),
      );
      principalPromises.push(
        create(this, 'reports', {
          ...principalQuery,
          entityId: entity.id,
        }),
      );
      escrowPromises.push(
        create(this, 'reports', {
          ...escrowQuery,
          entityId: entity.id,
        }),
      );
    });

    incomePromises.push(
      create(this, 'reports', {
        ...incomeQuery,
        entityId: null,
      }),
    );
    principalPromises.push(
      create(this, 'reports', {
        ...principalQuery,
        entityId: null,
      }),
    );
    escrowPromises.push(
      create(this, 'reports', {
        ...escrowQuery,
        entityId: null,
      }),
    );

    const incomeResults = await Promise.all(incomePromises);
    const principalResults = await Promise.all(principalPromises);
    const escrowResults = await Promise.all(escrowPromises);

    accountsObject.noi.accounts.forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    for (let i = 0; i < incomeResults.length; i += 1) {
      incomeResults[i].forEach((account, index) => {
        incomeResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          incomeResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    for (let i = 0; i < principalResults.length; i += 1) {
      principalResults[i].forEach((account, index) => {
        principalResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          principalResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    for (let i = 0; i < escrowResults.length; i += 1) {
      escrowResults[i].forEach((account, index) => {
        escrowResults[0][index].values.push(account.netCredits);
        escrowResults[0][index].contributions.push(-account.totalDebits);
        escrowResults[0][index].withdrawals.push(account.totalCredits);
        if (account.netCredits !== 0) {
          escrowResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const headers = entities.data.map((entity) => entity.name);
    headers.unshift('Total');
    headers.push('None Assigned');

    const additionalCriteriaArray = [{}];
    entities.data.forEach((entity) => {
      additionalCriteriaArray.push({ entityId: entity.id });
    });

    // Pushes final object into additional criteria object
    // for final column in report (none assigned)
    additionalCriteriaArray.push({
      entityId: 'None',
    });

    await asyncSetState(
      {
        loading: false,
        headers,
        accountsObject,
        additionalCriteria: additionalCriteriaArray,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  sumColumnValues = (accounts, property = 'values') => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account[property].forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        exportOptions,
        reportName: 'Operating Cash Flow by Sub-Portfolio',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'operating-cash-flow-by-subportfolio.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject,
      headers,
      filter,
      filterDialog,
      loading,
      viewEnableSubportfoliosDialog,
      downloadDialog,
      additionalCriteria,
    } = this.state;
    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Operating Cash Flow by Sub-Portfolio"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <ViewEnableSubportfoliosDialog isOpen={viewEnableSubportfoliosDialog} closeDialog={this.goToReports} />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          required={['dateRange']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <>
                  <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                    <FinancialAccountLine2 values={headers} bold />
                    <FinancialAccountLine2 label="Operating Income" underline overline bold />
                    {accountsObject.operatingRevenues.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Operating Revenues"
                      values={this.sumColumnValues(accountsObject.operatingRevenues.accounts)}
                      indent={1}
                      sumline
                      bold
                    />
                    {accountsObject.operatingExpenses.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Operating Expenses"
                      values={this.sumColumnValues(accountsObject.operatingExpenses.accounts)}
                      indent={1}
                      sumline
                      bold
                    />
                    <FinancialAccountLine2
                      label="Net Operating Income"
                      values={this.sumColumnValues(accountsObject.noi.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2 label="Debt Service" underline overline bold />
                    <FinancialAccountLine2
                      label="Interest Expenses"
                      values={this.sumColumnValues(accountsObject.interestExpenses.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Principal Repayment*"
                      values={this.sumColumnValues(accountsObject.principalPaydown.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Total Debt Service"
                      values={this.sumColumnValues(accountsObject.debtService.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2 label="Escrow Payments" underline overline bold />
                    <FinancialAccountLine2
                      label="Escrow Contributions"
                      values={this.sumColumnValues(accountsObject.escrows.accounts, 'contributions')}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Escrow Withdrawals"
                      values={this.sumColumnValues(accountsObject.escrows.accounts, 'withdrawals')}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Net Escrow Payments"
                      values={this.sumColumnValues(accountsObject.escrows.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2
                      label="Operating Cash Flow"
                      values={this.sumColumnValues(accountsObject.allIncluded.accounts)}
                      underline
                      overline
                      bold
                    />
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    // bgcolor="common.white"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      {`* Operating cash flow should include principal repayments that are part of your regular debt service. The Principal Repayments line totals Loan account activity recorded via the
                      Loan Payment transaction type. Use a Manual Journal to exclude a Loan transaction
                      from your operating cash flow.`}
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

OperatingCashFlowBySubportfolio.contextType = PersonContext;

OperatingCashFlowBySubportfolio.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OperatingCashFlowBySubportfolio;
