import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { get, patch, remove } from '../feathersWrapper';
import { handleTextFieldChange } from '../functions/InputHandlers';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
});

class EditSubportfolioDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    const { subportfolioId } = this.props;
    const subportfolio = await get(this, 'entities', subportfolioId);

    this.setState({
      subportfolio,
      confirmDelete: false,
      error: null,
      loading: false,
      submitting: false,
    });
  }

  editSubportfolio = async (event) => {
    event.preventDefault();
    const { submitting, subportfolio } = this.state;
    const { onEditSubportfolio, subportfolioId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    try {
      await patch(this, 'entities', subportfolioId, subportfolio);
      onEditSubportfolio();
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteSubportfolio = async (event) => {
    event.preventDefault();
    const { submitting } = this.state;
    const { onDeleteSubportfolio, subportfolioId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    try {
      await remove(this, 'entities', subportfolioId);
      onDeleteSubportfolio();
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { classes, closeDialog } = this.props;
    const { loading, error, subportfolio, confirmDelete } = this.state;

    return (
      <Dialog
        open
        scroll="body"
        disableBackdropClick
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editSubportfolio}>
            <DialogTitle id="alert-dialog-title">
              Edit Subportfolio
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                InputProps={{
                  value: subportfolio.name,
                  name: 'nested_subportfolio_name',
                  onChange: handleTextFieldChange(this),
                  required: true,
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Subportfolio
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <form onSubmit={this.deleteSubportfolio}>
            <DialogTitle id="alert-dialog-title">Really Delete Subportfolio?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`This subportfolio will be removed. All properties belonging to the subportfolio will remain in REI Hub.
                Any transactions booked to these properties will remain tagged at the property level.`}
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" className={classes.deleteConfirmationButton}>
                Delete Subportfolio
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

EditSubportfolioDialog.contextType = PersonContext;

EditSubportfolioDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditSubportfolio: PropTypes.func.isRequired,
  onDeleteSubportfolio: PropTypes.func.isRequired,
  subportfolioId: PropTypes.node.isRequired,
};

export default withStyles(styles)(EditSubportfolioDialog);
