import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import { Typography } from '@material-ui/core';
import history from '../history';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class OwnerStatementByProperty extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        entityId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityIdSelect: null,
      },
      additionalCriteria: [],
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const accountsChangeReportFilter = Object.assign(buildQuery(this), {
      basis,
      organizationId,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense', 'Equity'],
    });
    const startingAccountsReportFilter = Object.assign(buildQuery(this), {
      basis,
      organizationId,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense', 'Equity'],
    });

    startingAccountsReportFilter.date.$lte = moment(filter.startDate).subtract(1, 'days').format('YYYY-MM-DD');
    delete startingAccountsReportFilter.date.$gte;

    const propertiesQuery = {
      organizationId,
      $limit: 200,
      $sort: {
        address1: 1,
        id: 1,
      },
    };

    if (filter.entityId) {
      propertiesQuery.entityId = filter.entityId;
    }

    const properties = await find(this, 'properties', { query: propertiesQuery });

    const additionalCriteriaArray = [{}];
    properties.data.forEach((property) => {
      additionalCriteriaArray.push({
        propertyId: property.id,
      });
    });
    additionalCriteriaArray.push({
      propertyId: 'None',
    });

    const accountsObject = {
      revenues: {
        accounts: [],
      },
      expenses: {
        accounts: [],
      },
      income: {
        accounts: [],
      },
      ownerFunds: {
        accounts: [],
      },
      ownerFundsWithIncome: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    const accountsChangePromises = [create(this, 'reports', accountsChangeReportFilter)];

    const startingAccountsPromises = [create(this, 'reports', startingAccountsReportFilter)];

    properties.data.forEach((property) => {
      accountsChangePromises.push(
        create(this, 'reports', {
          ...accountsChangeReportFilter,
          propertyId: property.id,
        }),
      );

      startingAccountsPromises.push(
        create(this, 'reports', {
          ...startingAccountsReportFilter,
          propertyId: property.id,
        }),
      );
    });

    accountsChangePromises.push(
      create(this, 'reports', {
        ...accountsChangeReportFilter,
        propertyId: null,
      }),
    );

    startingAccountsPromises.push(
      create(this, 'reports', {
        ...startingAccountsReportFilter,
        propertyId: null,
      }),
    );

    const accountsChangeResults = await Promise.all(accountsChangePromises);
    accountsChangeResults[0].forEach((account) => {
      account.display = account.netCredits !== 0;
      account.subaccounts = [];
      account.values = [];
      if (account.type === 'Expense') {
        accountsObject.expenses.accounts.push(account);
        accountsObject.income.accounts.push(account);
        accountsObject.ownerFundsWithIncome.accounts.push(account);
      } else if (account.type === 'Revenue') {
        accountsObject.revenues.accounts.push(account);
        accountsObject.income.accounts.push(account);
        accountsObject.ownerFundsWithIncome.accounts.push(account);
      } else if (account.type2 === 'Owner Funds') {
        accountsObject.ownerFunds.accounts.push(account);
        accountsObject.ownerFundsWithIncome.accounts.push(account);
      } else {
        accountsObject.excluded.accounts.push(account);
      }
      accountsObject.byId[account.id] = account;
      accountsObject.byId[account.id].starting = [];
      accountsObject.byId[account.id].ending = [];
    });

    accountsChangeResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
        if (account.netCredits !== 0) {
          accountsObject.byId[account.parentAccountId].display = true;
        }
      }
    });

    for (let i = 0; i < accountsChangeResults.length; i += 1) {
      accountsChangeResults[i].forEach((account, index) => {
        accountsChangeResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          accountsChangeResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const startingAccountsResults = await Promise.all(startingAccountsPromises);

    for (let i = 0; i < startingAccountsResults.length; i += 1) {
      startingAccountsResults[i].forEach((result) => {
        const account = accountsObject.byId[result.id];
        account.starting.push(result.netCredits);
        account.ending.push(account.starting[i] + account.values[i]);
      });
    }

    const ownerFundsObject = {
      starting: [],
      ending: [],
    };

    for (let i = 0; i < accountsChangeResults.length; i += 1) {
      ownerFundsObject.starting.push(
        accountsObject.ownerFundsWithIncome.accounts.reduce((total, account) => total + account.starting[i], 0),
      );
      ownerFundsObject.ending.push(
        accountsObject.ownerFundsWithIncome.accounts.reduce((total, account) => total + account.ending[i], 0),
      );
    }

    const headers = properties.data.map((property) => property.address1);
    headers.unshift('Total');
    headers.push('None Assigned');

    await asyncSetState(
      {
        loading: false,
        headers,
        accountsObject,
        ownerFundsObject,
        additionalCriteria: additionalCriteriaArray,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, headers, ownerFundsObject, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        ownerFundsObject,
        headers,
        exportOptions,
        reportName: 'Owner Statement by Property',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'owner-statement-by-property.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      loading,
      filterDialog,
      downloadDialog,
      filter,
      headers,
      accountsObject,
      additionalCriteria,
      ownerFundsObject,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Owner Statement by Property"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine2 values={headers} bold />
                  <FinancialAccountLine2 label="Net Income" overline bold />
                  <FinancialAccountLine2 label="Revenues" indent={1} bold />
                  {accountsObject.revenues.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={2}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Revenues"
                    values={this.sumColumnValues(accountsObject.revenues.accounts)}
                    sumline
                    bold
                    indent={1}
                  />
                  <FinancialAccountLine2 label="Expenses" indent={1} bold />
                  {accountsObject.expenses.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={2}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Expenses"
                    values={this.sumColumnValues(accountsObject.expenses.accounts)}
                    sumline
                    bold
                    indent={1}
                  />
                  <FinancialAccountLine2
                    label="Net Income"
                    values={this.sumColumnValues(accountsObject.income.accounts)}
                    sumline
                    underline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2 label="Owner Funds" overline bold />
                  <FinancialAccountLine2 label="Starting Balance" values={ownerFundsObject.starting} indent={1} />
                  <FinancialAccountLine2
                    label="Net Income"
                    values={this.sumColumnValues(accountsObject.income.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine2
                    label="Net Distributions"
                    values={this.sumColumnValues(accountsObject.ownerFunds.accounts)}
                    indent={1}
                  />
                  <FinancialAccountLine2
                    label="Ending Balance*"
                    values={ownerFundsObject.ending}
                    indent={1}
                    sumline
                    bold
                    underline
                    marginBottom
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            bgcolor="common.white"
            padding={2}
            marginY={2}
          >
            <Typography variant="body2">
              {`* Owner funds differ from the owner cash balance if asset purchases, loan repayments, or non-cash expenses (such as depreciation) have been entered.
                In these cases use a balance sheet report.`}
            </Typography>
          </Box>
        </Grid>
      </PageGrid>
    );
  }
}

OwnerStatementByProperty.contextType = PersonContext;

OwnerStatementByProperty.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OwnerStatementByProperty;
