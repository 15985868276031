import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';
import PropTypes from 'prop-types';

import PageGrid from '~/components/PageGrid';

import AddBookedTransactionDialog from '../components/AddBookedTransactionDialog';
import AddFixedAssetDepeciationDialog from '../components/AddFixedAssetDepeciationDialog';
import CardBase from '../components/CardBase';
import EditBookedTransactionDialog from '../components/EditBookedTransactionDialog';
import EditFixedAssetDialog from '../components/EditFixedAssetDialog';
import ErrorCard from '../components/ErrorCard';
import FieldGrid from '../components/FieldGrid';
import PageHeader from '../components/PageHeader';
import SelectTransactionTypeDialog from '../components/SelectTransactionTypeDialog';
import ViewImportedTransactionDialog from '../components/ViewImportedTransactionDialog';
import { PersonContext } from '../contexts/PersonContext';
import { create, find, get } from '../feathersWrapper';
import { asyncHandleChange } from '../functions/InputHandlers';
import { TablePaginationButtons } from '../functions/TableFunctions';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
  headerContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
});

class FixedAsset extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editTransactionDialog: false,
      addTransactionDialog: false,
      selectTransactionTypeDialog: false,
      transactionType: null,
      viewImportedTransactionDialog: false,
      editFixedAssetDialog: false,
      pageSize: 5,
      addFixedAssetDepreciationDialog: false,
      filter: {
        fixedAssetTransactionsPage: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getAssetInfo();
  }

  getAssetInfo = async () => {
    const { organizationId, basis } = this.context;
    const { filter, pageSize } = this.state;
    const { match } = this.props;
    const { assetId } = match.params;

    const fixedAssetsPromise = get(this, 'fixed-assets', assetId);

    const assetAccountsQuery = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      fixedAssetId: assetId,
      type: 'Asset',
      type2: 'Fixed Asset',
    };

    const depreciationAccountsQuery = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      fixedAssetId: assetId,
      type: 'Asset',
      type2: 'Accumulated Depreciation',
    };

    const fixedAssetAccountsPromise = create(this, 'reports', assetAccountsQuery);
    const depreciationAccountsPromise = create(this, 'reports', depreciationAccountsQuery);

    const fixedAssetTransactionsPromise = find(this, 'journals', {
      query: {
        organizationId,
        basis,
        fixedAssetId: assetId,
        $limit: pageSize,
        $skip: filter.fixedAssetTransactionsPage * pageSize,
        $sort: {
          date: -1,
          amount: -1,
        },
      },
    });

    const fixedAsset = await fixedAssetsPromise;
    const fixedAssetAccounts = await fixedAssetAccountsPromise;
    const depreciationAccounts = await depreciationAccountsPromise;
    const fixedAssetTransactions = await fixedAssetTransactionsPromise;
    const nonZeroAssetAccounts = [];

    const fixedAssetTotals = {
      bookValue: 0,
      depreciable: 0,
      nonDepreciable: 0,
      startingBasis: 0,
      accumulatedDepreciation: 0,
    };
    fixedAssetAccounts.forEach((account) => {
      fixedAssetTotals.bookValue -= account.netCredits;
      fixedAssetTotals.startingBasis -= account.netCredits;
      if (account.nonDepreciable) {
        fixedAssetTotals.nonDepreciable -= account.netCredits;
      } else {
        fixedAssetTotals.depreciable -= account.netCredits;
      }
      if (account.netCredits) {
        nonZeroAssetAccounts.push(account);
      }
    });

    depreciationAccounts.forEach((account) => {
      fixedAssetTotals.bookValue -= account.netCredits;
      fixedAssetTotals.depreciable -= account.netCredits;
      fixedAssetTotals.accumulatedDepreciation -= account.netCredits;
      if (account.netCredits) {
        nonZeroAssetAccounts.push(account);
      }
    });

    this.setState({
      fixedAsset,
      fixedAssetTotals,
      fixedAssetAccounts: nonZeroAssetAccounts,
      fixedAssetTransactions,
      loading: false,
    });
  };

  formatNegative = (value, journalLine) => {
    const { classes } = this.props;
    if (!value) {
      return value;
    }
    if (journalLine.account.type === 'Asset') {
      if (journalLine.type === 'credit') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
      return value;
    }
    if (journalLine.account.type === 'Liability') {
      if (journalLine.type === 'debit') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
      return value;
    }
    return value;
  };

  handleChangeTransactionsPage = async (_event, newPage) => {
    await asyncHandleChange('nested_filter_fixedAssetTransactionsPage', newPage, this);
    this.getAssetInfo();
  };

  closeDialogs = () => {
    this.setState({
      editFixedAssetDialog: false,
      editTransactionDialog: false,
      viewImportedTransactionDialog: false,
      addFixedAssetDepreciationDialog: false,
    });
    this.getAssetInfo();
  };

  actionButtons = () => [
    { text: 'Edit', action: () => this.setState({ editFixedAssetDialog: true }), class: 'edit' },
    { text: 'Add Transaction', action: () => this.setState({ selectTransactionTypeDialog: true }), class: 'add' },
    { text: 'Add Depreciation', action: () => this.setState({ addFixedAssetDepreciationDialog: true }), class: 'add' },
  ];

  render() {
    const { classes, match } = this.props;
    const {
      fixedAsset,
      fixedAssetTotals,
      fixedAssetAccounts,
      fixedAssetTransactions,
      filter,
      pageSize,
      loading,
      error,
      journalId,
      editTransactionDialog,
      yodleeTransactionId,
      viewImportedTransactionDialog,
      editFixedAssetDialog,
      transactionType,
      addTransactionDialog,
      selectTransactionTypeDialog,
      addFixedAssetDepreciationDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    if (error) {
      return <ErrorCard error={error.message} />;
    }

    return (
      <>
        <Box className={classes.headerContainer}>
          <PageHeader match={match} title={fixedAsset.name} actionButtons={this.actionButtons()} />
        </Box>
        <PageGrid isMultiCard>
          <EditFixedAssetDialog
            assetId={fixedAsset.id}
            isOpen={editFixedAssetDialog}
            closeDialog={this.closeDialogs}
            onEditFixedAsset={this.getAssetInfo}
          />
          <SelectTransactionTypeDialog
            fixedAssetId={fixedAsset.id}
            isOpen={selectTransactionTypeDialog}
            closeDialog={() => this.setState({ selectTransactionTypeDialog: false })}
            selectTransactionType={(type) =>
              this.setState({
                transactionType: type,
                addTransactionDialog: true,
              })
            }
          />
          <AddBookedTransactionDialog
            assetId={fixedAsset.id}
            propertyId={fixedAsset.propertyId}
            type={transactionType}
            isOpen={addTransactionDialog}
            closeDialog={() => this.setState({ addTransactionDialog: false })}
            onAddTransaction={this.getAssetInfo}
          />
          <EditBookedTransactionDialog
            journalId={journalId}
            isOpen={editTransactionDialog}
            closeDialog={this.closeDialogs}
            onEditTransaction={this.getAssetInfo}
            isFixedAssetTransaction
          />
          <ViewImportedTransactionDialog
            yodleeTransactionId={yodleeTransactionId}
            isOpen={viewImportedTransactionDialog}
            closeDialog={this.closeDialogs}
            onUnmatchTransaction={this.getAssetInfo}
          />
          {addFixedAssetDepreciationDialog && (
            <AddFixedAssetDepeciationDialog
              isOpen={addFixedAssetDepreciationDialog}
              closeDialog={this.closeDialogs}
              fixedAssetId={fixedAsset.id}
            />
          )}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CardBase>
              <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
                <Typography variant="h6" gutterBottom>
                  Book Value
                </Typography>
                <Typography variant="h4">
                  <NumberFormat
                    displayType="text"
                    value={fixedAssetTotals.bookValue}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
                <Typography variant="subtitle2">&nbsp;</Typography>
                <Typography variant="subtitle2">Basis</Typography>
                <FieldGrid
                  label="Depreciable"
                  value={
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.depreciable}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  }
                />
                <FieldGrid
                  label="Non-Depreciable"
                  value={
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.nonDepreciable}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  }
                />
                <Typography variant="subtitle2">Depreciation</Typography>
                <FieldGrid
                  label="Starting Basis"
                  value={
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.startingBasis}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  }
                />
                <FieldGrid
                  label="Accumulated Depreciation"
                  value={
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.accumulatedDepreciation}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  }
                />
              </Box>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CardBase>
              <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
                <Typography variant="h6" gutterBottom>
                  Asset Information
                </Typography>
                <Typography variant="subtitle2">Details</Typography>
                <FieldGrid label="Type:" value={fixedAsset.type} />
                <FieldGrid
                  label="Useful Life:"
                  value={fixedAsset.lifespan ? `${fixedAsset.lifespan} years` : 'Unknown'}
                />
                <FieldGrid
                  label="Placed in Service:"
                  value={
                    fixedAsset.placedInServiceDate
                      ? moment(fixedAsset.placedInServiceDate, 'YYYY-MM-DD').format('M/D/YYYY')
                      : 'Unknown'
                  }
                />
                <FieldGrid label="Property:" value={fixedAsset.property ? fixedAsset.property.address1 : 'None'} />
                <Typography variant="subtitle2">Account Balances</Typography>
                {fixedAssetAccounts.map((account) => (
                  <FieldGrid
                    key={account.id}
                    label={account.name}
                    value={
                      <NumberFormat
                        displayType="text"
                        value={-account.netCredits}
                        thousandSeparator
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    }
                  />
                ))}
              </Box>
            </CardBase>
          </Grid>
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Transactions
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Type</Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography variant="subtitle2">Description/Account</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Debit</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Credit</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fixedAssetTransactions.data.map((journal) => (
                      <React.Fragment key={journal.id}>
                        <TableRow selected>
                          <TableCell>{moment(journal.date).format('M/D/YYYY')}</TableCell>
                          <TableCell>
                            <Typography variant="body2" gutterBottom={journal.propertyId !== null}>
                              {journal.type}
                              {journal.attachmentURL && (
                                <Button
                                  className={classes.inlineButton}
                                  aria-label="attachments"
                                  href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                                  target="_blank"
                                >
                                  <AttachFileIcon fontSize="small" />
                                </Button>
                              )}
                            </Typography>
                            {journal.entityId !== null && journal.propertyId === null && (
                              <Typography variant="caption" component="div">
                                {journal.entity.name}
                              </Typography>
                            )}
                            {journal.propertyId !== null && (
                              <Typography variant="caption" component="div">
                                {journal.property.address1}
                              </Typography>
                            )}
                            {journal.unitId !== null && (
                              <Typography variant="caption" component="div">
                                {journal.unit.name}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell colSpan={2}>
                            {journal.description ? journal.description : '---'}
                            <Button
                              className={classes.inlineButton}
                              aria-label="edit"
                              onClick={() => {
                                this.setState({
                                  journalId: journal.id,
                                  editTransactionDialog: true,
                                });
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </Button>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                        {journal.journalLines.map((line, index) => (
                          <TableRow key={line.id}>
                            {index === 0 && (
                              <TableCell size="small" colSpan={2} rowSpan={journal.journalLines.length} />
                            )}
                            <TableCell size="small">{line.account.name}</TableCell>
                            <TableCell size="small">
                              {line.yodleeTransactionId && (
                                <Button
                                  className={classes.inlineButton}
                                  aria-label="imported"
                                  onClick={() =>
                                    this.setState({
                                      viewImportedTransactionDialog: true,
                                      yodleeTransactionId: line.yodleeTransactionId,
                                    })
                                  }
                                  // href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                                  // target="_blank"
                                >
                                  <LinkIcon fontSize="small" />
                                </Button>
                              )}
                            </TableCell>
                            <TableCell size="small">
                              <NumberFormat
                                displayType="text"
                                value={line.debit}
                                thousandSeparator
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                                renderText={(value) => this.formatNegative(value, line)}
                              />
                            </TableCell>
                            <TableCell size="small">
                              <NumberFormat
                                displayType="text"
                                value={line.credit}
                                thousandSeparator
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                                renderText={(value) => this.formatNegative(value, line)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        variant="footer"
                        count={fixedAssetTransactions.total}
                        page={filter.fixedAssetTransactionsPage}
                        rowsPerPage={pageSize}
                        onPageChange={this.handleChangeTransactionsPage}
                        rowsPerPageOptions={[pageSize]}
                        ActionsComponent={TablePaginationButtons}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </CardContent>
            </CardBase>
          </Grid>
        </PageGrid>
      </>
    );
  }
}

FixedAsset.contextType = PersonContext;

FixedAsset.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(FixedAsset);
