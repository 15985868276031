import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { getImportedTransactionStatusOptions, nameLabel } from '~/components/Autocomplete/Library';
import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import FormGridContainer from '~/components/FormGridContainer';
import FormGridItem from '~/components/FormGridItem';
import { PersonContext } from '~/contexts/PersonContext';
import { getImportAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

const AMOUNT_FILTER_TYPE_OPTIONS = [
  { id: 'Amount', name: 'By Specific Amount' },
  { id: 'Amount Range', name: 'By Amount Range' },
];

const MIN_AMOUNT_GREATER_THAN_MAX_AMOUNT_ERROR = 'Min Amount must be less than Max Amount';

export default function FilterYodleeTransactionsDialog({ filter, isOpen, closeDialog, updateFilter }) {
  const { organizationId } = useContext(PersonContext);
  const [importAccountOptions, setImportAccountOptions] = useState([]);
  const [filterState, setFilterState] = useState(filter);
  const [error, setError] = useState(null);
  const isUsingAmountRange = filterState.amountFilterTypeSelect.id === 'Amount Range';
  const isUsingSpecificAmount = filterState.amountFilterTypeSelect.id === 'Amount';

  useEffect(() => {
    const getData = async () => {
      const importAccounts = await getImportAccountOptions(organizationId);
      setImportAccountOptions(importAccounts);
    };
    getData();
  }, [filter, organizationId]);

  useEffect(() => {
    if (error && filterState.minAmount < filterState.maxAmount) {
      setError(null);
    }
  }, [filterState.minAmount, filterState.maxAmount, error]);

  function handleUpdateFilter(event) {
    event.preventDefault();
    if (isUsingAmountRange && filterState.minAmount >= filterState.maxAmount) {
      setError(MIN_AMOUNT_GREATER_THAN_MAX_AMOUNT_ERROR);
      return;
    }
    const updatedFilter = { ...filterState };
    updatedFilter.page = 0;
    if (isUsingAmountRange) {
      updatedFilter.amount = null;
    } else if (isUsingSpecificAmount) {
      updatedFilter.minAmount = null;
      updatedFilter.maxAmount = null;
    }
    if (updatedFilter.startDateSelect && updatedFilter.startDateSelect.isValid()) {
      updatedFilter.startDate = updatedFilter.startDateSelect.format('YYYY-MM-DD');
    } else {
      updatedFilter.startDate = null;
      updatedFilter.startDateSelect = null;
    }
    if (updatedFilter.endDateSelect && updatedFilter.endDateSelect.isValid()) {
      updatedFilter.endDate = updatedFilter.endDateSelect.format('YYYY-MM-DD');
    } else {
      updatedFilter.endDate = null;
      updatedFilter.endDateSelect = null;
    }
    updateFilter(updatedFilter);
    closeDialog();
  }

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="filterYodleeTransactionsDialog"
    >
      <form onSubmit={handleUpdateFilter}>
        <DialogTitle id="alert-dialog-title">Filter Transactions</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={importAccountOptions}
            getOptionLabel={nameLabel}
            value={filterState.accountIdSelect}
            onChange={(_event, newValue) => {
              setFilterState({ ...filterState, accountIdSelect: newValue });
            }}
            disableClearable
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} margin="dense" label="Account" placeholder="Type to Search" fullWidth required />
            )}
          />
          <Autocomplete
            options={getImportedTransactionStatusOptions}
            getOptionLabel={nameLabel}
            value={filterState.transactionStatusSelect}
            onChange={(_event, newValue) => {
              setFilterState({ ...filterState, transactionStatusSelect: newValue });
            }}
            disableClearable
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Transaction Status"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          <TextField
            label="Description"
            fullWidth
            margin="dense"
            InputProps={{
              value: filterState.description,
              name: 'nested_filter_description',
              onChange: (event) => {
                setFilterState({ ...filterState, description: event.target.value });
              },
            }}
          />
          <Autocomplete
            options={AMOUNT_FILTER_TYPE_OPTIONS}
            getOptionLabel={nameLabel}
            value={filterState.amountFilterTypeSelect}
            onChange={(_event, newValue) => {
              setFilterState({ ...filterState, amountFilterTypeSelect: newValue });
            }}
            disableClearable
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Amount Filter Type"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          {isUsingSpecificAmount && (
            <CurrencyField
              fullWidth
              margin="dense"
              label="Amount"
              value={filterState.amount}
              onChange={(event) => {
                setFilterState({ ...filterState, amount: Number(event.target.value) });
              }}
            />
          )}
          {isUsingAmountRange ? (
            <>
              <FormGridContainer>
                <FormGridItem xs={6}>
                  <CurrencyField
                    fullWidth
                    required
                    margin="dense"
                    label="Min Amount"
                    value={filterState.minAmount}
                    onChange={(event) => {
                      setFilterState({ ...filterState, minAmount: Number(event.target.value) });
                    }}
                  />
                </FormGridItem>
                <FormGridItem xs={6}>
                  <CurrencyField
                    fullWidth
                    required
                    margin="dense"
                    label="Max Amount"
                    value={filterState.maxAmount}
                    onChange={(event) => {
                      setFilterState({ ...filterState, maxAmount: Number(event.target.value) });
                    }}
                  />
                </FormGridItem>
              </FormGridContainer>
              {error && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )}
            </>
          ) : null}
          <FormGridContainer>
            <FormGridItem xs={6}>
              <KeyboardDatePicker
                label="Start Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={filterState.startDateSelect}
                onChange={(date) => {
                  setFilterState({ ...filterState, startDateSelect: date });
                }}
                margin="dense"
                fullWidth
                clearable
              />
            </FormGridItem>
            <FormGridItem xs={6}>
              <KeyboardDatePicker
                label="End Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={filterState.endDateSelect}
                onChange={(date) => {
                  setFilterState({ ...filterState, endDateSelect: date });
                }}
                margin="dense"
                fullWidth
                clearable
              />
            </FormGridItem>
          </FormGridContainer>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" size="medium" disableElevation>
            Update Filter
          </Button>
          <Button onClick={closeDialog} color="primary" variant="text" size="medium">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

FilterYodleeTransactionsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};
