import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { get, patch, remove } from '../feathersWrapper';
import {
  asyncHandleChange,
  handleChange,
  handleKeyboardDatePickerChange,
  handleSearchSelectChange,
  handleTextFieldChange,
  handleUploaderChange,
} from '../functions/InputHandlers';

import { searchProperties, searchUnits } from './SearchSelect/SearchFunctions';
import SearchSelect from './SearchSelect/SearchSelect';

const styles = (theme) => ({
  datetime: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '4px',
  },
  hideWidget: {
    display: 'none',
  },
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
});

class EditNoteDialog extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getNote = async () => {
    const { noteId } = this.props;
    const note = {};
    try {
      const oldNote = await get(this, 'notes', noteId);

      note.id = oldNote.id;
      note.date = oldNote.date;
      note.dateSelect = moment(oldNote.date);
      note.title = oldNote.title;
      note.text = oldNote.text;
      note.attachmentURL = oldNote.attachmentURL;
      note.propertyId = oldNote.propertyId;
      note.propertyIdSelect = oldNote.propertyId
        ? {
            value: oldNote.propertyId,
            label: oldNote.property.address1,
          }
        : null;
      const selectedProperty = oldNote.propertyId
        ? {
            id: oldNote.propertyId,
            address1: oldNote.property.address1,
            unitSelection: oldNote.property.unitSelection,
          }
        : null;
      note.unitId = oldNote.unitId;
      note.unitIdSelect = oldNote.unitId
        ? {
            value: oldNote.unitId,
            label: oldNote.unit.name,
          }
        : null;

      this.setState({
        note,
        selectedProperty,
        error: null,
        loading: false,
        submitting: false,
        confirmDelete: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  handlePropertyChange = async (name, value, isMulti) => {
    const { note } = this.state;
    if (value !== null) {
      const property = await get(this, 'properties', value.value);
      handleChange('selectedProperty', property, this);
    } else {
      handleChange('selectedProperty', null, this);
    }
    note.unitId = null;
    note.unitIdSelect = null;
    await asyncHandleChange('note', { ...note }, this);

    handleSearchSelectChange(this)(name, value, isMulti);
  };

  editNote = async (event) => {
    event.preventDefault();
    const { submitting, note } = this.state;

    const { onEditNote, noteId } = this.props;

    if (submitting) {
      return;
    }

    if (!note.date) {
      this.setState({ error: { message: 'Please enter a valid date for this note' } });
      return;
    }

    this.setState({ submitting: true });

    patch(this, 'notes', noteId, note)
      .then(() => {
        onEditNote();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteNote = () => {
    const { submitting } = this.state;
    const { onEditNote, noteId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    remove(this, 'notes', noteId)
      .then(() => {
        onEditNote();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { loading, error, note, selectedProperty, confirmDelete } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEnforceFocus
        onClose={closeDialog}
        onEnter={this.getNote}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editNoteDialog"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editNote}>
            <DialogTitle id="alert-dialog-title">
              Edit Note
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <KeyboardDatePicker
                className={classes.datetime}
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={note.date}
                onChange={handleKeyboardDatePickerChange('nested_note_date', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <SearchSelect
                searchFunction={searchProperties(this)}
                changeFunction={this.handlePropertyChange}
                label="Property"
                name="nested_note_propertyId"
                isMulti={false}
                value={note.propertyIdSelect}
                margin="dense"
                target={document.getElementById('editNoteDialog')}
              />
              {selectedProperty && selectedProperty.unitSelection === 'multi' && (
                <SearchSelect
                  searchFunction={searchUnits(this, { propertyId: selectedProperty.id })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Unit"
                  name="nested_note_unitId"
                  isMulti={false}
                  value={note.unitIdSelect}
                  margin="dense"
                  target={document.getElementById('editNoteDialog')}
                />
              )}
              <TextField
                label="Title"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: note.title,
                  name: 'nested_note_title',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Text"
                fullWidth
                multiline
                rowsMax="5"
                margin="dense"
                InputProps={{
                  value: note.text,
                  name: 'nested_note_text',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControl margin="dense">
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Attachments
                </FormLabel>
                <div className={note.attachmentURL ? null : classes.hideWidget}>
                  <Widget
                    ref={this.uploadcareWidget}
                    multiple
                    tabs="file url camera"
                    value={note.attachmentURL}
                    onChange={handleUploaderChange('nested_note_attachmentURL', this)}
                  />
                </div>
                {!note.attachmentURL && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.editImageButton}
                    onClick={() => {
                      this.uploadcareWidget.current.openDialog();
                    }}
                  >
                    Click to Add Pictures or Files
                  </Button>
                )}
                {note.attachmentURL && (
                  <Button
                    color="primary"
                    className={classes.editImageButton}
                    onClick={async () => {
                      await asyncHandleChange('nested_note_attachmentURL', '', this);
                      this.uploadcareWidget.current.reloadInfo();
                    }}
                  >
                    Remove All Attachments
                  </Button>
                )}
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Note
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Note?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">This note will be removed.</DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteNote} className={classes.deleteConfirmationButton}>
                Delete Note
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditNoteDialog.contextType = PersonContext;

EditNoteDialog.defaultProps = {
  noteId: null,
};

EditNoteDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  noteId: PropTypes.node,
};

export default withStyles(styles)(EditNoteDialog);
