import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { PlayCircleFilled } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import VideoPlayerDialog from '~/components/VideoPlayerDialog';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const SEGMENT_LOCATION = 'Onboarding: Get Started Intro';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px',
    padding: theme.spacing(3),
  },
  heroImageContainer: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  heroImage: {
    maxWidth: '248px',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
    },
  },
  playButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    color: 'white',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  getStartedButton: {
    color: theme.palette.primary.contrastText,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
  successAlert: {
    marginBottom: theme.spacing(4),
    maxWidth: '600px',
    width: '100%',
  },
}));

export default function Welcome() {
  const { adminLogin, partnerName, setContextState } = useContext(PersonContext);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  const tracking = useSegmentTrack();

  const skipWelcome = useCallback(() => {
    setContextState({ showWelcome: false });
  }, [setContextState]);

  function openVideoDialog() {
    tracking('watch_overview_video clicked', { location: 'Onboarding: Get Started Intro' });
    setIsVideoDialogOpen(true);
  }

  const history = useHistory();

  function handleGetStarted() {
    tracking('get_started clicked', { location: SEGMENT_LOCATION });
    history.push('/describes-you');
  }

  const classes = useStyles();

  return (
    <>
      {partnerName ? (
        <Alert severity="success" color="success" variant="filled" elevation={1} className={classes.successAlert}>
          Property, payments, and other data were successfully imported.
        </Alert>
      ) : null}
      <Card className={classes.card}>
        <Box className={classes.heroImageContainer}>
          <img className={classes.heroImage} src="./rei_hub_welcome_intro_hero.png" alt="Welcome" />
          <Fab color="secondary" onClick={() => openVideoDialog()} className={classes.playButton}>
            <PlayCircleFilled />
          </Fab>
        </Box>

        <Typography variant="h6" className={classes.title}>
          Let&apos;s personalize your bookkeeping experience
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          We&apos;ll ask a few questions to set up your books based on your real estate situation.
        </Typography>

        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => handleGetStarted()}
          className={classes.getStartedButton}
        >
          Get Started
        </Button>
        {adminLogin && (
          <Button color="primary" onClick={() => skipWelcome()}>
            Close (Admin)
          </Button>
        )}
      </Card>

      <VideoPlayerDialog
        allowFullScreen={false}
        open={isVideoDialogOpen}
        source="https://www.youtube.com/embed/NsSJQXfe46g?rel=0&amp;wmode=opaque"
        onClose={() => {
          setIsVideoDialogOpen(false);
        }}
      />
    </>
  );
}
