import React, { useContext, useEffect, useState } from 'react';
import { Box, Button as MuiButton, Divider, makeStyles, TextField, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { AccountBalanceOutlined, Check, CheckCircleOutline, CreditCard } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import moment from 'moment';
import PropTypes from 'prop-types';

import AddSubscribePaymentCardDialog from '~/components/AddSubscribePaymentCardDialog';
import Button from '~/components/Button';
import ExpertSupportSubscribeDialog from '~/components/ExpertSupportSubscribeDialog';
import PlaidLinkAuth from '~/components/Plaid/PlaidLinkAuth';
import { annualPlanOptions, planOptions } from '~/constants/buy-it-now';
import DISCOUNTS from '~/constants/discounts';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  dialogComplete: {
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
    color: theme.palette.success.main,
  },
  dialogContentContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width: '100%',
    backgroundColor: '#FAFAFA',
  },
  limitedTimeOfferButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    cursor: 'pointer',
    width: 'fit-content',
    pointerEvents: 'none',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  expiredTextContainer: {
    color: theme.palette.error.main,
  },
  autocomplete: {
    backgroundColor: 'white',
  },
  autoCompleteContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  autocompleteCaption: {
    color: theme.palette.text.secondary,
    marginLeft: '14px',
  },
  subscriptionTotalCard: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    marginBottom: '24px',
    backgroundColor: 'white',
  },
  subscriptionLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  subscriptionLineLeft: {
    marginLeft: 0,
    marginRight: 'auto',
  },
  subscriptionLineRight: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  savingsBox: {
    backgroundColor: '#EDF7ED',
    padding: theme.spacing(1.5),
    borderRadius: '4px',
    color: '#1E4620',
  },
  bulletsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    gap: theme.spacing(1),
    marginTop: theme.spacing(1.25),
  },
  bulletContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  paymentMethodsContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  paymentMethodCaption: {
    color: theme.palette.text.secondary,
  },
  paymentMethodButtonsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    gap: theme.spacing(2.75),
    marginBottom: '16px',
  },
  paymentMethodButton: {
    border: '1px solid #C4C4C4',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  paymentMethodButtonSelected: {
    border: '1px solid #4CAF51',
    backgroundColor: '#EDF7ED',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    alignItems: 'center',
  },
  paymentMethodButtonSelectedIcon: {
    color: '#2E7D32',
    height: '35px',
    width: '35px',
  },
  paymentMethodButtonIcon: {
    color: theme.palette.primary.main,
    height: '35px',
    width: '35px',
  },
  dialogButtonsContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  dialogButtonsSecondRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
  },
  completedStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    width: '100%',
  },
  completedAnimationContainer: {
    width: '120px',
    height: '120px',
    margin: 'auto',
    alignContent: 'center',
  },
  completedStepTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  secondaryStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  toggleButtonGroup: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    width: '50%',
  },
  toggleButtonLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'center',
  },
  toggleButtonLabelSecondaryText: {
    fontWeight: 700,
    textTransform: 'none',
  },
  greenText: {
    color: '#1E4620',
  },
  specialOfferTextSecondaryColor: {
    color: theme.palette.secondary.main,
  },
}));

export default function SubscribeDialog({ isOpen, closeDialog, welcomeOffer, onWelcomeSubscribed }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { organizationId, organizationCreated, freeTrialExpires, partnerName, setOrganizationId } =
    useContext(PersonContext);
  const tracking = useSegmentTrack();
  const isSmallScreen = checkIsSmallScreen();

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [planSelect, setPlanSelect] = useState(null);
  const [unitCount, setUnitCount] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [chargeAmount, setChargeAmount] = useState(0);
  const [error, setError] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [isLimitedTimeOffer, setIsLimitedTimeOffer] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const [isDiscountEligible, setIsDiscountEligible] = useState(false);
  const [annualDiscountAmount, setAnnualDiscountAmount] = useState(0);
  const [monthsFreeAmount, setMonthsFreeAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [isAnnualPlan, setIsAnnualPlan] = useState(true);
  const [plans, setPlans] = useState(annualPlanOptions);
  const [isSpecialOfferEligible, setIsSpecialOfferEligible] = useState(false);

  const annualPercentageOff = 40;
  const discountOptions = DISCOUNTS;

  const planPeriod = isAnnualPlan ? 'year' : 'month';

  // Black friday special offer. This variable will need to set to false after the offer ends.
  const isSpecialOfferAvailable = false;
  // Tax season promo offer. This variable will need to set to false after the offer ends.
  const isTaxSeasonPromoAvailable = true;

  useEffect(() => {
    setPlans(isAnnualPlan ? annualPlanOptions : planOptions);
    if (planSelect) {
      setPlanSelect(
        isAnnualPlan
          ? annualPlanOptions.find((plan) => plan.name === planSelect.name)
          : planOptions.find((plan) => plan.name === planSelect.name),
      );
    }
  }, [isAnnualPlan, planSelect]);

  useEffect(() => {
    const getUnitCount = async () => {
      const unitCountReport = await create(this, 'reports', {
        organizationId,
        reportName: 'unitCount',
      });

      setUnitCount(unitCountReport);
    };
    getUnitCount();
    setLoading(false);
  }, [organizationId]);

  useEffect(() => {
    if (loading) {
      return;
    }
    let newStep;
    let newDiscount;
    let newPlanSelect;

    if (!step) {
      newStep = 'select';
      if (['TurboTenant', 'RentRedi'].includes(partnerName)) {
        newStep = partnerName;
      }
    }

    const trialFirstWeek = moment(freeTrialExpires).diff(moment(), 'days') >= 7;
    const trialExpired = moment().isAfter(moment(freeTrialExpires));
    const organizationCreatedAtBetween21And44Days =
      moment().isAfter(moment(organizationCreated).add(21, 'days')) &&
      moment().isBefore(moment(organizationCreated).add(45, 'days'));
    const trialExpiredAfterMonth =
      trialExpired && moment().isAfter(moment(freeTrialExpires).add(30, 'days').endOf('day'));
    const organizationCreatedAfterFortyFiveDays = moment().isAfter(moment(organizationCreated).add(45, 'days'));

    if (trialExpired) {
      setIsTrialExpired(true);
    }
    if (isAnnualPlan) {
      if (welcomeOffer || trialFirstWeek) {
        newDiscount = discountOptions.find((option) => option.id === 'early_subscriber_2months_free');
        setIsLimitedTimeOffer(true);
        setIsDiscountEligible(true);
      } else if (organizationCreatedAtBetween21And44Days) {
        newDiscount = discountOptions.find((option) => option.id === 'trial_expired_2months_free');
        setIsLimitedTimeOffer(true);
        setIsDiscountEligible(true);
      } else if (trialExpiredAfterMonth && !partnerName && isSpecialOfferAvailable) {
        setIsDiscountEligible(true);
        setIsLimitedTimeOffer(true);
        setIsSpecialOfferEligible(true);
        newDiscount = discountOptions.find((option) => option.id === 'buy_now_75off');
      } else if (organizationCreatedAfterFortyFiveDays && isTaxSeasonPromoAvailable) {
        setIsDiscountEligible(true);
        setIsLimitedTimeOffer(true);
        newDiscount = discountOptions.find((option) => option.id === 'promo_offer_2months_free');
      }
    }

    if (!planSelect) {
      const planSelectPlans = isAnnualPlan ? annualPlanOptions : planOptions;

      if (unitCount > 20) {
        const professional = isAnnualPlan ? 'professional_annual' : 'professional';
        newPlanSelect = planSelectPlans.find((plan) => plan.id === professional);
      } else if (unitCount > 10) {
        const investor = isAnnualPlan ? 'investor_annual' : 'investor';
        newPlanSelect = planSelectPlans.find((plan) => plan.id === investor);
      } else if (unitCount > 3) {
        const growth = isAnnualPlan ? 'growth_annual' : 'growth';
        newPlanSelect = planSelectPlans.find((plan) => plan.id === growth);
      } else {
        const essential = isAnnualPlan ? 'essential_annual' : 'essential';
        newPlanSelect = planSelectPlans.find((plan) => plan.id === essential);
      }
      setPlanSelect(newPlanSelect);
    }

    setStep(newStep || step);
    setDiscount(newDiscount);
  }, [unitCount, isAnnualPlan]);

  useEffect(() => {
    if (planSelect) {
      if (isAnnualPlan) {
        const fortyPercentOff = planSelect.price * (annualPercentageOff / 100);
        setAnnualDiscountAmount(fortyPercentOff.toFixed(2));
        if (discount) {
          const twoMonthsFree = ((planSelect.price - fortyPercentOff) / 12) * 2;
          setMonthsFreeAmount(twoMonthsFree.toFixed(2));
          setTotal((planSelect.price - fortyPercentOff - twoMonthsFree).toFixed(2));
        } else {
          setTotal((planSelect.price - fortyPercentOff).toFixed(2));
        }
      } else {
        setTotal(planSelect.price.toFixed(2));
      }
    }
  }, [discount, isAnnualPlan, planSelect]);

  useEffect(() => {
    if (error && planSelect.maxUnits >= unitCount) {
      setError(null);
    }
  }, [error, planSelect, unitCount]);

  const continueToPayment = () => {
    if (planSelect.maxUnits !== undefined && unitCount > planSelect.maxUnits) {
      setError(
        `You currently have ${unitCount} units in REI Hub. Please select a
        plan that includes enough units.`,
      );
      return;
    }

    if (paymentMethod === 'card') {
      const amount = Number(total);

      setStep('card');
      setChargeAmount(amount);
    }

    if (paymentMethod === 'ach') {
      setStep('ach');
    }
  };

  const subscribe = async () => {
    try {
      await create(
        this,
        'stripe-subscriptions',
        {
          organizationId,
          plan: planSelect.id,
          maxUnits: planSelect.maxUnits,
          discount: discount?.id,
        },
        true, // throw error
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newSubscriptionComplete',
        plan: planSelect.id,
        value: planSelect.price * 36,
        organizationId,
      });
      if (welcomeOffer) {
        onWelcomeSubscribed();
      }
      setStep('complete');
    } catch (err) {
      setStep('subscriptionError');
      setSubscriptionError(err);
    }
  };

  const addACHPaymentMethod = async (stripeBankAccountToken) => {
    await create(this, 'stripe-sources', {
      organizationId,
      stripeSource: stripeBankAccountToken,
    });
    subscribe();
  };

  const getHeader = () => {
    if (isTrialExpired && !isSpecialOfferEligible) {
      return (
        <Box>
          <Typography variant="h6">
            <Box className={classes.expiredTextContainer} component="span">
              {'Your Trial Expired: '}
            </Box>
            Keep your bookkeeping on autopilot
          </Typography>
        </Box>
      );
    }
    if (isSpecialOfferAvailable && isSpecialOfferEligible) {
      return (
        <Box>
          <Typography variant="h5">
            Regain access and put your bookkeeping on autopilot with
            <Box component="span" className={classes.specialOfferTextSecondaryColor}>
              {` ${discount.discountPercent}% for 3 months`}
            </Box>
          </Typography>
        </Box>
      );
    }
    return <Typography variant="h5">Keep your bookkeeping on autopilot</Typography>;
  };

  const getDialogContent = () => {
    switch (step) {
      case 'select':
        return (
          <>
            <Box className={classes.messageContainer}>
              {isLimitedTimeOffer && (
                <MuiButton size="small" variant="contained" disableElevation className={classes.limitedTimeOfferButton}>
                  LIMITED TIME OFFER
                </MuiButton>
              )}
              {getHeader()}
              <Typography variant="body1">
                Select a plan and payment method and get ready to turn next tax season into “relax” season!
              </Typography>
            </Box>
            <Box className={classes.autoCompleteContainer}>
              <Autocomplete
                options={plans}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.units} Units`}
                value={planSelect}
                onChange={(_event, newValue) => setPlanSelect(newValue)}
                disableClearable
                variant="outlined"
                size="medium"
                className={classes.autocomplete}
                renderInput={(params) => <TextField {...params} label="Plan Tier" fullWidth variant="outlined" />}
              />
              <Box className={classes.autocompleteCaption}>
                <Typography variant="caption">Based on the number of units in your portfolio.</Typography>
              </Box>
            </Box>
            <ToggleButtonGroup
              className={classes.toggleButtonGroup}
              value={isAnnualPlan ? 'annual' : 'monthly'}
              exclusive
              size="medium"
              onChange={(_event, newValue) => {
                if (!newValue) {
                  return;
                }
                setIsAnnualPlan(newValue === 'annual');
                tracking(newValue === 'annual' ? 'annual selected' : 'monthly selected', {
                  location: 'Subscribe Flow',
                });
              }}
            >
              <ToggleButton value="annual" className={classes.toggleButton}>
                <Box className={classes.toggleButtonLabelContainer}>
                  <Box>ANNUAL</Box>
                  {!isSmallScreen ? <Box className={classes.toggleButtonLabelSecondaryText}>(Save 40%)</Box> : null}
                </Box>
              </ToggleButton>
              <ToggleButton value="monthly" className={classes.toggleButton}>
                MONTHLY
              </ToggleButton>
            </ToggleButtonGroup>
            <Box className={classes.subscriptionTotalCard}>
              <Box className={classes.subscriptionLineContainer}>
                <Box className={classes.subscriptionLineLeft}>
                  <Typography variant="subtitle2">{`${isAnnualPlan ? 'Annual' : 'Monthly'} Plan`}</Typography>
                </Box>
                <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                  {`$${planSelect.price}.00/${planPeriod}`}
                </Typography>
              </Box>
              {isAnnualPlan && (
                <Box className={classes.subscriptionLineContainer}>
                  <Box className={classes.subscriptionLineLeft}>
                    <Typography variant="body2">{`${annualPercentageOff}% Annual Discount`}</Typography>
                  </Box>
                  <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                    <Box className={classes.greenText}>{`-$${annualDiscountAmount}`}</Box>
                  </Typography>
                </Box>
              )}
              {isDiscountEligible && isAnnualPlan && (
                <Box className={classes.subscriptionLineContainer}>
                  <Box className={classes.subscriptionLineLeft}>
                    <Typography variant="body2">Extra 2 months free</Typography>
                  </Box>
                  <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                    <Box className={classes.greenText}>{`-$${monthsFreeAmount}`}</Box>
                  </Typography>
                </Box>
              )}
              {isAnnualPlan && (
                <>
                  <Divider />
                  <Box className={classes.subscriptionLineContainer}>
                    <Box className={classes.subscriptionLineLeft}>
                      <Typography variant="subtitle2">Your Total</Typography>
                    </Box>
                    <Box className={classes.subscriptionLineRight}>
                      <Typography variant="subtitle2">{`$${total}/${planPeriod}`}</Typography>
                    </Box>
                  </Box>
                  {isAnnualPlan && (
                    <Box className={classes.savingsBox}>
                      {isDiscountEligible ? (
                        <Typography variant="subtitle2">{`You're saving a total of $${Number(total).toFixed(0)}. That's 6 months free!`}</Typography>
                      ) : (
                        <Typography variant="subtitle2">{`You're saving $${Number(annualDiscountAmount).toFixed(0)}!`}</Typography>
                      )}
                    </Box>
                  )}
                </>
              )}
              <Box className={classes.bulletsContainer}>
                <Box className={classes.bulletContainer}>
                  <Check fontSize="small" />
                  <Typography variant="body2">{`Billed ${isAnnualPlan ? 'yearly' : 'monthly'}`}</Typography>
                </Box>
                <Box className={classes.bulletContainer}>
                  <Check fontSize="small" />
                  <Typography variant="body2">Cancel anytime</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className={classes.paymentMethodsContainer}>
              <Typography variant="caption" className={classes.paymentMethodCaption}>
                Payment Method
              </Typography>
              <Box className={classes.paymentMethodButtonsContainer}>
                <Box
                  className={
                    paymentMethod === 'ach' ? classes.paymentMethodButtonSelected : classes.paymentMethodButton
                  }
                  onClick={() => {
                    tracking('ach clicked', { location: 'Subscribe Flow' });
                    setPaymentMethod('ach');
                  }}
                >
                  {paymentMethod === 'ach' ? (
                    <CheckCircleOutline className={classes.paymentMethodButtonSelectedIcon} />
                  ) : (
                    <AccountBalanceOutlined className={classes.paymentMethodButtonIcon} />
                  )}
                  <Typography variant="subtitle1">ACH (Preferred)</Typography>
                </Box>
                <Box
                  className={
                    paymentMethod === 'card' ? classes.paymentMethodButtonSelected : classes.paymentMethodButton
                  }
                  onClick={() => {
                    tracking('card clicked', { location: 'Subscribe Flow' });
                    setPaymentMethod('card');
                  }}
                >
                  {paymentMethod === 'card' ? (
                    <CheckCircleOutline className={classes.paymentMethodButtonSelectedIcon} />
                  ) : (
                    <CreditCard className={classes.paymentMethodButtonIcon} />
                  )}
                  <Typography variant="subtitle1">Credit / Debit Card</Typography>
                </Box>
              </Box>
            </Box>
            {error && (
              <Typography variant="body1" color="error">
                {error}
              </Typography>
            )}
            <Box className={classes.dialogButtonsContainer}>
              <Button
                disabled={!paymentMethod}
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('continue clicked', { location: 'Subscribe Flow' });
                  continueToPayment();
                }}
              >
                Continue
              </Button>
              <Box className={isTrialExpired ? classes.dialogButtonsSecondRowContainer : null}>
                {isTrialExpired && (
                  <Button
                    hasFullWidth
                    variant="text"
                    onClick={() => {
                      tracking('contact_us clicked', { location: 'Subscribe Flow' });
                      window.Intercom('show');
                    }}
                  >
                    Contact Us
                  </Button>
                )}
                {isTrialExpired ? (
                  <Button
                    hasFullWidth
                    variant="text"
                    onClick={() => {
                      tracking('view_portfolios clicked', { location: 'Subscribe Flow' });
                      setStep('select');
                      closeDialog();
                      if (isTrialExpired) {
                        setOrganizationId(null);
                      }
                    }}
                  >
                    View Portfolios
                  </Button>
                ) : (
                  <Button
                    hasFullWidth
                    variant="text"
                    onClick={() => {
                      tracking('not_right_now clicked', { location: 'Subscribe Flow' });
                      setStep('select');
                      closeDialog();
                      if (isTrialExpired) {
                        setOrganizationId(null);
                      }
                    }}
                  >
                    Not Right Now
                  </Button>
                )}
              </Box>
            </Box>
          </>
        );
      case 'subscriptionError':
        return (
          <Box className={classes.secondaryStepContainer}>
            <Typography variant="h5">Subscription Error</Typography>
            <Typography variant="body1" color="error" gutterBottom>
              {`Sorry, we were unable to process your subscription with the payment information
                provided. Please try again. Message: ${subscriptionError}`}
            </Typography>
            <Button
              hasFullWidth
              variant="contained"
              onClick={() => {
                setStep('select');
              }}
            >
              Go Back
            </Button>
          </Box>
        );
      case 'RentRedi':
        return (
          <Box className={classes.secondaryStepContainer}>
            <Typography variant="h5">Partner Subscription Ended</Typography>
            <Typography variant="body1" gutterBottom>
              {`Your subscription to REI Hub was canceled by our partner RentRedi. If you intend to
              continue using RentRedi's integration with REI Hub please visit the RentRedi
              dashboard to check that your accounting add-on is still active and that the payment
              for your most recent invoice completed successfully. To continue using REI Hub
              without additional data synced from RentRedi, you may subscribe this portfolio
              directly through REI Hub.`}
            </Typography>
            <Button
              hasFullWidth
              variant="contained"
              onClick={() => {
                setStep('select');
              }}
            >
              Subscribe Through REI Hub
            </Button>
            <Box className={classes.dialogButtonsSecondRowContainer}>
              <Button
                hasFullWidth
                variant="text"
                onClick={() => {
                  window.Intercom('show');
                }}
              >
                Contact Us
              </Button>
              <Button hasFullWidth variant="text" href="https://app.rentredi.com/accounting" onClick={() => {}}>
                Go To RentRedi
              </Button>
            </Box>
          </Box>
        );
      case 'TurboTenant':
        return (
          <Box className={classes.secondaryStepContainer}>
            <Typography variant="h5">Partner Subscription Ended</Typography>
            <Typography variant="body1" gutterBottom>
              {`Your subscription to REI Hub was canceled by our partner TurboTenant. If you intend
              to continue using TurboTenants's integration with REI Hub please visit the
              TurboTenant dashboard to check that your accounting add-on is still active and that
              the payment for your most recent invoice completed successfully. To continue using
              REI Hub without additional data synced from TurboTenant, you may subscribe this
              portfolio directly through REI Hub.`}
            </Typography>
            <Button
              hasFullWidth
              variant="contained"
              onClick={() => {
                setStep('select');
              }}
            >
              Subscribe Through REI Hub
            </Button>
            <Box className={classes.dialogButtonsSecondRowContainer}>
              <Button
                hasFullWidth
                variant="text"
                onClick={() => {
                  window.Intercom('show');
                }}
              >
                Contact Us
              </Button>
              <Button
                hasFullWidth
                variant="text"
                href="https://rental.turbotenant.com/owners/payments/accounting"
                onClick={() => {}}
              >
                Go To TurboTenant
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return null;
  }

  if (step === 'ach') {
    return (
      <PlaidLinkAuth
        closeDialog={() => {
          setStep('select');
        }}
        onSuccess={addACHPaymentMethod}
      />
    );
  }

  if (step === 'card') {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <AddSubscribePaymentCardDialog
            elements={elements}
            stripe={stripe}
            onSuccess={subscribe}
            onCancel={() => {
              setStep('select');
            }}
            cancelLabel="Go Back"
            amount={chargeAmount}
            isInSubscribeFlow
            isAnnualPlan={isAnnualPlan}
          />
        )}
      </ElementsConsumer>
    );
  }

  if (step === 'complete') {
    return (
      <ExpertSupportSubscribeDialog
        isNewSubscription
        location="SUBSCRIBE"
        closeDialog={() => {
          setStep(null);
          closeDialog();
          setOrganizationId(organizationId);
        }}
      />
    );
  }

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setStep('null');
          closeDialog();
        }
      }}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={step === 'complete' ? classes.dialogComplete : null}
    >
      <Box className={step === 'complete' ? null : classes.dialogContentContainer}>{getDialogContent()}</Box>
    </Dialog>
  );
}

SubscribeDialog.defaultProps = {
  welcomeOffer: false,
  onWelcomeSubscribed: () => {},
};

SubscribeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  welcomeOffer: PropTypes.bool,
  onWelcomeSubscribed: PropTypes.func,
};
