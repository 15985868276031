import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import {
  Box,
  makeStyles,
  MobileStepper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Proptypes from 'prop-types';

import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';

const CustomStepConnector = withStyles((theme) => ({
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
      borderTopWidth: 3,
    },
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
      borderTopWidth: 3,
    },
  },
  line: {
    borderColor: '#BDBDBD',
    borderTopWidth: 1,
  },
}))(StepConnector);

const StyledMobileStepper = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingTop: 8,
    backgroundColor: 'transparent',
    width: '100%',
  },
  progress: {
    width: '100%',
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
}))(MobileStepper);

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: theme.zIndex.drawer + 3,
    padding: '0',

    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
    },
  },
  stepper: {
    width: '100%',
    maxWidth: '960px',
    backgroundColor: 'transparent',
    padding: 0,
  },
  defaultStepIcon: {
    height: '27px',
    width: '27px',
    borderRadius: '50%',
    border: ' 2px solid #BDBDBD',
    borderColor: '#BDBDBD',
    color: '#9B9B9B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeStepIcon: {
    height: '27px',
    width: '27px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  completedStepIcon: {
    height: '27px',
    width: '27px',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileStepperContainer: {
    width: '100%',
    maxWidth: 600,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 24,
  },
  mobileStepText: {
    color: theme.palette.text.secondary,
  },
}));

export default function CustomStepper({ orientation, stepProps, allCompletedUrl }) {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles(theme);
  const isSmallScreen = checkIsSmallScreen();
  const stepCount = stepProps.length;
  const [activeStep, setActiveStep] = useState(0);

  /**
   * Set the current active step based on the current location
   */
  useEffect(() => {
    const includedUrls = stepProps.map((step) => step.url);
    if (!includedUrls.includes(location.pathname.split('?')[0]) && location.pathname !== allCompletedUrl) {
      setActiveStep(0);
      return;
    }
    stepProps.forEach((step, index) => {
      if (location.pathname.includes(step.url)) {
        setActiveStep(index);
      }
    });
    // Specifically on mobile/small screens, the active step was not accurately being set if the
    // user was on the allCompletedUrl and they refreshed the page. This looks for the
    // allCompletedUrl in the pathname and sets the active step to the last step in the stepProps
    // array so the progress bar and label/count are accurate.
    if (location.pathname.includes(allCompletedUrl)) {
      setActiveStep(stepCount - 1);
    }
  }, [location]);

  const getCustomStepIcon = (componentProps) => {
    const { active, completed, icon } = componentProps;

    if (completed) {
      return (
        <div className={classes.completedStepIcon}>
          <Check fontSize="small" />
        </div>
      );
    }
    if (active) {
      return <div className={classes.activeStepIcon}>{icon}</div>;
    }
    return <div className={classes.defaultStepIcon}>{icon}</div>;
  };

  if (isSmallScreen) {
    return (
      <Box className={classes.stepperContainer}>
        <Box display="flex" flexDirection="column" className={classes.mobileStepperContainer}>
          <Box display="flex" flexDirection="row">
            <Box className={classes.mobileStepText}>
              <Typography variant="subtitle2">
                <Box component="span" fontWeight="bold" marginRight={0.5}>
                  {`Step ${activeStep + 1} of ${stepCount}:`}
                </Box>
              </Typography>
            </Box>
            <Typography variant="body2">
              <Box component="span" fontWeight="bold">
                {`${stepProps[activeStep].label}`}
              </Box>
            </Typography>
          </Box>
          <StyledMobileStepper
            variant="progress"
            position="static"
            className={classes.mobileProgressBar}
            steps={stepCount + 2}
            activeStep={location.pathname.includes(allCompletedUrl) ? activeStep + 2 : activeStep + 1}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.stepperContainer}>
      <Stepper className={classes.stepper} orientation={orientation} connector={<CustomStepConnector />}>
        {stepProps.map((step, index) => (
          <Step
            key={step.label}
            completed={index < activeStep || location.pathname.includes(allCompletedUrl)}
            active={activeStep === index}
          >
            <StepLabel StepIconComponent={getCustomStepIcon}>
              <Typography variant="subtitle2">{step.label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

CustomStepper.propTypes = {
  orientation: Proptypes.string.isRequired,
  stepProps: Proptypes.arrayOf(Proptypes.object).isRequired,
  allCompletedUrl: Proptypes.string.isRequired,
};
