import React from 'react';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CheckCircle as CheckCircleIcon, Lock as LockIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const SEGMENT_LOCATION = 'Onboarding - Accounts: Intro';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: '900px',
    },
  },
  title: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  card: {
    flex: 1,
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(2.5),
  },
  subTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'flex-start',
    marginBottom: theme.spacing(0.5),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  },
  subTitleTag: {
    order: 1,
    backgroundColor: '#FFCD00',
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(3),
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(1),
    width: 'fit-content',
    color: '#042238',
    fontFamily: theme.typography.fontFamily,
    fontSize: '11px',
    fontWeight: 700,

    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  subTitle: {
    order: 2,

    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBlock: theme.spacing(3),
    padding: '0',
    listStyleType: 'none',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  listIcon: {
    flexShrink: 0,
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  subTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  subTextIcon: {
    height: '20px',
    color: theme.palette.grey[600],
  },
  endText: {
    marginTop: theme.spacing(3),
    fontWeight: 700,
    textAlign: 'center',
  },
}));

export default function ExperimentalOverview({ setAddAccountDialog, setPlaidAddNew, setState }) {
  const tracking = useSegmentTrack();
  const classes = useStyles();

  const handleImportTransactions = () => {
    tracking('start_importing_transactions clicked', { location: SEGMENT_LOCATION });
    setPlaidAddNew(true);
  };

  const handleUploadTransactions = () => {
    tracking('upload_transactions_manually clicked', { location: SEGMENT_LOCATION });
    setState((prevState) => ({ ...prevState, accountType: 'manual' }));
    setAddAccountDialog(true);
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h5">
        How would you like to add transactions to track income and expenses?
      </Typography>

      <div className={classes.cardsContainer}>
        <Card className={classes.card}>
          <div className={classes.subTitleContainer}>
            <div className={classes.subTitleTag}>Recommended</div>
            <Typography className={classes.subTitle} variant="h6">
              Automatically Import
            </Typography>
          </div>

          <Typography variant="body2">Link directly to your financial accounts.</Typography>

          <ul className={classes.list}>
            <li className={classes.listItem}>
              <CheckCircleIcon className={classes.listIcon} color="primary" />
              <Typography variant="body2">
                <b>Save Time:</b> Automate transaction imports
              </Typography>
            </li>
            <li className={classes.listItem}>
              <CheckCircleIcon className={classes.listIcon} color="primary" />
              <Typography variant="body2">
                <b>Stay Accurate:</b> Categorize seamlessly
              </Typography>
            </li>
            <li className={classes.listItem}>
              <CheckCircleIcon className={classes.listIcon} color="primary" />
              <Typography variant="body2">
                <b>Stay Secure:</b> Bank-grade encryption protects your data
              </Typography>
            </li>
          </ul>

          <Button className={classes.button} hasFullWidth onClick={handleImportTransactions}>
            Start Importing Transactions
          </Button>

          <div className={classes.subTextContainer}>
            <LockIcon className={classes.subTextIcon} />
            <Typography variant="caption">Your data is private and secured</Typography>
          </div>

          <Typography className={classes.endText} variant="body2">
            80% of users save time by linking their accounts
          </Typography>
        </Card>

        <Card className={classes.card}>
          <div className={classes.subTitleContainer}>
            <Typography className={classes.subTitle} variant="h6">
              Manually Import Transactions
            </Typography>
          </div>

          <Typography variant="body2">Upload a transaction file from your bank.</Typography>

          <ul className={classes.list}>
            <li className={classes.listItem}>
              <DotIcon className={classes.listIcon} />
              <Typography variant="body2">Choose exactly which transactions to upload</Typography>
            </li>
            <li className={classes.listItem}>
              <DotIcon className={classes.listIcon} />
              <Typography variant="body2">No need to connect your bank account</Typography>
            </li>
            <li className={classes.listItem}>
              <DotIcon className={classes.listIcon} />
              <Typography variant="body2">Start with manual uploads and switch to automatic syncing later</Typography>
            </li>
          </ul>

          <Button
            className={classes.button}
            color="secondary"
            hasFullWidth
            variant="outlined"
            onClick={handleUploadTransactions}
          >
            Upload Transactions Manually
          </Button>

          <div className={classes.subTextContainer}>
            <LockIcon className={classes.subTextIcon} />
            <Typography variant="caption">Your data is private and secured</Typography>
          </div>
        </Card>
      </div>
    </div>
  );
}

ExperimentalOverview.propTypes = {
  setAddAccountDialog: PropTypes.func.isRequired,
  setPlaidAddNew: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

function DotIcon({ className }) {
  return (
    <svg width="20" height="32" viewBox="0 0 20 32" fill="none" className={className}>
      <circle cx="10" cy="16" r="6" fill="black" fillOpacity="0.3" />
    </svg>
  );
}

DotIcon.propTypes = {
  className: PropTypes.string,
};
