import React, { useEffect, useMemo, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import IconButton from '~/components/IconButton';
import SplitLines, { generateSplitLine } from '~/components/SplitLines';
import { create, patch, remove } from '~/feathersFunctionalWrapper';
import { checkIsNegativeAmount, parseTransactionAmount } from '~/functions/AmountFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { sumProperty } from '~/functions/SumFunctions';

const SEGMENT_LOCATION = 'Split Transaction Modal - Import Feed';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '600px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(8),
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  bodyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  footerCheckbox: {
    alignSelf: 'flex-start',
    marginInline: '0',

    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      marginBottom: '0',
      marginLeft: '-11px',
    },
  },
  footerButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  cancelButton: {
    order: 2,

    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  splitButton: {
    order: 1,

    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
}));

export default function SplitImportedTransactionDialog({ transaction, onClose, onSplit }) {
  const [splitLines, setSplitLines] = useState([generateSplitLine(), generateSplitLine()]);
  const [shouldCreateRule, setShouldCreateRule] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUserSubmitted, setHasUserSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const tracking = useSegmentTrack();
  const classes = useStyles();

  const isNegativeTransactionAmount = checkIsNegativeAmount(
    transaction.account.type,
    transaction.yodleeType,
    transaction.amount === 0,
  );
  const parsedTransactionAmount = parseTransactionAmount(transaction.amount);
  const transactionParsed = useMemo(
    () => ({
      accountName: transaction.account.name,
      amount: isNegativeTransactionAmount ? `(${parsedTransactionAmount})` : parsedTransactionAmount,
      date: moment(transaction.date).format('M/D/YYYY'),
      description: transaction.description,
      type: transaction.yodleeType,
    }),
    [isNegativeTransactionAmount, parsedTransactionAmount, transaction],
  );

  function handleSplitLineAdded() {
    tracking('add_line clicked', { location: SEGMENT_LOCATION });
  }

  function handleShouldCreateRuleChanged(event) {
    setShouldCreateRule(event.target.checked);
    if (event.target.checked) {
      tracking('create_rule checked', { location: SEGMENT_LOCATION });
    } else {
      tracking('create_rule unchecked', { location: SEGMENT_LOCATION });
    }
  }

  function handleCancel() {
    tracking('cancel clicked', { location: SEGMENT_LOCATION });
    onClose();
  }

  async function handleSubmit(event) {
    event.preventDefault();

    tracking('split clicked', { location: SEGMENT_LOCATION });

    setHasUserSubmitted(true);
    if (isSubmitting || errorMessage) {
      return;
    }
    setIsSubmitting(true);

    try {
      const splitTransactionsPromises = splitLines.map((line) => {
        const newTransaction = {
          ...transaction,
          amount: Math.abs(line.amount),
          splitParentId: transaction.splitParentId || transaction.id,
        };
        delete newTransaction.id;
        delete newTransaction.createdAt;
        if (line.amount < 0) {
          newTransaction.yodleeType = newTransaction.yodleeType === 'DEBIT' ? 'CREDIT' : 'DEBIT';
        }
        return create('yodlee-transactions', newTransaction);
      });
      await Promise.allSettled(splitTransactionsPromises);

      if (transaction.splitParentId) {
        await remove('yodlee-transactions', transaction.id);
      } else {
        await patch('yodlee-transactions', transaction.id, { split: true });
      }

      onSplit(shouldCreateRule, transaction, splitLines);
      onClose();
    } catch {
      setErrorMessage('An error occurred while splitting the transaction');
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (parseFloat(sumProperty(splitLines, 'amount')) !== transaction.amount) {
      setErrorMessage('The split total must match the original transaction amount');
    } else {
      setErrorMessage('');
    }
  }, [splitLines, transaction.amount]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open
      onClose={onClose}
    >
      <form className={classes.container} onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Typography variant="subtitle1">Split Transaction</Typography>
          <IconButton aria-label="Close" color="primary" disabled={isSubmitting} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.body}>
          <Typography variant="caption">
            {transactionParsed.date} • {transactionParsed.accountName}
          </Typography>
          <div className={classes.bodyHeader}>
            <Typography variant="h6">{transactionParsed.description}</Typography>
            <Typography color={isNegativeTransactionAmount ? 'error' : 'textPrimary'} variant="h6">
              {transactionParsed.amount}
            </Typography>
          </div>

          <SplitLines splitLines={splitLines} onSplitLineAdded={handleSplitLineAdded} setSplitLines={setSplitLines} />

          {errorMessage && hasUserSubmitted && (
            <Alert className={classes.error} severity="error">
              {errorMessage}
            </Alert>
          )}
        </div>

        <div className={classes.footer}>
          <FormControlLabel
            className={classes.footerCheckbox}
            control={<Checkbox checked={shouldCreateRule} color="primary" onChange={handleShouldCreateRuleChanged} />}
            label="Create rule after splitting"
          />
          <div className={classes.footerButtons}>
            <Button
              className={classes.cancelButton}
              color="primary"
              disabled={isSubmitting}
              size="medium"
              variant="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={classes.ignoreButton}
              color="primary"
              disabled={isSubmitting}
              size="medium"
              type="submit"
            >
              Split
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

SplitImportedTransactionDialog.propTypes = {
  transaction: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSplit: PropTypes.func.isRequired,
};
