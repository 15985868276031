import React, { createRef, useContext, useEffect, useState } from 'react';
import { Paper, Popper, Tooltip, Typography } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import PropTypes from 'prop-types';

import useStyles from '~/components/BatchBookActionBar/styles';
import { getBatchOptionButtonLabel } from '~/components/BatchBookActionBar/utils';
import Button from '~/components/Button';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import { BATCH_TYPES } from '~/constants/batch-booking';
import { PersonContext } from '~/contexts/PersonContext';
import { find } from '~/feathersFunctionalWrapper';
export default function BatchBookActionBar({
  batchType,
  updateBatchType,
  batchCount,
  transactions,
  cancelBatchChange,
  handleBatchBookingAction,
}) {
  const classes = useStyles();
  const autocompleteRef = createRef();

  const { organizationId } = useContext(PersonContext);

  const [batchTypeOptions, setBatchTypeOptions] = useState([]);

  const [hasFixedAssets, setHasFixedAssets] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkForFixedAssets = async () => {
      const fixedAssets = await find('fixed-assets', {
        query: {
          organizationId,
          inactive: { $or: [null, false] },
          $limit: 1,
          $select: ['id'],
        },
      });
      setHasFixedAssets(fixedAssets.total > 0);
    };
    checkForFixedAssets();
  }, [organizationId]);

  useEffect(() => {
    const allUnbooked = transactions.every(
      (transaction) =>
        transaction.journalId === null && !transaction.ignored && !transaction.split && !transaction.deleted,
    );
    const allIgnored = transactions.every((transaction) => transaction.ignored === true);
    const allBooked = transactions.every((transaction) => transaction.journalId !== null);

    const availableBatchTypeOptions = [];

    if (allUnbooked) {
      const allMoneyIn = transactions.every((transaction) => transaction.yodleeType === 'CREDIT');
      const allMoneyOut = transactions.every((transaction) => transaction.yodleeType === 'DEBIT');
      availableBatchTypeOptions.push({ name: BATCH_TYPES.IGNORE, id: BATCH_TYPES.IGNORE });
      if (transactions.every((transaction) => !transaction.ignored)) {
        availableBatchTypeOptions.push({ name: BATCH_TYPES.DELETE, id: BATCH_TYPES.DELETE });
      }
      if (allMoneyIn) {
        availableBatchTypeOptions.unshift(
          { name: BATCH_TYPES.REVENUE, id: BATCH_TYPES.REVENUE },
          { name: BATCH_TYPES.TRANSFER, id: BATCH_TYPES.TRANSFER },
        );
      } else if (allMoneyOut) {
        if (hasFixedAssets) {
          availableBatchTypeOptions.unshift(
            { name: BATCH_TYPES.EXPENSE, id: BATCH_TYPES.EXPENSE },
            { name: BATCH_TYPES.TRANSFER, id: BATCH_TYPES.TRANSFER },
            { name: BATCH_TYPES.FIXED_ASSET_PURCHASE, id: BATCH_TYPES.FIXED_ASSET_PURCHASE },
          );
        } else {
          availableBatchTypeOptions.unshift(
            { name: BATCH_TYPES.EXPENSE, id: BATCH_TYPES.EXPENSE },
            { name: BATCH_TYPES.TRANSFER, id: BATCH_TYPES.TRANSFER },
          );
        }
      }
    } else if (allIgnored) {
      availableBatchTypeOptions.push({ name: BATCH_TYPES.UNIGNORE, id: BATCH_TYPES.UNIGNORE });
    } else if (allBooked) {
      availableBatchTypeOptions.push({ name: BATCH_TYPES.UNDO, id: BATCH_TYPES.UNDO });
    }
    setBatchTypeOptions(availableBatchTypeOptions);
    if (!availableBatchTypeOptions.length) {
      updateBatchType(null);
    }
    setIsLoading(false);
  }, [hasFixedAssets, transactions, updateBatchType]);

  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.batchActionBar} elevation={3}>
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            <div ref={autocompleteRef} className={classes.autocompleteFieldContainer}>
              <AutocompleteField
                classes={{
                  noOptions: classes.noOptionsText,
                }}
                placeholder="Batch Action"
                noOptionsText="No actions available based on the transactions selected."
                options={batchTypeOptions}
                onChange={(value) => updateBatchType(value)}
                value={batchType}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    disablePortal
                    anchorEl={autocompleteRef.current ? autocompleteRef.current : null}
                  />
                )}
              />
            </div>
            <Tooltip title="Available actions are filtered based on the transactions selected" placement="top" arrow>
              <Info fontSize="medium" className={classes.infoIcon} />
            </Tooltip>
          </div>
          <div className={classes.rightContainer}>
            <Typography variant="subtitle2">{`${batchCount} Selected`}</Typography>
            <div className={classes.buttonsContainer}>
              <Button className={classes.cancelButton} variant="text" size="medium" onClick={cancelBatchChange}>
                <div className={classes.actionButtonInnerContainer}>
                  <Close fontSize="small" />
                  Clear
                </div>
              </Button>
              {batchType && batchCount > 0 ? (
                <div>
                  {batchType.id === BATCH_TYPES.DELETE ? (
                    <div>
                      <Button color="error" variant="contained" onClick={() => handleBatchBookingAction(batchType.id)}>
                        <Typography variant="subtitle2">Delete</Typography>
                      </Button>
                    </div>
                  ) : (
                    <Button className={classes.actionButton} onClick={() => handleBatchBookingAction(batchType.id)}>
                      <Typography variant="subtitle2">{getBatchOptionButtonLabel(batchType.id)}</Typography>
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

BatchBookActionBar.propTypes = {
  batchType: PropTypes.object,
  updateBatchType: PropTypes.func.isRequired,
  batchCount: PropTypes.number,
  transactions: PropTypes.array.isRequired,
  cancelBatchChange: PropTypes.func.isRequired,
  handleBatchBookingAction: PropTypes.func.isRequired,
};
