import React from 'react';
import NumberFormat from 'react-number-format';
import { IconButton, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { CallSplitOutlined, MoreVert, OfflineBoltOutlined } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import AddRuleDrawer from '~/components/AddRuleDrawer';
import BatchBookActionBar from '~/components/BatchBookActionBar';
import CelebrationBar from '~/components/CelebrationBar';
import DeleteImportedTransactionDialog from '~/components/DeleteImportedTransactionDialog';
import EditImportedTransactionDialog from '~/components/EditImportedTransactionDialog';
import EditTransactionMenu from '~/components/EditTransactionMenu';
import EmptyState from '~/components/EmptyState';
import IgnoreImportedTransactionDialog from '~/components/IgnoreImportedTransactionDialog';
import LoadingSpinner from '~/components/LoadingSpinner';
import QuickBookRow from '~/components/QuickBookRow';
import SplitImportedTransactionDialog from '~/components/SplitImportedTransactionDialog';
import SuggestRuleDialog from '~/components/SuggestRuleDialog';
import { BATCH_TYPES } from '~/constants/batch-booking';
import { checkIsNegativeAmount } from '~/functions/AmountFunctions';
import { segmentTracking } from '~/functions/SegmentFunctions';

import AddImportedTransactionDialog from '../../components/AddImportedTransactionDialog';
import BatchBookFixedAssetPurchasesDialog from '../../components/BatchBookFixedAssetPurchasesDialog';
import BatchBookTransactionsDialog from '../../components/BatchBookTransactionsDialog';
import BatchBookTransfersDialog from '../../components/BatchBookTransfersDialog';
import BatchChangeImportedTransactionDialog from '../../components/BatchChangeImportedTransactionDialog';
import CardBase from '../../components/CardBase';
import DownloadDialog from '../../components/DownloadDialog';
import FilterYodleeTransactionsDialog from '../../components/FilterYodleeTransactionsDialog';
import PageGrid from '../../components/PageGrid';
import PageHeader from '../../components/PageHeader';
import UnsplitYodleeTransactionDialog from '../../components/UnsplitYodleeTransactionDialog';
import UploadTransactionsDialog from '../../components/UploadTransactionsDialog';
import ViewBookedTransactionDialog from '../../components/ViewBookedTransactionDialog';
import { PersonContext } from '../../contexts/PersonContext';
import client from '../../feathers';
import { find, get } from '../../feathersWrapper';
import { asyncHandleChange } from '../../functions/InputHandlers';
import { TablePaginationButtons } from '../../functions/TableFunctions';
import history from '../../history';

import { TRANSACTIONS_PER_PAGE } from './constants';
import ImportFeedFilterCard from './ImportFeedFilterCard';
import TransactionsListMobile from './TransactionsListMobile';
import { getBookedTransactionDescription, getCheckboxTooltip } from './utils';

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main,
  },
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '35vw',
  },
  attachmentButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
  connectionsButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    width: 'fit-content',
  },
  childRow: {
    backgroundColor: theme.palette.grey[100],
  },
  whiteButton: {
    backgroundColor: 'white',
  },
  selectedTableCell: {
    borderBottom: 'none',
    maxWidth: 0,
  },
  disabledBatchCell: {
    'color': theme.palette.text.disabled,
    '& span': {
      color: theme.palette.text.disabled,
    },
    '& svg': {
      opacity: 0.45,
    },
  },
  quickBookRow: {
    backgroundColor: theme.palette.grey[200],
  },
  batchBookRow: {
    backgroundColor: theme.palette.alert.info.backgroundColor,
  },
  quickBookIconsContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: theme.spacing(1),
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  quickBookIconButton: {
    color: theme.palette.primary.main,
  },
  splitTransactionIcon: {
    color: theme.tokens.materialColors.reiHubPurple[900],
    width: '20px',
    height: '20px',
    position: 'relative',
    top: '3px',
    right: '3px',
  },
  autobookedTransactionIcon: {
    color: theme.tokens.materialColors.reiHubPurple[900],
    width: '20px',
    height: '20px',
    position: 'relative',
    top: '3px',
    right: '3px',
    paddingLeft: '2px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  disabledTooltip: {
    justifySelf: 'flex-start',
    opacity: 0.4,
  },
  importedTransactionsTable: {
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
});

class ImportedTransactions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterYodleeTransactionsDialog: false,
      uploadTransactionsDialog: false,
      addImportedTransactionDialog: false,
      unsplitYodleeTransactionDialog: false,
      selectedTransactionId: null,
      selectedTransaction: null,
      filter: {
        transactionStatusSelect: { name: 'All Transactions', id: 'All' },
        accountIdSelect: { name: 'All Accounts', id: 'All' },
        description: '',
        amount: null,
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
        amountFilterTypeSelect: { id: 'Amount', name: 'By Specific Amount' },
        minAmount: '',
        maxAmount: '',
        page: 0,
      },
      batchChangeImportedTransactionDialog: false,
      batchBookTransactionsDialog: false,
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
      batchPages: [],
      erroredAccount: false,
      quickBookLines: [],
      ruleJournal: null,
      ruleTransaction: null,
      isAddRuleDrawerOpen: false,
      isEditTransactionMenuDisplayed: false,
      isSplitImportedTransactionDialogOpen: false,
      editTransactionAnchorEl: null,
      transactionToEdit: null,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { filter } = this.state;
    const newFilter = { ...filter };
    const parsedQuery = queryString.parse(location.search);

    if (parsedQuery.accountId) {
      const account = await get(this, 'accounts', parsedQuery.accountId);
      newFilter.accountIdSelect = account;
    }

    if (parsedQuery.description) {
      newFilter.description = parsedQuery.description;
    }

    if (parsedQuery.amount) {
      newFilter.amount = parsedQuery.amount;
    }

    if (parsedQuery.minAmount) {
      newFilter.minAmount = parsedQuery.minAmount;
    }

    if (parsedQuery.maxAmount) {
      newFilter.maxAmount = parsedQuery.maxAmount;
    }

    if (parsedQuery.startDate) {
      newFilter.startDate = parsedQuery.startDate;
      newFilter.startDateSelect = moment(parsedQuery.startDate, 'YYYY-MM-DD');
    }

    if (parsedQuery.endDate) {
      newFilter.endDate = parsedQuery.endDate;
      newFilter.endDateSelect = moment(parsedQuery.endDate, 'YYYY-MM-DD');
    }

    if (['Booked', 'Unbooked', 'Ignored', 'Split'].includes(parsedQuery.status)) {
      newFilter.transactionStatusSelect = {
        name: parsedQuery.status,
        id: parsedQuery.status,
      };
    }
    await asyncHandleChange('filter', newFilter, this);

    await this.getYodleeTransactions();

    await this.checkForErroredAccounts();

    this.setState({
      loading: false,
    });
  }

  getYodleeTransactions = async (newPage) => {
    const { match } = this.props;
    const { organizationId } = this.context;
    const { filter } = this.state;

    this.setState({ loadingTransactions: true });

    let hasAccounts = true;

    const accountsPromise = find(this, 'accounts', {
      query: {
        organizationId,
        type: { $or: ['Asset', 'Liability'] },
        type2: { $or: ['Bank', 'Credit Card'] },
        inactive: { $or: [null, false] },
        $limit: 1,
      },
    });

    const accountsResponse = await accountsPromise;

    if (accountsResponse.data.length === 0) {
      hasAccounts = false;
    }

    const yodleeTransactionsService = client.service('yodlee-transactions');

    const hasPage = newPage !== undefined;

    const query = {
      organizationId,
      pending: false,
      deleted: false,
      includeFullJournals: true,
      $limit: TRANSACTIONS_PER_PAGE,
      $skip: hasPage ? newPage * TRANSACTIONS_PER_PAGE : filter.page * TRANSACTIONS_PER_PAGE,
      $sort: {
        date: -1,
        amount: -1,
        id: 1,
      },
    };

    let newQuery = '';

    let displaySplitChildrenRows = false;

    // Used to determine if the filter is active so we can determine which empty state to display
    let isFilterActive = false;

    // If filter transaction status is 'all' or 'split' and the filter does not contain a value for
    // amount, set the displaySplitChildrenRows flag to true and exclude transactions with a split
    // parent ID.
    if (
      ['All', 'Split'].includes(filter.transactionStatusSelect.id) &&
      !filter.amount &&
      !filter.minAmount &&
      !filter.maxAmount
    ) {
      query.splitParentId = null;
      displaySplitChildrenRows = true;
    }

    if (['Booked', 'Unbooked'].includes(filter.transactionStatusSelect.id)) {
      query.transactionStatus = filter.transactionStatusSelect.id.toLowerCase();
      isFilterActive = true;
    }

    if (['Unbooked'].includes(filter.transactionStatusSelect.id)) {
      query.ignored = { $or: [false, null] };
      query.split = { $or: [false, null] };
      isFilterActive = true;
    }

    if (['Ignored'].includes(filter.transactionStatusSelect.id)) {
      query.ignored = true;
      isFilterActive = true;
    }

    if (['Split'].includes(filter.transactionStatusSelect.id)) {
      query.split = true;
      isFilterActive = true;
    }

    if (filter.accountIdSelect.id !== 'All') {
      query.accountId = filter.accountIdSelect.id;
      newQuery += `&accountId=${filter.accountIdSelect.id}`;
      isFilterActive = true;
    }

    if (filter.description) {
      query.description = { $iLike: `%${filter.description}%` };
      newQuery += `&description=${filter.description}`;
      isFilterActive = true;
    }

    if (filter.amountFilterTypeSelect.id === 'Amount' && filter.amount) {
      query.amount = filter.amount;
      newQuery += `&amount=${filter.amount}`;
      isFilterActive = true;
    }

    if (filter.amountFilterTypeSelect.id === 'Amount Range' && filter.minAmount && filter.maxAmount) {
      query.amount = { $gte: filter.minAmount, $lte: filter.maxAmount };
      newQuery += `&minAmount=${filter.minAmount}&maxAmount=${filter.maxAmount}`;
      isFilterActive = true;
    }

    if (filter.startDate !== null) {
      query.date = { $gte: filter.startDate };
      newQuery += `&startDate=${filter.startDate}`;
      isFilterActive = true;
    }

    if (filter.endDate !== null) {
      if (query.date) {
        query.date.$lte = filter.endDate;
      } else {
        query.date = { $lte: filter.endDate };
      }
      newQuery += `&endDate=${filter.endDate}`;
      isFilterActive = true;
    }

    // update url with new params
    if (newQuery.length > 0) {
      history.replace(`${match.path}?${newQuery.substring(1)}`);
    } else {
      history.replace(match.path);
    }

    const yodleeTransactions = await yodleeTransactionsService.find({
      query,
    });

    const parentsAndChildren = [];

    // If the displaySplitChildrenRows flag is true, find all split transactions in the
    // yodleeTransactions array and find all their children. Then add all the children to the
    // parentsAndChildren array to reference when rendering the table.
    if (displaySplitChildrenRows) {
      const splitParents = yodleeTransactions.data.filter((transaction) => transaction.split === true);
      splitParents.forEach((parent) => {
        parentsAndChildren.push({ parentId: parent.id, children: [] });
      });
      const childrenPromises = [];

      splitParents.forEach((parent) => {
        const splitQuery = {
          organizationId,
          pending: false,
          deleted: false,
          includeFullJournals: true,
          splitParentId: parent.id,
          $limit: TRANSACTIONS_PER_PAGE,
          $sort: {
            amount: -1,
          },
        };
        childrenPromises.push(yodleeTransactionsService.find({ query: splitQuery }));
      });

      const childrenResults = await Promise.all(childrenPromises);

      childrenResults.forEach((result) => {
        if (result.data.length) {
          const resultParent = parentsAndChildren.find((item) => item.parentId === result.data[0].splitParentId);
          resultParent.children = result.data;
        }
      });
    }

    this.setState({
      totalTransactions: yodleeTransactions.total,
      yodleeTransactions: yodleeTransactions.data,
      displaySplitChildrenRows,
      parentsAndChildren: displaySplitChildrenRows ? parentsAndChildren : null,
      isFilterActive,
      hasAccounts,
      filter: {
        ...filter,
        page: hasPage ? newPage : filter.page,
      },
      loadingTransactions: false,
    });
  };

  checkForErroredAccounts = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    let erroredAccount = false;
    const query = {
      organizationId,
      type2: ['Bank', 'Credit Card'],
      inactive: { $or: [null, false] },
      includeYodleeAccounts: true,
      includePlaidAccounts: true,
    };
    if (parsedQuery.accountId) {
      query.id = parsedQuery.accountId;
    }

    const accounts = await find(this, 'accounts', { query });

    accounts.data.forEach((account) => {
      if (account.plaid_account && account.plaid_account.plaid_item.requiresUpdate === true) {
        erroredAccount = true;
      }
      if (
        account.yodleeAccountId &&
        account.yodlee_account.accountData.dataset[0].updateEligibility === 'ALLOW_UPDATE_WITH_CREDENTIALS'
      ) {
        erroredAccount = true;
      }
    });

    this.setState({ erroredAccount });
  };

  appliedFilter = () => {
    const { filter } = this.state;
    const filterArray = [];
    if (filter.description) {
      filterArray.push({
        label: 'Description Contains',
        value: filter.description,
        clear: () => this.clearFilterFields(['description']),
      });
    }
    if (filter.amount) {
      filterArray.push({
        label: 'Amount',
        value: `$${Number.parseFloat(filter.amount).toFixed(2)}`,
        clear: () => this.clearFilterFields(['amount']),
      });
    }
    if (filter.minAmount && filter.maxAmount) {
      filterArray.push({
        label: 'Amount Between',
        value: `$${Number.parseFloat(filter.minAmount).toFixed(2)} and $${Number.parseFloat(filter.maxAmount).toFixed(2)}`,
        clear: () => this.clearFilterFields(['minAmount', 'maxAmount']),
      });
    }
    if (filter.startDate !== null && filter.endDate !== null) {
      filterArray.push({
        label: 'Between',
        value: `${filter.startDateSelect.format('M/D/YYYY')} and ${filter.endDateSelect.format('M/D/YYYY')}`,
        clear: () => this.clearFilterFields(['startDate', 'startDateSelect', 'endDate', 'endDateSelect']),
      });
    } else if (filter.startDate !== null) {
      filterArray.push({
        label: 'On or After',
        value: filter.startDateSelect.format('M/D/YYYY'),
        clear: () => this.clearFilterFields(['startDate', 'startDateSelect']),
      });
    } else if (filter.endDate !== null) {
      filterArray.push({
        label: 'On or Before',
        value: filter.endDateSelect.format('M/D/YYYY'),
        clear: () => this.clearFilterFields(['endDate', 'endDateSelect']),
      });
    }
    return filterArray;
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateFilter(newFilter);
  };

  closeAddImportedTransactionDialog = () => {
    this.setState({
      addImportedTransactionDialog: false,
    });
  };

  onAddImportedTransactionDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      selectedTransactionId: null,
    });
  };

  onAddQuickBookTransaction = (shouldAddRule, journal, descriptionMatches) => {
    this.getYodleeTransactions();

    this.context.updateOrganizationCelebrations();

    if (shouldAddRule) {
      this.setState({
        ruleJournal: journal,
        isAddRuleDrawerOpen: true,
      });
    } else if (descriptionMatches?.length) {
      this.setState({
        isCreateDescriptionMatchDialogOpen: true,
        ruleJournal: journal,
        descriptionMatches,
      });
    }
  };

  onSplitImportedTransaction = (shouldAddRule, transaction, splitLines) => {
    this.getYodleeTransactions();
    if (shouldAddRule) {
      transaction.ruleType = 'Split';
      transaction.splitLines = splitLines;
      this.setState({
        ruleTransaction: transaction,
        isAddRuleDrawerOpen: true,
      });
    }
  };

  onIgnoreImportedTransaction = (shouldAddRule, transaction) => {
    this.getYodleeTransactions();
    if (shouldAddRule) {
      transaction.ruleType = 'Ignore';
      this.setState({
        ruleTransaction: transaction,
        isAddRuleDrawerOpen: true,
      });
    }
  };

  openViewBookedTransactionDialog = (transaction) => {
    this.setState({
      selectedTransaction: transaction,
    });
  };

  openUnsplitDialog = (transactionId) => {
    this.setState({
      selectedTransactionId: transactionId,
      unsplitYodleeTransactionDialog: true,
    });
  };

  closeViewBookedTransactionDialog = () => {
    this.setState({
      selectedTransaction: null,
    });
  };

  onBookedTransactionUpdatedDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      selectedTransaction: null,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getYodleeTransactions();
    this.checkForErroredAccounts();
    this.setState({
      quickBookLines: [],
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  closeFilterYodleeTransactionsDialog = () => {
    this.setState({
      filterYodleeTransactionsDialog: false,
    });
  };

  closeUploadTransactionsDialog = () => {
    this.setState({
      uploadTransactionsDialog: false,
    });
    this.getYodleeTransactions();
  };

  unignoreYodleeTransaction = async (yodleeTransactionId) => {
    const yodleeTransactionsService = client.service('yodlee-transactions');
    await yodleeTransactionsService.patch(yodleeTransactionId, {
      ignored: false,
    });
    this.getYodleeTransactions();
  };

  closeUnsplitYodleeTransactionDialog = () => {
    this.setState({
      unsplitYodleeTransactionDialog: false,
      selectedTransactionId: null,
    });
    this.getYodleeTransactions();
  };

  handleChangePage = (_event, newPage) => {
    this.getYodleeTransactions(newPage);
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (checkIsNegativeAmount(transaction.account.type, transaction.yodleeType, transaction.amount === 0)) {
      return <span className={classes.red}>{`(${value})`}</span>;
    }
    return value;
  };

  bookedDisplay = (transaction) => {
    const { classes } = this.props;
    // return description for unbooked transactions
    if (!transaction.journal) {
      return null;
    }

    const hasGutter = transaction.journal.propertyId || transaction.journal.entityId;
    return (
      <Typography className={classes.truncateOneLine} variant="body2" gutterBottom={!!hasGutter}>
        {transaction.journal.autobooked ? (
          <Tooltip title="Booked by a rule" placement="top" arrow>
            <OfflineBoltOutlined className={classes.autobookedTransactionIcon} />
          </Tooltip>
        ) : null}

        {getBookedTransactionDescription(transaction)}
      </Typography>
    );
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, account, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        account,
        exportOptions,
        reportName: 'Import Feed',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'import-feed.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  updateBatchType = async (type) => {
    const { fullBatchChangeTargets, batchPages } = this.state;

    if (!type) {
      return;
    }

    await asyncHandleChange('batchType', type, this);

    let filteredTargets = [];
    let filteredTargetIds = [];

    if (type.id === BATCH_TYPES.REVENUE) {
      filteredTargets = fullBatchChangeTargets.filter(
        (target) => !target.journalId && !target.ignored && !target.split && target.yodleeType === 'CREDIT',
      );

      filteredTargetIds = filteredTargets.map((target) => target.id);
    } else if (type.id === BATCH_TYPES.EXPENSE || type.id === BATCH_TYPES.FIXED_ASSET_PURCHASE) {
      filteredTargets = fullBatchChangeTargets.filter(
        (target) => !target.journalId && !target.ignored && !target.split && target.yodleeType === 'DEBIT',
      );

      filteredTargetIds = filteredTargets.map((target) => target.id);
    } else if (type.id === BATCH_TYPES.IGNORE || type.id === BATCH_TYPES.TRANSFER) {
      filteredTargets = fullBatchChangeTargets.filter(
        (target) => !target.journalId && !target.ignored && !target.split,
      );

      filteredTargetIds = filteredTargets.map((target) => target.id);
    } else if (type.id === BATCH_TYPES.DELETE) {
      filteredTargets = fullBatchChangeTargets.filter(
        (target) => !target.journalId && !target.split && !target.splitParentId,
      );
      filteredTargetIds = filteredTargets.map((target) => target.id);
    } else if (type.id === BATCH_TYPES.UNIGNORE) {
      filteredTargets = fullBatchChangeTargets.filter((target) => target.ignored);

      filteredTargetIds = filteredTargets.map((target) => target.id);
    } else if (type.id === BATCH_TYPES.UNDO) {
      filteredTargets = fullBatchChangeTargets.filter((target) => target.journal);

      filteredTargetIds = filteredTargets.map((target) => target.id);
    }
    return this.setState({
      batchChangeTargets: filteredTargetIds,
      fullBatchChangeTargets: filteredTargets,
      batchPages: filteredTargetIds.length ? batchPages : [],
    });
  };

  openBatchChangeImportedTransactionDialog = () => {
    this.setState({ batchChangeImportedTransactionDialog: true });
  };

  openBatchBookTransactionsDialog = () => {
    this.setState({ batchBookTransactionsDialog: true });
  };

  getBatchTransactions = (_event, value, transaction) => {
    const { batchChangeTargets, fullBatchChangeTargets, batchType, batchPages, filter } = this.state;
    let currentNumberOfBatchTargets = batchChangeTargets.length;
    if (value) {
      this.setState({
        batchChangeTargets: [...batchChangeTargets, transaction.id],
        fullBatchChangeTargets: [...fullBatchChangeTargets, transaction],
      });
      currentNumberOfBatchTargets += 1;
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter((target) => target !== transaction.id),
        fullBatchChangeTargets: fullBatchChangeTargets.filter((target) => target.id !== transaction.id),
        batchPages: batchPages.filter((page) => page !== filter.page),
      });
      currentNumberOfBatchTargets -= 1;
    }
    if (currentNumberOfBatchTargets < 1 && !batchType) {
      this.setState({
        batchChange: false,
        batchType: null,
        batchPages: [],
      });
    } else {
      this.setState({
        batchChange: true,
      });
    }
  };

  closeBatchDialogs = () => {
    this.setState({
      batchChangeImportedTransactionDialog: false,
      batchBookTransactionsDialog: false,
      batchBookFixedAssetPurchasesDialog: false,
      batchBookTransfersDialog: false,
    });
  };

  addAllPageTransactionsToBatch = (_event, value) => {
    const {
      yodleeTransactions,
      batchChangeTargets,
      fullBatchChangeTargets,
      filter,
      batchPages,
      batchType,
      batchChange,
      parentsAndChildren,
    } = this.state;

    // Builds an array of transaction ids for the current page as dictated by the filter
    const batchArray = [];
    const fullBatchArray = [];

    let allTransactions = yodleeTransactions;

    if (
      !filter.transactionStatusSelect ||
      filter.transactionStatusSelect.id === 'All' ||
      filter.transactionStatusSelect.id === 'Split'
    ) {
      const children = parentsAndChildren.map((parent) => parent.children).flat();
      allTransactions = allTransactions.concat(children);
    }

    if (!batchType) {
      allTransactions.forEach((transaction) => {
        if (!transaction.split) {
          batchArray.push(transaction.id);
          fullBatchArray.push(transaction);
        }
      });
    } else {
      switch (batchType.id) {
        case BATCH_TYPES.IGNORE:
        case BATCH_TYPES.TRANSFER:
          for (let i = 0; i < allTransactions.length; i += 1) {
            if (!allTransactions[i].journalId && !allTransactions[i].ignored && !allTransactions[i].split) {
              fullBatchArray.push(allTransactions[i]);
              batchArray.push(allTransactions[i].id);
            }
          }
          break;
        case BATCH_TYPES.DELETE:
          for (let i = 0; i < allTransactions.length; i += 1) {
            if (!allTransactions[i].journalId && !allTransactions[i].split && !allTransactions[i].splitParentId) {
              fullBatchArray.push(allTransactions[i]);
              batchArray.push(allTransactions[i].id);
            }
          }
          break;
        case BATCH_TYPES.UNIGNORE:
          for (let i = 0; i < allTransactions.length; i += 1) {
            if (allTransactions[i].ignored) {
              fullBatchArray.push(allTransactions[i]);
              batchArray.push(allTransactions[i].id);
            }
          }
          break;
        case BATCH_TYPES.UNDO:
          for (let i = 0; i < allTransactions.length; i += 1) {
            if (allTransactions[i].journalId) {
              batchArray.push(allTransactions[i].id);
              fullBatchArray.push(allTransactions[i]);
            }
          }
          break;
        case BATCH_TYPES.REVENUE:
          for (let i = 0; i < allTransactions.length; i += 1) {
            if (
              !allTransactions[i].journalId &&
              !allTransactions[i].ignored &&
              !allTransactions[i].split &&
              allTransactions[i].yodleeType === 'CREDIT'
            ) {
              batchArray.push(allTransactions[i].id);
              fullBatchArray.push(allTransactions[i]);
            }
          }
          break;
        case BATCH_TYPES.EXPENSE:
        case BATCH_TYPES.FIXED_ASSET_PURCHASE:
          for (let i = 0; i < allTransactions.length; i += 1) {
            if (
              !allTransactions[i].journalId &&
              !allTransactions[i].ignored &&
              !allTransactions[i].split &&
              allTransactions[i].yodleeType === 'DEBIT'
            ) {
              batchArray.push(allTransactions[i].id);
              fullBatchArray.push(allTransactions[i]);
            }
          }
          break;
        default:
          return;
      }
    }

    if (value) {
      // Ensures that duplicates are not present in the batch array if the users has already
      // checked some of the transactions on the page
      const newBatchTargets = [...new Set([...batchChangeTargets, ...batchArray])];
      const newFullBatchTargets = [...new Set([...fullBatchChangeTargets, ...fullBatchArray])];

      // Adds the current page to the batchPages array to be referenced when the user opts to
      // uncheck all - the check/uncheck functionality only affects the current paginated page
      const newBatchPage = [...batchPages, filter.page];

      this.setState({
        batchChangeTargets: newBatchTargets,
        fullBatchChangeTargets: newFullBatchTargets,
        batchPages: newBatchPage,
      });

      if (!batchChange) {
        this.setState({
          batchChange: true,
        });
      }
    } else {
      const newTargets = batchChangeTargets.filter((target) => {
        return !batchArray.includes(target);
      });
      const newFullTargets = fullBatchChangeTargets.filter(
        (target) => !fullBatchArray.find((fullTarget) => fullTarget.id === target.id),
      );
      this.setState({
        batchChangeTargets: newTargets,
        fullBatchChangeTargets: newFullTargets,
        batchPages: batchPages.filter((page) => page !== filter.page),
      });

      if (!newTargets.length) {
        this.setState({
          batchChange: false,
          batchType: null,
          batchPages: [],
        });
      }
    }
  };

  onBatchChangeTransactionsDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  onBatchBookTransactionDialog = () => {
    this.getYodleeTransactions();

    this.context.updateOrganizationCelebrations();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  cancelBatchChange = () => {
    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  getBatchTransactionCheckBoxes = (transaction) => {
    const { classes } = this.props;
    const { batchType, batchChangeTargets, quickBookLines, loadingTransactions } = this.state;

    const checkBox = (
      <Checkbox
        color="primary"
        onChange={(event, value) => {
          this.getBatchTransactions(event, value, transaction);
        }}
        checked={!!batchChangeTargets.find((target) => target === transaction.id)}
        disabled={quickBookLines.length > 0 || loadingTransactions}
      />
    );

    const disabledCheckBox = (
      <Tooltip
        title={getCheckboxTooltip(quickBookLines.length, transaction)}
        placement="top"
        arrow
        className={classes.disabledTooltip}
      >
        <div>
          <Checkbox disabled />
        </div>
      </Tooltip>
    );

    if (transaction.split) {
      return disabledCheckBox;
    }

    if (quickBookLines.length) {
      return disabledCheckBox;
    }

    if (!batchType) {
      return checkBox;
    }

    if (batchType.id === BATCH_TYPES.EXPENSE || batchType.id === BATCH_TYPES.FIXED_ASSET_PURCHASE) {
      if (!transaction.journalId && !transaction.ignored && !transaction.split && transaction.yodleeType === 'DEBIT') {
        return checkBox;
      }
      return disabledCheckBox;
    } else if (batchType.id === BATCH_TYPES.REVENUE) {
      if (!transaction.journalId && !transaction.ignored && !transaction.split && transaction.yodleeType === 'CREDIT') {
        return checkBox;
      }
      return disabledCheckBox;
    } else if (batchType.id === BATCH_TYPES.IGNORE || batchType.id === BATCH_TYPES.TRANSFER) {
      if (!transaction.journalId && !transaction.ignored && !transaction.split) {
        return checkBox;
      }
      return disabledCheckBox;
    } else if (batchType.id === BATCH_TYPES.DELETE) {
      if (!transaction.journalId && !transaction.split && !transaction.splitParentId && !transaction.ignored) {
        return checkBox;
      }
      return disabledCheckBox;
    } else if (batchType.id === BATCH_TYPES.UNIGNORE) {
      if (!transaction.journal && transaction.ignored) {
        return checkBox;
      }
      return disabledCheckBox;
    } else if (batchType.id === BATCH_TYPES.UNDO) {
      if (transaction.journal) {
        return checkBox;
      }
      return disabledCheckBox;
    }
  };

  getBatchTransactionStatus = (transaction) => {
    if (transaction.journal) {
      return 'Booked';
    }
    if (transaction.split) {
      return 'Split';
    }
    if (transaction.ignored) {
      return 'Ignored';
    }
    return 'Unbooked';
  };

  actionButtons = () => {
    const { match } = this.props;

    const { totalTransactions, isFilterActive } = this.state;

    if (totalTransactions === 0 && !isFilterActive) {
      return [];
    }

    const buttons = [
      {
        text: 'Upload',
        action: () => this.setState({ uploadTransactionsDialog: true }),
        class: 'add',
      },
      { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
      { text: 'Rules', link: `${match.path}/rules`, class: 'lighting' },
    ];
    return buttons;
  };

  /**
   * Adds clicked transaction line to the quick book lines array
   * @param {object} transaction: yodlee transaction object
   * @returns
   */
  addRowToQuickBookLines = (transaction) => {
    const { quickBookLines } = this.state;
    if (quickBookLines.find((line) => line.id === transaction.id)) {
      return;
    }
    this.setState({ quickBookLines: [...quickBookLines, transaction] });
  };

  /**
   * Gets the class name for a row based on if it is a child row or a quick book line (child rows
   * are indented and a light shade of grey and quick book rows are a darker shade of grey). Rows
   * that are neither a child row or a quick book row are not assigned a class.
   * @param {object} transaction: yodlee transaction object
   * @param {object} childRow: yodlee transaction object that is a child of a split transaction
   * @returns class name for the row
   */
  getRowClassName = (transaction, childRow) => {
    const { classes } = this.props;
    const { quickBookLines, batchChangeTargets } = this.state;
    const isQuickBookLine = quickBookLines.find((line) => line.id === transaction.id);
    const isBatchBookRow = batchChangeTargets.find((target) => target === transaction.id);
    if (childRow && !isQuickBookLine && !transaction.splitParentId) {
      return classes.childRow;
    } else if (isQuickBookLine) {
      return classes.quickBookRow;
    } else if (isBatchBookRow) {
      return classes.batchBookRow;
    }
    return null;
  };

  /**
   * Gets the class name for a cell based on if it is a quick book line (we want to hide the bottom
   * border of the cell if it is a quick book line so there is not a divider present between the
   * row and the quick book component). Cells that are not quick book lines are not assigned a
   * class
   * @param {object} transaction: yodlee transaction object
   * @returns class name for the cell
   */
  getCellClassName = (transaction) => {
    const { classes } = this.props;
    const { quickBookLines, batchChange, batchType } = this.state;
    if (quickBookLines.find((line) => line.id === transaction.id)) {
      return classes.selectedTableCell;
    }
    if (batchChange && batchType) {
      switch (batchType.id) {
        case BATCH_TYPES.EXPENSE:
        case BATCH_TYPES.FIXED_ASSET_PURCHASE:
          if (transaction.journalId || transaction.ignored || transaction.split || transaction.yodleeType !== 'DEBIT') {
            return classes.disabledBatchCell;
          }
          break;
        case BATCH_TYPES.REVENUE:
          if (
            transaction.journalId ||
            transaction.ignored ||
            transaction.split ||
            transaction.yodleeType !== 'CREDIT'
          ) {
            return classes.disabledBatchCell;
          }
          break;
        case BATCH_TYPES.IGNORE:
        case BATCH_TYPES.TRANSFER:
          if (transaction.journalId || transaction.ignored || transaction.split) {
            return classes.disabledBatchCell;
          }
          break;
        case BATCH_TYPES.DELETE:
          if (transaction.journalId || transaction.split || transaction.splitParentId || transaction.ignored) {
            return classes.disabledBatchCell;
          }
          break;
        case BATCH_TYPES.UNIGNORE:
          if (!transaction.ignored) {
            return classes.disabledBatchCell;
          }
          break;
        case BATCH_TYPES.UNDO:
          if (!transaction.journalId) {
            return classes.disabledBatchCell;
          }
          break;
        default:
          return null;
      }
    }
    return null;
  };

  /**
   * @param {object} transaction: yodlee transaction object
   * @returns boolean indicating if the transaction is in the quick book lines array
   */
  isQuickBookLine = (transaction) => {
    const { quickBookLines } = this.state;
    return quickBookLines.find((line) => line.id === transaction.id);
  };

  /**
   * Removes a transaction from the quick book lines array
   * @param {object} transaction: yodlee transaction object
   */
  removeQuickSelectLine = (transaction) => {
    const { quickBookLines } = this.state;
    const newQuickBookLines = quickBookLines.filter((line) => line.id !== transaction.id);
    this.setState({ quickBookLines: newQuickBookLines });
  };

  handleRowSegmentEvents = (event) => {
    const { organizationId, showWelcome, partnerName, adminLogin } = this.context;
    if (event.target.innerText === 'Book' && event.target.tagName !== 'TD') {
      segmentTracking('book clicked', 'Transaction Row - Import Feed', {
        organizationId,
        showWelcome,
        partnerName,
        adminLogin,
      });
      return;
    }
    segmentTracking(
      'row clicked',
      { location: 'Transaction Row - Import Feed' },
      { organizationId, showWelcome, partnerName, adminLogin },
    );
  };

  handleTransactionMenuClick = (event, transaction) => {
    const { organizationId, showWelcome, partnerName, adminLogin } = this.context;

    segmentTracking('more_icon clicked', 'Transaction Row - Import Feed', {
      organizationId,
      showWelcome,
      partnerName,
      adminLogin,
    });

    this.setState({
      isEditTransactionMenuDisplayed: true,
      editTransactionAnchorEl: event.currentTarget,
      transactionToEdit: transaction,
    });
  };

  rowBuilder = (transaction, childRow) => {
    const { classes } = this.props;
    const { batchChange, filter, displaySplitChildrenRows, quickBookLines } = this.state;
    const quickBookLine = quickBookLines.find((line) => line.id === transaction.id);
    return (
      <>
        <TableRow
          className={this.getRowClassName(transaction, childRow)}
          key={`yodlee-${transaction.id}`}
          onClick={(event) => {
            // Prevent line from being added to quick book lines if user clicks on the edit menu
            // button/icon
            if (
              event.target.id === 'edit-menu' ||
              event.target.closest('button')?.id === 'edit-menu' ||
              event.target.type === 'checkbox'
            ) {
              return;
            }
            // Prevent line from being added to quick book lines if in batch change state
            if (batchChange) {
              return;
            }
            if (!transaction.journal && !transaction.ignored && !transaction.split && !quickBookLine) {
              this.handleRowSegmentEvents(event);
              this.addRowToQuickBookLines(transaction);
            }
          }}
        >
          <TableCell className={this.getCellClassName(transaction)}>
            {this.getBatchTransactionCheckBoxes(transaction)}
          </TableCell>
          <TableCell className={this.getCellClassName(transaction)}>
            {!childRow && <>{moment(transaction.date).format('M/D/YYYY')}</>}
          </TableCell>
          <TableCell className={this.getCellClassName(transaction)}>
            {filter.accountIdSelect.id === 'All' ? (
              <>
                {displaySplitChildrenRows && !childRow && (
                  <>
                    <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                      {transaction.split ? (
                        <Tooltip title="This is a split transaction" placement="top" arrow>
                          <CallSplitOutlined className={classes.splitTransactionIcon} />
                        </Tooltip>
                      ) : null}
                      {transaction.description}
                    </Typography>
                    <Typography className={classes.truncateOneLine} variant="caption">
                      {transaction.account.name}
                    </Typography>
                  </>
                )}
                {displaySplitChildrenRows && childRow && (
                  <Box marginLeft={4.5}>
                    <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                      {`Split - ${transaction.description}`}
                    </Typography>
                    <Typography className={classes.truncateOneLine} variant="caption">
                      {transaction.account.name}
                    </Typography>
                  </Box>
                )}
                {!displaySplitChildrenRows && (
                  <>
                    <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                      {transaction.splitParentId ? 'Split - ' : ''}
                      {transaction.description}
                    </Typography>
                    <Typography className={classes.truncateOneLine} variant="caption">
                      {transaction.account.name}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <Box marginLeft={transaction.splitParentId ? 4.5 : 0}>
                <Typography className={classes.truncateOneLine} variant="body2">
                  {transaction.split ? (
                    <Tooltip title="This is a split transaction" placement="top" arrow>
                      <CallSplitOutlined className={classes.splitTransactionIcon} />
                    </Tooltip>
                  ) : null}
                  {transaction.splitParentId ? 'Split - ' : ''}
                  {transaction.description}
                </Typography>
              </Box>
            )}
          </TableCell>
          <TableCell className={this.getCellClassName(transaction)}>
            {this.bookedDisplay(transaction)}
            {transaction.journal && transaction.journal.entityId && !transaction.journal.propertyId && (
              <Typography variant="caption" component="div">
                {transaction.journal.entity.name}
              </Typography>
            )}
            {transaction.journal && transaction.journal.propertyId && (
              <Typography variant="caption" component="div">
                {transaction.journal.property.address1}
              </Typography>
            )}
            {transaction.journal && transaction.journal.unitId && (
              <Typography variant="caption" component="div">
                {transaction.journal.unit.name}
              </Typography>
            )}
          </TableCell>
          <TableCell className={this.getCellClassName(transaction)}>
            {transaction.journal && transaction.journal.attachmentURL && (
              <Button
                className={classes.attachmentButton}
                aria-label="attachments"
                href={transaction.journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                target="_blank"
              >
                <AttachFileIcon fontSize="small" />
              </Button>
            )}
          </TableCell>
          <TableCell align="right" className={this.getCellClassName(transaction)}>
            <NumberFormat
              displayType="text"
              value={transaction.amount}
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value) => this.formatNegative(value, transaction)}
            />
          </TableCell>
          {batchChange && (
            <TableCell align="right" className={this.getCellClassName(transaction)}>
              <Typography variant="body2">{this.getBatchTransactionStatus(transaction)}</Typography>
            </TableCell>
          )}
          {!batchChange && (
            <TableCell align="right" className={this.getCellClassName(transaction)}>
              {this.isQuickBookLine(transaction) && (
                <Box className={classes.quickBookIconsContainer}>
                  <IconButton
                    className={classes.quickBookIconButton}
                    onClick={() => {
                      this.removeQuickSelectLine(transaction);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              )}
              {!this.isQuickBookLine(transaction) &&
                !transaction.journal_line &&
                !transaction.ignored &&
                !transaction.split && (
                  <Box className={classes.actionsContainer}>
                    <IconButton
                      id="edit-menu"
                      className={classes.menuIcon}
                      onClick={(event) => this.handleTransactionMenuClick(event, transaction)}
                    >
                      <MoreVert id="edit-menu-icon" />
                    </IconButton>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      disableElevation
                      onClick={() => {
                        return;
                      }}
                    >
                      Book
                    </Button>
                  </Box>
                )}
              {transaction.journal_line && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.whiteButton}
                  onClick={() => {
                    this.openViewBookedTransactionDialog(transaction);
                  }}
                >
                  View
                </Button>
              )}
              {transaction.ignored && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.whiteButton}
                  onClick={() => {
                    this.unignoreYodleeTransaction(transaction.id);
                  }}
                >
                  Unignore
                </Button>
              )}
              {transaction.split && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.openUnsplitDialog(transaction.id);
                  }}
                >
                  Unsplit
                </Button>
              )}
            </TableCell>
          )}
        </TableRow>
        {quickBookLine && (
          <QuickBookRow
            transaction={quickBookLine}
            removeQuickSelectLine={this.removeQuickSelectLine}
            onAddTransaction={(shouldAddRule, journal, descriptionMatches) =>
              this.onAddQuickBookTransaction(shouldAddRule, journal, descriptionMatches)
            }
            colCount={7}
          />
        )}
      </>
    );
  };

  getSplitChildRow = (transaction) => {
    const { parentsAndChildren } = this.state;
    const parent = parentsAndChildren.find((item) => item.parentId === transaction.id);
    if (!parent) {
      return null;
    }
    return parent.children.map((child) => <>{this.rowBuilder(child, true)}</>);
  };

  handleBatchBookingAction = (batchType) => {
    switch (batchType) {
      case BATCH_TYPES.REVENUE:
      case BATCH_TYPES.EXPENSE:
        this.setState({ batchBookTransactionsDialog: true });
        break;
      case BATCH_TYPES.FIXED_ASSET_PURCHASE:
        this.setState({ batchBookFixedAssetPurchasesDialog: true });
        break;
      case BATCH_TYPES.TRANSFER:
        this.setState({ batchBookTransfersDialog: true });
        break;
      case BATCH_TYPES.IGNORE:
      case BATCH_TYPES.DELETE:
      case BATCH_TYPES.UNIGNORE:
      case BATCH_TYPES.UNDO:
        this.setState({ batchChangeImportedTransactionDialog: true });
        break;
      default:
        return;
    }
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      yodleeTransactions,
      filter,
      totalTransactions,
      addImportedTransactionDialog,
      filterYodleeTransactionsDialog,
      uploadTransactionsDialog,
      selectedTransactionId,
      selectedTransaction,
      unsplitYodleeTransactionDialog,
      batchChange,
      batchChangeTargets,
      fullBatchChangeTargets,
      batchChangeImportedTransactionDialog,
      batchBookTransactionsDialog,
      batchBookFixedAssetPurchasesDialog,
      batchBookTransfersDialog,
      batchType,
      batchPages,
      downloadDialog,
      erroredAccount,
      displaySplitChildrenRows,
      isFilterActive,
      hasAccounts,
      isAddRuleDrawerOpen,
      ruleJournal,
      isEditImportedTransactionDialogOpen,
      isIgnoreImportedTransactionDialogOpen,
      isDeleteImportedTransactionDialogOpen,
      transactionToEdit,
      editTransactionAnchorEl,
      isEditTransactionMenuDisplayed,
      isSplitImportedTransactionDialogOpen,
      ruleTransaction,
      parentsAndChildren,
      isCreateDescriptionMatchDialogOpen,
      descriptionMatches,
      quickBookLines,
      loadingTransactions,
    } = this.state;
    const { selectedOrganizationCelebrationType, isOrganizationCelebrationVisible, hideOrganizationCelebration } =
      this.context;

    if (loading) {
      return <LoadingSpinner size="medium" hasHeader={false} />;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Import Feed"
          actionButtons={this.actionButtons()}
          appliedFilter={this.appliedFilter()}
        />
        <AddImportedTransactionDialog
          isOpen={addImportedTransactionDialog}
          closeDialog={this.closeAddImportedTransactionDialog}
          onAddTransaction={this.onAddImportedTransactionDialog}
          yodleeTransactionId={selectedTransactionId}
        />
        {isEditImportedTransactionDialogOpen && (
          <EditImportedTransactionDialog
            closeDialog={() => this.setState({ isEditImportedTransactionDialogOpen: false })}
            onEdit={this.onAddImportedTransactionDialog}
            transaction={transactionToEdit}
          />
        )}
        {isSplitImportedTransactionDialogOpen && (
          <SplitImportedTransactionDialog
            transaction={transactionToEdit}
            onClose={() => this.setState({ isSplitImportedTransactionDialogOpen: false })}
            onSplit={this.onSplitImportedTransaction}
          />
        )}
        {isIgnoreImportedTransactionDialogOpen && (
          <IgnoreImportedTransactionDialog
            closeDialog={() => this.setState({ isIgnoreImportedTransactionDialogOpen: false })}
            onIgnore={(shouldAddRule, transaction) => this.onIgnoreImportedTransaction(shouldAddRule, transaction)}
            transaction={transactionToEdit}
          />
        )}
        {isDeleteImportedTransactionDialogOpen && (
          <DeleteImportedTransactionDialog
            closeDialog={() => this.setState({ isDeleteImportedTransactionDialogOpen: false })}
            onDelete={this.onAddImportedTransactionDialog}
            transaction={transactionToEdit}
          />
        )}
        {selectedTransaction && (
          <ViewBookedTransactionDialog
            closeDialog={this.closeViewBookedTransactionDialog}
            onTransactionModified={this.onBookedTransactionUpdatedDialog}
            journalId={selectedTransaction.journalId}
            importedTransactionId={selectedTransaction.id}
          />
        )}
        {filterYodleeTransactionsDialog && (
          <FilterYodleeTransactionsDialog
            filter={filter}
            isOpen={filterYodleeTransactionsDialog}
            closeDialog={this.closeFilterYodleeTransactionsDialog}
            updateFilter={this.updateFilter}
          />
        )}
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({ downloadDialog: false })}
          setExportOptions={this.setExportOptions}
        />
        {uploadTransactionsDialog && (
          <UploadTransactionsDialog
            closeDialog={() => this.setState({ uploadTransactionsDialog: false })}
            onUploadTransactions={this.getYodleeTransactions}
            accountId={filter.accountIdSelect.id === 'All' ? null : filter.accountIdSelect.id}
          />
        )}
        {batchChangeImportedTransactionDialog && (
          <BatchChangeImportedTransactionDialog
            closeDialog={() => this.closeBatchDialogs()}
            transactions={batchChangeTargets}
            fullTransactions={fullBatchChangeTargets}
            onBatchChangeTransactions={this.onBatchChangeTransactionsDialog}
            batchType={batchType}
          />
        )}
        {batchBookTransactionsDialog && (
          <BatchBookTransactionsDialog
            closeDialog={() => this.closeBatchDialogs()}
            fullTransactions={fullBatchChangeTargets}
            onBatchBookTransactions={this.onBatchBookTransactionDialog}
            batchType={batchType}
          />
        )}
        {batchBookFixedAssetPurchasesDialog && (
          <BatchBookFixedAssetPurchasesDialog
            closeDialog={() => this.closeBatchDialogs()}
            fullTransactions={fullBatchChangeTargets}
            onBatchBookFixedAssetPurchases={this.onBatchBookTransactionDialog}
          />
        )}
        {batchBookTransfersDialog && (
          <BatchBookTransfersDialog
            closeDialog={() => this.closeBatchDialogs()}
            fullTransactions={fullBatchChangeTargets}
            onBatchBookTransfers={this.onBatchBookTransactionDialog}
          />
        )}
        {unsplitYodleeTransactionDialog && (
          <UnsplitYodleeTransactionDialog
            closeDialog={this.closeUnsplitYodleeTransactionDialog}
            onSplitTransaction={this.getYodleeTransactions}
            transactionId={selectedTransactionId}
          />
        )}
        {isAddRuleDrawerOpen && (
          <AddRuleDrawer
            onAdd={this.onAddImportedTransactionDialog}
            onClose={() => this.setState({ isAddRuleDrawerOpen: false })}
            transactionJournal={ruleJournal}
            transaction={ruleTransaction}
          />
        )}
        {isCreateDescriptionMatchDialogOpen && (
          <SuggestRuleDialog
            ruleJournal={ruleJournal}
            descriptionMatches={descriptionMatches}
            onAddRule={(shouldAddRule, journal, descriptionMatches) =>
              this.onAddQuickBookTransaction(shouldAddRule, journal, descriptionMatches)
            }
            closeDialog={() => this.setState({ isCreateDescriptionMatchDialogOpen: false })}
          />
        )}
        {(totalTransactions > 0 || isFilterActive || hasAccounts) && (
          <ImportFeedFilterCard
            openFilter={() => this.setState({ filterYodleeTransactionsDialog: true })}
            updateFilter={this.updateFilter}
            filter={filter}
          />
        )}
        {erroredAccount && (
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body1" align="center">
                    {filter.accountIdSelect.id !== 'All'
                      ? `This account could not be automatically refreshed. Click below
                        to view and restore your connections.`
                      : `One or more of your accounts could not be automatically refreshed. Click below
                        to view and restore your connections.`}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      className={classes.connectionsButton}
                      onClick={() => {
                        history.push('/accounts/banking/connections');
                      }}
                      fullWidth
                    >
                      View Connections
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </CardBase>
          </Grid>
        )}
        {isEditTransactionMenuDisplayed && transactionToEdit && (
          <EditTransactionMenu
            anchorEl={editTransactionAnchorEl}
            isSplitOptionVisible={transactionToEdit.amount !== 0}
            onClose={() => this.setState({ isEditTransactionMenuDisplayed: false, selectedTransaction: null })}
            onClickEdit={() => {
              this.setState({
                isEditImportedTransactionDialogOpen: true,
                isEditTransactionMenuDisplayed: false,
              });
            }}
            onClickSplit={() => {
              this.setState({
                isSplitImportedTransactionDialogOpen: true,
                isEditTransactionMenuDisplayed: false,
              });
            }}
            onClickIgnore={() => {
              this.setState({
                isIgnoreImportedTransactionDialogOpen: true,
                isEditTransactionMenuDisplayed: false,
              });
            }}
            onClickDelete={() => {
              this.setState({ isDeleteImportedTransactionDialogOpen: true, isEditTransactionMenuDisplayed: false });
            }}
            isSplitChild={transactionToEdit.splitParentId}
          />
        )}
        {totalTransactions === 0 && isFilterActive && (
          <EmptyState
            icon="/icons/user_action_empty_state_icon.svg"
            title="No imported transactions were found"
            body={
              <Typography variant="body1" align="center">
                Try adjusting your filter options to find what you are looking for or upload any new transactions.
              </Typography>
            }
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Clear Filters',
                onClickFunction: () => {
                  this.updateFilter({
                    transactionStatusSelect: { name: 'All Transactions', id: 'All' },
                    accountIdSelect: { name: 'All Accounts', id: 'All' },
                    description: '',
                    amountFilterTypeSelect: { id: 'Amount', name: 'By Specific Amount' },
                    amount: null,
                    minAmount: null,
                    maxAmount: null,
                    startDate: null,
                    endDate: null,
                    startDateSelect: null,
                    endDateSelect: null,
                    page: 0,
                  });
                },
              },
              {
                color: 'secondary',
                variant: 'outlined',
                text: 'Upload Transactions',
                segmentProps: {
                  event: 'import_manually clicked',
                  location: 'Import Feed Empty State',
                },
                onClickFunction: () => {
                  this.setState({ uploadTransactionsDialog: true });
                },
              },
            ]}
          />
        )}
        {totalTransactions === 0 && !isFilterActive && hasAccounts && (
          <EmptyState
            icon="/icons/no_data_empty_state_icon.svg"
            title="You don’t have any transactions"
            body={
              <Typography variant="body1" align="center">
                If you&apos;re not seeing a transaction here, try checking the status of your linked accounts or you can
                also upload one below.
              </Typography>
            }
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Upload Transaction',
                onClickFunction: () => {
                  this.setState({ uploadTransactionsDialog: true });
                },
              },
              {
                color: 'secondary',
                variant: 'outlined',
                text: 'View Accounts',
                onClickFunction: () => {
                  history.push('/accounts/banking');
                  this.setState({ loading: true });
                },
              },
            ]}
          />
        )}
        {!hasAccounts && totalTransactions === 0 && !isFilterActive && (
          <EmptyState
            image="/rei_hub_accounts_intro_hero.png"
            title="Get your books in order within minutes"
            body={
              <Typography variant="body1" align="center">
                Add financial transactions securely and automatically by linking an account. It&apos;ll accurately and
                automatically categorize revenue and expenses, eliminating manual data entry.
              </Typography>
            }
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Import Automatically',
                segmentProps: {
                  event: 'import_automatically clicked',
                  location: 'Import Feed Empty State',
                },
                onClickFunction: () => {
                  history.push('/accounts/banking?add=linked');
                },
              },
              {
                color: 'secondary',
                variant: 'outlined',
                text: 'Import Manually',
                segmentProps: {
                  event: 'import_manually clicked',
                  location: 'Import Feed Empty State',
                },
                onClickFunction: () => {
                  history.push('/accounts/banking?add=manual');
                },
              },
            ]}
            videoProps={{
              source: 'https://www.youtube.com/embed/FsUJU6eHLys?rel=0&amp;wmode=opaque',
              videoSegmentProps: {
                event: 'watch_overview_video clicked',
                location: 'Import Feed Empty State',
              },
            }}
          />
        )}
        {totalTransactions > 0 && (
          <>
            <TransactionsListMobile
              page={filter.page}
              parentAndChildrenTransactions={parentsAndChildren}
              transactions={yodleeTransactions}
              transactionCount={totalTransactions}
              onPageChange={this.handleChangePage.bind(this)}
              onTransactionBooked={(shouldAddRule, journal, descriptionMatches) =>
                this.onAddQuickBookTransaction(shouldAddRule, journal, descriptionMatches)
              }
              onTransactionMenuClicked={this.handleTransactionMenuClick.bind(this)}
              onViewTransactionClicked={this.openViewBookedTransactionDialog.bind(this)}
              onUnignoreTransactionClicked={this.unignoreYodleeTransaction.bind(this)}
              onUnsplitTransactionClicked={this.openUnsplitDialog.bind(this)}
            />

            <Grid className={classes.importedTransactionsTable} item xs={12}>
              <CardBase>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TablePagination
                          count={totalTransactions}
                          page={filter.page}
                          rowsPerPage={TRANSACTIONS_PER_PAGE}
                          onPageChange={this.handleChangePage}
                          rowsPerPageOptions={[TRANSACTIONS_PER_PAGE]}
                          ActionsComponent={TablePaginationButtons}
                        />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {quickBookLines.length < 1 ? (
                            <Checkbox
                              color="primary"
                              onChange={(event, value) => {
                                this.addAllPageTransactionsToBatch(event, value);
                              }}
                              checked={batchPages.includes(filter.page) && batchChangeTargets.length > 0}
                              disabled={quickBookLines.length > 0 || loadingTransactions}
                            />
                          ) : (
                            <Tooltip
                              title={getCheckboxTooltip(quickBookLines.length)}
                              placement="top"
                              arrow
                              className={classes.disabledTooltip}
                            >
                              <div>
                                <Checkbox disabled />
                              </div>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Date</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Description</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Booked</Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          <Typography variant="subtitle2" align="right">
                            Amount
                          </Typography>
                        </TableCell>
                        {/* Hide the action column when batching... */}
                        {!batchChange && (
                          <TableCell>
                            <Typography variant="subtitle2" align="right">
                              Action
                            </Typography>
                          </TableCell>
                        )}
                        {/* ... and instead show a status column */}
                        {batchChange && (
                          <TableCell>
                            <Typography variant="subtitle2" align="right">
                              Status
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {yodleeTransactions.map((transaction) => (
                        <>
                          {!displaySplitChildrenRows && this.rowBuilder(transaction, false)}
                          {displaySplitChildrenRows && (
                            <>
                              {this.rowBuilder(transaction, false)}
                              {transaction.split && <>{this.getSplitChildRow(transaction)}</>}
                            </>
                          )}
                        </>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          variant="footer"
                          count={totalTransactions}
                          page={filter.page}
                          rowsPerPage={TRANSACTIONS_PER_PAGE}
                          onPageChange={this.handleChangePage}
                          rowsPerPageOptions={[TRANSACTIONS_PER_PAGE]}
                          ActionsComponent={TablePaginationButtons}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </CardBase>
            </Grid>
          </>
        )}
        {batchChange && (
          <BatchBookActionBar
            updateBatchType={this.updateBatchType}
            batchType={batchType}
            batchCount={batchChangeTargets.length}
            transactions={fullBatchChangeTargets}
            cancelBatchChange={this.cancelBatchChange}
            handleBatchBookingAction={this.handleBatchBookingAction}
          />
        )}

        <CelebrationBar
          isOpen={isOrganizationCelebrationVisible}
          messageType={selectedOrganizationCelebrationType}
          onClose={() => hideOrganizationCelebration()}
        />
      </PageGrid>
    );
  }
}

ImportedTransactions.contextType = PersonContext;

ImportedTransactions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ImportedTransactions);
