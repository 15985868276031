import React, { useMemo } from 'react';

import QuickBookRowCreditCardPaymentTemplate from './transactionTemplates/QuickBookRowCreditCardPaymentTemplate';
import QuickBookRowEscrowExpenseTemplate from './transactionTemplates/QuickBookRowEscrowExpenseTemplate';
import QuickBookRowExpenseTemplate from './transactionTemplates/QuickBookRowExpenseTemplate';
import QuickBookRowFixedAssetPurchaseTemplate from './transactionTemplates/QuickBookRowFixedAssetPurchaseTemplate';
import QuickBookRowIncomeMatchTemplate from './transactionTemplates/QuickBookRowIncomeMatchTemplate';
import QuickBookRowLoanPaymentTemplate from './transactionTemplates/QuickBookRowLoanPaymentTemplate';
import QuickBookRowMatchTemplate from './transactionTemplates/QuickBookRowMatchTemplate';
import QuickBookRowNetIncomeTemplate from './transactionTemplates/QuickBookRowNetIncomeTemplate';
import QuickBookRowOwnerContributionTemplate from './transactionTemplates/QuickBookRowOwnerContributionTemplate';
import QuickBookRowOwnerDistributionTemplate from './transactionTemplates/QuickBookRowOwnerDistributionTemplate';
import QuickBookRowPartnerDepositTemplate from './transactionTemplates/QuickBookRowPartnerDepositTemplate';
import QuickBookRowRefundIssuedTemplate from './transactionTemplates/QuickBookRowRefundIssuedTemplate';
import QuickBookRowRefundReceivedTemplate from './transactionTemplates/QuickBookRowRefundReceivedTemplate';
import QuickBookRowRevenueTemplate from './transactionTemplates/QuickBookRowRevenueTemplate';
import QuickBookRowSecurityDepositReceiveTemplate from './transactionTemplates/QuickBookRowSecurityDepositReceiveTemplate';
import QuickBookRowSecurityDepositReturnTemplate from './transactionTemplates/QuickBookRowSecurityDepositReturnTemplate';
import QuickBookRowTransferTemplate from './transactionTemplates/QuickBookRowTransferTemplate';
import { useQuickBookRowContext } from './context';

export default function QuickBookRowTemplate() {
  const { matchState, selectedTransactionType, transaction, onGetHelp, onTransactionAction, onTransactionTypeChange } =
    useQuickBookRowContext();

  const commonProps = useMemo(
    () => ({
      transaction,
      onGetHelp,
      onTransactionAction,
    }),
    [transaction, onGetHelp, onTransactionAction],
  );

  switch (selectedTransactionType?.value) {
    case 'Match':
      return (
        <QuickBookRowMatchTemplate
          {...commonProps}
          matchState={matchState}
          onTransactionTypeChange={onTransactionTypeChange}
        />
      );
    case 'Expense':
      return (
        <QuickBookRowExpenseTemplate
          {...commonProps}
          onTransactionTypeChange={onTransactionTypeChange}
          matchState={matchState}
        />
      );
    case 'Transfer':
      return <QuickBookRowTransferTemplate {...commonProps} matchState={matchState} />;
    case 'Revenue':
      return (
        <QuickBookRowRevenueTemplate
          {...commonProps}
          onTransactionTypeChange={onTransactionTypeChange}
          matchState={matchState}
        />
      );
    case 'securityDepositReceived':
      return <QuickBookRowSecurityDepositReceiveTemplate {...commonProps} />;
    case 'securityDepositRefunded':
      return <QuickBookRowSecurityDepositReturnTemplate {...commonProps} />;
    case 'Refund Issued':
      return <QuickBookRowRefundIssuedTemplate {...commonProps} />;
    case 'Fixed Asset Purchase':
      return (
        <QuickBookRowFixedAssetPurchaseTemplate
          {...commonProps}
          matchState={matchState}
          onTransactionTypeChange={onTransactionTypeChange}
        />
      );
    case 'Distribution':
      return <QuickBookRowOwnerDistributionTemplate {...commonProps} />;
    case 'Credit Card Payment':
      return (
        <QuickBookRowCreditCardPaymentTemplate {...commonProps} onTransactionTypeChange={onTransactionTypeChange} />
      );
    case 'Loan Payment':
      return <QuickBookRowLoanPaymentTemplate {...commonProps} matchState={matchState} />;
    case 'Refund':
      return <QuickBookRowRefundReceivedTemplate {...commonProps} />;
    case 'Partner Deposit':
      return <QuickBookRowPartnerDepositTemplate {...commonProps} onTransactionTypeChange={onTransactionTypeChange} />;
    case 'Net Income':
      return <QuickBookRowNetIncomeTemplate {...commonProps} />;
    case 'Contribution':
      return <QuickBookRowOwnerContributionTemplate {...commonProps} />;
    case 'Income Template Match':
      return (
        <QuickBookRowIncomeMatchTemplate
          {...commonProps}
          matchState={matchState}
          onTransactionTypeChange={onTransactionTypeChange}
        />
      );
    case 'Escrow Expense':
      return <QuickBookRowEscrowExpenseTemplate {...commonProps} />;
    default:
      return null;
  }
}
