import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, InputAdornment, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';

import Button from '~/components/Button';
import Confetti from '~/components/Confetti';
import PasswordStrengthChecker from '~/components/PasswordStrengthChecker';
import { STATUSES as PASSWORD_STATUSES } from '~/constants/password-requirements';
import { PersonContext } from '~/contexts/PersonContext';
import { get, patch } from '~/feathersFunctionalWrapper';
import history from '~/history';
import usePasswordRequirements from '~/hooks/usePasswordRequirements';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: '4%',
    justifyItems: 'center',
  },
  cardRoot: {
    display: 'flex',
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    width: '100%',
    maxWidth: '550px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3.75),
    alignSelf: 'stretch',
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    alignSelf: 'stretch',
  },
  passwordFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    alignSelf: 'stretch',
  },
  passwordRequirements: {
    marginRight: 'auto',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  successIcon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    color: theme.palette.success.main,
  },
}));

export default function AdminPasswordReset() {
  const { adminLogin, adminUserSelect, setAdminUserSelect } = useContext(PersonContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordSubmitted, setIsPasswordSubmitted] = useState(false);
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [passwordWasReset, setPasswordWasReset] = useState(false);
  const passwordRequirements = usePasswordRequirements(password, isPasswordSubmitted);
  const isPasswordValid = useMemo(
    () => Object.values(passwordRequirements).every((status) => status === PASSWORD_STATUSES.MET),
    [passwordRequirements],
  );

  useEffect(() => {
    const getUser = async () => {
      const user = await get('users', adminUserSelect.id);
      setUser(user);
      setLoading(false);
    };
    getUser();
  }, [adminUserSelect]);

  useEffect(() => {
    if (isPasswordValid) {
      setError(null);
    }
  }, [isPasswordValid, password, confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (!isPasswordValid) {
      setError('Password does not meet the requirements.');
      return;
    }

    if (password !== confirmPassword) {
      setError('New password and confirmation password are not equal.');
      return;
    }

    setIsSubmitting(true);
    setIsPasswordSubmitted(true);

    try {
      await patch('users', user.id, {
        password,
        isAdminPasswordReset: adminLogin,
      });
      setPasswordWasReset(true);
      setIsSubmitting(false);
    } catch (error) {
      setError(error.message);
      setIsSubmitting(false);
      return;
    }
  };

  if (loading) {
    return null;
  }

  if (passwordWasReset) {
    return (
      <>
        <Confetti />
        <Grid item xs={12} className={classes.root}>
          <Paper className={classes.cardRoot}>
            <CheckCircleIcon className={classes.successIcon} />
            <div className={classes.headingContainer}>
              <Typography variant="h5">Password Successfully Reset</Typography>
              <Typography variant="body1">{`Password successfully reset for ${user.firstName} ${user.lastName}`}</Typography>
            </div>
            <div className={classes.buttonsContainer}>
              <Button color="primary" variant="contained" hasFullWidth onClick={() => setAdminUserSelect(null)}>
                Return to Dashboard
              </Button>
              <Button color="primary" variant="outlined" hasFullWidth onClick={() => history.goBack()}>
                Go to User Profile
              </Button>
            </div>
          </Paper>
        </Grid>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12} className={classes.root}>
        <Paper className={classes.cardRoot}>
          <div className={classes.headingContainer}>
            <Typography variant="h5">Reset Password</Typography>
            <Typography variant="body1">{`Resetting password for ${user.firstName} ${user.lastName}`}</Typography>
          </div>
          <div className={classes.passwordFieldsContainer}>
            <TextField
              label="Password*"
              id="password"
              fullWidth
              required
              variant="outlined"
              className={classes.textField}
              InputProps={{
                value: password,
                name: 'loginFormPassword',
                onChange: (event) => setPassword(event.target.value),
                type: showPassword ? 'text' : 'password',
                autoComplete: 'current-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityIcon className={classes.visibilityIcon} />
                      ) : (
                        <VisibilityOffIcon className={classes.visibilityIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password*"
              id="confirm-password"
              fullWidth
              required
              variant="outlined"
              className={classes.textField}
              InputProps={{
                value: confirmPassword,
                name: 'loginFormPassword',
                onChange: (event) => setConfirmPassword(event.target.value),
                type: showConfirmPassword ? 'text' : 'password',
                autoComplete: 'current-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      {showConfirmPassword ? (
                        <VisibilityIcon className={classes.visibilityIcon} />
                      ) : (
                        <VisibilityOffIcon className={classes.visibilityIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <PasswordStrengthChecker requirements={passwordRequirements} className={classes.passwordRequirements} />
          {error && <Typography color="error">{error}</Typography>}
          <div className={classes.buttonsContainer}>
            <Button color="primary" type="submit" variant="contained" disabled={isSubmitting} hasFullWidth>
              Set New Password
            </Button>
            <Button color="primary" variant="outlined" onClick={() => history.goBack()} hasFullWidth>
              Cancel
            </Button>
          </div>
        </Paper>
      </Grid>
    </form>
  );
}
