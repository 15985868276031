import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { PersonContext } from '~/contexts/PersonContext';
import { patch } from '~/feathersFunctionalWrapper';
import { checkIsNegativeAmount } from '~/functions/AmountFunctions';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
  },
  upperTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  createARuleText: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  alert: {
    paddingBottom: theme.spacing(1),
  },
  alertTitle: {
    fontSize: '16px',
    marginBottom: 0,
  },
  gradientOverlay: {
    maxHeight: '350px',
    mask: 'linear-gradient(to bottom, rgb(255, 255, 255) 80%, rgba(0,0,0,0) 100%)',
  },
  transactionsTableContainer: {
    maxHeight: '250px',
    paddingBottom: theme.spacing(4),
    overflowY: 'scroll',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifySelf: 'flex-start',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '330px',
    width: '100%',
    alignSelf: 'center',
  },
  negativeAmountText: {
    color: theme.palette.error.main,
  },
  mobileMatchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  mobileMatchRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  mobileMatchDescriptionAndAccount: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  mobileAmount: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
}));

const SEGMENT_LOCATION = 'Rule Suggestion Modal';

export default function SuggestRuleDialog({ descriptionMatches, ruleJournal, closeDialog, onAddRule }) {
  const classes = useStyles();
  const tracking = useSegmentTrack();
  const isSmallScreen = checkIsSmallScreen();
  const { organizationId, setOrganizationSettings } = useContext(PersonContext);
  const [isOptOutChecked, setIsOptOutChecked] = useState(false);

  useEffect(() => {
    tracking('rule_suggestion_modal loaded', { location: 'Import Feed' });
    // We only wan to event to fire once on the initial load of the component hence the empty
    // dependency array and the eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptOut = async () => {
    if (isOptOutChecked) {
      await patch('organizations', organizationId, { settings: { shouldHideSuggestRule: true } });
      setOrganizationSettings({ shouldHideSuggestRule: true });
    }
  };

  return (
    <Dialog open maxWidth="sm" fullWidth scroll="paper">
      <div className={classes.dialogContentContainer}>
        <div className={classes.upperTextContainer}>
          <Typography variant="caption" className={classes.createARuleText}>
            CREATE A RULE
          </Typography>
          <Typography variant="h5">{`Auto book ${descriptionMatches.length} similar transaction${descriptionMatches.length > 1 ? 's' : ''}?`}</Typography>
        </div>
        <Alert severity="info">
          <AlertTitle className={classes.alertTitle}>
            Rules are ideal for repeat transactions that have the same type, account, and applicable property or
            portfolio.
          </AlertTitle>
        </Alert>
        <div className={descriptionMatches.length > 3 ? classes.gradientOverlay : ''}>
          <div className={classes.transactionsTableContainer}>
            {isSmallScreen ? (
              <>
                {descriptionMatches.map((match, index) => (
                  <div key={match.id} className={classes.mobileMatchContainer}>
                    <div className={classes.mobileMatchRow}>
                      <div className={classes.mobileMatchDescriptionAndAccount}>
                        <Typography variant="body2">{match.description}</Typography>
                        <Typography variant="caption">{match.account.name}</Typography>
                      </div>
                      <div className={classes.mobileAmount}>
                        <Typography
                          className={
                            checkIsNegativeAmount(match.account.type, match.yodleeType)
                              ? classes.negativeAmountText
                              : null
                          }
                        >
                          {checkIsNegativeAmount(match.account.type, match.yodleeType)
                            ? `($${match.amount.toFixed(2)})`
                            : `$${match.amount.toFixed(2)}`}
                        </Typography>
                      </div>
                    </div>
                    {index !== descriptionMatches.length - 1 && <Divider />}
                  </div>
                ))}
              </>
            ) : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {descriptionMatches.map((match) => (
                    <TableRow key={match.id}>
                      <TableCell>{moment(match.date).format('MM/DD/YYYY')}</TableCell>
                      <TableCell>
                        <Typography variant="body2">{match.description}</Typography>
                        <Typography variant="caption">{match.account.name}</Typography>
                      </TableCell>
                      <TableCell
                        className={
                          checkIsNegativeAmount(match.account.type, match.yodleeType)
                            ? classes.negativeAmountText
                            : null
                        }
                      >
                        {checkIsNegativeAmount(match.account.type, match.yodleeType)
                          ? `($${match.amount.toFixed(2)})`
                          : `$${match.amount.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
        <div className={classes.checkBoxContainer}>
          <Checkbox
            size="medium"
            color="primary"
            checked={isOptOutChecked}
            onClick={() => {
              isOptOutChecked
                ? tracking('dont_ask_me_again unchecked', { location: SEGMENT_LOCATION })
                : tracking('dont_ask_me_again checked', { location: SEGMENT_LOCATION });
              setIsOptOutChecked(!isOptOutChecked);
            }}
          />
          <Typography variant="body2">Don't ask me this again</Typography>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="primary"
            hasFullWidth
            onClick={() => {
              tracking('continue clicked', { location: SEGMENT_LOCATION });
              handleOptOut();
              onAddRule(true, ruleJournal);
              closeDialog();
            }}
          >
            Continue
          </Button>
          <Button
            variant="outlined"
            color="primary"
            hasFullWidth
            onClick={() => {
              tracking('not_right_now clicked', { location: SEGMENT_LOCATION });
              handleOptOut();
              closeDialog();
            }}
          >
            Not Right Now
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

SuggestRuleDialog.propTypes = {
  descriptionMatches: PropTypes.arrayOf(PropTypes.object).isRequired,
  ruleJournal: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddRule: PropTypes.func.isRequired,
};
