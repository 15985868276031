import React, { Component } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import { create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';

const styles = {
  fastlink: {
    position: 'relative',
    height: '600px',
  },
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class YodleeLinkAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initializeURL: import.meta.env.VITE_YODLEE_FASTLINK_SCRIPT,
    };
  }

  onYodleeSuccess = async () => {
    const { importStartDate } = this.props;
    const { organizationId } = this.context;
    await create(this, 'yodlee-accounts', {
      organizationId,
      importStartDate,
    });
  };

  onScriptError = () => {
    console.error('There was an issue loading the initialize.js script');
  };

  onScriptLoaded = async () => {
    const { organizationId } = this.context;
    const { onSuccess } = this.props;

    const tokenResponse = await create(this, 'yodlee-tokens', {
      organizationId,
      tokenType: 'organization',
      register: true,
    });
    const { token } = tokenResponse;

    const params = {
      configName: 'Aggregation',
    };

    let fastLinkURL;
    if (import.meta.env.PROD) {
      if (organizationId > 397) {
        fastLinkURL = import.meta.env.VITE_YODLEE_FASTLINK_URL_PRODUCTION;
      } else {
        fastLinkURL = import.meta.env.VITE_YODLEE_FASTLINK_URL_DEVELOPMENT;
      }
    } else {
      fastLinkURL = import.meta.env.VITE_YODLEE_FASTLINK_URL;
    }
    console.log(fastLinkURL);
    window.fastlink.open(
      {
        fastLinkURL,
        accessToken: `Bearer ${token}`,
        params,
        onSuccess: (data) => {
          console.log(data);
          // don't close the dialog - that stops account selection
          // this.closeDialog();
        },
        onError: (data) => {
          console.log(data);
        },
        onClose: async (data) => {
          console.log(data);
          if (data.sites.length > 0 && data.sites[0].status === 'SUCCESS') {
            window.fastlink.close();
            await this.onYodleeSuccess();
            onSuccess(data.sites[0]);
          } else {
            this.closeDialog();
          }
        },
        onEvent: (data) => {
          console.log(data);
        },
      },
      'container-fastlink',
    );
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    window.fastlink.close();
    closeDialog();
  };

  render() {
    const { initializeURL } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open
          TransitionComponent={Transition}
          scroll="body"
          onEnter={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="addYodleeAccount"
          fullScreen
        >
          <div id="container-fastlink" className={classes.fastlink} />
        </Dialog>
        <Script url={initializeURL} onError={this.onScriptError} onLoad={this.onScriptLoaded} />
      </>
    );
  }
}

YodleeLinkAdd.contextType = PersonContext;

YodleeLinkAdd.defaultProps = {};

YodleeLinkAdd.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  importStartDate: PropTypes.string.isRequired,
};

export default withStyles(styles)(YodleeLinkAdd);
