export const BATCH_TYPES = {
  EXPENSE: 'Book as Expenses',
  REVENUE: 'Book as Revenues',
  TRANSFER: 'Book as Transfers',
  FIXED_ASSET_PURCHASE: 'Book as Fixed Asset Purchases',
  IGNORE: 'Ignore',
  UNIGNORE: 'Unignore',
  UNDO: 'Undo Bookings',
  DELETE: 'Delete',
};
