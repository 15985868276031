import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import TransactionScope from '~/components/TransactionScope';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { getBalanceSheetAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function BatchBookTransfersDialog({ closeDialog, fullTransactions, onBatchBookTransfers }) {
  const { organizationName, organizationId } = useContext(PersonContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transferAccount, setTransferAccount] = useState(null);
  const [journal, setJournal] = useState({
    ...getManualBasicJournal(),
    journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
    type: 'Transfer',
  });
  const [balanceSheetAccountOptions, setBalanceSheetAccountOptions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setBalanceSheetAccountOptions(await getBalanceSheetAccountOptions(organizationId));
      setIsLoading(false);
    };
    getData();
  }, [organizationId]);

  const batchBookTransfers = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setJournalScope(journal);

    const batchJournalsArray = [];

    fullTransactions.forEach((transaction) => {
      const journalTemplate = getManualBasicJournal();
      const transactionType = transaction.yodleeType;

      journalTemplate.organizationId = organizationId;
      journalTemplate.description = transaction.description;
      journalTemplate.date = transaction.date;
      journalTemplate.amount = transaction.amount;

      journalTemplate.journalScopeSelect = journal.journalScopeSelect;
      journalTemplate.propertyIdSelect = journal.propertyIdSelect;
      journalTemplate.propertyId = journal.propertyId;
      journalTemplate.unitIdSelect = journal.unitIdSelect;
      journalTemplate.unitId = journal.unitId;
      journalTemplate.entityIdSelect = journal.entityIdSelect;
      journalTemplate.entityId = journal.entityId;

      journalTemplate.type = journal.type;

      journalTemplate.debitLines[0].debit = transaction.amount;
      journalTemplate.creditLines[0].credit = transaction.amount;

      if (transactionType === 'CREDIT') {
        journalTemplate.debitLines[0].accountId = transaction.accountId;
        journalTemplate.debitLines[0].accountIdSelect = transaction.account;
        journalTemplate.debitLines[0].yodleeTransactionId = transaction.id;
        journalTemplate.creditLines[0].accountId = transferAccount.id;
        journalTemplate.creditLines[0].accountIdSelect = transferAccount;
      } else if (transactionType === 'DEBIT') {
        journalTemplate.creditLines[0].accountId = transaction.accountId;
        journalTemplate.creditLines[0].accountIdSelect = transaction.account;
        journalTemplate.creditLines[0].yodleeTransactionId = transaction.id;
        journalTemplate.debitLines[0].accountId = transferAccount.id;
        journalTemplate.debitLines[0].accountIdSelect = transferAccount;
      }

      journalTemplate.journalLines = journalTemplate.debitLines.concat(journalTemplate.creditLines);

      batchJournalsArray.push(journalTemplate);
    });

    const createPromises = batchJournalsArray.map((batchJournalsArrayItem) =>
      create('journals', batchJournalsArrayItem, true),
    );

    try {
      await Promise.all(createPromises);
      setIsSubmitting(false);
      closeDialog();
      onBatchBookTransfers();
    } catch (error) {
      setError(error.message);
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog
      open
      scroll="body"
      fullWidth
      disableBackdropClick
      disableEnforceFocus
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="addTransactionDialog"
    >
      <DialogTitle>Book Transfers</DialogTitle>
      <form onSubmit={batchBookTransfers}>
        <DialogContent>
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValue) => setJournal({ ...journal, ...newScopeValue })}
          />
          <div className={classes.autocompleteContainer}>
            <AutocompleteField
              options={balanceSheetAccountOptions}
              value={transferAccount}
              onChange={(value) => setTransferAccount(value)}
              label="Transfer Account"
              placeholder="Type to Search"
              required
              textFieldVariant="standard"
              disableShrink
            />
          </div>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined" size="medium">
            Cancel
          </Button>
          <Button color="primary" variant="contained" size="medium" type="submit" disabled={isSubmitting}>
            {`Book ${pluralize('Transfer', fullTransactions.length, true)}`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

BatchBookTransfersDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fullTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBatchBookTransfers: PropTypes.func.isRequired,
};
