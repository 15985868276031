import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Radio, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, patch } from '~/feathersFunctionalWrapper';
import { checkIsNegativeAmount } from '~/functions/AmountFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import {
  getPropertyOptions,
  getSubportfolioOptions,
  getUnitOptions,
} from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowLoading from '../QuickBookRowLoading';

export default function QuickBookRowMatchTemplate({
  transaction,
  onTransactionAction,
  matchState,
  onTransactionTypeChange,
}) {
  const classes = useStyles();
  const tracking = useSegmentTrack();
  const { organizationId, organizationName } = useContext(PersonContext);
  const [selectedTransactionMatch, setSelectedTransactionMatch] = useState(null);
  const [selectedRuleMatch, setSelectedRuleMatch] = useState(null);
  const [selectedIncomeTemplateMatch, setSelectedIncomeTemplateMatch] = useState(null);
  const [transactionMatches, setTransactionMatches] = useState([]);
  const [ruleMatches, setRuleMatches] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(true);

  const transactionMatchCount = matchState.journalMatch.concat(matchState.ownerFundsMatch).length;
  const ruleMatchCount = matchState.importedTransactionRuleMatch.length;
  const incomeTemplateMatchCount = matchState.incomeTemplateMatch.length;
  const multipleMatches = transactionMatchCount + ruleMatchCount + incomeTemplateMatchCount > 1;

  useEffect(() => {
    const getMatchScope = (match, properties, units, entities) => {
      if (match.propertyId) {
        const property = properties.value.find((property) => property.id === match.propertyId);
        if (match.unitId) {
          const unit = units.value.find((unit) => unit.id === match.unitId);
          return (match.scopeSelect = `${property.address1} - ${unit.name}`);
        }
        return (match.scopeSelect = property.address1);
      } else if (match.entityId) {
        return (match.scopeSelect = entities.value.find((entity) => entity.id === match.entityId).name);
      }
      return (match.scopeSelect = `${organizationName} Portfolio`);
    };

    const setUpMatchData = async () => {
      const promises = [
        getPropertyOptions(organizationId),
        getUnitOptions(organizationId),
        getSubportfolioOptions(organizationId),
      ];
      const [properties, units, entities] = await Promise.allSettled(promises);

      const transactionMatches = [...matchState.journalMatch.concat(matchState.ownerFundsMatch)];
      transactionMatches.forEach(async (match) => {
        getMatchScope(match.journal, properties, units, entities);

        if (match.type === 'debit') {
          match.journalAccountType = match.journal.journalLines.find((line) => line.credit).account.name;
        } else {
          match.journalAccountType = match.journal.journalLines.find((line) => line.debit).account.name;
        }
      });

      setTransactionMatches(transactionMatches);

      const ruleMatches = [...matchState.importedTransactionRuleMatch];
      ruleMatches.forEach((match) => {
        match.journal = getManualBasicJournal();
        match.journal.propertyId = match.propertyId;
        match.journal.unitId = match.unitId;
        match.journal.entityId = match.entityId;
        match.journal.vendorId = match.vendorId;
        match.journal.type = match.type;
        match.journal.date = transaction.date;
        match.journal.fixedAssetId = match.fixedAssetId;
        match.journal.description = transaction.description;
        match.journal.amount = transaction.amount;
        match.journal.organizationId = organizationId;

        switch (match.type) {
          case 'Expense':
          case 'Transfer To':
          case 'Fixed Asset Purchase':
            match.journal.debitLines[0].account = match.destinationAccount;
            match.journal.debitLines[0].accountId = match.destinationAccount.id;
            match.journal.debitLines[0].debit = transaction.amount;
            match.journal.creditLines[0].account = transaction.account;
            match.journal.creditLines[0].accountId = transaction.account.id;
            match.journal.creditLines[0].credit = transaction.amount;
            match.journal.creditLines[0].yodleeTransactionId = transaction.id;
            break;
          default:
            match.journal.creditLines[0].account = match.destinationAccount;
            match.journal.creditLines[0].accountId = match.destinationAccount.id;
            match.journal.creditLines[0].credit = transaction.amount;
            match.journal.debitLines[0].account = transaction.account;
            match.journal.debitLines[0].accountId = transaction.account.id;
            match.journal.debitLines[0].debit = transaction.amount;
            match.journal.debitLines[0].yodleeTransactionId = transaction.id;
            break;
        }
        match.journal.journalLines = match.journal.debitLines.concat(match.journal.creditLines);
        getMatchScope(match.journal, properties, units, entities);
      });
      setRuleMatches(ruleMatches);

      if (!multipleMatches) {
        if (transactionMatches.length === 1) {
          setSelectedTransactionMatch(transactionMatches[0]);
        } else if (ruleMatches.length === 1) {
          setSelectedRuleMatch(ruleMatches[0]);
        } else if (matchState.incomeTemplateMatch.length === 1) {
          setSelectedIncomeTemplateMatch(matchState.incomeTemplateMatch[0]);
        }
      }

      setLoading(false);
    };

    setUpMatchData();
  }, [matchState, multipleMatches, organizationId, organizationName, transaction]);

  useEffect(() => {
    if ((selectedTransactionMatch || selectedRuleMatch) && error?.message === 'Please select an option to book') {
      setError(null);
    }
  }, [selectedTransactionMatch, selectedRuleMatch, error]);

  const bookAsMatch = async () => {
    if (isSubmitting) {
      return;
    }

    if (
      (transactionMatches.length > 1 || ruleMatches.length || matchState.incomeTemplateMatch[0]) &&
      !selectedTransactionMatch &&
      !selectedRuleMatch &&
      !selectedIncomeTemplateMatch
    ) {
      setError({ message: 'Please select an option to book' });
      return;
    }

    if (selectedIncomeTemplateMatch) {
      onTransactionTypeChange({
        label: 'Income Template Match',
        value: 'Income Template Match',
      });
      return;
    }

    setIsSubmitting(true);

    if (selectedTransactionMatch) {
      const journalLinesPromises = [];

      journalLinesPromises.push(
        patch('journal-lines', selectedTransactionMatch.id, {
          yodleeTransactionId: transaction.id,
        }),
      );
      journalLinesPromises.push(
        patch('yodlee-transactions', transaction.id, {
          journalId: selectedTransactionMatch.journalId,
          journalLineId: selectedTransactionMatch.id,
        }),
      );

      try {
        await Promise.allSettled(journalLinesPromises);
        setIsSubmitting(false);
        onTransactionAction();
      } catch (err) {
        setError(err);
        setIsSubmitting(false);
        return;
      }
    } else if (selectedRuleMatch) {
      try {
        await create('journals', selectedRuleMatch.journal);
        setIsSubmitting(false);
        onTransactionAction();
      } catch (err) {
        setError(err);
        setIsSubmitting(false);
        return;
      }
    }
  };

  const handleRadioClick = (match, type) => {
    if (type === 'transaction') {
      setSelectedRuleMatch(null);
      setSelectedIncomeTemplateMatch(null);
      setSelectedTransactionMatch(match);
    } else if (type === 'rule') {
      setSelectedTransactionMatch(null);
      setSelectedIncomeTemplateMatch(null);
      setSelectedRuleMatch(match);
    } else {
      setSelectedTransactionMatch(null);
      setSelectedRuleMatch(null);
      setSelectedIncomeTemplateMatch(match);
    }
  };

  if (loading) {
    return <QuickBookRowLoading />;
  }

  return (
    <Box className={classes.rootContainer}>
      {transactionMatchCount > 0 && (
        <>
          <Typography variant="subtitle2">
            {transactionMatchCount > 1
              ? 'We found existing transactions below. Select the correct matching transaction to match to avoid double booking.'
              : 'We found an existing transaction below. Save this transaction as a match to avoid double booking.'}
          </Typography>
          <Alert severity="warning" className={classes.matchAlert}>
            <AlertTitle>{pluralize('Matching Transaction', transactionMatchCount)}</AlertTitle>
            <Box className={classes.matchRowsContainer}>
              {transactionMatches.map((match, index) => (
                <React.Fragment key={match.id}>
                  <Box className={classes.matchRowContainer}>
                    {multipleMatches && (
                      <Radio
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleRadioClick(match, 'transaction');
                        }}
                        checked={match.id === selectedTransactionMatch?.id}
                      />
                    )}
                    <Typography color="inherit" variant="caption">
                      {`${moment(match.journal.date).format('M/DD/YYYY')} • ${match.account.name}${match.journal.description ? ` • Description: ${match.journal.description}` : ''} • Booked as `}
                      <Box component="span" className={classes.boldText}>
                        {`${match.journal.type} (${match.journalAccountType}) `}
                      </Box>
                      for{' '}
                      <Box component="span" className={classes.boldText}>
                        {match.journal.scopeSelect}
                      </Box>
                    </Typography>
                    <Box className={classes.left}>
                      {checkIsNegativeAmount(match.accountType, match.type) ? (
                        <Typography color="error" variant="subtitle2">
                          {`-$${Number(match.amount).toFixed(2)}`}
                        </Typography>
                      ) : (
                        <Typography color="inherit" variant="subtitle2">
                          {`$${Number(match.amount).toFixed(2)}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {index < transactionMatches.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </Alert>
        </>
      )}
      {ruleMatchCount > 0 && (
        <>
          <Typography variant="subtitle2">
            {`${ruleMatchCount > 1 ? 'We found existing rules below' : 'We found an existing rule below'}. Select the correct rule to use as a template to book this transaction.`}
          </Typography>
          <Alert severity="warning" className={classes.matchAlert}>
            <AlertTitle>{pluralize('Matching Rule', ruleMatchCount)}</AlertTitle>
            <Box className={classes.matchRowsContainer}>
              {ruleMatches.map((match, index) => (
                <React.Fragment key={match.id}>
                  <Box className={classes.matchRowContainer}>
                    <Radio
                      color="primary"
                      size="small"
                      onClick={() => {
                        handleRadioClick(match, 'rule');
                      }}
                      checked={match.id === selectedRuleMatch?.id}
                    />
                    <Typography color="inherit" variant="caption">
                      <Box component="span" className={classes.boldText}>
                        {`${match.type}: ${match.destinationAccount.name} `}
                      </Box>
                      {`(Description Contains: ${match.descriptionContains}${match.transactionAmount ? `, Amount: $${match.transactionAmount.toFixed(2)}` : ''}) - ${match.journal.scopeSelect}`}
                    </Typography>
                  </Box>
                  {index < ruleMatches.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </Alert>
        </>
      )}
      {matchState.incomeTemplateMatch.length > 0 && (
        <>
          <Typography variant="subtitle2">
            {` We found an income template that matches this transaction. ${multipleMatches ? 'Select the option below to book using this template.' : 'Please click the Match & Save button below to book using this template.'}`}
          </Typography>
          <Alert severity="warning" className={classes.matchAlert}>
            <AlertTitle>Matching Template</AlertTitle>
            <Box className={classes.matchRowsContainer}>
              <Box className={classes.matchRowContainer}>
                {multipleMatches && (
                  <Radio
                    color="primary"
                    size="small"
                    onClick={() => {
                      handleRadioClick(matchState.incomeTemplateMatch[0], 'template');
                    }}
                    checked={matchState.incomeTemplateMatch[0].name === selectedIncomeTemplateMatch?.name}
                  />
                )}
                <Typography color="inherit" variant="caption">
                  <Box component="span" className={classes.boldText}>
                    {`${matchState.incomeTemplateMatch[0].type}: ${matchState.incomeTemplateMatch[0].name} `}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Alert>
        </>
      )}
      {error?.message && (
        <Alert severity="error" className={classes.errorAlert}>
          {error.message}
        </Alert>
      )}
      <Box className={classes.actionsContainer}>
        <Box className={classes.rowActionsSecondaryContainer}>
          <Button
            variant="outlined"
            className={classes.saveButton}
            size="small"
            fullWidth={false}
            type="submit"
            onClick={() => {
              tracking('book_separately', { location: 'Inline Booking - Import Feed' });
              onTransactionTypeChange();
            }}
          >
            Book Separately
          </Button>
          <Button
            variant="contained"
            className={classes.saveButton}
            size="small"
            fullWidth={false}
            type="submit"
            onClick={() => {
              tracking('match_and_save', { location: 'Inline Booking - Import Feed' });
              bookAsMatch();
            }}
          >
            Match & Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

QuickBookRowMatchTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  matchState: PropTypes.object.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
};
