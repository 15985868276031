import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import Confetti from '~/components/Confetti';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4),
    paddingInline: theme.spacing(3),
    paddingTop: theme.spacing(5),
    width: '440px',
  },
  icon: {
    height: '80px',
    marginBottom: theme.spacing(2),
    width: '80px',
  },
  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    minHeight: theme.spacing(4),
  },
  itemIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(3),
  },
  button: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: '240px',
    },
  },
}));

function FreemiumSuccessDialog({ isOpen, onDone }) {
  const [dialogRoot, setDialogRoot] = useState(null);

  const isSmallScreen = checkIsSmallScreen();
  const classes = useStyles();

  return (
    <>
      <Dialog
        classes={{ paper: classes.container }}
        id="freemium-success-dialog"
        open={isOpen}
        TransitionProps={{
          onEntered: () => {
            setDialogRoot(document.getElementById('freemium-success-dialog'));
          },
          onExiting: () => {
            setDialogRoot(null);
          },
        }}
      >
        <img alt="party popper emoji" className={classes.icon} src="/icons/party_popper_icon.svg" />
        <Typography className={classes.title} variant={isSmallScreen ? 'h6' : 'h5'}>
          You've Been Upgraded!
        </Typography>

        <Typography className={classes.subtitle} variant="body2">
          Enjoy 3 free months of our premium plan to get you through tax season—no credit card required!
        </Typography>

        <div className={classes.list}>
          <div className={classes.item}>
            <CheckCircleIcon className={classes.itemIcon} />
            <Typography variant="body2">Auto-import transactions, saving you time</Typography>
          </div>
          <div className={classes.item}>
            <CheckCircleIcon className={classes.itemIcon} />
            <Typography variant="body2">Link directly to your bank to improve accuracy</Typography>
          </div>
          <div className={classes.item}>
            <CheckCircleIcon className={classes.itemIcon} />
            <Typography variant="body2">Includes unlimited rental properties</Typography>
          </div>
          <div className={classes.item}>
            <CheckCircleIcon className={classes.itemIcon} />
            <Typography variant="body2">Expert help from our customer support team</Typography>
          </div>
        </div>

        <Button className={classes.button} size="large" onClick={onDone}>
          Get Started
        </Button>
      </Dialog>

      {dialogRoot && createPortal(<Confetti />, dialogRoot)}
    </>
  );
}

FreemiumSuccessDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default FreemiumSuccessDialog;
