import React, { useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import Button from '~/components/Button';
import CancelSubscriptionDialog from '~/components/CancelSubscriptionDialog';
import CardBase from '~/components/CardBase';
import ExpertSupportSubscribeDialog from '~/components/ExpertSupportSubscribeDialog';
import PageGrid from '~/components/PageGrid';
import PageHeader from '~/components/PageHeader';
import UpdateSubscriptionDialog from '~/components/UpdateSubscriptionDialog';
import UpdateSubscriptionPaymentDialog from '~/components/UpdateSubscriptionPaymentDialog';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const searchParams = new URLSearchParams(window.location.search);

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  button: {
    maxWidth: 'fit-content',
  },
  italicText: {
    fontStyle: 'italic',
  },
  boldText: {
    fontWeight: 'bold',
  },
  disclaimerText: {
    color: theme.palette.grey[600],
  },
  giftIcon: {
    fontSize: theme.tokens.typography.fontSize.h5,
    marginRight: theme.spacing(1),
    position: 'relative',
    top: theme.spacing(0.25),
  },
}));

export default function Subscription() {
  const {
    demo,
    partnerSubscribed,
    partnerName,
    promptPayment,
    setDismissPayment,
    freeTrialExpires,
    subscriptionPaymentMethod,
    subscriptionItem,
    expertSupportSubscription,
    freemiumSubscription,
  } = useContext(PersonContext);
  const match = useRouteMatch();
  const classes = useStyles();
  const tracking = useSegmentTrack();
  const [isUpdatePaymentDisplayed, setIsUpdatePaymentDisplayed] = useState(false);
  const [isUpdateSubscriptionDisplayed, setIsUpdateSubscriptionDisplayed] = useState(false);
  const [isCancelSubscriptionDisplayed, setIsCancelSubscriptionDisplayed] = useState(false);
  const [isExpertSupportSubscribeDisplayed, setIsExpertSupportSubscribeDisplayed] = useState(
    searchParams.get('ExpertSupportUpgrade') === 'true' && !expertSupportSubscription,
  );
  const [shouldShowCancelExpertSubscriptionDialog, setShouldShowCancelExpertSubscriptionDialog] = useState(false);

  const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

  const getExpertSupportContent = () => {
    if (partnerName || !subscriptionItem) {
      return null;
    }

    if (!expertSupportSubscription) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">Expert Bookkeeping Support</Typography>
              <Typography variant="body2">
                Get more than standard tech support. Our Virginia-based experts provide bookkeeping advice via email or
                phone.
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  tracking('upgrade_to_expert_support clicked', {
                    location: 'Subscription Settings - Expert Support',
                  });
                  setIsExpertSupportSubscribeDisplayed(true);
                }}
                className={classes.button}
              >
                Upgrade to Expert Support
              </Button>
              <Typography variant="body2" className={classes.disclaimerText}>
                Expert Bookkeeping Support is NOT an outsourced bookkeeping or a replacement for a financial
                professional, including tax filing.
              </Typography>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    if (expertSupportSubscription) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">Expert Bookkeeping Support</Typography>
              <Typography variant="body2">
                Get more than standard tech support. Our Virginia-based experts provide bookkeeping advice via email or
                phone.
                <Box component="span" className={classes.italicText}>
                  {' '}
                  Expert Bookkeeping Support is{' '}
                  <Box component="span" className={classes.boldText}>
                    {' '}
                    not
                  </Box>{' '}
                  an outsourced bookkeeping service or a replacement for a financial professional, including tax filing.
                </Box>
              </Typography>
              <Typography variant="body1">
                {`Renews ${expertSupportSubscription.interval === 'month' ? 'monthly' : 'yearly'} for `}
                <NumberFormat
                  displayType="text"
                  value={expertSupportSubscription.price / 100}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
                {'. '}
                {`Next renewal is on ${moment(expertSupportSubscription.currentPeriodEnd).format('MM/DD/YYYY')}.`}
              </Typography>
              <Button
                variant="text"
                color="error"
                size="small"
                onClick={() => {
                  setShouldShowCancelExpertSubscriptionDialog(true);
                  tracking('cancel_subscription clicked', {
                    location: 'Subscription Settings - Expert Support',
                  });
                }}
                className={classes.button}
              >
                Cancel Subscription
              </Button>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }
  };

  const getSubscriptionContent = () => {
    if (demo) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Demo Portfolio
              </Typography>
              <Typography variant="body1">
                {`This is a demo portfolio.
                  Use this page to subscribe, unsubscribe, or update the subscription for any portfolios that you manage in REI Hub.`}
              </Typography>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    if (freemiumSubscription && !subscriptionItem) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <span className={classes.giftIcon}>🎁</span>
                Gifted 3-Month Free Plan
              </Typography>
              <Typography variant="body2">
                You are currently on a Gifted 3-Month Free Plan. This plan will expire on{' '}
                {moment(freemiumSubscription.currentPeriodEnd * 1000).format('MM/DD/YYYY')}. Once the plan expires, you
                will be automatically downgraded to the Free Plan unless you choose to upgrade.
              </Typography>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    if (partnerSubscribed) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Partner Subscription
              </Typography>
              <Typography variant="body1">
                {`Your subscription to REI Hub is managed through our partner ${partnerName}.
                Please visit their site or contact ${partnerName} customer service for assistance
                with your subscription or billing.`}
              </Typography>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    if (promptPayment) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Trial Account: ${moment().diff(moment(freeTrialExpires).endOf('day')) < 0 ? `${moment(freeTrialExpires).diff(moment(), 'days') + 1} days remaining` : 'Expired'}`}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => {
                  setDismissPayment(false);
                }}
              >
                Subscribe Now
              </Button>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <CardBase>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">{`Payment Method: ${subscriptionPaymentMethod.type}`}</Typography>
              {subscriptionPaymentMethod.type === 'Card' && (
                <Box>
                  <Typography variant="body1">
                    {`Brand: ${capitalize(subscriptionPaymentMethod.brand)}`}
                    <br />
                    {`Number: ****${subscriptionPaymentMethod.last4}`}
                    <br />
                    {`Expires: ${subscriptionPaymentMethod.exp_month}/${subscriptionPaymentMethod.exp_year}`}
                  </Typography>
                </Box>
              )}
              {subscriptionPaymentMethod.type === 'Bank Account' && (
                <Box>
                  <Typography variant="body1">
                    {`Bank Name: ${capitalize(subscriptionPaymentMethod.bank_name)}`}
                    <br />
                    {`Account Number: ****${subscriptionPaymentMethod.last4}`}
                  </Typography>
                </Box>
              )}
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                disableElevation
                onClick={() => setIsUpdatePaymentDisplayed(true)}
                className={classes.button}
              >
                Update Payment Method
              </Button>
            </CardContent>
          </CardBase>
        </Grid>
        <Grid item xs={12}>
          <CardBase>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">{`Subscription: ${subscriptionItem.name}`}</Typography>
              <Box>
                <Typography variant="body1">
                  {`Renews ${subscriptionItem.interval === 'month' ? 'monthly' : 'yearly'} for `}
                  <NumberFormat
                    displayType="text"
                    value={subscriptionItem.price / 100}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                  {'. '}
                  {`Next renewal is on ${moment(subscriptionItem.currentPeriodEnd * 1000).format('MM/DD/YYYY')}.`}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    tracking('update_subscription_plan clicked', {
                      location: 'Subscription Settings',
                    });
                    setIsUpdateSubscriptionDisplayed(true);
                  }}
                  className={classes.button}
                >
                  Update Subscription Plan
                </Button>
                <Box marginLeft={2} component="span" color="error.main">
                  <Button
                    variant="text"
                    color="error"
                    size="small"
                    onClick={() => {
                      tracking('cancel_subscription clicked', {
                        location: 'Subscription Settings',
                      });
                      setIsCancelSubscriptionDisplayed(true);
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardBase>
        </Grid>
      </>
    );
  };

  return (
    <Box className={classes.root}>
      <PageGrid>
        {shouldShowCancelExpertSubscriptionDialog && (
          <CancelSubscriptionDialog
            isOpen
            title="Cancel your subscription to Expert Bookkeeping Support?"
            subscriptionName="Expert Bookkeeping Support"
            location="Cancel Expert Support Modal"
            loseAccessItems={[
              'More than standard tech support',
              'Guidance on how to set up and maintain your books',
              'Best practices for real estate bookkeeping',
              'Help understanding reports and account balances',
            ]}
            closeDialog={() => setShouldShowCancelExpertSubscriptionDialog(false)}
          />
        )}
        {isExpertSupportSubscribeDisplayed && (
          <ExpertSupportSubscribeDialog
            closeDialog={() => setIsExpertSupportSubscribeDisplayed(false)}
            location="SETTINGS"
          />
        )}
        {isUpdatePaymentDisplayed && (
          <UpdateSubscriptionPaymentDialog isOpen closeDialog={() => setIsUpdatePaymentDisplayed(false)} />
        )}
        {isUpdateSubscriptionDisplayed && (
          <UpdateSubscriptionDialog isOpen closeDialog={() => setIsUpdateSubscriptionDisplayed(false)} />
        )}
        {isCancelSubscriptionDisplayed && (
          <CancelSubscriptionDialog isOpen closeDialog={() => setIsCancelSubscriptionDisplayed(false)} />
        )}
        <PageHeader match={match} title="Manage Subscription" />
        {getSubscriptionContent()}
        {getExpertSupportContent()}
      </PageGrid>
    </Box>
  );
}
