import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PersonContext } from '~/contexts/PersonContext';
import history from '~/history';
import ImportedTransactions from '~/views/ImportedTransactions/ImportedTransactions';

import AcceptInvite from '../views/AcceptInvite';
import Access from '../views/Access';
import AccountRegister from '../views/AccountRegister';
import AddLease from '../views/AddLease';
import AttachmentsExport from '../views/AttachmentsExport';
import BalanceSheet from '../views/BalanceSheet';
import BalanceSheetByPeriod from '../views/BalanceSheetByPeriod';
import BalanceSheetByProperty from '../views/BalanceSheetByProperty';
import BalanceSheetBySubportfolio from '../views/BalanceSheetBySubportfolio';
import BankAccount from '../views/BankAccount';
import Banking from '../views/Banking';
import BookedTransactions from '../views/BookedTransactions';
import CapRate from '../views/CapRate';
import CashFlows from '../views/CashFlows';
import CashFlowsByProperty from '../views/CashFlowsByProperty';
import CashOnCash from '../views/CashOnCash';
import ChartOfAccounts from '../views/ChartOfAccounts';
import Connections from '../views/Connections';
import Contacts from '../views/Contacts';
import CreditCard from '../views/CreditCard';
import Demo from '../views/Demo';
import EditLease from '../views/EditLease';
import EditProperty from '../views/EditProperty';
import EditUnit from '../views/EditUnit';
import Expenses from '../views/Expenses';
import ExpensesByVendor from '../views/ExpensesByVendor';
import FixedAsset from '../views/FixedAsset';
import FixedAssets from '../views/FixedAssets';
import FixedAssetsSchedule from '../views/FixedAssetsSchedule';
import Form8825 from '../views/Form8825';
import GeneralLedger from '../views/GeneralLedger';
import GetStarted from '../views/GetStarted';
import Home from '../views/Home';
import Hours from '../views/Hours';
import ImportedTransactionRules from '../views/ImportedTransactionRules';
import Leases from '../views/Leases';
import Loan from '../views/Loan';
import Loans from '../views/Loans';
import Logout from '../views/Logout';
import Mileage from '../views/Mileage';
import MileageByProperty from '../views/MileageByProperty';
import MyProfile from '../views/MyProfile';
import NetIncome from '../views/NetIncome';
import NetIncomeByPeriod from '../views/NetIncomeByPeriod';
import NetIncomeByProperty from '../views/NetIncomeByProperty';
import NetIncomeBySubportfolio from '../views/NetIncomeBySubportfolio';
import NetIncomeByUnit from '../views/NetIncomeByUnit';
import NetOperatingIncome from '../views/NetOperatingIncome';
import NetOperatingIncomeByPeriod from '../views/NetOperatingIncomeByPeriod';
import NetOperatingIncomeByProperty from '../views/NetOperatingIncomeByProperty';
import NetOperatingIncomeBySubportfolio from '../views/NetOperatingIncomeBySubportfolio';
import NetOperatingIncomeByUnit from '../views/NetOperatingIncomeByUnit';
import Notes from '../views/Notes';
import OperatingCashFlow from '../views/OperatingCashFlow';
import OperatingCashFlowByPeriod from '../views/OperatingCashFlowByPeriod';
import OperatingCashFlowByProperty from '../views/OperatingCashFlowByProperty';
import OperatingCashFlowBySubportfolio from '../views/OperatingCashFlowBySubportfolio';
import OperatingCashFlowByUnit from '../views/OperatingCashFlowByUnit';
import OperatingExpenseRatio from '../views/OperatingExpenseRatio';
import OwnerStatement from '../views/OwnerStatement';
import OwnerStatementByProperty from '../views/OwnerStatementByProperty';
import Partner from '../views/Partner';
import PlaidOAuthRedirect from '../views/PlaidOAuthRedirect';
import PortfolioValueByProperty from '../views/PortfolioValueByProperty';
import Properties from '../views/Properties';
import Property from '../views/Property';
import PropertyManager from '../views/PropertyManager';
import PropertyManagerMappingRules from '../views/PropertyManagerMappingRules';
import PropertyManagers from '../views/PropertyManagers';
import RecurringTransactions from '../views/RecurringTransactions';
import Reload from '../views/Reload';
import RentRoll from '../views/RentRoll';
import Reports from '../views/Reports';
import Revenues from '../views/Revenues';
import ScheduleE from '../views/ScheduleE';
import SecurityDepositsHeld from '../views/SecurityDepositsHeld';
import Settings from '../views/Settings';
import Subportfolio from '../views/Subportfolio';
import Subportfolios from '../views/Subportfolios';
import Subscription from '../views/Subscription';
import TaxPacket from '../views/TaxPacket';
import TaxReview from '../views/TaxReview';
import TrialBalance from '../views/TrialBalance';
import Unit from '../views/Unit';
import Units from '../views/Units';
import Vehicles from '../views/Vehicles';
import Vendors from '../views/Vendors';
import VerifyEmail from '../views/VerifyEmail';

export const dashboardRouteArray = [
  {
    exact: true,
    path: '/dashboard',
    component: Home,
    breadcrumb: 'Home',
  },
  {
    exact: true,
    path: '/properties',
    component: Properties,
    breadcrumb: 'Properties',
  },
  {
    exact: true,
    path: '/subportfolios',
    component: Subportfolios,
    breadcrumb: 'Sub-Portfolios',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId',
    component: Property,
    breadcrumb: 'Property',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/edit',
    component: EditProperty,
    breadcrumb: 'Edit',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units',
    component: Units,
    breadcrumb: 'Units',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units/unit/:unitId',
    component: Unit,
    breadcrumb: 'Unit',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units/unit/:unitId/edit',
    component: EditUnit,
    breadcrumb: 'Edit',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/unit/:unitId',
    component: Unit,
    breadcrumb: 'Unit',
  },
  {
    exact: true,
    path: '/subportfolios/subportfolio/:subportfolioId',
    component: Subportfolio,
    breadcrumb: 'Sub-Portfolio',
  },
  {
    exact: true,
    path: '/transactions/imported',
    component: ImportedTransactions,
    breadcrumb: 'Import Feed',
  },
  {
    exact: true,
    path: '/transactions/imported/rules',
    component: ImportedTransactionRules,
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/transactions/booked',
    component: BookedTransactions,
    breadcrumb: 'Booked Transactions',
  },
  {
    exact: true,
    path: '/transactions/revenues',
    component: Revenues,
    breadcrumb: 'Revenues',
  },
  {
    exact: true,
    path: '/transactions/expenses',
    component: Expenses,
    breadcrumb: 'Expenses',
  },
  {
    exact: true,
    path: '/organization/vendors',
    component: Vendors,
    breadcrumb: 'Vendors',
  },
  {
    exact: true,
    path: '/organization/leases',
    component: Leases,
    breadcrumb: 'Leases',
  },
  {
    exact: true,
    path: '/organization/leases/lease/:leaseId',
    component: EditLease,
    breadcrumb: 'Edit Lease',
  },
  {
    exact: true,
    path: '/organization/leases/add',
    component: AddLease,
    breadcrumb: 'Add Lease',
  },
  {
    exact: true,
    path: '/organization/mileage',
    component: Mileage,
    breadcrumb: 'Mileage',
  },
  {
    exact: true,
    path: '/organization/mileage/vehicles',
    component: Vehicles,
    breadcrumb: 'Vehicles',
  },
  {
    exact: true,
    path: '/organization/notes',
    component: Notes,
    breadcrumb: 'Notes',
  },
  {
    exact: true,
    path: '/organization/contacts',
    component: Contacts,
    breadcrumb: 'Contacts',
  },
  {
    exact: true,
    path: '/organization/time-tracking',
    component: Hours,
    breadcrumb: 'Time Tracking',
  },
  {
    exact: true,
    path: '/transactions/recurring-transactions',
    component: RecurringTransactions,
    breadcrumb: 'Recurring Transactions',
  },
  {
    exact: true,
    path: '/reports',
    component: Reports,
    breadcrumb: 'Reports',
  },
  {
    exact: true,
    path: '/reports/net-income',
    component: NetIncome,
    breadcrumb: 'Net Income',
  },
  {
    exact: true,
    path: '/reports/net-income-by-property',
    component: NetIncomeByProperty,
    breadcrumb: 'Net Income By Property',
  },
  {
    exact: true,
    path: '/reports/net-income-by-subportfolio',
    component: NetIncomeBySubportfolio,
    breadcrumb: 'Net Income By Sub-Portfolio',
  },
  {
    exact: true,
    path: '/reports/net-income-by-unit',
    component: NetIncomeByUnit,
    breadcrumb: 'Net Income By Unit',
  },
  {
    exact: true,
    path: '/reports/net-income-by-period',
    component: NetIncomeByPeriod,
    breadcrumb: 'Net Income By Period',
  },
  {
    exact: true,
    path: '/reports/rent-roll',
    component: RentRoll,
    breadcrumb: 'Rent Roll',
  },
  {
    exact: true,
    path: '/reports/owner-statement',
    component: OwnerStatement,
    breadcrumb: 'Owner Statement',
  },
  {
    exact: true,
    path: '/reports/owner-statement-by-property',
    component: OwnerStatementByProperty,
    breadcrumb: 'Owner Statement By Property',
  },
  {
    exact: true,
    path: '/reports/net-operating-income',
    component: NetOperatingIncome,
    breadcrumb: 'Net Operating Income',
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-property',
    component: NetOperatingIncomeByProperty,
    breadcrumb: 'Net Operating Income By Property',
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-period',
    component: NetOperatingIncomeByPeriod,
    breadcrumb: 'Net Operating Income By Period',
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-subportfolio',
    component: NetOperatingIncomeBySubportfolio,
    breadcrumb: 'Net Operating Income By Sub-Portfolio',
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-unit',
    component: NetOperatingIncomeByUnit,
    breadcrumb: 'Net Operating Income By Unit',
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow',
    component: OperatingCashFlow,
    breadcrumb: 'Operating Cash Flow',
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-property',
    component: OperatingCashFlowByProperty,
    breadcrumb: 'Operating Cash Flow By Property',
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-subportfolio',
    component: OperatingCashFlowBySubportfolio,
    breadcrumb: 'Operating Cash Flow By Sub-Portfolio',
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-unit',
    component: OperatingCashFlowByUnit,
    breadcrumb: 'Operating Cash Flow By Unit',
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-period',
    component: OperatingCashFlowByPeriod,
    breadcrumb: 'Operating Cash Flow By Period',
  },
  {
    exact: true,
    path: '/reports/cash-flows',
    component: CashFlows,
    breadcrumb: 'Cash Flows',
  },
  {
    exact: true,
    path: '/reports/cash-flows-by-property',
    component: CashFlowsByProperty,
    breadcrumb: 'Cash Flows By Property',
  },
  {
    exact: true,
    path: '/reports/cash-on-cash',
    component: CashOnCash,
    breadcrumb: 'Cash on Cash',
  },
  {
    exact: true,
    path: '/reports/cap-rate',
    component: CapRate,
    breadcrumb: 'Cap Rate',
  },
  {
    exact: true,
    path: '/reports/operating-expense-ratio',
    component: OperatingExpenseRatio,
    breadcrumb: 'Operating Expense Ratio',
  },
  {
    exact: true,
    path: '/reports/fixed-assets',
    component: FixedAssetsSchedule,
    breadcrumb: 'Fixed Assets Schedule',
  },
  {
    exact: true,
    path: '/reports/tax-packet',
    component: TaxPacket,
    breadcrumb: 'Tax Packet',
  },
  {
    exact: true,
    path: '/reports/tax-review',
    component: TaxReview,
    breadcrumb: 'Tax Review',
  },
  {
    exact: true,
    path: '/reports/expenses-by-vendor',
    component: ExpensesByVendor,
    breadcrumb: 'Expenses By Vendor',
  },
  {
    exact: true,
    path: '/reports/schedule-e',
    component: ScheduleE,
    breadcrumb: 'Schedule E',
  },
  {
    exact: true,
    path: '/reports/form-8825',
    component: Form8825,
    breadcrumb: 'Form 8825',
  },
  {
    exact: true,
    path: '/reports/portfolio-value-by-property',
    component: PortfolioValueByProperty,
    breadcrumb: 'Portfolio Value By Property',
  },
  {
    exact: true,
    path: '/reports/balance-sheet',
    component: BalanceSheet,
    breadcrumb: 'Balance Sheet',
  },
  {
    exact: true,
    path: '/reports/balance-sheet-by-property',
    component: BalanceSheetByProperty,
    breadcrumb: 'Balance Sheet By Property',
  },
  {
    exact: true,
    path: '/reports/balance-sheet-by-subportfolio',
    component: BalanceSheetBySubportfolio,
    breadcrumb: 'Balance Sheet By Sub-Portfolio',
  },
  {
    exact: true,
    path: '/reports/balance-sheet-by-period',
    component: BalanceSheetByPeriod,
    breadcrumb: 'Balance Sheet By Period',
  },
  {
    exact: true,
    path: '/reports/trial-balance',
    component: TrialBalance,
    breadcrumb: 'Trial Balance',
  },
  {
    exact: true,
    path: '/reports/account-register',
    component: AccountRegister,
    breadcrumb: 'Account Register',
  },
  {
    exact: true,
    path: '/reports/general-ledger',
    component: GeneralLedger,
    breadcrumb: 'General Ledger',
  },
  {
    exact: true,
    path: '/reports/attachments-export',
    component: AttachmentsExport,
  },
  {
    exact: true,
    path: '/reports/security-deposits-held',
    component: SecurityDepositsHeld,
    breadcrumb: 'Security Deposits Held',
  },
  {
    exact: true,
    path: '/reports/mileage-by-property',
    component: MileageByProperty,
    breadcrumb: 'Mileage By Property',
  },
  {
    exact: true,
    path: '/accounts/banking',
    component: Banking,
    breadcrumb: 'Banking',
  },
  {
    exact: true,
    path: '/onboarding-complete',
    component: Banking,
  },
  {
    exact: true,
    path: '/accounts/banking/connections',
    component: Connections,
    breadcrumb: 'Banking',
  },
  {
    exact: true,
    path: '/accounts/banking/bank/:accountId',
    component: BankAccount,
    breadcrumb: 'Bank Account',
  },
  {
    exact: true,
    path: '/accounts/banking/card/:accountId',
    component: CreditCard,
    breadcrumb: 'Credit Card',
  },
  {
    exact: true,
    path: '/accounts/loans/loan/:accountId',
    component: Loan,
    breadcrumb: 'Loan',
  },
  {
    exact: true,
    path: '/accounts/banking/import-feed',
    component: ImportedTransactions,
    breadcrumb: 'Import Feed',
  },
  {
    exact: true,
    path: '/accounts/banking/import-feed/rules',
    component: ImportedTransactionRules,
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/accounts/loans',
    component: Loans,
    breadcrumb: 'Loans',
  },
  {
    exact: true,
    path: '/accounts/loans/escrow/:accountId',
    component: BankAccount,
    breadcrumb: 'Escrow Account',
  },
  {
    exact: true,
    path: '/accounts/partner',
    component: Partner,
    breadcrumb: 'Partner',
  },
  {
    exact: true,
    path: '/accounts/property-managers',
    component: PropertyManagers,
    breadcrumb: 'Property Managers',
  },
  {
    exact: true,
    path: '/accounts/property-managers/:accountId',
    component: PropertyManager,
    breadcrumb: 'Property Manager',
  },
  {
    exact: true,
    path: '/accounts/property-managers/:accountId/rules',
    component: PropertyManagerMappingRules,
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/accounts/airbnb/:accountId',
    component: PropertyManager,
    breadcrumb: 'Airbnb',
  },
  {
    exact: true,
    path: '/accounts/airbnb/:accountId/rules',
    component: PropertyManagerMappingRules,
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/accounts/vrbo/:accountId',
    component: PropertyManager,
    breadcrumb: 'VRBO',
  },
  {
    exact: true,
    path: '/accounts/vrbo/:accountId/rules',
    component: PropertyManagerMappingRules,
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/organization/fixed-assets',
    component: FixedAssets,
    breadcrumb: 'Fixed Assets',
  },
  {
    exact: true,
    path: '/organization/fixed-assets/:assetId',
    component: FixedAsset,
    breadcrumb: 'Fixed Asset',
  },
  {
    exact: true,
    path: '/settings',
    component: Settings,
    breadcrumb: 'Settings',
  },
  {
    exact: true,
    path: '/access',
    component: Access,
    breadcrumb: 'Portfolio Access',
  },
  {
    exact: true,
    path: '/my-profile',
    component: MyProfile,
    breadcrumb: 'My Profile',
  },
  {
    exact: true,
    path: '/chart-of-accounts',
    component: ChartOfAccounts,
    breadcrumb: 'Chart of Accounts',
  },
  {
    exact: true,
    path: '/subscription',
    component: Subscription,
    breadcrumb: 'Subscription',
  },
  {
    exact: true,
    path: '/verifyemailaddress',
    component: VerifyEmail,
    breadcrumb: 'Verify Email',
  },
  {
    exact: true,
    path: '/acceptinvite',
    component: AcceptInvite,
    breadcrumb: 'Accept Invite',
  },
  {
    exact: true,
    path: '/plaid-oauth-redirect',
    component: PlaidOAuthRedirect,
  },
  {
    exact: true,
    path: '/reload',
    component: Reload,
  },
  {
    exact: true,
    path: '/sso',
    component: Logout,
  },
  {
    exact: true,
    path: '/invite',
    component: Logout,
  },
  {
    exact: true,
    path: '/demo',
    component: Demo,
  },
  {
    exact: true,
    path: '/turbotenant',
    component: Demo,
  },
  {
    exact: true,
    path: '/rentredi',
    component: Demo,
  },
  {
    exact: true,
    path: '/get-started',
    component: GetStarted,
  },
];

function DashboardRoutes() {
  const { redirectPath } = useContext(PersonContext);

  return (
    <Switch>
      {dashboardRouteArray.map((route) => (
        <Route {...route} key={route.path} />
      ))}
      <Redirect exact from="/verifyemail" to={{ pathname: '/verifyemailaddress', search: history.location.search }} />
      <Redirect to={redirectPath || '/dashboard'} />
    </Switch>
  );
}

export default DashboardRoutes;
