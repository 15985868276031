import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import { PersonContext } from '../contexts/PersonContext';
import { create } from '../feathersWrapper';
import history from '../history';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';
import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import ViewEnableVendorsDialog from '../components/ViewEnableVendorsDialog';

class ExpensesByVendor extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      viewEnableVendorsDialog: false,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    const { vendorTracking } = this.context;
    if (!vendorTracking) {
      this.setState({ viewEnableVendorsDialog: true });
      return;
    }

    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    // launch async calls
    const vendors = await create(this, 'reports', {
      basis,
      ...buildQuery(this),
      reportName: 'vendorJournalTotals',
    });

    await asyncSetState(
      {
        loading: false,
        vendors,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, vendors, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        vendors,
        exportOptions,
        reportName: 'Expenses By Vendor',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelection === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'expenses-by-vendor.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptiomns = async (options) => {
    asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const { vendors, loading, filter, filterDialog, downloadDialog, viewEnableVendorsDialog } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Expenses By Vendor"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <ViewEnableVendorsDialog isOpen={viewEnableVendorsDialog} closeDialog={this.goToReports} />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptiomns}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" maxWidth="600px">
                  <FinancialAccountLine label="Vendors" values={['Payments']} bold underline overline />
                  {vendors.map((vendor) => (
                    <FinancialAccountLine
                      key={vendor.id}
                      label={vendor.name}
                      value={vendor.netPayments}
                      vendorId={vendor.id}
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                      propertyId={filter.propertyId}
                      unitId={filter.unitId}
                      entityId={filter.entityId}
                      indent={1}
                    />
                  ))}
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

ExpensesByVendor.contextType = PersonContext;

ExpensesByVendor.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ExpensesByVendor;
