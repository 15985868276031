import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import Alert from '@material-ui/lab/Alert';
import queryString from 'query-string';

import PasswordStrengthChecker from '~/components/PasswordStrengthChecker';
import { STATUSES as PASSWORD_STATUSES } from '~/constants/password-requirements';
// import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import usePasswordRequirements from '~/hooks/usePasswordRequirements';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginCard: {
    maxWidth: '440px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  passwordRequirements: {
    marginTop: '16px',
    width: '100%',
  },
  alert: {
    marginTop: '16px',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
  },
  visibilityIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export default function SetPassword() {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [arePasswordRequirementsVisible, setArePasswordRequirementsVisible] = useState(false);
  const [isPasswordSubmitted, setIsPasswordSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('warning');
  const [isPasswordSet, setIsPasswordSet] = useState(false);

  const passwordRequirements = usePasswordRequirements(password, isPasswordSubmitted);
  const isPasswordValid = useMemo(
    () => Object.values(passwordRequirements).every((status) => status === PASSWORD_STATUSES.MET),
    [passwordRequirements],
  );
  useEffect(() => {
    setErrorMessage('');
  }, [isPasswordValid]);

  const location = useLocation();
  const token = useMemo(() => queryString.parse(location.search)?.token, [location.search]);

  async function handleSubmit(event) {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    setIsPasswordSubmitted(true);
    setErrorMessage('');

    if (!isPasswordValid) {
      setErrorSeverity('warning');
      setErrorMessage('Your new password must meet the requirements.');
      setIsSubmitting(false);
      return;
    }

    const payload = {
      action: 'resetPwdLong',
      value: {
        token,
        password,
      },
    };

    try {
      await create('authManagement', payload);
      setIsPasswordSet(true);
    } catch (_error) {
      setErrorSeverity('error');
      setErrorMessage('Failed to set password. Please try again.');
      setIsSubmitting(false);
    }
  }

  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} className={classes.centerContent}>
        {isPasswordSet ? (
          <Card className={classes.loginCard}>
            <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
              <Box mb="30px">
                <Typography variant="h5" align="center" component="div">
                  Success
                </Typography>
              </Box>
              <Typography variant="body1">Your new password has been set</Typography>
              <Box marginTop="30px">
                <Button
                  component={Link}
                  to="/sign-in"
                  color="primary"
                  size="large"
                  variant="contained"
                  fullWidth
                  disableElevation
                >
                  Go To Sign In
                </Button>
              </Box>
            </Box>
          </Card>
        ) : (
          <Card className={classes.loginCard}>
            <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
              <form onSubmit={handleSubmit}>
                <Box mb="30px">
                  <Typography variant="h5" align="center" component="div">
                    Choose a Password
                  </Typography>
                </Box>
                <TextField
                  label="New Password"
                  id="password"
                  variant="outlined"
                  fullWidth
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onFocus={() => setArePasswordRequirementsVisible(true)}
                  InputProps={{
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <VisibilityIcon className={classes.visibilityIcon} color="primary" />
                          ) : (
                            <VisibilityOffIcon className={classes.visibilityIcon} color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {arePasswordRequirementsVisible && (
                  <PasswordStrengthChecker
                    requirements={passwordRequirements}
                    className={classes.passwordRequirements}
                  />
                )}

                {errorMessage && (
                  <Alert severity={errorSeverity} className={classes.alert}>
                    {errorMessage}
                  </Alert>
                )}

                <Box marginTop="30px">
                  <Button
                    color="primary"
                    type="submit"
                    size="large"
                    variant="contained"
                    fullWidth
                    disableElevation
                    disabled={isSubmitting}
                  >
                    Set New Password
                  </Button>
                </Box>
              </form>
            </Box>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
