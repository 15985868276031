import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { PersonContext } from '../contexts/PersonContext';
import { get, create, find } from '../feathersWrapper';
import history from '../history';

import { asyncSetState, asyncHandleChange } from '../functions/InputHandlers';

import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import DownloadDialog from '../components/DownloadDialog';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';
import FilterDialog from '../components/FilterDialog';

const styles = {
  reportTitle: {
    textDecoration: 'underline',
    textDecorationStyle: 'double',
  },
};

class ScheduleE extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: null,
        proration: true,
        mileage: true,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment().subtract(1, 'years').endOf('year');
      newFilter.startDateSelect = moment().subtract(1, 'years').startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate && filter.propertyId)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const property = await get(this, 'properties', filter.propertyId);
    const query = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const accountsObject = {
      rentRevenue: {
        accounts: [],
      },
      advertisingExpense: {
        accounts: [],
      },
      autoAndTravelExpense: {
        accounts: [],
      },
      cleaningAndMaintenanceExpense: {
        accounts: [],
      },
      commissionsExpense: {
        accounts: [],
      },
      insuranceExpense: {
        accounts: [],
      },
      legalAndProfessionalFeesExpense: {
        accounts: [],
      },
      managementFeesExpense: {
        accounts: [],
      },
      mortgageInterestExpense: {
        accounts: [],
      },
      otherInterestExpense: {
        accounts: [],
      },
      repairsExpense: {
        accounts: [],
      },
      suppliesExpense: {
        accounts: [],
      },
      taxesExpense: {
        accounts: [],
      },
      utilitiesExpense: {
        accounts: [],
      },
      depreciationExpense: {
        accounts: [],
      },
      otherExpense: {
        accounts: [],
      },
      allExpenseAccounts: {
        accounts: [],
      },
      allAccounts: {
        accounts: [],
      },
      byId: {},
    };

    // launch async calls
    const accounts = await create(this, 'reports', query).then((result) => {
      /* eslint-disable no-param-reassign */
      result.forEach((account) => {
        // account.display = account.netCredits !== 0;
        account.subaccounts = [];
        account.values = [account.netCredits];
        accountsObject.byId[account.id] = account;
      });
      /* eslint-enable no-param-reassign */
      return result;
    });

    accounts.forEach((account) => {
      if (account.type2 === 'Operating Revenue') {
        accountsObject.rentRevenue.accounts.push(account);
        accountsObject.allAccounts.accounts.push(account);
      } else if (account.default && accountsObject[account.default]) {
        accountsObject[account.default].accounts.push(account);
        accountsObject.allExpenseAccounts.accounts.push(account);
        accountsObject.allAccounts.accounts.push(account);
      } else if (account.parentAccountId && accountsObject[accountsObject.byId[account.parentAccountId].default]) {
        accountsObject[accountsObject.byId[account.parentAccountId].default].accounts.push(account);
        accountsObject.allExpenseAccounts.accounts.push(account);
        accountsObject.allAccounts.accounts.push(account);
      } else if (account.type === 'Expense') {
        accountsObject.otherExpense.accounts.push(account);
        accountsObject.allExpenseAccounts.accounts.push(account);
        accountsObject.allAccounts.accounts.push(account);
      }
    });

    let portfolioPropertiesCount;
    let subportfolioPropertiesCount;

    if (filter.proration) {
      const portfolioProperties = await find(this, 'properties', {
        query: { organizationId, inactive: false, $limit: 0 },
      });
      portfolioPropertiesCount = portfolioProperties.total ? portfolioProperties.total : 1;

      const portfolioAccounts = await create(this, 'reports', {
        ...query,
        propertyId: null,
        entityId: null,
      });

      portfolioAccounts.forEach((account) => {
        accountsObject.byId[account.id].values[0] += account.netCredits / portfolioPropertiesCount;
      });

      if (property.entityId) {
        const suportfolioProperties = await find(this, 'properties', {
          query: {
            organizationId,
            entityId: property.entityId,
            inactive: false,
            $limit: 0,
          },
        });
        subportfolioPropertiesCount = suportfolioProperties.total ? suportfolioProperties.total : 1;

        const subportfolioAccounts = await create(this, 'reports', {
          ...query,
          propertyId: null,
          entityId: property.entityId,
        });

        subportfolioAccounts.forEach((account) => {
          accountsObject.byId[account.id].values[0] += account.netCredits / subportfolioPropertiesCount;
        });
      }
    }

    if (filter.mileage) {
      const mileageQuery = Object.assign(buildQuery(this, true), {
        reportName: 'mileageTotals',
      });
      const mileage = await create(this, 'reports', mileageQuery);
      accountsObject.autoAndTravelExpense.accounts[0].values[0] -= mileage.totalDeduction;

      if (filter.proration) {
        const portfolioMileage = await create(this, 'reports', {
          ...mileageQuery,
          propertyId: null,
          entityId: null,
        });

        accountsObject.autoAndTravelExpense.accounts[0].values[0] -=
          portfolioMileage.totalDeduction / portfolioPropertiesCount;

        if (property.entityId) {
          const subportfolioMileage = await create(this, 'reports', {
            ...mileageQuery,
            propertyId: null,
            entityId: property.entityId,
          });

          accountsObject.autoAndTravelExpense.accounts[0].values[0] -=
            subportfolioMileage.totalDeduction / subportfolioPropertiesCount;
        }
      }
    }

    accounts.forEach((account) => {
      // eslint-disable-next-line no-param-reassign
      account.display = account.values[0] !== 0;
    });

    const headers = [null];

    await asyncSetState(
      {
        loading: false,
        property,
        headers,
        accountsObject,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportPdf = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, property, headers, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-pdf`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        property,
        headers,
        exportOptions,
        reportName: 'Schedule E',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${filter.propertyIdSelect.address1} ${filter.endDateSelect.format('YYYY')} Schedule E.pdf`;
        document.body.appendChild(a);
        a.click();
      });
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, property, headers, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        property,
        headers,
        exportOptions,
        reportName: 'Schedule E',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (!resp.ok) {
          this.setState(() => {
            throw new Error('There was an error while generating the .xls');
          });
          return;
        }
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${filter.propertyIdSelect.address1} ${filter.endDateSelect.format('YYYY')} Schedule E.xlsx`;
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const { property, accountsObject, loading, filter, filterDialog, downloadDialog } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Schedule E"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          property
          proration
          mileage
          required={['dateRange', 'property']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          exportPdf={this.exportPdf}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" maxWidth="600px">
                  <Box mt="25px" mb="50px" textAlign="center">
                    <Typography variant="h5" gutterBottom>
                      Schedule E
                    </Typography>
                    <Typography variant="body1">{property.address1}</Typography>
                    <Typography variant="body1">{`Tax Year ${filter.endDateSelect.format('YYYY')}`}</Typography>
                  </Box>
                  <FinancialAccountLine label="Information" indent={0} bold />
                  <FinancialAccountLine
                    label="1a. Physical Address"
                    textValue={`${property.address1}, ${property.city}, ${property.state} ${property.zip}`}
                    indent={0}
                    marginBottom
                  />
                  <FinancialAccountLine label="Income" indent={0} bold />
                  <FinancialAccountLine
                    label="3. Rents received"
                    values={this.sumColumnValues(accountsObject.rentRevenue.accounts)}
                    indent={0}
                    marginBottom
                  />
                  <FinancialAccountLine label="Expenses" indent={0} bold />
                  <FinancialAccountLine
                    label="5. Advertising"
                    values={this.sumColumnValues(accountsObject.advertisingExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="6. Auto and travel"
                    values={this.sumColumnValues(accountsObject.autoAndTravelExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="7. Cleaning and maintenance"
                    values={this.sumColumnValues(accountsObject.cleaningAndMaintenanceExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="8. Commissions"
                    values={this.sumColumnValues(accountsObject.commissionsExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="9. Insurance"
                    values={this.sumColumnValues(accountsObject.insuranceExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="10. Legal and professional fees"
                    values={this.sumColumnValues(accountsObject.legalAndProfessionalFeesExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="11. Management fees"
                    values={this.sumColumnValues(accountsObject.managementFeesExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="12. Mortgage interest"
                    values={this.sumColumnValues(accountsObject.mortgageInterestExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="13. Other interest"
                    values={this.sumColumnValues(accountsObject.otherInterestExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="14. Repairs"
                    values={this.sumColumnValues(accountsObject.repairsExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="15. Supplies"
                    values={this.sumColumnValues(accountsObject.suppliesExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="16. Taxes"
                    values={this.sumColumnValues(accountsObject.taxesExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="17. Utilities"
                    values={this.sumColumnValues(accountsObject.utilitiesExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine
                    label="18. Depreciation expense"
                    values={this.sumColumnValues(accountsObject.depreciationExpense.accounts)}
                    indent={0}
                    invertSign
                  />
                  <FinancialAccountLine label="19. Other" indent={0} invertSign />
                  {accountsObject.otherExpense.accounts.map((account) => (
                    <FinancialAccountLine
                      key={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display}
                      indent={3}
                      invertSign
                    />
                  ))}
                  <FinancialAccountLine
                    label="Total Other"
                    values={this.sumColumnValues(accountsObject.otherExpense.accounts)}
                    indent={3}
                    sumline
                    invertSign
                  />
                  <FinancialAccountLine
                    label="20. Total expenses"
                    values={this.sumColumnValues(accountsObject.allExpenseAccounts.accounts)}
                    indent={0}
                    bold
                    sumline
                    marginBottom
                    invertSign
                  />
                  <FinancialAccountLine
                    label="21. Net income"
                    values={this.sumColumnValues(accountsObject.allAccounts.accounts)}
                    indent={0}
                    bold
                    marginBottom
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

ScheduleE.contextType = PersonContext;

ScheduleE.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ScheduleE);
