import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import { PersonContext } from '~/contexts/PersonContext';
import AdminPasswordReset from '~/views/authenticate/AdminPasswordReset';

import AuthenticateContainer from '../layouts/Authenticate/Container';
import GrayLayout from '../layouts/Authenticate/GrayLayout';
import AuthenticateLayout from '../layouts/Authenticate/Layout';
import AccessInvite from '../views/authenticate/AccessInvite';
import AccountantFirms from '../views/authenticate/AccountantFirms';
import AccountantLogin from '../views/authenticate/AccountantLogin';
import AccountantRegister from '../views/authenticate/AccountantRegister';
import AdminLogin from '../views/authenticate/AdminLogin';
import Checkout from '../views/authenticate/Checkout';
import FurnishedFinder from '../views/authenticate/FurnishedFinder';
import Login from '../views/authenticate/Login';
import Organizations from '../views/authenticate/Organizations';
import Register from '../views/authenticate/Register/View';
import RegisterProfile from '../views/authenticate/RegisterProfile';
import ResetPassword from '../views/authenticate/ResetPassword';
import SelectPricing from '../views/authenticate/SelectPricing';
import SetPassword from '../views/authenticate/SetPassword';
import Sso from '../views/authenticate/Sso';
import VerifyEmail from '../views/authenticate/VerifyEmail';
import Demo from '../views/Demo';

import AUTHENTICATE_ROUTES_PATHS from './constants';

export default function AuthenticateRoutes() {
  const { setContextState } = useContext(PersonContext);

  useEffect(() => {
    // Do not set a redirect path if the current path is a route for unauthenticated users
    if (!Object.values(AUTHENTICATE_ROUTES_PATHS).includes(window.location.pathname)) {
      setContextState({ redirectPath: window.location.pathname + window.location.search });
    }
  }, [setContextState]);

  return (
    <AuthenticateContainer>
      <Switch>
        <Route exact path={AUTHENTICATE_ROUTES_PATHS.REGISTER} component={Register} />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.SELECT_PRICING}
          component={(props) => (
            <GrayLayout>
              <SelectPricing {...props} />
            </GrayLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.CHECKOUT}
          component={(props) => (
            <GrayLayout>
              <ElementsConsumer>
                {({ elements, stripe }) => <Checkout elements={elements} stripe={stripe} {...props} />}
              </ElementsConsumer>
            </GrayLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.REGISTER_PROFILE}
          component={(props) => (
            <GrayLayout>
              <RegisterProfile {...props} />
            </GrayLayout>
          )}
        />
        {/* routes for limited time offer */}
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.SELECT_PRICING_LIMITED}
          component={(props) => (
            <GrayLayout>
              <SelectPricing {...props} isLimitedTimeOffer />
            </GrayLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.REGISTER_PROFILE_LIMITED}
          component={(props) => (
            <GrayLayout>
              <RegisterProfile {...props} isLimitedTimeOffer />
            </GrayLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.CHECKOUT_LIMITED}
          component={(props) => (
            <GrayLayout>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <Checkout elements={elements} stripe={stripe} {...props} isLimitedTimeOffer />
                )}
              </ElementsConsumer>
            </GrayLayout>
          )}
        />
        {/* routes for limited time offer */}
        {/* routes for furnished finder */}
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.FURNISHED_FINDER}
          component={(props) => (
            <GrayLayout>
              <FurnishedFinder {...props} isFurnishedFinder />
            </GrayLayout>
          )}
        />
        {/* routes for furnished finder */}
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.RESET_PASSWORD}
          component={(props) => (
            <AuthenticateLayout>
              <ResetPassword {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.SET_PASSWORD}
          component={(props) => (
            <AuthenticateLayout>
              <SetPassword {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.VERIFY_EMAIL}
          component={(props) => (
            <AuthenticateLayout>
              <VerifyEmail {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.SIGN_IN}
          component={(props) => (
            <AuthenticateLayout>
              <Login {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.SSO}
          component={(props) => (
            <AuthenticateLayout>
              <Sso {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route exact path={AUTHENTICATE_ROUTES_PATHS.PORTFOLIOS} component={(props) => <Organizations {...props} />} />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.INVITE}
          component={(props) => (
            <AuthenticateLayout>
              <AccessInvite {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.DEMO}
          component={(props) => (
            <AuthenticateLayout>
              <Demo {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.TURBOTENANT}
          component={(props) => (
            <AuthenticateLayout>
              <Demo {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.RENTREDI}
          component={(props) => (
            <AuthenticateLayout>
              <Demo {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.ACCOUNTANT_SIGN_IN}
          component={(props) => (
            <AuthenticateLayout>
              <AccountantLogin {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.ACCOUNTANT_REGISTER}
          component={(props) => (
            <AuthenticateLayout>
              <AccountantRegister {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.ACCOUNTANT_FIRMS}
          component={(props) => (
            <AuthenticateLayout>
              <AccountantFirms {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.ADMIN_SIGN_IN}
          component={(props) => (
            <AuthenticateLayout>
              <AdminLogin {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path={AUTHENTICATE_ROUTES_PATHS.ADMIN_PASSWORD_RESET}
          component={(props) => (
            <AuthenticateLayout>
              <AdminPasswordReset {...props} />
            </AuthenticateLayout>
          )}
        />

        <Redirect from="/accountant" to={AUTHENTICATE_ROUTES_PATHS.ACCOUNTANT_SIGN_IN} />
        <Redirect from="/admin" to={AUTHENTICATE_ROUTES_PATHS.ADMIN_SIGN_IN} />
        <Redirect push to={AUTHENTICATE_ROUTES_PATHS.SIGN_IN} />
      </Switch>
    </AuthenticateContainer>
  );
}
