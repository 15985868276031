import React from 'react';
import { Box, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import GetStartedChecklistProgress from '~/components/GetStartedChecklistProgress';
import PageGrid from '~/components/PageGrid';
import { find } from '~/feathersWrapper';
import { segmentTracking } from '~/functions/SegmentFunctions';
import history from '~/history';

import FilterDialog from '../components/FilterDialog';
import PageHeader from '../components/PageHeader';
import { PersonContext } from '../contexts/PersonContext';
import { asyncHandleChange, handleChange } from '../functions/InputHandlers';

import BankingCard from './cards/BankingCard';
import BookValueCard from './cards/BookValueCard';
import ExpensesCard from './cards/ExpensesCard';
import GraphsCard from './cards/GraphsCard';
import PropertiesCard from './cards/PropertiesCard';
import QuicklinksCard from './cards/QuicklinksCard';
import ValueCard from './cards/ValueCard';

const styles = (theme) => ({
  headerContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  setupChecklistContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    borderRadius: theme.spacing(0.5),
    justifyContent: 'space-between',
    backgroundColor: theme.palette.alert.info.backgroundColor,
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
    width: '319px',
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    flexShrink: 0,
  },
  finishButton: {
    width: '104px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },
});

class Home extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { checkCache, checkLogin, organizationId } = this.context;
    checkCache();
    await checkLogin();
    const checklistStatusesResponse = await find(this, 'get-started-checklist', { query: { organizationId } });
    this.setState({
      stale: {
        cashflow: false,
        expenses: false,
      },
      filterDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      loading: false,
      isChecklistComplete: false,
      noChecklistData: checklistStatusesResponse.total === 0,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ stale: { cashflow: true, expenses: true } });
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  onFinishClick = () => {
    segmentTracking('finish clicked', 'Dashboard', {
      organizationId: this.context.organizationId,
      showWelcome: this.context.showWelcome,
      partnerName: this.context.partnerName,
      adminLogin: this.context.adminLogin,
    });

    history.push('/get-started');
  };

  render() {
    const { classes, match } = this.props;
    const { filter, stale, filterDialog, loading, isChecklistComplete, noChecklistData } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <Box className={classes.headerContainer}>
          <PageHeader match={match} title="Portfolio Dashboard" />
          {isChecklistComplete || noChecklistData ? null : (
            <div className={classes.setupChecklistContainer}>
              <Typography variant="subtitle2">Setup Checklist</Typography>
              <GetStartedChecklistProgress
                onChecklistComplete={(value) => this.setState({ isChecklistComplete: value })}
              />
              <Button className={classes.finishButton} onClick={this.onFinishClick} variant="contained" size="medium">
                Finish
              </Button>
            </div>
          )}
        </Box>
        <PageGrid isMultiCard>
          <FilterDialog
            filter={filter}
            isOpen={filterDialog}
            closeDialog={() => this.setState({ filterDialog: false })}
            updateFilter={this.updateFilter}
            dateRange
          />
          <GraphsCard view="portfolio" id={null} />
          <QuicklinksCard markStale={this.markStale} view="portfolio" id={null} />
          <PropertiesCard />
          <ValueCard view="portfolio" id={null} />
          <BankingCard />
          <ExpensesCard
            stale={stale.expenses}
            removeStale={this.removeStale}
            view="portfolio"
            id={null}
            startDate={filter.startDate ? filter.startDate : undefined}
            endDate={filter.endDate ? filter.endDate : undefined}
          />
          <BookValueCard view="portfolio" id={null} />
        </PageGrid>
      </>
    );
  }
}

Home.contextType = PersonContext;

Home.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Home);
