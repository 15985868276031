import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { GET_STARTED_CHECKLIST_ITEM_STATUS, GET_STARTED_CHECKLIST_ITEMS } from '~/constants/get-started';
import { PersonContext } from '~/contexts/PersonContext';
import client from '~/feathers';

const useStyles = makeStyles((theme) => ({
  progressIndicatorContainer: {
    position: 'relative',
    display: 'inline-flex',
  },
  progressIndicatorLabelContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressIndicatorLabel: {
    fontSize: '10px',
    color: theme.palette.primary.main,
  },
  circle: {
    strokeLinecap: 'round',
    color: theme.palette.primary.dark,
  },
}));

const GetStartedChecklistProgress = ({ onChecklistComplete }) => {
  const classes = useStyles();
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const context = useContext(PersonContext);
  const { organizationId, partnerSubscribed } = context;

  const getProgressPercentage = (data) => {
    if (data.completedAt) {
      onChecklistComplete(true);
      return;
    }

    const checklistItems = [...GET_STARTED_CHECKLIST_ITEMS];

    // If user is partner subscribed, remove the subscribe item from the checklist
    if (partnerSubscribed) {
      const subscribeIndex = checklistItems.findIndex((checklistItem) => checklistItem.column === 'subscribe');
      checklistItems.splice(subscribeIndex, 1);
    }

    const finishedItemCount = checklistItems.filter((checklistItem) =>
      [GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED, GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED].includes(
        data[checklistItem.column],
      ),
    ).length;
    const percentage = ((finishedItemCount + 1) / checklistItems.length) * 100;
    setProgressBarPercentage(percentage);

    if (onChecklistComplete) {
      onChecklistComplete(false);
    }
  };

  useEffect(() => {
    async function fetchChecklistStatus() {
      const checklistStatusesResponse = await client.service('get-started-checklist').find({
        query: { organizationId },
      });

      const statuses = checklistStatusesResponse.data[0];

      if (statuses) {
        getProgressPercentage(statuses);
      }

      client.service('get-started-checklist').on('patched', (data) => {
        getProgressPercentage(data);
      });
    }

    if (organizationId) {
      fetchChecklistStatus();
    }
  }, [organizationId]);

  return (
    <Box className={classes.progressIndicatorContainer}>
      <CircularProgress
        size={32}
        variant="determinate"
        value={progressBarPercentage}
        thickness={5}
        className={classes.circle}
      />
      <Box className={classes.progressIndicatorLabelContainer}>
        <Typography
          className={classes.progressIndicatorLabel}
          variant="caption"
        >{`${Number(progressBarPercentage).toFixed()}%`}</Typography>
      </Box>
    </Box>
  );
};

GetStartedChecklistProgress.propTypes = {
  onChecklistComplete: PropTypes.func,
};

GetStartedChecklistProgress.defaultProps = {
  onChecklistComplete: null,
};

export default GetStartedChecklistProgress;
