import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import {
  setUpJournalForSubmit,
  setUpRuleJournalScope,
} from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowLoading from '../QuickBookRowLoading';

export default function QuickBookRowRevenueTemplate({
  transaction,
  onTransactionAction,
  onTransactionTypeChange,
  onGetHelp,
  matchState,
}) {
  const context = useContext(PersonContext);
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const { organizationId, organizationName, partnerName, partnerSubscribed } = context;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldAddRule, setShouldAddRule] = useState(false);
  const [revenueAccountOptions, setRevenueAccountOptions] = useState([]);
  const [journal, setJournal] = useState(null);
  const [shouldWarnPartnerPayment, setShouldWarnPartnerPayment] = useState(false);
  const [shouldConfirmUnique, setShouldConfirmUnique] = useState(false);
  const [partnerTransactions, setPartnerTransactions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const getData = async () => {
      const revenueAccountOptions = await getAccountOptions(organizationId, { type: 'Revenue' });

      const interestRevenueAccount = revenueAccountOptions.find((option) => option.default === 'interestRevenue');
      const rentRevenueAccount = revenueAccountOptions.find((option) => option.default === 'rentRevenue');

      const journalObject = getManualBasicJournal();
      const { account, id, description, date, amount } = transaction;
      journalObject.debitLines[0].accountIdSelect = account;
      journalObject.debitLines[0].yodleeTransactionId = id;
      journalObject.description = description;
      journalObject.date = date;
      journalObject.amount = amount;

      if (
        matchState.importedTransactionRuleMatch.length === 1 &&
        matchState.importedTransactionRuleMatch[0].type === 'Revenue'
      ) {
        const match = matchState.importedTransactionRuleMatch[0];
        journalObject.creditLines[0].accountIdSelect = revenueAccountOptions.find(
          (option) => option.id === match.destinationAccountId,
        );
        await setUpRuleJournalScope(match, journalObject, organizationName);
      } else {
        journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

        if (transaction.recommendedTransactionType === 'Revenue' && transaction.recommendedAccount) {
          const defaultAccount = revenueAccountOptions.find(
            (account) => account.default === transaction.recommendedAccount,
          );
          if (defaultAccount) {
            journalObject.creditLines[0].accountIdSelect = defaultAccount;
          }
        } else if (transaction.amount && transaction.amount < 5) {
          journalObject.creditLines[0].accountIdSelect = interestRevenueAccount;
          journalObject.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };
        } else if (transaction.amount && transaction.amount > 300) {
          journalObject.creditLines[0].accountIdSelect = rentRevenueAccount;
        } else if (!transaction.amount) {
          journalObject.creditLines[0].accountIdSelect = rentRevenueAccount;
        }

        if (partnerName && (partnerSubscribed || partnerName === 'Innago') && transaction.amount >= 50) {
          setShouldWarnPartnerPayment(true);
        }
      }

      setJournal(journalObject);
      setRevenueAccountOptions(revenueAccountOptions);
      setIsLoading(false);
    };
    getData();
  }, [organizationId, organizationName, partnerName, partnerSubscribed, transaction, isLoading, matchState]);

  const switchToPartnerDeposit = () => {
    const partnerDepositTransactionType = { label: `${partnerName} Deposit`, value: 'Partner Deposit' };
    onTransactionTypeChange(partnerDepositTransactionType);
  };

  const addRevenue = async (event) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (shouldWarnPartnerPayment && !shouldConfirmUnique) {
      const partnerAccountsResult = await find('accounts', {
        query: {
          organizationId,
          type2: 'Partner',
        },
      });

      // find partner account transactions for the selected property and unit
      const journalLineMatchQuery = {
        organizationId,
        accountId: partnerAccountsResult.data.map((account) => account.id),
        propertyId: journal.propertyId,
        unitId: journal.unitId,
        startDate: moment(journal.date).subtract(21, 'days').format('YYYY-MM-DD'),
        endDate: moment(journal.date).add(4, 'days').format('YYYY-MM-DD'),
      };
      const journalLineMatch = await find('journal-lines', { query: journalLineMatchQuery });

      if (journalLineMatch.data.length) {
        setIsSubmitting(false);
        setShouldConfirmUnique(true);
        setPartnerTransactions(journalLineMatch.data);
        return;
      }
    }

    const journalData = {
      journal,
      organizationId,
      direction: 'cash in',
      transactionId: transaction.id,
      type: 'Revenue',
      bookAsRefund: true,
    };
    const journalSubmit = setUpJournalForSubmit(journalData);

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction(shouldAddRule, journalSubmit, matchState.descriptionMatches);
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
      return;
    }
  };

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <>
      {shouldConfirmUnique && (
        <Dialog open maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="alert-dialog-title">Warning: Possible Duplicate</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {`The transactions listed below were already imported into REI Hub for this ${journal.unitId ? 'unit' : 'property'} from our partner ${partnerName}.
              Paid invoices (both electronic and manual) from ${partnerName} automatically sync into REI Hub.
              If you marked these funds as paid in ${partnerName}, use the '${partnerName} Deposit Template' to avoid double booking revenue.`}
            </Typography>
            <ul>
              {partnerTransactions.map((match) => (
                <li key={match.id}>
                  {match.journal.description}
                  <Box color="text.secondary">
                    {moment(match.journal.date).format('M/D/YYYY')}
                    <Box px={2} component="span">
                      |
                    </Box>
                    <NumberFormat
                      displayType="text"
                      value={match.credit ? match.credit : match.debit}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </Box>
                </li>
              ))}
            </ul>
            <Box mt={3} marginBottom={2} marginX="auto" textAlign="center">
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    switchToPartnerDeposit();
                  }}
                >
                  Use Deposit Template
                </Button>
              </Box>
              <Box marginBottom={1}>
                <Button color="secondary" variant="outlined" size="large" fullWidth onClick={addRevenue}>
                  Save Additional Revenue
                </Button>
              </Box>
              <Button color="primary" onClick={() => setShouldConfirmUnique(false)}>
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <form onSubmit={addRevenue}>
        <Box className={classes.rootContainer}>
          {shouldWarnPartnerPayment && (
            <Alert severity="info" className={classes.infoAlert}>
              {`All paid invoices automatically sync from ${partnerName} into REI Hub. `}
              <Box
                component="span"
                className={classes.link}
                onClick={() => {
                  switchToPartnerDeposit();
                }}
              >
                Use the deposit template
              </Box>
              {` if these funds are marked as received in ${partnerName} to avoid double booking revenue.`}
            </Alert>
          )}
          <Box className={classes.container}>
            <QuickBookRowAutocomplete
              className={classes.autoCompleteField}
              options={revenueAccountOptions}
              onChange={(value) => {
                setJournal({ ...journal, creditLines: [{ ...journal.creditLines[0], accountIdSelect: value }] });
              }}
              label="Revenue Account"
              value={journal.creditLines[0].accountIdSelect}
              required
            />
            <TextField
              className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
              label="Description"
              placeholder="Description"
              variant="outlined"
              size="small"
              value={journal.description}
              onChange={(event) => {
                setJournal({ ...journal, description: event.target.value });
              }}
            />
          </Box>
          <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
          <QuickBookRowNotesAndAttachment
            journal={journal}
            onChange={(newJournal) => {
              setJournal(newJournal);
            }}
          />
          {error && error.message ? (
            <Alert severity="error" className={classes.errorAlert}>
              {error.message}
            </Alert>
          ) : null}
          <Box className={classes.actionsContainer}>
            <Button
              variant="outlined"
              size="small"
              className={classes.helpButton}
              onClick={() => {
                tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
                onGetHelp();
              }}
            >
              <HelpOutline className={classes.helpIcon} />
              Get Help
            </Button>
            <Box className={classes.rowActionsSecondaryContainer}>
              <Box className={classes.checkboxContainer}>
                <Checkbox
                  checked={shouldAddRule}
                  color="primary"
                  onClick={() => {
                    tracking('create_rule clicked', { location: 'Inline Booking - Import Feed' });
                    setShouldAddRule(!shouldAddRule);
                  }}
                />
                <Typography variant="body2">Create rule after saving</Typography>
              </Box>
              <Button
                variant="contained"
                className={classes.saveButton}
                size="small"
                fullWidth={false}
                type="submit"
                onClick={() => {
                  tracking('save clicked', { location: 'Inline Booking - Import Feed' });
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

QuickBookRowRevenueTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
  onGetHelp: PropTypes.func.isRequired,
  matchState: PropTypes.object.isRequired,
};
