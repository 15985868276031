import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  batchActionBar: {
    position: 'fixed',
    bottom: theme.spacing(3),
    backgroundColor: theme.palette.primary.dark,
    width: '50%',
    borderRadius: theme.shape.borderRadius,
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '50%',
  },
  autocompleteFieldContainer: {
    width: '100%',
    justifyContent: 'center',
    maxWidth: '320px',
  },
  textfield: {
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
  },
  infoIcon: {
    color: theme.palette.primary.contrastText,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
    alignItems: 'center',
    width: '50%',
    justifyContent: 'flex-end',
    color: theme.palette.primary.contrastText,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  actionButtonInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  cancelButton: {
    gap: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  noOptionsText: {
    fontStyle: 'italic',
    color: theme.palette.text.primary,
  },
  actionButton: {
    'color': theme.palette.primary.dark,
    'backgroundColor': theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: '#CABCCA',
    },
  },
}));

export default useStyles;
