import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Button from '~/components/Button';

import { useQuickBookRowContext } from './context';
import GetHelpDialog from './GetHelpDialog';
import QuickBookRowLoading from './QuickBookRowLoading';
import QuickBookRowTemplate from './QuickBookRowTemplate';
import QuickBookRowTransactionTypeSelect from './QuickBookRowTransactionTypeSelect';
import useStyles from './styles';

export default function QuickBookRow() {
  const {
    colCount,
    isAirbnbMessageVisible,
    isAlreadyBooked,
    isGetHelpDialogOpen,
    isLoading,
    isVRBOMessageVisible,
    selectedTransactionType,
    transaction,
    activateAirbnb,
    activateVRBO,
    onCancelTransactionBookingClicked,
    onTransactionAction,
    setIsGetHelpDialogOpen,
  } = useQuickBookRowContext();
  const classes = useStyles();

  if (isAlreadyBooked) {
    return (
      <Dialog
        open
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEnforceFocus
        data-testid="quickbookrow-already-booked"
      >
        <DialogTitle id="alert-dialog-title">Transaction Booked</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">This transaction has already been booked</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onTransactionAction()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      {isGetHelpDialogOpen && (
        <GetHelpDialog transaction={transaction} closeDialog={() => setIsGetHelpDialogOpen(false)} />
      )}

      <TableRow className={classes.root}>
        <TableCell className={classes.tableCellRoot} colSpan={colCount}>
          {isLoading ? (
            <QuickBookRowLoading />
          ) : (
            <Box className={classes.mainContainer}>
              {selectedTransactionType?.value === 'Match' ||
              selectedTransactionType?.value === 'Income Template Match' ? null : (
                <QuickBookRowTransactionTypeSelect />
              )}

              {isAirbnbMessageVisible && (
                <Alert severity="info" className={classes.infoAlert} data-testid="quickbookrow-airbnb-message">
                  {`You can upload your Airbnb transactions to REI Hub. `}
                  <Box
                    component="span"
                    className={classes.link}
                    onClick={activateAirbnb}
                    data-testid="quickbookrow-airbnb-link"
                  >
                    Get Started
                  </Box>
                </Alert>
              )}

              {isVRBOMessageVisible && (
                <Alert severity="info" className={classes.infoAlert} data-testid="quickbookrow-vrbo-message">
                  {`You can upload your VRBO transactions to REI Hub. `}
                  <Box
                    component="span"
                    className={classes.link}
                    onClick={activateVRBO}
                    data-testid="quickbookrow-vrbo-link"
                  >
                    Get Started
                  </Box>
                </Alert>
              )}

              <QuickBookRowTemplate />

              {Boolean(onCancelTransactionBookingClicked) && (
                <Button
                  className={classes.cancelButton}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={onCancelTransactionBookingClicked}
                  data-testid="quickbookrow-cancel-button"
                >
                  Cancel
                </Button>
              )}
            </Box>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
