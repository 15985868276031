import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1.5),
    width: '100%',
  },
}));

export default function QuickBookRowLoading() {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid="quickbookrow-loading-container">
      <CircularProgress />
    </div>
  );
}
