import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  successIcon: {
    height: '64px',
    width: '64px',
    color: theme.palette.success.main,
  },
}));

export default function AddAccountSuccess({ accountCount, setAddAccountDialog, setPlaidAddNew, setState }) {
  const { bookkeepingStartDate } = useContext(PersonContext);

  const tracking = useSegmentTrack();
  const classes = useStyles();

  return (
    <DialogContent>
      <Box mx="auto" textAlign="center" marginBottom={2} marginTop={2}>
        <CheckCircleIcon className={classes.successIcon} />
        <Box>
          <Typography variant="h6" gutterBottom>
            {`You've added ${accountCount} ${pluralize('account', accountCount)}
                  to your portfolio.`}
          </Typography>
        </Box>
      </Box>
      <Box mb={1} textAlign="center">
        <Typography variant="body1">
          {`You can now categorize your transactions by property as they are imported. If you
                linked an account, we pulled in everything possible since
                ${moment(bookkeepingStartDate).format('M/D/YYYY')}, but how far back we can go
                depends on the financial institution.`}
        </Typography>
      </Box>
      <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
        <Button
          hasFullWidth
          variant="contained"
          onClick={() => {
            tracking('im_done clicked', { location: 'Onboarding - Accounts: Success' });
            setState((prevState) => ({ ...prevState, key: 'addLoanAccounts' }));
          }}
        >
          I'm Done
        </Button>

        <Box mt={1}>
          <MuiButton
            color="secondary"
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => {
              tracking('link_new_account clicked', { location: 'Onboarding - Accounts: Success' });
              setState((prevState) => ({ ...prevState, accountType: 'linked' }));
              setPlaidAddNew(true);
            }}
          >
            Link New Account
          </MuiButton>
        </Box>

        <Box mt={1}>
          <Button
            hasFullWidth
            variant="text"
            onClick={() => {
              tracking('import_manually clicked', { location: 'Onboarding - Accounts: Success' });
              setState((prevState) => ({ ...prevState, accountType: 'manual' }));
              setAddAccountDialog(true);
            }}
          >
            Add Manually
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
}

AddAccountSuccess.propTypes = {
  accountCount: PropTypes.number.isRequired,
  setAddAccountDialog: PropTypes.func.isRequired,
  setPlaidAddNew: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
