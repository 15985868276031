import React, { useContext, useState } from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { PlayCircleFilled as PlayCircleFilledIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import VideoPlayerDialog from '~/components/VideoPlayerDialog';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '248px',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
    },
  },
}));

export default function CurrentOverview({ setPlaidAddNew, setSkipForNowDialogVisible, setState }) {
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  const { isProduction, partnerName } = useContext(PersonContext);
  const tracking = useSegmentTrack();
  const classes = useStyles();

  return (
    <>
      <VideoPlayerDialog
        open={videoDialogOpen}
        onClose={() => {
          setVideoDialogOpen(false);
        }}
        source="https://www.youtube.com/embed/914EJSM_w-g?rel=0&amp;wmode=opaque"
        allowFullScreen={false}
      />

      <DialogContent>
        <Box mx="auto" textAlign="center" mb={2}>
          <Box style={{ position: 'relative' }} mb={1.5}>
            <img className={classes.heroImage} src="/rei_hub_accounts_intro_hero.png" alt="Accounts" />
            <Fab
              color="secondary"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
              }}
              onClick={() => {
                tracking('watch_accounts_overview clicked', { location: 'Onboarding - Accounts Intro' });
                setVideoDialogOpen(true);
              }}
            >
              <PlayCircleFilledIcon color="white" fontSize="24px" />
            </Fab>
          </Box>
          <Box mb={3}>
            <Box mb={2} textAlign="center">
              <Typography variant="h6">Link the accounts used for your rentals</Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`Transactions will securely and automatically import from your financial institution for easy
                    and accurate categorization. `}
                {partnerName && (
                  <Box component="span" fontWeight="bold">
                    Over 80% of REI Hub customers link at least one account.
                  </Box>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        {partnerName && (
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginTop={3} textAlign="left">
            <Typography variant="body2">
              <li>
                {`Linked accounts in REI Hub require separate authorization from deposit accounts
                  connected through ${partnerName}.`}
              </li>
              <li>
                {`We recommend creating all accounts receiving deposits from ${partnerName} as
                  linked accounts in REI Hub.`}
              </li>
            </Typography>
          </Box>
        )}
        <Box maxWidth="400px" marginX="auto" textAlign="center" mt={3} mb={2}>
          <Button
            hasFullWidth
            variant="contained"
            onClick={() => {
              tracking('link_account clicked', { location: 'Onboarding - Accounts: Intro' });
              setPlaidAddNew(true);
            }}
          >
            Link Account
          </Button>
          {partnerName ? (
            <Box mt={1}>
              <Button
                hasFullWidth
                variant="text"
                onClick={() => {
                  tracking('skip_for_now clicked', { location: 'Onboarding - Accounts Intro' });
                  setSkipForNowDialogVisible(true);
                }}
              >
                Skip For Now
              </Button>
            </Box>
          ) : null}
          {!isProduction ? (
            <Box mt={1}>
              <Button
                hasFullWidth
                variant="text"
                onClick={() => setState((prevState) => ({ ...prevState, key: 'addLoanAccounts' }))}
              >
                Skip (Test)
              </Button>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
    </>
  );
}

CurrentOverview.propTypes = {
  setPlaidAddNew: PropTypes.func.isRequired,
  setSkipForNowDialogVisible: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
