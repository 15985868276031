import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import CardBase from '../components/CardBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import { PersonContext } from '../contexts/PersonContext';
import { find, remove } from '../feathersWrapper';
import { asyncHandleChange } from '../functions/InputHandlers';

const styles = (theme) => ({
  textButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
});

class PropertyManagerMappingRules extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editRuleId: null,
      confirmDeleteDialog: false,
      pageSize: 20,
      filter: {
        page: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const { pageSize, filter } = this.state;
    const { match } = this.props;

    const accountsQuery = {
      organizationId,
      $limit: 500,
      $skip: 0,
      $sort: {
        name: 1,
        id: 1,
      },
    };
    let accounts = [];
    let accountsResult;
    do {
      accountsResult = await find(this, 'accounts', { query: accountsQuery });
      accounts = accounts.concat(accountsResult.data);
      accountsQuery.$skip += accountsQuery.$limit;
    } while (accountsResult.total > accounts.length);

    const properties = await find(this, 'properties', {
      query: { organizationId, $limit: 500 },
    });

    const propertyManagerAccount = accounts.find((account) => account.id.toString() === match.params.accountId);

    const rulesQuery = {
      organizationId,
      propertyManagerAccountId: match.params.accountId,
      $limit: pageSize,
      $skip: pageSize * filter.page,
      $sort: {
        mappingType: 1,
        accountName: 1,
      },
    };

    const rules = await find(this, 'property-manager-pdf-mapping-rules', { query: rulesQuery });

    this.setState({
      rules,
      accounts,
      properties,
      propertyManagerAccount,
      loading: false,
    });
  };

  handleChangePage = async (_event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getData();
  };

  deleteRule = async (ruleId) => {
    this.setState({ loading: true });
    await remove(this, 'property-manager-pdf-mapping-rules', ruleId)
      .then(() => {
        this.setState({ confirmDeleteDialog: false });
        this.getData();
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  getMappedToName = (rule) => {
    const { accounts, properties } = this.state;
    switch (rule.mappingType) {
      case 'Account': {
        if (!rule.accountId) {
          return '---';
        }
        const targetAccount = accounts.find((account) => account.id === rule.accountId);
        return targetAccount.name;
      }
      case 'Property': {
        if (!rule.propertyId) {
          return '---';
        }
        const targetProperty = properties.data.find((property) => property.id === rule.propertyId);
        return targetProperty.address1;
      }
      default:
        return '---';
    }
  };

  render() {
    const { match, classes } = this.props;
    const { rules, propertyManagerAccount, editRuleId, filter, pageSize, loading, confirmDeleteDialog, error } =
      this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader match={match} title={`${propertyManagerAccount.name} Mapping Rules`} />
        {confirmDeleteDialog && (
          <Dialog
            open={confirmDeleteDialog}
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Rule</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Deleting this rule will remove the property or account mapping saved during a previous statement upload.
                You will be prompted to re-map the rule if we encounter the description in a future statement upload.
                Are you sure you want to delete this rule?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({ confirmDeleteDialog: false })}>
                Cancel
              </Button>
              <Button className={classes.deleteConfirmationButton} onClick={() => this.deleteRule(editRuleId)}>
                Delete Rule
              </Button>
            </DialogActions>
            {error && (
              <DialogContent>
                <Typography variant="body1" color="error">
                  {error.message}
                </Typography>
              </DialogContent>
            )}
          </Dialog>
        )}
        <Grid item xs={12}>
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
            <Typography variant="body2">
              These rules have been created based on your selections during previous statement uploads. If you delete a
              rule here, you will be prompted to create a new mapping rule when we encounter the same property or
              account description in a future statement upload.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Mapping Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Mapped To</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Rule Description</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Ignored</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rules.data.map((rule) => (
                    <TableRow key={rule.id}>
                      <TableCell>
                        <Typography variant="body2">{rule.mappingType}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{this.getMappedToName(rule)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {rule.mappingType === 'Account' ? rule.accountName : rule.propertyName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{rule.ignored ? 'Yes' : 'No'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="red"
                          size="small"
                          onClick={() => {
                            this.setState({ editRuleId: rule.id, confirmDeleteDialog: true });
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={rules.total}
                      page={filter.page}
                      rowsPerPage={pageSize}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

PropertyManagerMappingRules.contextType = PersonContext;

PropertyManagerMappingRules.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(PropertyManagerMappingRules);
