import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { PersonContext } from '../../contexts/PersonContext';

import { get } from '../../feathersWrapper';

import LinkBase from '../LinkBase';

const breadcrumbArray = [
  {
    exact: true,
    path: '/',
    breadcrumb: 'Home',
  },
  {
    exact: true,
    path: '/properties',
    breadcrumb: 'Properties',
  },
  {
    exact: true,
    path: '/subportfolios',
    breadcrumb: 'Sub-Portfolios',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId',
    breadcrumb: 'Property',
    property: true,
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/edit',
    breadcrumb: 'Edit',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/loans',
    breadcrumb: 'Loans',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/notes',
    breadcrumb: 'Notes',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units',
    breadcrumb: 'Units',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units/unit/:unitId',
    breadcrumb: 'Unit',
    unit: true,
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units/unit/:unitId/edit',
    breadcrumb: 'Edit',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/units/unit/:unitId/notes',
    breadcrumb: 'Notes',
  },
  {
    exact: true,
    path: '/properties/property/:propertyId/unit/:unitId',
    breadcrumb: 'Unit',
    unit: true,
  },
  {
    exact: true,
    path: '/properties/unit/:unitId',
    breadcrumb: 'Unit',
  },
  {
    exact: true,
    path: '/subportfolios/subportfolio/:subportfolioId',
    breadcrumb: 'Sub-Portfolio',
    subportfolio: true,
  },
  {
    exact: true,
    path: '/transactions/imported',
    breadcrumb: 'Import Feed',
  },
  {
    exact: true,
    path: '/transactions/recurring-transactions',
    breadcrumb: 'Recurring Transactions',
  },
  {
    exact: true,
    path: '/transactions/imported/rules',
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/transactions/booked',
    breadcrumb: 'Booked Transactions',
  },
  {
    exact: true,
    path: '/transactions/revenues',
    breadcrumb: 'Revenues',
  },
  {
    exact: true,
    path: '/transactions/expenses',
    breadcrumb: 'Expenses',
  },
  {
    exact: true,
    path: '/organization/vendors',
    breadcrumb: 'Vendors',
  },
  {
    exact: true,
    path: '/organization/leases',
    breadcrumb: 'Leases',
  },
  {
    exact: true,
    path: '/organization/leases/lease/:leaseId',
    breadcrumb: 'Edit Lease',
  },
  {
    exact: true,
    path: '/organization/leases/add',
    breadcrumb: 'Add Lease',
  },
  {
    exact: true,
    path: '/organization/mileage',
    breadcrumb: 'Mileage Log',
  },
  {
    exact: true,
    path: '/organization/mileage/vehicles',
    breadcrumb: 'Vehicles',
  },
  {
    exact: true,
    path: '/organization/notes',
    breadcrumb: 'Documents & Notes',
  },
  {
    exact: true,
    path: '/organization/contacts',
    breadcrumb: 'Contacts',
  },
  {
    exact: true,
    path: '/organization/time-tracking',
    breadcrumb: 'Time Tracking',
  },
  {
    exact: true,
    path: '/reports',
    breadcrumb: 'Reports',
  },
  {
    exact: true,
    path: '/reports/net-income-by-property',
    breadcrumb: 'Net Income By Property',
  },
  {
    exact: true,
    path: '/reports/net-income-by-subportfolio',
    breadcrumb: 'Net Income By Sub-Portfolio',
  },
  {
    exact: true,
    path: '/reports/net-income-by-unit',
    breadcrumb: 'Net Income By Unit',
  },
  {
    exact: true,
    path: '/reports/net-income-by-period',
    breadcrumb: 'Net Income By Period',
  },
  {
    exact: true,
    path: '/reports/rent-roll',
    breadcrumb: 'Rent Roll',
  },
  {
    exact: true,
    path: '/reports/owner-statement',
    breadcrumb: 'Owner Statement',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/owner-statement-by-property',
    breadcrumb: 'Owner Statement By Property',
  },
  {
    exact: true,
    path: '/reports/general-ledger',
    breadcrumb: 'General Ledger',
  },
  {
    exact: true,
    path: '/reports/attachment-export',
    breadcrumb: 'Attachments Export',
  },
  {
    exact: true,
    path: '/reports/security-deposits-held',
    breadcrumb: 'Security Deposits Held',
  },
  {
    exact: true,
    path: '/reports/net-income',
    breadcrumb: 'Net Income',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/net-operating-income',
    breadcrumb: 'Net Operating Income',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-subportfolio',
    breadcrumb: 'Net Operating Income By Sub-Portfolio',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-property',
    breadcrumb: 'Net Operating Income By Property',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-unit',
    breadcrumb: 'Net Operating Income By Unit',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/net-operating-income-by-period',
    breadcrumb: 'Net Operating Income By Period',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow',
    breadcrumb: 'Operating Cash Flow',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-property',
    breadcrumb: 'Operating Cash Flow By Property',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-subportfolio',
    breadcrumb: 'Operating Cash Flow By Sub-Portfolio',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-unit',
    breadcrumb: 'Operating Cash Flow By Unit',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/operating-cash-flow-by-period',
    breadcrumb: 'Operating Cash Flow By Period',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/cash-flows-by-property',
    breadcrumb: 'Cash Flows By Property',
  },
  {
    exact: true,
    path: '/reports/cash-flows',
    breadcrumb: 'Cash Flows',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/portfolio-value-by-property',
    breadcrumb: 'Portfolio Value By Property',
  },
  {
    exact: true,
    path: '/reports/cash-on-cash',
    breadcrumb: 'Cash on Cash',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/cap-rate',
    breadcrumb: 'Cap Rate',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/operating-expense-ratio',
    breadcrumb: 'Operating Expense Ratio',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/tax-packet',
    breadcrumb: 'Tax Packet',
  },
  {
    exact: true,
    path: '/reports/tax-review',
    breadcrumb: 'Tax Review',
  },
  {
    exact: true,
    path: '/reports/fixed-assets',
    breadcrumb: 'Fixed Assets Schedule',
  },
  {
    exact: true,
    path: '/reports/balance-sheet',
    breadcrumb: 'Balance Sheet',
    isParent: false,
  },
  {
    exact: true,
    path: '/reports/balance-sheet-by-property',
    breadcrumb: 'Balance Sheet By Property',
  },
  {
    exact: true,
    path: '/reports/balance-sheet-by-subportfolio',
    breadcrumb: 'Balance Sheet By Sub-Portfolio',
  },
  {
    exact: true,
    path: '/reports/balance-sheet-by-period',
    breadcrumb: 'Balance Sheet By Period',
  },
  {
    exact: true,
    path: '/reports/trial-balance',
    breadcrumb: 'Trial Balance',
  },
  {
    exact: true,
    path: '/reports/account-register',
    breadcrumb: 'Account Register',
  },
  {
    exact: true,
    path: '/reports/schedule-e',
    breadcrumb: 'Schedule E',
  },
  {
    exact: true,
    path: '/reports/form-8825',
    breadcrumb: 'Form 8825',
  },
  {
    exact: true,
    path: '/reports/expenses-by-vendor',
    breadcrumb: 'Expenses By Vendor',
  },
  {
    exact: true,
    path: '/reports/mileage-by-property',
    breadcrumb: 'Mileage By Property',
  },
  {
    exact: true,
    path: '/accounts/banking',
    breadcrumb: 'Banking',
    // isParent: true,
  },
  {
    exact: true,
    path: '/accounts/banking/connections',
    breadcrumb: 'Connections',
  },
  {
    exact: true,
    path: '/accounts/banking/bank/:accountId',
    breadcrumb: 'Account',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/banking/card/:accountId',
    breadcrumb: 'Account',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/banking/import-feed',
    breadcrumb: 'Import Feed',
  },
  {
    exact: true,
    path: '/accounts/banking/import-feed/rules',
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/accounts/loans',
    breadcrumb: 'Loans',
  },
  {
    exact: true,
    path: '/accounts/loans/loan/:accountId',
    breadcrumb: 'Account',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/loans/escrow/:accountId',
    breadcrumb: 'Account',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/property-managers',
    breadcrumb: 'Property Managers',
  },
  {
    exact: true,
    path: '/organization/fixed-assets',
    breadcrumb: 'Fixed Assets',
  },
  {
    exact: true,
    path: '/organization/fixed-assets/:assetId',
    breadcrumb: 'Fixed Asset',
    fixedAsset: true,
  },
  {
    exact: true,
    path: '/accounts/partner',
    breadcrumb: 'Partner',
    partner: true,
  },
  {
    exact: true,
    path: '/accounts/property-managers/:accountId',
    breadcrumb: 'Property Manager',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/airbnb/:accountId',
    breadcrumb: 'Airbnb',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/vrbo/:accountId',
    breadcrumb: 'VRBO',
    account: true,
  },
  {
    exact: true,
    path: '/accounts/property-managers/:accountId/rules',
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/accounts/airbnb/:accountId/rules',
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/accounts/vrbo/:accountId/rules',
    breadcrumb: 'Rules',
  },
  {
    exact: true,
    path: '/settings',
    breadcrumb: 'Settings',
  },
  {
    exact: true,
    path: '/access',
    breadcrumb: 'Portfolio Access',
  },
  {
    exact: true,
    path: '/my-profile',
    breadcrumb: 'My Profile',
  },
  {
    exact: true,
    path: '/chart-of-accounts',
    breadcrumb: 'Chart of Accounts',
  },
  {
    exact: true,
    path: '/subscription',
    breadcrumb: 'Subscription',
  },
  {
    exact: true,
    path: '/verifyemailaddress',
    breadcrumb: 'Verify Email',
  },
  {
    exact: true,
    path: '/accountant/my-profile',
    breadcrumb: 'My Profile',
  },
  {
    exact: true,
    path: '/accountant/settings',
    breadcrumb: 'Settings',
  },
];

class NavBreadcrumbs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await this.replaceNameFields();
  }

  replaceNameFields = async () => {
    const { match } = this.props;
    const { propertyId, unitId, assetId, accountId, subportfolioId } = match.params;

    // create promises
    let propertyPromise;
    let unitPromise;
    let assetPromise;
    let accountPromise;
    let subportfolioPromise;
    const newState = { loading: false };

    // fire matched promises
    if (propertyId) {
      propertyPromise = get(this, 'properties', propertyId);
    }
    if (unitId) {
      unitPromise = get(this, 'units', unitId);
    }
    if (assetId) {
      assetPromise = get(this, 'fixed-assets', assetId);
    }
    if (accountId) {
      accountPromise = get(this, 'accounts', accountId);
    }
    if (subportfolioId) {
      subportfolioPromise = get(this, 'entities', subportfolioId);
    }

    // resolve promises
    if (propertyId) {
      const property = await propertyPromise;
      newState.propertyName = property.address1;
    }
    if (unitId) {
      const unit = await unitPromise;
      newState.unitName = unit.name;
    }
    if (assetId) {
      const asset = await assetPromise;
      newState.assetName = asset.name;
    }
    if (accountId) {
      const account = await accountPromise;
      newState.accountName = account.name;
    }
    if (subportfolioId) {
      const subportfolio = await subportfolioPromise;
      newState.subportfolioName = subportfolio.name;
    }
    this.setState(newState);
  };

  generateBreadcrumb = (route, currentPath, currentUrl) => {
    if (currentPath.startsWith(route.path)) {
      if (currentPath === route.path) {
        return (
          <Typography color="textPrimary" variant="body2" key={route.path}>
            {this.breadcrumbText(route)}
          </Typography>
        );
      }
      if (route.isParent === false) {
        return null;
      }
      const routePathArray = route.path.split('/');
      const urlArray = currentUrl.split('/', routePathArray.length);
      let url = urlArray.join('/');
      if (route.path === '/') {
        url = '/dashboard';
      }
      return (
        <LinkBase to={url} key={route.path}>
          <Typography variant="body2">{this.breadcrumbText(route)}</Typography>
        </LinkBase>
      );
    }
    return null;
  };

  breadcrumbText = (route) => {
    if (route.property) {
      const { propertyName } = this.state;
      return propertyName || 'Property';
    }
    if (route.unit) {
      const { unitName } = this.state;
      return unitName || 'Unit';
    }
    if (route.fixedAsset) {
      const { assetName } = this.state;
      return assetName || 'Fixed Asset';
    }
    if (route.account) {
      const { accountName } = this.state;
      return accountName || 'Account';
    }
    if (route.subportfolio) {
      const { subportfolioName } = this.state;
      return subportfolioName || 'Sub-Portfolio';
    }
    if (route.partner) {
      const { partnerName } = this.context;
      return partnerName || 'Partner';
    }
    return route.breadcrumb;
  };

  render() {
    const { match } = this.props;
    const { loading } = this.state;

    if (loading) {
      return null;
    }
    if (match.path === '/dashboard') {
      return null;
    }
    return (
      <Breadcrumbs className="noprint" aria-label="Breadcrumb">
        {breadcrumbArray.map((route) => this.generateBreadcrumb(route, match.path, match.url))}
      </Breadcrumbs>
    );
  }
}

NavBreadcrumbs.contextType = PersonContext;

NavBreadcrumbs.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NavBreadcrumbs;
