import { find } from '~/feathersFunctionalWrapper';

export const getAccountOptions = async (organizationId, additionalParams = {}) => {
  const accountsQuery = {
    $and: [{ organizationId }, { inactive: { $or: [null, false] }, ...additionalParams }],
    $skip: 0,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };

  let accounts = [];
  let accountsResult;
  do {
    accountsResult = await find('accounts', { query: accountsQuery });
    accounts = accounts.concat(accountsResult.data);
    accountsQuery.$skip += accountsQuery.$limit;
  } while (accountsResult.total > accounts.length);
  return accounts;
};

export const getVendorOptions = async (organizationId, addNew = false) => {
  const query = {
    organizationId,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const vendorsResult = await find('vendors', { query });
  if (addNew) {
    vendorsResult.data.unshift({
      name: '+ Add New',
      id: 'New Instance',
    });
  }
  return vendorsResult.data;
};

export const getPropertyOptions = async (organizationId, additionalParams = {}) => {
  const query = {
    organizationId,
    inactive: false,
    ...additionalParams,
    $limit: 500,
    $sort: {
      address1: 1,
      id: 1,
    },
  };
  const propertiesResult = await find('properties', { query });
  return propertiesResult.data;
};

export const getUnitOptions = async (organizationId, additionalParams = {}) => {
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    ...additionalParams,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const collator = new Intl.Collator('en', { numeric: true });
  const result = await find('units', { query });
  return result.data.sort((a, b) => collator.compare(a.name, b.name));
};

export const getSubportfolioOptions = async (organizationId, additionalParams = {}) => {
  const query = {
    organizationId,
    ...additionalParams,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const result = await find('entities', { query });
  return result.data;
};

export const getBalanceSheetAccountOptions = async (organizationId, additionalParams = {}) => {
  const accountsQuery = {
    organizationId,
    inactive: { $or: [null, false] },
    type: ['Asset', 'Liability', 'Equity'],
    ...additionalParams,
    $limit: 500,
    $skip: 0,
    $sort: {
      name: 1,
      id: 1,
    },
  };

  let accounts = [];
  let accountsResult;
  do {
    accountsResult = await find('accounts', { query: accountsQuery });
    accounts = accounts.concat(accountsResult.data);
    accountsQuery.$skip += accountsQuery.$limit;
  } while (accountsResult.total > accounts.length);
  return accounts;
};

export const getFixedAssetOptions = async (organizationId, addNew = false) => {
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const assetsResult = await find('fixed-assets', { query });
  if (addNew) {
    assetsResult.data.unshift({
      name: '+ Add New',
      id: 'New Instance',
    });
  }
  return assetsResult.data;
};

export const getPaymentAccountOptions = async (organizationId, additionalParams = {}) => {
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    type2: [
      'Bank',
      'Credit Card',
      'Property Management',
      'Owner Funds',
      'HELOC',
      'Accounts Payable',
      'Prepaid Expense',
    ],
    ...additionalParams,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const accountsResult = await find('accounts', { query });
  return accountsResult.data;
};

export const getImportAccountOptions = async (organizationId) => {
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    type2: ['Bank', 'Credit Card'],
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const accountsResult = await find('accounts', { query });

  const newArr = [{ name: 'All Accounts', id: 'All' }, ...accountsResult.data];
  return newArr;
};
