import { useEffect } from 'react';

export default function useSelectedTransactionTypeEffects({
  matchState,
  partnerName,
  selectedTransactionType,
  transactionAmount,
  transactionTypes,
  setSelectedTransactionType,
}) {
  useEffect(() => {
    if (!selectedTransactionType && transactionTypes?.length) {
      const expense = transactionTypes.find((transactionType) => transactionType.label === 'Expense');
      const revenue = transactionTypes.find((transactionType) => transactionType.label === 'Revenue');
      const partnerDeposit = transactionTypes.find(
        (transactionType) => transactionType.label === `${partnerName} Deposit`,
      );

      if (partnerName && transactionAmount > 100 && partnerDeposit) {
        setSelectedTransactionType(partnerDeposit);
      } else if (expense) {
        setSelectedTransactionType(expense);
      } else if (revenue) {
        setSelectedTransactionType(revenue);
      } else {
        setSelectedTransactionType(transactionTypes[0]);
      }
    }
  }, [partnerName, selectedTransactionType, transactionAmount, transactionTypes, setSelectedTransactionType]);

  const { hasLoanMatch, hasTransactionMatch, importedTransactionRuleMatch, incomeTemplateMatch } = matchState;
  useEffect(() => {
    if (hasTransactionMatch || importedTransactionRuleMatch.length > 1 || incomeTemplateMatch.length === 1) {
      setSelectedTransactionType({ label: 'Match', value: 'Match' });
    } else if (importedTransactionRuleMatch.length === 1 && importedTransactionRuleMatch[0].type !== 'Split') {
      const match = importedTransactionRuleMatch[0];
      const type = match.type.includes('Transfer') ? 'Transfer' : match.type;
      setSelectedTransactionType({ label: type, value: type });
    } else if (hasLoanMatch) {
      setSelectedTransactionType({ label: 'Loan Payment', value: 'Loan Payment' });
    }
  }, [
    hasLoanMatch,
    hasTransactionMatch,
    importedTransactionRuleMatch,
    incomeTemplateMatch,
    setSelectedTransactionType,
  ]);
}
