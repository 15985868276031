import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowAutocomplete from '../QuickBookRowAutocomplete';
import QuickBookRowLoading from '../QuickBookRowLoading';
import QuickBookRowNotesAndAttachment from '../QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '../QuickBookRowScopeSelect';

import { setUpJournalForSubmit } from './helpers/journalFunctions';
import { useStyles } from './styles';

export default function QuickBookRowOwnerContributionTemplate({ transaction, onTransactionAction, onGetHelp }) {
  const [isLoading, setIsLoading] = useState(true);
  const [equityAccountOptions, setEquityAccountOptions] = useState([]);
  const [journal, setJournal] = useState(getManualBasicJournal());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organizationId, organizationName, triggerSnackbar } = useContext(PersonContext);
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const classes = useStyles();

  const fetchEquityAccounts = useCallback(async (organizationId) => {
    setIsLoading(true);

    const accounts = await getAccountOptions(organizationId, {
      default: { $or: [null, { $ne: 'autoBalance' }] },
      type: 'Equity',
    });
    setEquityAccountOptions(accounts);

    setIsLoading(false);
  }, []);

  function handleEquityAccountChange(value) {
    setJournal((prevJournal) => ({
      ...prevJournal,
      creditLines: [{ ...prevJournal.creditLines[0], accountIdSelect: value }],
    }));
  }

  function handleDescriptionChange(event) {
    event.persist();
    setJournal((prevJournal) => ({ ...prevJournal, description: event.target.value }));
  }

  function validateTransactionForContribution() {
    if (!journal.date) {
      triggerSnackbar({
        horizontal: 'center',
        message: 'Please enter a valid date for this transaction',
        type: 'warning',
        vertical: 'top',
      });
      return false;
    }

    if (!journal.amount) {
      triggerSnackbar({
        horizontal: 'center',
        message: 'Please enter a non-zero amount for this transaction',
        type: 'warning',
        vertical: 'top',
      });
      return false;
    }

    return true;
  }

  async function bookContribution(event) {
    event.preventDefault();

    const isValid = validateTransactionForContribution();
    if (isSubmitting || !isValid) {
      return;
    }

    setIsSubmitting(true);

    try {
      const journalBody = setUpJournalForSubmit({
        journal,
        organizationId,
        direction: 'cash in',
        transactionId: transaction.id,
        type: 'Contribution',
        bookAsRefund: false,
      });
      await create('journals', journalBody);

      setIsSubmitting(false);
      onTransactionAction();
    } catch {
      triggerSnackbar({
        horizontal: 'center',
        message: 'Something went wrong. Please try again.',
        type: 'error',
        vertical: 'top',
      });

      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    fetchEquityAccounts(organizationId);
  }, [fetchEquityAccounts, organizationId]);

  useEffect(() => {
    setJournal((prevJournal) => {
      const newJournal = { ...prevJournal };
      newJournal.debitLines[0].accountIdSelect = transaction.account;
      newJournal.debitLines[0].transactionId = transaction.id;
      newJournal.description = transaction.description;
      newJournal.date = transaction.date;
      newJournal.amount = transaction.amount;
      newJournal.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };
      return newJournal;
    });
  }, [organizationName, transaction]);

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <form onSubmit={bookContribution}>
      <div className={classes.rootContainer}>
        <div className={classes.container}>
          <QuickBookRowAutocomplete
            className={classes.autoCompleteField}
            options={equityAccountOptions}
            onChange={handleEquityAccountChange}
            label="Equity Account"
            value={journal.creditLines[0].accountIdSelect}
            required
          />
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={handleDescriptionChange}
          />
        </div>

        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />

        <QuickBookRowNotesAndAttachment journal={journal} onChange={setJournal} />

        <div className={classes.actionsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.helpButton}
            onClick={() => {
              tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
              onGetHelp();
            }}
          >
            <HelpOutline className={classes.helpIcon} />
            Get Help
          </Button>
          <div className={classes.rowActionsSecondaryContainer}>
            <Button
              className={classes.saveButton}
              disabled={isSubmitting}
              size="small"
              type="submit"
              variant="contained"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

QuickBookRowOwnerContributionTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onGetHelp: PropTypes.func.isRequired,
};
