import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { get, patch } from '../feathersWrapper';

const useStyles = makeStyles((theme) => ({
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
  red: {
    color: theme.palette.error.main,
  },
}));

export default function ViewReconciledTransactionDialog({ closeDialog, onUnreconcileTransaction, journalLineId }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [key, setKey] = useState(null);
  const [journalLine, setJournalLine] = useState(null);

  useEffect(() => {
    if (!loading) {
      return;
    }
    async function getData() {
      const journalLineResponse = await get(this, 'journal-lines', journalLineId);
      setJournalLine(journalLineResponse);
      setKey('viewReconciledTransaction');
      setLoading(false);
    }
    getData();
  }, [journalLineId, loading]);

  const unreconcileTransaction = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    try {
      await patch(this, 'journal-lines', journalLineId, { reconciled: false });
    } catch (patchError) {
      setError(patchError);
      setSubmitting(false);
      return;
    }
    onUnreconcileTransaction();
    closeDialog();
  };

  const getDialogContent = () => {
    switch (key) {
      case 'viewReconciledTransaction':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Reconciled Transaction</DialogTitle>
            <DialogContent>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Status
                </FormLabel>
                <Typography>{journalLine.reconciled ? 'Reconciled' : 'Not Reconciled'}</Typography>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
              {journalLine.reconciled && (
                <Button onClick={() => setKey('confirmUnreconcile')} className={classes.deleteConfirmationButton}>
                  Unreconcile
                </Button>
              )}
            </DialogActions>
          </>
        );
      case 'confirmUnreconcile':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Really Unreconcile Transaction?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`This transaction will be unreconciled.
                  This may impact your next attempt to reconcile this account.`}
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={unreconcileTransaction} className={classes.deleteConfirmationButton}>
                Unreconcile Transaction
              </Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Dialog
      open
      scroll="body"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {getDialogContent(key)}
    </Dialog>
  );
}

ViewReconciledTransactionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onUnreconcileTransaction: PropTypes.func.isRequired,
  journalLineId: PropTypes.node.isRequired,
};
