import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { PersonContext } from '~/contexts/PersonContext';
import history from '~/history';
import authenticateTheme from '~/theme/authenticateTheme';

export default function AuthenticateContainer({ children }) {
  const [loading, setLoading] = useState(true);
  const { checkCache, id, accountantLogin } = useContext(PersonContext);
  const location = useLocation();

  async function checkRedirect() {
    if (id && accountantLogin) {
      if (location.pathname !== '/accountant/firms') {
        await history.push('/accountant/firms');
      }
    } else if (id) {
      if (location.pathname !== '/portfolios' && location.pathname !== '/admin-password-reset') {
        await history.push('/portfolios');
      }
    }
  }

  useEffect(() => {
    async function init() {
      checkCache();
      await checkRedirect();
      setLoading(false);
    }

    init();
  }, []);

  useEffect(() => {
    checkRedirect();
  }, [id, accountantLogin, location.pathname]);

  if (loading) {
    return null;
  }

  return <MuiThemeProvider theme={authenticateTheme}>{children}</MuiThemeProvider>;
}

AuthenticateContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
