import React, { useCallback, useMemo, useState } from 'react';
import { Card, makeStyles, TablePagination } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

import { checkIsNegativeAmount, parseTransactionAmount } from '~/functions/AmountFunctions';
import { TablePaginationButtons } from '~/functions/TableFunctions';

import { TRANSACTIONS_PER_PAGE } from '../constants';
import { getBookedTransactionDescription } from '../utils';

import TransactionItem from './TransactionItem';

const useListStyles = makeStyles((theme) => ({
  container: {
    'display': 'flex',
    'flexDirection': 'column',
    'marginBottom': theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    '& .MuiTableCell-head': {
      padding: 0,
    },
  },
  paginationContainer: {
    'position': 'relative',
    'padding': 0,

    '& > #pagination-buttons': {
      marginLeft: 'auto',
      maxHeight: theme.spacing(4),
    },
  },
  paginationSpacer: {
    flex: 0,
  },
  paginationCaption: {
    marginLeft: theme.spacing(2),
  },
}));

export default function TransactionsListMobile({
  page,
  parentAndChildrenTransactions,
  transactions,
  transactionCount,
  onPageChange,
  onTransactionBooked,
  onTransactionMenuClicked,
  onViewTransactionClicked,
  onUnignoreTransactionClicked,
  onUnsplitTransactionClicked,
}) {
  const [selectedTransactionIdForBooking, setSelectedTransactionIdForBooking] = useState(null);

  const parsedTransactions = useMemo(() => {
    return transactions.flatMap((transaction) => {
      const transactionChildren = parentAndChildrenTransactions?.find(
        (parentAndChild) => parentAndChild.parentId === transaction.id,
      )?.children;
      return [transaction, ...(transactionChildren || [])].map((transaction) => {
        const isTransactionAmountNegative = checkIsNegativeAmount(
          transaction.account.type,
          transaction.yodleeType,
          transaction.amount === 0,
        );
        const parsedTransactionAmount = parseTransactionAmount(transaction.amount);

        return {
          accountName: transaction.account.name,
          amount: isTransactionAmountNegative ? `(${parsedTransactionAmount})` : parsedTransactionAmount,
          bookedDescription: transaction.journalId ? getBookedTransactionDescription(transaction) : null,
          date: moment(transaction.date).format('M/D/YYYY'),
          description: transaction.splitParentId ? `Split - ${transaction.description}` : transaction.description,
          id: transaction.id,
          isAutoBooked: Boolean(transaction.journal?.autobooked),
          isBooked: Boolean(transaction.journalId),
          isIgnored: Boolean(transaction.ignored),
          isSplitChild: Boolean(transaction.splitParentId),
          isSplitParent: Boolean(transaction.split),
          rawTransaction: transaction,
        };
      });
    });
  }, [transactions, parentAndChildrenTransactions]);

  const classes = useListStyles();

  const handleBookClicked = useCallback((transactionId) => {
    setSelectedTransactionIdForBooking(transactionId);
  }, []);

  return (
    <Card className={classes.container}>
      <TablePagination
        classes={{
          caption: classes.paginationCaption,
          spacer: classes.paginationSpacer,
          toolbar: classes.paginationContainer,
        }}
        count={transactionCount}
        page={page}
        rowsPerPage={TRANSACTIONS_PER_PAGE}
        rowsPerPageOptions={[TRANSACTIONS_PER_PAGE]}
        variant="head"
        onPageChange={onPageChange}
        ActionsComponent={TablePaginationButtons}
      />
      {parsedTransactions.map((transaction) => (
        <TransactionItem
          isSelectedForBooking={selectedTransactionIdForBooking === transaction.id}
          key={transaction.id}
          transaction={transaction}
          onBookTransactionClicked={handleBookClicked}
          onTransactionBooked={onTransactionBooked}
          onTransactionMenuClicked={onTransactionMenuClicked}
          onUnsplitTransactionClicked={onUnsplitTransactionClicked}
          onUnignoreTransactionClicked={onUnignoreTransactionClicked}
          onViewTransactionClicked={onViewTransactionClicked}
        />
      ))}
      <TablePagination
        classes={{
          caption: classes.paginationCaption,
          spacer: classes.paginationSpacer,
          toolbar: classes.paginationContainer,
        }}
        count={transactionCount}
        page={page}
        rowsPerPage={TRANSACTIONS_PER_PAGE}
        rowsPerPageOptions={[TRANSACTIONS_PER_PAGE]}
        variant="footer"
        onPageChange={onPageChange}
        ActionsComponent={TablePaginationButtons}
      />
    </Card>
  );
}

TransactionsListMobile.propTypes = {
  page: PropTypes.number.isRequired,
  parentAndChildrenTransactions: PropTypes.array,
  transactions: PropTypes.array.isRequired,
  transactionCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onTransactionBooked: PropTypes.func.isRequired,
  onTransactionMenuClicked: PropTypes.func.isRequired,
  onViewTransactionClicked: PropTypes.func.isRequired,
  onUnignoreTransactionClicked: PropTypes.func.isRequired,
  onUnsplitTransactionClicked: PropTypes.func.isRequired,
};
