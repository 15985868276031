import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '~/components/IconButton';
import { ORGANIZATION_CELEBRATION_TYPES } from '~/constants';

const MESSAGES = {
  [ORGANIZATION_CELEBRATION_TYPES.FIRST]:
    "Boom! Your first transaction is in the books! 🎉 You're on your way to making tax season, relax season.",
  [ORGANIZATION_CELEBRATION_TYPES.MORE_THAN_25]:
    '25+ transactions logged—your books look solid. 🚀 Investors with clean books make smarter decisions.',
  [ORGANIZATION_CELEBRATION_TYPES.MORE_THAN_50]:
    "50+ transactions and counting! 🔥 You're a bookkeeping pro. Keep building that financial clarity!",
  [ORGANIZATION_CELEBRATION_TYPES.MORE_THAN_100]:
    '100+ transactions logged! 🏆 With books this organized, tax time will be a breeze!',
};

const useStyles = makeStyles((theme) => ({
  container: ({ isOpen }) => ({
    'position': 'fixed',
    'top': isOpen ? theme.spacing(4.5) : -100,
    'zIndex': 100000,
    'display': 'flex',
    'gap': theme.spacing(1.25),
    'alignItems': 'center',
    'backgroundColor': theme.palette.primary.main,
    'backgroundImage': 'url(/celebration_confetti.gif)',
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'borderRadius': theme.tokens.shape.borderRadius.md,
    'marginRight': theme.spacing(1.5),
    'opacity': isOpen ? 1 : 0,
    'paddingBlock': theme.spacing(1.5),
    'paddingInline': theme.spacing(2),
    'width': '-webkit-fill-available',
    'transition': 'top 0.5s ease-in-out, opacity 0.5s ease-in-out',

    '& > *': {
      color: theme.palette.common.whiteStates.main,
    },
  }),
  text: {
    flex: 1,
  },
}));

export default function CelebrationBar({ className, isOpen, messageType, onClose }) {
  const matchesSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const classes = useStyles({ isOpen });

  useEffect(() => {
    const onCloseTimeout = isOpen ? setTimeout(onClose, 8000) : null;
    return () => clearTimeout(onCloseTimeout);
    // Since ImportedTransactions is a class component, I don't have a way to memoize the onClose prop, and that will cause this effect to run more than necessary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className={cx(classes.container, className)}>
      <Typography className={classes.text} variant={matchesSmUp ? 'subtitle1' : 'subtitle2'}>
        {MESSAGES[messageType]}
      </Typography>
      <IconButton aria-label="Dismiss celebration bar" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}

CelebrationBar.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  messageType: PropTypes.oneOf(Object.values(ORGANIZATION_CELEBRATION_TYPES)).isRequired,
  onClose: PropTypes.func.isRequired,
};
