import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import history from '../history';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class OwnerStatement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const accountsChangeReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense', 'Equity'],
    });
    const startingAccountsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense', 'Equity'],
    });
    startingAccountsReportFilter.date.$lte = moment(filter.startDate).subtract(1, 'days').format('YYYY-MM-DD');
    delete startingAccountsReportFilter.date.$gte;

    // launch async calls
    const accountsChangePromise = create(this, 'reports', accountsChangeReportFilter);
    const startingAccountsPromise = create(this, 'reports', startingAccountsReportFilter);

    const accounts = await accountsChangePromise;

    const accountsObject = {
      revenues: {
        accounts: [],
      },
      expenses: {
        accounts: [],
      },
      income: {
        accounts: [],
      },
      ownerFunds: {
        accounts: [],
      },
      ownerFundsWithIncome: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    accounts.forEach((account) => {
      account.display = account.netCredits !== 0;
      account.subaccounts = [];
      account.values = [account.netCredits];
      if (account.type === 'Expense') {
        accountsObject.expenses.accounts.push(account);
        accountsObject.income.accounts.push(account);
        accountsObject.ownerFundsWithIncome.accounts.push(account);
      } else if (account.type === 'Revenue') {
        accountsObject.revenues.accounts.push(account);
        accountsObject.income.accounts.push(account);
        accountsObject.ownerFundsWithIncome.accounts.push(account);
      } else if (account.type2 === 'Owner Funds') {
        accountsObject.ownerFunds.accounts.push(account);
        accountsObject.ownerFundsWithIncome.accounts.push(account);
      } else {
        accountsObject.excluded.accounts.push(account);
      }
      accountsObject.byId[account.id] = account;
    });

    // assign parent accounts
    accounts.forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
        if (account.netCredits !== 0) {
          accountsObject.byId[account.parentAccountId].display = true;
        }
      }
    });

    const startingAccountsArray = await startingAccountsPromise;
    startingAccountsArray.forEach((startingAccount) => {
      const account = accountsObject.byId[startingAccount.id];
      account.starting = startingAccount.netCredits;
      account.ending = account.starting + account.values[0];
    });

    const ownerFundsObject = {};
    ownerFundsObject.starting = accountsObject.ownerFundsWithIncome.accounts.reduce(
      (total, account) => total + account.starting,
      0,
    );
    ownerFundsObject.ending = accountsObject.ownerFundsWithIncome.accounts.reduce(
      (total, account) => total + account.ending,
      0,
    );

    const headers = [null];

    await asyncSetState(
      {
        loading: false,
        headers,
        accountsObject,
        ownerFundsObject,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportPdf = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, ownerFundsObject, headers, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-pdf`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        ownerFundsObject,
        headers,
        exportOptions,
        reportName: 'Owner Statement',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'owner-statement.pdf';
        document.body.appendChild(a);
        a.click();
      });
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, ownerFundsObject, headers, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        ownerFundsObject,
        headers,
        exportOptions,
        reportName: 'Owner Statement',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'owner-statement.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const { accountsObject, ownerFundsObject, filter, filterDialog, loading, downloadDialog } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Owner Statement"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          exportPdf={this.exportPdf}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <>
                  <Box mx="auto" mt="50px" maxWidth="600px">
                    <FinancialAccountLine2 label="Net Income" overline bold />
                    <FinancialAccountLine2 label="Revenues" indent={1} bold />
                    {accountsObject.revenues.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Revenues"
                      values={this.sumColumnValues(accountsObject.revenues.accounts)}
                      sumline
                      bold
                      indent={1}
                    />
                    <FinancialAccountLine2 label="Expenses" indent={1} bold />
                    {accountsObject.expenses.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Expenses"
                      values={this.sumColumnValues(accountsObject.expenses.accounts)}
                      sumline
                      bold
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Net Income"
                      values={this.sumColumnValues(accountsObject.income.accounts)}
                      sumline
                      underline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2 label="Owner Funds" overline bold />
                    <FinancialAccountLine2 label="Starting Balance" values={[ownerFundsObject.starting]} indent={1} />
                    <FinancialAccountLine2
                      label="Net Income"
                      values={this.sumColumnValues(accountsObject.income.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Net Distributions"
                      values={this.sumColumnValues(accountsObject.ownerFunds.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Ending Balance*"
                      values={[ownerFundsObject.ending]}
                      indent={1}
                      sumline
                      bold
                      underline
                      marginBottom
                    />
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    // bgcolor="common.white"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      {`* Owner funds differ from the owner cash balance if asset purchases, loan repayments, or non-cash expenses (such as depreciation) have been entered.
                        In these cases use a balance sheet report.`}
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

OwnerStatement.contextType = PersonContext;

OwnerStatement.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OwnerStatement;
