import React, { useState } from 'react';
import { Checkbox, Dialog, FormControlLabel, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import client from '~/feathers';
import { checkIsNegativeAmount, parseTransactionAmount } from '~/functions/AmountFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const SEGMENT_LOCATION = 'Ignore Transaction Modal - Import Feed';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(8),
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  bodyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  footerCheckbox: {
    alignSelf: 'flex-start',
    marginInline: '0',

    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      marginBottom: '0',
      marginLeft: '-11px',
    },
  },
  footerButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  cancelButton: {
    order: 2,

    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  ignoreButton: {
    order: 1,

    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
}));

export default function IgnoreImportedTransactionDialog({ closeDialog, onIgnore, transaction }) {
  const classes = useStyles();
  const tracking = useSegmentTrack();
  const [submitting, setSubmitting] = useState(false);
  const [shouldAddRule, setShouldAddRule] = useState(false);
  const [error, setError] = useState(null);

  const isNegativeTransactionAmount = checkIsNegativeAmount(
    transaction.account.type,
    transaction.yodleeType,
    transaction.amount === 0,
  );
  const parsedTransactionAmount = isNegativeTransactionAmount
    ? `(${parseTransactionAmount(transaction.amount)})`
    : parseTransactionAmount(transaction.amount);

  const ignoreTransaction = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const yodleeTransactionsService = client.service('yodlee-transactions');
    try {
      await yodleeTransactionsService.patch(transaction.id, { ignored: true });
      closeDialog();
      onIgnore(shouldAddRule, transaction);
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="subtitle1">Ignore Transaction</Typography>
          <IconButton aria-label="Close" color="primary" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.body}>
          <Typography variant="caption">{`${moment(transaction.date).format('M/D/YYYY')} • ${transaction.account.name}`}</Typography>
          <div className={classes.bodyHeader}>
            <Typography variant="h6">{transaction.description}</Typography>
            <Typography color={isNegativeTransactionAmount ? 'error' : 'textPrimary'} variant="h6">
              {parsedTransactionAmount}
            </Typography>
          </div>

          {error?.message && (
            <Alert className={classes.error} severity="error">
              {error.message}
            </Alert>
          )}
        </div>

        <div className={classes.footer}>
          <FormControlLabel
            className={classes.footerCheckbox}
            control={
              <Checkbox
                checked={shouldAddRule}
                color="primary"
                onChange={() => {
                  if (shouldAddRule) {
                    tracking('create_rule unchecked', { location: SEGMENT_LOCATION });
                  } else {
                    tracking('create_rule checked', { location: SEGMENT_LOCATION });
                  }
                  setShouldAddRule(!shouldAddRule);
                }}
              />
            }
            label="Create rule after ignoring"
          />
          <div className={classes.footerButtons}>
            <Button
              className={classes.cancelButton}
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => {
                tracking('cancel clicked', { location: SEGMENT_LOCATION });
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.ignoreButton}
              color="primary"
              size="medium"
              onClick={() => {
                tracking('ignore clicked', { location: SEGMENT_LOCATION });
                ignoreTransaction();
              }}
            >
              Ignore
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

IgnoreImportedTransactionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
