import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CustomStepper from '~/components/CustomStepper';
import { PersonContext } from '~/contexts/PersonContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.secondary,
  },
  subLogoContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'order': 1,
    'backgroundColor': theme.tokens.materialColors.grey[100],
    'paddingBlock': theme.spacing(1),
    'paddingInline': theme.spacing(3),
    'width': '100%',

    '& img': {
      height: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      'order': 2,

      '& img': {
        height: theme.spacing(3),
      },
    },
  },
  appBar: ({ isTurboTenantPartner }) => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    order: 2,
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: isTurboTenantPartner ? theme.spacing(6) : theme.spacing(9),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      order: 1,
      height: theme.spacing(9),
    },
  }),
  logo: ({ isTurboTenantPartner }) => ({
    height: isTurboTenantPartner ? theme.spacing(4) : theme.spacing(5),

    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(5),
    },
  }),
  stepperBackground: {
    order: 3,
    backgroundColor: theme.palette.background.secondary,
  },
  stepperContainer: {
    maxWidth: '960px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    order: 4,
    backgroundColor: theme.palette.background.secondary,
    width: '100%',
    padding: 0,
  },
}));

function OnboardingLayout({ children, className }) {
  const { partnerName, settings } = useContext(PersonContext);

  const isTurboTenantPartner = partnerName === 'TurboTenant';
  const accountsSetupLabel = settings?.['2_variant_test'] === 'test' ? 'Add Transactions' : 'Link Accounts';

  const classes = useStyles({ isTurboTenantPartner });

  return (
    <div className={classes.container}>
      {isTurboTenantPartner && (
        <div className={classes.subLogoContainer}>
          <img src="/powered_by_reihub_logo.svg" alt="Powered by REI Hub" />
        </div>
      )}
      <div className={classes.appBar}>
        <img
          alt="REI Hub logo"
          src={isTurboTenantPartner ? '/turbotenant_full_logo.svg' : '/rei_hub_logo_purple.svg'}
          className={classes.logo}
        />
      </div>
      <div className={classes.stepperBackground}>
        <div className={classes.stepperContainer}>
          <CustomStepper
            orientation="horizontal"
            stepProps={[
              {
                label: 'Profile Info',
                url: '/dashboard',
              },
              {
                label: 'Add Properties',
                url: '/properties',
              },
              {
                label: accountsSetupLabel,
                url: '/accounts/banking',
              },
            ]}
            allCompletedUrl="/onboarding-complete"
          />
        </div>
      </div>
      <div className={classNames(classes.content, className)}>{children}</div>
    </div>
  );
}

OnboardingLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

OnboardingLayout.defaultProps = {
  className: undefined,
};

export default OnboardingLayout;
