import React from 'react';
import NumberFormat from 'react-number-format';

export const axisLabels = (label) => {
  if (label >= 1000000) {
    return `$${label / 1000000}M`;
  }
  if (label >= 10000) {
    return `$${label / 1000}K`;
  }
  if (label <= -10000) {
    return `-$${-label / 1000}K`;
  }
  if (label < 0) {
    return `-$${-label}`;
  }
  return `$${label}`;
};

export const monthAxisLabels = (label) => label.split('-')[0];

const getColor = (value) => {
  if (value < 0) {
    return '#4F7CAC';
  }
  return '#82CA9D';
};

// eslint-disable-next-line no-unused-vars
export const tooltipFormatter = (value, name, props) => (
  <NumberFormat
    displayType="text"
    value={value}
    thousandSeparator
    prefix="$"
    decimalScale={2}
    fixedDecimalScale
    style={props.shouldShowDefaultColor ? {} : { color: getColor(value) }}
  />
);
