import React, { useMemo } from 'react';
import { ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Add as AddIcon, GetApp as GetAppIcon, OfflineBoltOutlined as OfflineBoltIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const SEGMENT_LOCATION = 'Import Feed - Actions Menu';

const SEGMENT_EVENTS = {
  Upload: 'upload clicked',
  Export: 'export clicked',
  Rules: 'rules clicked',
};

const MENU_ICONS = {
  Upload: <AddIcon />,
  Export: <GetAppIcon />,
  Rules: <OfflineBoltIcon />,
};

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    minWidth: 0,
    paddingRight: theme.spacing(1.5),
  },
}));

export default function ActionsMenu({ actions, anchorEl, onClose }) {
  const tracking = useSegmentTrack();
  const classes = useStyles();

  const actionsWithoutBatchBooking = useMemo(() => {
    return actions.filter((action) => action.text !== 'Batch Book');
  }, [actions]);

  return (
    <Menu
      anchorEl={anchorEl}
      open
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: '220px',
          width: '100%',
        },
      }}
    >
      {actionsWithoutBatchBooking.map((action) => (
        <MenuItem
          dense
          key={action.text}
          onClick={() => {
            tracking(SEGMENT_EVENTS[action.text], { location: SEGMENT_LOCATION });
            action.action?.();
            onClose();
            if (action.link) {
              history.push(action.link);
            }
          }}
        >
          <ListItemIcon className={classes.iconContainer}>{MENU_ICONS[action.text]}</ListItemIcon>
          <ListItemText>{action.text}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
}

ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
};
