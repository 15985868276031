import React from 'react';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  addAnotherLoanAccountCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  addAnotherLoanAccountCardIcon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.success.main,
    fontSize: '64px',
  },
  addAnotherLoanAccountCardButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  addAnotherLoanAccountCardButton: {
    maxWidth: '400px',
    width: '100%',
  },
}));

export default function AddAnotherLoanAccount({ loanAccountCount, setAddLoanAccountDialog, setState }) {
  const tracking = useSegmentTrack();
  const classes = useStyles();

  return (
    <DialogContent className={classes.addAnotherLoanAccountCard}>
      <CheckCircleIcon className={classes.addAnotherLoanAccountCardIcon} color="secondary" />

      <Typography align="center" variant="h6">
        {`You've added ${pluralize('loan', loanAccountCount, true)} to your portfolio.`}
      </Typography>
      <Typography align="center" variant="body1">
        You can now automatically categorize your loan payments and accurately track your equity value.
      </Typography>

      <Box className={classes.addAnotherLoanAccountCardButtonsContainer}>
        <Button
          hasFullWidth
          variant="contained"
          onClick={() => {
            tracking('finished clicked', { location: 'Onboarding - Loans: Success' });
            setState((prevState) => ({ ...prevState, key: 'setupComplete' }));
            history.replace('/onboarding-complete');
          }}
          className={classes.addAnotherLoanAccountCardButton}
        >
          Finished
        </Button>
        <Button
          className={classes.addAnotherLoanAccountCardButton}
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => {
            tracking('add_loan_account clicked', { location: 'Onboarding - Loans: Intro' });
            setAddLoanAccountDialog(true);
          }}
        >
          Add Another Loan
        </Button>
      </Box>
    </DialogContent>
  );
}

AddAnotherLoanAccount.propTypes = {
  loanAccountCount: PropTypes.number.isRequired,
  setAddLoanAccountDialog: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
