import React from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import {
  CallSplitOutlined as SplitIcon,
  MoreVert as MoreVertIcon,
  OfflineBoltOutlined as OfflineBoltIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import Chip from '~/components/Chip';
import IconButton from '~/components/IconButton';
import QuickBookRow from '~/components/QuickBookRow';

const useItemStyles = makeStyles((theme) => ({
  container: ({ isSelectedForBooking }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: isSelectedForBooking ? 'none' : `1px solid ${theme.palette.divider}`,
  }),
  itemContainer: ({ isSelectedForBooking }) => ({
    display: 'flex',
    backgroundColor: isSelectedForBooking ? theme.palette.grey[200] : 'transparent',
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(2),
    paddingBottom: isSelectedForBooking ? 0 : theme.spacing(2),
  }),
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  truncatedText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  date: {
    marginTop: theme.spacing(0.75),
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '50%',
  },
  rightTopContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  amount: ({ amount, isBooked, isIgnored, isSelectedForBooking, isSplitParent }) => ({
    color: amount.startsWith('(') ? theme.palette.error.main : theme.palette.text.primary,
    marginRight: isBooked || isIgnored || isSelectedForBooking || isSplitParent ? theme.spacing(1.5) : 0,
  }),
  button: {
    marginRight: theme.spacing(1.5),
  },
  bookedChip: {
    'marginRight': theme.spacing(1.5),
    'maxWidth': '100%',

    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export default function TransactionItem({
  isSelectedForBooking,
  transaction,
  onBookTransactionClicked,
  onTransactionBooked,
  onTransactionMenuClicked,
  onViewTransactionClicked,
  onUnignoreTransactionClicked,
  onUnsplitTransactionClicked,
}) {
  const canShowBookButton =
    !transaction.isBooked && !transaction.isIgnored && !transaction.isSplitParent && !isSelectedForBooking;

  const classes = useItemStyles({
    amount: transaction.amount,
    isBooked: transaction.isBooked,
    isIgnored: transaction.isIgnored,
    isSelectedForBooking: isSelectedForBooking && !transaction.isBooked,
    isSplitParent: transaction.isSplitParent,
  });

  function handleTransactionItemClicked() {
    if (isSelectedForBooking || transaction.isIgnored) return;

    if (canShowBookButton) {
      onBookTransactionClicked(transaction.id);
    } else {
      onViewTransactionClicked(transaction.rawTransaction);
    }
  }

  function handleMoreTransactionOptionsClicked(event) {
    event.stopPropagation();
    onTransactionMenuClicked(event, transaction.rawTransaction);
  }

  function handleUnignoreClicked(event) {
    event.stopPropagation();
    onUnignoreTransactionClicked(transaction.id);
  }

  function handleUnsplitClicked(event) {
    event.stopPropagation();
    onUnsplitTransactionClicked(transaction.id);
  }

  function handleBookClicked(event) {
    event.stopPropagation();
    onBookTransactionClicked(transaction.id);
  }

  return (
    <>
      <div
        className={classes.container}
        role="button"
        tabIndex={0}
        onClick={handleTransactionItemClicked}
        onKeyDown={handleTransactionItemClicked}
      >
        <div className={classes.itemContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.descriptionContainer}>
              {transaction.isAutoBooked && (
                <Tooltip title="Booked by a rule" placement="top" arrow>
                  <OfflineBoltIcon className={classes.icon} color="primary" />
                </Tooltip>
              )}
              {transaction.isSplitParent && (
                <Tooltip title="Splitted transaction" placement="top" arrow>
                  <SplitIcon className={classes.icon} color="primary" />
                </Tooltip>
              )}
              <Typography className={classes.truncatedText} variant="body2">
                {transaction.description}
              </Typography>
            </div>

            {Boolean(transaction.accountName) && (
              <Typography className={classes.truncatedText} color="textPrimary" variant="caption">
                {transaction.accountName}
              </Typography>
            )}

            {!transaction.isSplitChild && (
              <Typography className={classes.date} variant="caption">
                {transaction.date}
              </Typography>
            )}
          </div>

          <div className={classes.rightContainer}>
            <div className={classes.rightTopContainer}>
              <Typography className={classes.amount} variant="body2">
                {transaction.amount}
              </Typography>

              {!transaction.isBooked &&
                !transaction.isIgnored &&
                !transaction.isSplitParent &&
                !isSelectedForBooking && (
                  <IconButton
                    aria-label="More transaction options"
                    color="primary"
                    onClick={handleMoreTransactionOptionsClicked}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
            </div>

            {transaction.isIgnored && (
              <Button
                className={classes.button}
                color="primary"
                onClick={handleUnignoreClicked}
                size="small"
                variant="outlined"
              >
                Unignore
              </Button>
            )}

            {transaction.isSplitParent && (
              <Button
                className={classes.button}
                color="primary"
                onClick={handleUnsplitClicked}
                size="small"
                variant="outlined"
              >
                Unsplit
              </Button>
            )}

            {transaction.isBooked && !transaction.isSplitParent && (
              <Chip className={classes.bookedChip} label={transaction.bookedDescription} size="small" />
            )}

            {canShowBookButton && (
              <Button
                className={classes.button}
                color="primary"
                size="small"
                variant="contained"
                onClick={handleBookClicked}
              >
                Book
              </Button>
            )}
          </div>
        </div>
      </div>

      {isSelectedForBooking && !transaction.isBooked && (
        <QuickBookRow
          transaction={transaction.rawTransaction}
          onAddTransaction={onTransactionBooked}
          onCancelTransactionBookingClicked={() => onBookTransactionClicked(null)}
          removeQuickSelectLine={() => onBookTransactionClicked(null)}
        />
      )}
    </>
  );
}

TransactionItem.propTypes = {
  isSelectedForBooking: PropTypes.bool.isRequired,
  transaction: PropTypes.object.isRequired,
  onBookTransactionClicked: PropTypes.func.isRequired,
  onTransactionBooked: PropTypes.func.isRequired,
  onTransactionMenuClicked: PropTypes.func.isRequired,
  onViewTransactionClicked: PropTypes.func.isRequired,
  onUnignoreTransactionClicked: PropTypes.func.isRequired,
  onUnsplitTransactionClicked: PropTypes.func.isRequired,
};
