import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import AddFixedAssetDialog from '~/components/AddFixedAssetDialog';
import AddVendorDialog from '~/components/AddVendorDialog';
import { nameAndAddressLabel, nameLabel } from '~/components/Autocomplete/Library';
import Button from '~/components/Button';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import TransactionScope from '~/components/TransactionScope';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import {
  getAccountOptions,
  getFixedAssetOptions,
  getVendorOptions,
} from '~/helpers/utils/functionalAutocompleteLibrary';

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function BatchBookFixedAssetPurchasesDialog({
  closeDialog,
  fullTransactions,
  onBatchBookFixedAssetPurchases,
}) {
  const classes = useStyles();
  const { organizationId, vendorTracking } = useContext(PersonContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [journal, setJournal] = useState({
    ...getManualBasicJournal(),
    journalScopeSelect: { name: 'Property/Unit', id: 'Property/Unit' },
    type: 'Fixed Asset Purchase',
    organizationId,
  });
  const [error, setError] = useState(null);
  const [isAddFixedAssetDialogOpen, setIsAddFixedAssetDialogOpen] = useState(false);
  const [isAddVendorDialogOpen, setIsAddVendorDialogOpen] = useState(false);
  const [fixedAssetAccountOptions, setFixedAssetAccountOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setFixedAssetAccountOptions(await getFixedAssetOptions(organizationId, true));
      setAccountOptions(await getAccountOptions(organizationId, { type2: 'Fixed Asset' }));
      if (vendorTracking) {
        setVendorOptions(await getVendorOptions(organizationId));
      }
      setIsLoading(false);
    };
    getData();
  }, [organizationId, vendorTracking]);

  const batchBookFixedAssetPurchases = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setJournalScope(journal);

    const batchJournalsArray = [];

    fullTransactions.forEach((transaction) => {
      const journalTemplate = getManualBasicJournal();

      journalTemplate.organizationId = organizationId;

      journalTemplate.description = transaction.description;
      journalTemplate.date = transaction.date;
      journalTemplate.amount = transaction.amount;

      journalTemplate.journalScopeSelect = journal.journalScopeSelect;
      journalTemplate.propertyIdSelect = journal.propertyIdSelect;
      journalTemplate.propertyId = journal.propertyId;
      journalTemplate.unitId = journal.unitId;
      journalTemplate.unitIdSelect = journal.unitIdSelect;
      journalTemplate.entityIdSelect = journal.entityIdSelect;
      journalTemplate.entityId = journal.entityId;

      journalTemplate.vendorIdSelect = journal.vendorIdSelect;
      if (journal.vendorIdSelect) {
        journalTemplate.vendorId = journal.vendorIdSelect.id;
      }

      journalTemplate.fixedAssetIdSelect = journal.fixedAssetIdSelect;
      if (journal.fixedAssetIdSelect) {
        journalTemplate.fixedAssetId = journal.fixedAssetIdSelect.id;
      }

      journalTemplate.debitLines[0].debit = transaction.amount;
      journalTemplate.creditLines[0].credit = transaction.amount;

      journalTemplate.type = journal.type;

      journalTemplate.debitLines[0].accountIdSelect = journal.debitLines[0].accountIdSelect;
      journalTemplate.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

      journalTemplate.creditLines[0].yodleeTransactionId = transaction.id;
      journalTemplate.creditLines[0].accountIdSelect = transaction.account;
      journalTemplate.creditLines[0].accountId = transaction.account.id;

      journalTemplate.journalLines = journalTemplate.debitLines.concat(journalTemplate.creditLines);

      batchJournalsArray.push(journalTemplate);
    });

    const createPromises = batchJournalsArray.map((batchJournalsArrayItem) =>
      create('journals', batchJournalsArrayItem),
    );

    setIsSubmitting(true);

    try {
      await Promise.allSettled(createPromises);
      onBatchBookFixedAssetPurchases();
      setIsSubmitting(false);
      closeDialog();
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return null;
  }

  if (isAddFixedAssetDialogOpen) {
    return (
      <AddFixedAssetDialog
        isOpen
        closeDialog={() => setIsAddFixedAssetDialogOpen(false)}
        onAddFixedAsset={(fixedAsset) => {
          setFixedAssetAccountOptions([...fixedAssetAccountOptions, fixedAsset]);
          setJournal({ ...journal, fixedAssetIdSelect: fixedAsset });
        }}
      />
    );
  }

  if (isAddVendorDialogOpen) {
    return (
      <AddVendorDialog
        isOpen
        closeDialog={() => setIsAddVendorDialogOpen(false)}
        onAddVendor={(vendor) => {
          setVendorOptions([...vendorOptions, vendor]);
          setJournal({ ...journal, vendorIdSelect: vendor });
        }}
      />
    );
  }

  return (
    <Dialog open onClose={closeDialog} fullWidth disableBackdropClick disableEscapeKeyDown scroll="body">
      <DialogTitle id="form-dialog-title">Book Fixed Asset Purchases</DialogTitle>
      <form onSubmit={batchBookFixedAssetPurchases}>
        <DialogContent>
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValue) => setJournal({ ...journal, ...newScopeValue })}
          />
          <div className={classes.autocompleteContainer}>
            <AutocompleteField
              options={fixedAssetAccountOptions}
              getOptionLabel={nameAndAddressLabel}
              value={journal.fixedAssetIdSelect}
              onChange={(value) => setJournal({ ...journal, fixedAssetIdSelect: value })}
              label="Fixed Asset"
              placeholder="Type to Search"
              textFieldVariant="standard"
              disableShrink
              required
            />
            <AutocompleteField
              options={accountOptions}
              getOptionLabel={nameLabel}
              value={journal.accountIdSelect}
              onChange={(value) =>
                setJournal({ ...journal, debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }] })
              }
              label="Account"
              placeholder="Type to Search"
              textFieldVariant="standard"
              disableShrink
              required
            />
            {vendorTracking && (
              <AutocompleteField
                options={vendorOptions}
                getOptionLabel={nameLabel}
                value={journal.vendorIdSelect}
                onChange={(value) => setJournal({ ...journal, vendorIdSelect: value })}
                label="Vendor (optional)"
                placeholder="Type to Search"
                textFieldVariant="standard"
                disableShrink
              />
            )}
          </div>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined" size="medium">
            Cancel
          </Button>
          <Button color="primary" variant="contained" size="medium" type="submit" disabled={isSubmitting}>
            {`Book ${pluralize('Fixed Asset Purchase', fullTransactions.length, true)}`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

BatchBookFixedAssetPurchasesDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fullTransactions: PropTypes.array.isRequired,
  onBatchBookFixedAssetPurchases: PropTypes.func.isRequired,
};
