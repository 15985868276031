import React from 'react';
import { ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { CallSplit, DeleteOutline, EditOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { useSegmentTrack } from '~/functions/SegmentFunctions';
import ContentPasteOff from '~/icons/ContentPasteOff';

const useStyles = makeStyles((theme) => ({
  menuItemDelete: {
    color: theme.palette.error.main,
  },
  editMenuIconContainer: {
    minWidth: 0,
    paddingRight: theme.spacing(1.5),
  },
  deleteMenuIconContainer: {
    minWidth: 0,
    paddingRight: theme.spacing(1.5),
    color: theme.palette.error.main,
  },
}));

export default function EditTransactionMenu({
  anchorEl,
  isSplitOptionVisible,
  onClose,
  onClickDelete,
  onClickEdit,
  onClickIgnore,
  onClickSplit,
  isSplitChild,
}) {
  const classes = useStyles();
  const tracking = useSegmentTrack();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      open
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: '220px',
          width: '100%',
        },
      }}
    >
      {isSplitOptionVisible && (
        <MenuItem
          dense
          onClick={() => {
            tracking('split clicked', { location: 'Transaction Row - Import Feed - More Menu' });
            onClickSplit();
          }}
        >
          <ListItemIcon className={classes.editMenuIconContainer}>
            <CallSplit />
          </ListItemIcon>
          <ListItemText>Split</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        dense
        onClick={() => {
          tracking('ignore clicked', { location: 'Transaction Row - Import Feed - More Menu' });
          onClickIgnore();
        }}
      >
        <ListItemIcon className={classes.editMenuIconContainer}>
          <ContentPasteOff />
        </ListItemIcon>
        <ListItemText>Ignore</ListItemText>
      </MenuItem>
      <MenuItem
        divider={!isSplitChild}
        dense
        onClick={() => {
          tracking('edit clicked', { location: 'Transaction Row - Import Feed - More Menu' });
          onClickEdit();
        }}
      >
        <ListItemIcon className={classes.editMenuIconContainer}>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      {!isSplitChild && (
        <MenuItem
          dense
          className={classes.menuItemDelete}
          onClick={() => {
            tracking('delete clicked', { location: 'Transaction Row - Import Feed - More Menu' });
            onClickDelete();
          }}
        >
          <ListItemIcon className={classes.deleteMenuIconContainer}>
            <DeleteOutline />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}

EditTransactionMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  isSplitOptionVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickIgnore: PropTypes.func.isRequired,
  onClickSplit: PropTypes.func.isRequired,
  isSplitChild: PropTypes.bool,
};
