import React from 'react';
import NumberFormat from 'react-number-format';
import { ButtonBase } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../../contexts/PersonContext';
import client from '../../feathers';
import { find, get } from '../../feathersWrapper';
import {
  asyncHandleChange,
  handleSearchSelectChange,
  handleTextFieldChange,
  handleTransactionScopeChange,
  handleUploaderChange,
} from '../../functions/InputHandlers';
import { setJournalScope } from '../../functions/JournalFunctions';
import { searchAccounts } from '../SearchSelect/SearchFunctions';
import SearchSelect from '../SearchSelect/SearchSelect';
import {
  getCardPaymentBankJournal,
  getCardPaymentCreditJournal,
  getContributionJournal,
  getDistributionJournal,
  getEscrowExpenseJournal,
  getRefundIssuedJournal,
  getSecurityDepositReceivedJournal,
  getSecurityDepositRefundedJournal,
  getTransferDepositedJournal,
  getTransferWithdrawnJournal,
} from '../SearchSelect/TransactionTypeOptions';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  hideWidget: {
    display: 'none',
  },
  red: {
    color: theme.palette.error.main,
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddYodleeTransactionDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    this.setState(await this.getInitialState());
  }

  getInitialState = async () => {
    const { yodleeTransaction, transactionTypeSelect } = this.props;
    const { organizationId, organizationName } = this.context;

    if (!yodleeTransaction || !transactionTypeSelect) {
      return { loading: true };
    }

    const account = await get(this, 'accounts', yodleeTransaction.accountId);
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      displayNotesSection: false,
    };

    if (transactionTypeSelect.value === 'Escrow Expense') {
      initialState.journal = getEscrowExpenseJournal(yodleeTransaction);
      initialState.journal.journalLines[1].accountId = account.mortgageEscrowAccountId;
    }
    if (transactionTypeSelect.value === 'cardPaymentBank') {
      initialState.journal = getCardPaymentBankJournal(yodleeTransaction);
    }
    if (transactionTypeSelect.value === 'cardPaymentCredit') {
      initialState.journal = getCardPaymentCreditJournal(yodleeTransaction);
    }
    if (transactionTypeSelect.value === 'Refund Issued') {
      initialState.journal = getRefundIssuedJournal(yodleeTransaction);
    }
    if (transactionTypeSelect.value === 'Distribution') {
      const journal = getDistributionJournal(yodleeTransaction);
      initialState.journal = journal;
    }
    if (transactionTypeSelect.value === 'Contribution') {
      const journal = getContributionJournal(yodleeTransaction);
      initialState.journal = journal;
    }
    if (transactionTypeSelect.value === 'securityDepositReceived') {
      const journal = getSecurityDepositReceivedJournal(yodleeTransaction);
      const securityDepositAccountData = await find(this, 'accounts', {
        query: { organizationId, default: 'securityDeposits' },
      });
      journal.journalLines[1].accountId = securityDepositAccountData.data[0].id;
      initialState.journal = journal;
    }
    if (transactionTypeSelect.value === 'securityDepositRefunded') {
      const journal = getSecurityDepositRefundedJournal(yodleeTransaction);
      const securityDepositAccountData = await find(this, 'accounts', {
        query: { organizationId, default: 'securityDeposits' },
      });
      journal.journalLines[0].accountId = securityDepositAccountData.data[0].id;
      initialState.journal = journal;
    }
    if (transactionTypeSelect.value === 'transferDeposited') {
      const journal = getTransferDepositedJournal(yodleeTransaction);
      initialState.journal = journal;
    }
    if (transactionTypeSelect.value === 'transferWithdrawn') {
      const journal = getTransferWithdrawnJournal(yodleeTransaction);
      initialState.journal = journal;
    }

    if (initialState.journal.propertyId) {
      initialState.journal.propertyIdSelect = {
        label: account.property.address1,
        value: account.propertyId,
      };
      const property = await get(this, 'properties', account.propertyId);
      initialState.selectedProperty = property;
    }

    if (
      ['Escrow Expense', 'Refund Issued', 'securityDepositReceived', 'securityDepositRefunded'].includes(
        transactionTypeSelect.value,
      )
    ) {
      initialState.journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    }

    if (
      [
        'cardPaymentBank',
        'cardPaymentCredit',
        'Distribution',
        'Contribution',
        'transferDeposited',
        'transferWithdrawn',
      ].includes(transactionTypeSelect.value)
    ) {
      initialState.journal.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };
    }

    initialState.account = account;

    return initialState;
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addYodleeTransaction = async (event) => {
    event.preventDefault();
    const { submitting, journal } = this.state;

    const { onAddTransaction } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    setJournalScope(journal);

    const journalsService = client.service('journals');
    journalsService
      .create(journal)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, yodleeTransaction } = this.props;
    const { loading, error, journal, displayNotesSection } = this.state;

    if (loading) {
      return null;
    }

    return (
      <form onSubmit={this.addYodleeTransaction}>
        <DialogTitle id="alert-dialog-title">Book Transaction</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" fullWidth>
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Transaction
            </FormLabel>
            <Typography>
              {moment(yodleeTransaction.date).format('M/D/YYYY')}
              <Box px={2} component="span">
                |
              </Box>
              <NumberFormat
                displayType="text"
                value={yodleeTransaction.amount}
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                renderText={(value) => this.formatNegative(value, yodleeTransaction)}
              />
              <Box px={2} component="span">
                |
              </Box>
              {yodleeTransaction.account.name}
            </Typography>
          </FormControl>
          <TextField
            label="Description"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          {journal.type === 'Refund Issued' && (
            <SearchSelect
              searchFunction={searchAccounts(this, { type: 'Revenue' })}
              changeFunction={handleSearchSelectChange(this)}
              label="Revenue Account"
              name="nested_journal_journalLines_0_accountId"
              isMulti={false}
              required
              value={journal.journalLines[0].accountIdSelect}
              margin="dense"
              target={document.getElementById('addYodleeTransactionDialog')}
            />
          )}
          {journal.type === 'Escrow Expense' && (
            <SearchSelect
              searchFunction={searchAccounts(this, { type: 'Expense' })}
              changeFunction={handleSearchSelectChange(this)}
              label="Expense Account"
              name="nested_journal_journalLines_0_accountId"
              isMulti={false}
              required
              value={journal.journalLines[0].accountIdSelect}
              margin="dense"
              target={document.getElementById('addYodleeTransactionDialog')}
            />
          )}
          {journal.type === 'Credit Card Payment' && (
            <>
              {!journal.journalLines[1].yodleeTransactionId && (
                <SearchSelect
                  searchFunction={searchAccounts(this, { type2: ['Bank', 'HELOC', 'Owner Funds'] })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Paid From Account"
                  name="nested_journal_journalLines_1_accountId"
                  isMulti={false}
                  required
                  value={journal.journalLines[1].accountIdSelect}
                  margin="dense"
                  target={document.getElementById('addYodleeTransactionDialog')}
                />
              )}
              {!journal.journalLines[0].yodleeTransactionId && (
                <SearchSelect
                  searchFunction={searchAccounts(this, { type2: 'Credit Card' })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Credit Card Account"
                  name="nested_journal_journalLines_0_accountId"
                  isMulti={false}
                  required
                  value={journal.journalLines[0].accountIdSelect}
                  margin="dense"
                  target={document.getElementById('addYodleeTransactionDialog')}
                />
              )}
            </>
          )}
          {journal.type === 'Transfer' && (
            <>
              {!journal.journalLines[1].yodleeTransactionId && (
                <SearchSelect
                  searchFunction={searchAccounts(this, { type: ['Asset', 'Liability', 'Equity'] })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Transferred From Account"
                  name="nested_journal_journalLines_1_accountId"
                  isMulti={false}
                  required
                  value={journal.journalLines[1].accountIdSelect}
                  margin="dense"
                  target={document.getElementById('addYodleeTransactionDialog')}
                />
              )}
              {!journal.journalLines[0].yodleeTransactionId && (
                <SearchSelect
                  searchFunction={searchAccounts(this, { type: ['Asset', 'Liability', 'Equity'] })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Transferred To Account"
                  name="nested_journal_journalLines_0_accountId"
                  isMulti={false}
                  required
                  value={journal.journalLines[0].accountIdSelect}
                  margin="dense"
                  target={document.getElementById('addYodleeTransactionDialog')}
                />
              )}
            </>
          )}
          {journal.type === 'Contribution' && (
            <SearchSelect
              searchFunction={searchAccounts(this, {
                type: 'Equity',
                default: { $or: [null, { $ne: 'autoBalance' }] },
              })}
              changeFunction={handleSearchSelectChange(this)}
              label="Equity Account"
              name="nested_journal_journalLines_1_accountId"
              isMulti={false}
              required
              value={journal.journalLines[1].accountIdSelect}
              margin="dense"
            />
          )}
          {journal.type === 'Distribution' && (
            <SearchSelect
              searchFunction={searchAccounts(this, {
                type: 'Equity',
                default: { $or: [null, { $ne: 'autoBalance' }] },
              })}
              changeFunction={handleSearchSelectChange(this)}
              label="Equity Account"
              name="nested_journal_journalLines_0_accountId"
              isMulti={false}
              required
              value={journal.journalLines[0].accountIdSelect}
              margin="dense"
            />
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Transaction
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddYodleeTransactionDialogContent.contextType = PersonContext;

AddYodleeTransactionDialogContent.defaultProps = {
  yodleeTransaction: null,
  transactionTypeSelect: null,
};

AddYodleeTransactionDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
  transactionTypeSelect: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(styles)(AddYodleeTransactionDialogContent);
