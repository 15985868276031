import React, { useState } from 'react';
import { Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { patch } from '~/feathersFunctionalWrapper';
import { checkIsNegativeAmount, parseTransactionAmount } from '~/functions/AmountFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(8),
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  bodyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  backButton: {
    order: 2,

    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  deleteButton: {
    order: 1,

    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
}));

const SEGMENT_LOCATION = 'Delete Transaction Modal - Import Feed';

export default function DeleteImportedTransactionDialog({ closeDialog, onDelete, transaction }) {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tracking = useSegmentTrack();
  const classes = useStyles();

  const isNegativeTransactionAmount = checkIsNegativeAmount(
    transaction.account.type,
    transaction.yodleeType,
    transaction.amount === 0,
  );
  const parsedTransactionAmount = isNegativeTransactionAmount
    ? `(${parseTransactionAmount(transaction.amount)})`
    : parseTransactionAmount(transaction.amount);

  const deleteTransaction = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const transactionSubmit = { ...transaction };
    transactionSubmit.deleted = true;
    try {
      await patch('yodlee-transactions', transactionSubmit.id, transactionSubmit);
      onDelete();
      closeDialog();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
    >
      <form onSubmit={deleteTransaction}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography variant="subtitle1">Delete Transaction</Typography>
            <IconButton aria-label="Close" color="primary" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className={classes.body}>
            <Typography variant="caption">{`${moment(transaction.date).format('M/D/YYYY')} • ${transaction.account.name}`}</Typography>
            <div className={classes.bodyHeader}>
              <Typography variant="h6">{transaction.description}</Typography>
              <Typography color={isNegativeTransactionAmount ? 'error' : 'textPrimary'} variant="h6">
                {parsedTransactionAmount}
              </Typography>
            </div>

            {error?.message && (
              <Alert className={classes.error} severity="error">
                {error.message}
              </Alert>
            )}
          </div>

          <div className={classes.footer}>
            <Button
              className={classes.backButton}
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => {
                tracking('go_back clicked', { location: SEGMENT_LOCATION });
                closeDialog();
              }}
            >
              Go Back
            </Button>
            <Button
              className={classes.deleteButton}
              color="error"
              size="medium"
              type="submit"
              onClick={() => {
                tracking('delete_transaction clicked', { location: SEGMENT_LOCATION });
              }}
            >
              Delete Transaction
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

DeleteImportedTransactionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
