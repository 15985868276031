import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Chip from '~/components/Chip';

const useStyles = makeStyles((theme) => ({
  transactionTypeOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  transactionTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
  },
  transactionTypeLabelContainer: {
    marginLeft: theme.spacing(0.75),
    color: theme.palette.text.secondary,
  },
  header: {
    lineHeight: '13.72px',
  },
}));

export default function TransactionTypeSelect({
  className,
  selectedTransactionType,
  transactionTypes,
  onTransactionTypeChange,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Box className={cx(classes.transactionTypeOuterContainer, className)} {...rest}>
      <Box className={classes.transactionTypeLabelContainer}>
        <Typography variant="caption" className={classes.header}>
          Transaction Type *
        </Typography>
      </Box>
      <Box className={classes.transactionTypeContainer}>
        {transactionTypes.map((transactionType) => (
          <Chip
            color="primary"
            key={transactionType.label}
            label={transactionType.label}
            size="small"
            variant={transactionType.label === selectedTransactionType?.label ? 'filled' : 'outlined'}
            onClick={() => {
              onTransactionTypeChange(transactionType);
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

TransactionTypeSelect.propTypes = {
  className: PropTypes.string,
  selectedTransactionType: PropTypes.object,
  transactionTypes: PropTypes.array.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
};
