import React, { useCallback, useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, TextField, Typography } from '@material-ui/core';
import { Delete, HelpOutline } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import {
  getCreditJournalLine,
  getDebitJournalLine,
  getManualBasicJournal,
} from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { sumProperty } from '~/functions/SumFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowLoading from '../QuickBookRowLoading';

const AMOUNT_MISMATCH_ERROR =
  'Please double-check that the net amount for the transaction equals the net amount shown below expenses.';

export default function QuickBookRowNetIncomeTemplate({ transaction, onTransactionAction, onGetHelp }) {
  const tracking = useSegmentTrack();
  const isMobile = checkIsSmallScreen();
  const { organizationId } = useContext(PersonContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [revenueAccountOptions, setRevenueAccountOptions] = useState([]);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [journal, setJournal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const getData = async () => {
      const revenueAccounts = await getAccountOptions(organizationId, {
        $or: [{ type: 'Revenue' }, { type2: 'Sales and Use Tax' }],
      });
      const expenseAccounts = await getAccountOptions(organizationId, { type: 'Expense' });

      const journalObject = getManualBasicJournal();
      journalObject.debitLines = journalObject.debitLines.concat([getDebitJournalLine()]);

      const { account, id, description, date, amount } = transaction;
      journalObject.debitLines[0].accountIdSelect = account;
      journalObject.debitLines[0].yodleeTransactionId = id;
      journalObject.debitLines[0].debit = amount;
      journalObject.description = description;
      journalObject.date = date;
      journalObject.amount = amount;
      journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

      setJournal(journalObject);
      setRevenueAccountOptions(revenueAccounts);
      setExpenseAccountOptions(expenseAccounts);
      setIsLoading(false);
    };

    getData();
  }, [isLoading, journal, organizationId, transaction]);

  const addLine = (type) => {
    const newJournal = { ...journal };
    if (type === 'credit') {
      newJournal.creditLines = journal.creditLines.concat([getCreditJournalLine()]);
    }
    if (type === 'debit') {
      newJournal.debitLines = journal.debitLines.concat([getDebitJournalLine()]);
    }
    setJournal(newJournal);
  };

  const removeLine = (type, index) => {
    const newJournal = { ...journal };
    if (type === 'credit') {
      newJournal.creditLines.splice(index, 1);
    } else if (type === 'debit') {
      newJournal.debitLines.splice(index, 1);
    }
    setJournal(newJournal);
  };

  const checkLinesEquality = useCallback(() => {
    const creditAmount = sumProperty(journal.creditLines, 'credit');
    const debitAmount = sumProperty(journal.debitLines, 'debit');
    return creditAmount === debitAmount;
  }, [journal]);

  useEffect(() => {
    if (error?.message === AMOUNT_MISMATCH_ERROR) {
      if (checkLinesEquality()) {
        setError(null);
      }
    }
  }, [checkLinesEquality, error]);

  const addNetIncome = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (!checkLinesEquality()) {
      setError({
        message: AMOUNT_MISMATCH_ERROR,
      });
      return;
    }

    setIsSubmitting(true);

    const journalSubmit = { ...journal };
    journalSubmit.type = 'Net Income';
    journalSubmit.organizationId = organizationId;
    journalSubmit.journalLines = journalSubmit.creditLines.concat(journalSubmit.debitLines);
    journalSubmit.journalLines.forEach((line) => {
      line.accountId = line.accountIdSelect.id;
    });
    setJournalScope(journalSubmit);

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction();
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
      return;
    }
  };

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <form onSubmit={addNetIncome}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        <Box className={classes.netIncomeSectionContainer}>
          <Box className={classes.netIncomeContainer}>
            <Typography variant="subtitle2">Revenues</Typography>
            <Box className={classes.netIncomeBox}>
              <Box className={classes.netIncomeRowsContainer}>
                {journal.creditLines.map((journalLine, index) => (
                  <Box className={classes.netIncomeRow} key={journalLine.key}>
                    <Autocomplete
                      className={classes.netIncomeAutocompleteField}
                      options={revenueAccountOptions}
                      value={journal.creditLines[index].accountIdSelect}
                      onChange={(_event, newValue) => {
                        const newJournal = { ...journal };
                        newJournal.creditLines[index].accountIdSelect = newValue;
                        setJournal(newJournal);
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      variant="outlined"
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Revenue Account"
                          fullWidth
                          variant="outlined"
                          required
                          placeholder="Select one"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <Box className={classes.netIncomeRowSecondary}>
                      <CurrencyField
                        label="Amount"
                        isRequired={true}
                        value={journalLine.credit}
                        variant="outlined"
                        size="small"
                        placeholder="$0.00"
                        backgroundColor="white"
                        onChange={(event) => {
                          const newJournal = { ...journal };
                          newJournal.creditLines[index].credit = event.target.value;
                          setJournal(newJournal);
                        }}
                        InputLabelProps={{ shrink: true }}
                        className={classes.netIncomeAmountField}
                      />
                      {journal.creditLines.length >= 2 ? (
                        <Delete
                          className={classes.netIncomeDeleteIcon}
                          onClick={() => {
                            removeLine('credit', index);
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={classes.netIncomeBottomRow}>
              <Button
                variant="text"
                onClick={() => {
                  addLine('credit');
                }}
              >
                Add Line
              </Button>
              <Box className={classes.netIncomeAmountLine}>
                <Typography variant="body2">
                  Revenues:{' '}
                  <NumberFormat
                    displayType="text"
                    value={sumProperty(journal.creditLines, 'credit')}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.netIncomeContainer}>
            <Typography variant="subtitle2">Expenses</Typography>
            <Box className={classes.netIncomeBox}>
              <Box className={classes.netIncomeRowsContainer}>
                {journal.debitLines.map((journalLine, index) => {
                  if (index !== 0) {
                    return (
                      <Box className={classes.netIncomeRow} key={journalLine.key}>
                        <Autocomplete
                          className={classes.netIncomeAutocompleteField}
                          options={expenseAccountOptions}
                          value={journal.debitLines[index].accountIdSelect}
                          onChange={(_event, newValue) => {
                            const newJournal = { ...journal };
                            newJournal.debitLines[index].accountIdSelect = newValue;
                            setJournal(newJournal);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          variant="outlined"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Expense Account"
                              fullWidth
                              variant="outlined"
                              required
                              placeholder="Select one"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        <Box className={classes.netIncomeRowSecondary}>
                          <CurrencyField
                            label="Amount"
                            isRequired={true}
                            value={journalLine.debit}
                            variant="outlined"
                            size="small"
                            placeholder="$0.00"
                            backgroundColor="white"
                            onChange={(event) => {
                              const newJournal = { ...journal };
                              newJournal.debitLines[index].debit = event.target.value;
                              setJournal(newJournal);
                            }}
                            InputLabelProps={{ shrink: true }}
                            className={classes.netIncomeAmountField}
                          />
                          {journal.debitLines.length >= 3 ? (
                            <Delete
                              className={classes.netIncomeDeleteIcon}
                              onClick={() => {
                                removeLine('debit', index);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </Box>
            <Box className={classes.netIncomeBottomRow}>
              <Button
                variant="text"
                onClick={() => {
                  addLine('debit');
                }}
              >
                Add Line
              </Button>
              <Box className={classes.netIncomeAmountLine}>
                <Typography variant="body2">
                  Expenses:{' '}
                  <NumberFormat
                    displayType="text"
                    value={sumProperty(journal.debitLines, 'debit') - journal.debitLines[0].debit}
                    thousandSeparator
                    prefix={sumProperty(journal.debitLines, 'debit') - journal.debitLines[0].debit < 0 ? '-$' : '$'}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              </Box>
            </Box>
            <Box className={classes.netIncomeNetAmountLine}>
              <Typography variant="subtitle2">
                Net Amount:{' '}
                <NumberFormat
                  displayType="text"
                  value={
                    sumProperty(journal.creditLines, 'credit') -
                    sumProperty(journal.debitLines, 'debit') +
                    journal.debitLines[0].debit
                  }
                  thousandSeparator
                  prefix={
                    sumProperty(journal.creditLines, 'credit') -
                      sumProperty(journal.debitLines, 'debit') +
                      journal.debitLines[0].debit <
                    0
                      ? '-$'
                      : '$'
                  }
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.helpButton}
            onClick={() => {
              tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
              onGetHelp();
            }}
          >
            <HelpOutline className={classes.helpIcon} />
            Get Help
          </Button>
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              fullWidth={false}
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowNetIncomeTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
  onGetHelp: PropTypes.func.isRequired,
};
