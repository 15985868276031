import React from 'react';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  addLoansCard: {
    padding: theme.spacing(3),
  },
  addLoansButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  addLoansButton: {
    maxWidth: '400px',
    width: '100%',
  },
}));

export default function AddLoanAccount({ setAddLoanAccountDialog, setState }) {
  const tracking = useSegmentTrack();
  const classes = useStyles();

  return (
    <DialogContent className={classes.addLoansCard}>
      <Typography variant="h6" gutterBottom>
        Loan payments aren't expenses. Categorize them properly with REI Hub.
      </Typography>
      <Typography variant="body1">
        We automatically split your loan payments into principal, interest, and escrow (if applicable).
      </Typography>
      <Box className={classes.addLoansButtonsContainer}>
        <Button
          hasFullWidth
          variant="contained"
          onClick={() => {
            tracking('add_loan clicked', { location: 'Onboarding - Loans: Intro' });
            setAddLoanAccountDialog(true);
          }}
          className={classes.addLoansButton}
        >
          Add Loan
        </Button>
        <Button
          className={classes.addLoansButton}
          color="primary"
          variant="text"
          size="large"
          onClick={() => {
            tracking('i_dont_have_loans clicked', { location: 'Onboarding - Loans: Intro' });
            setState((prevState) => ({ ...prevState, key: 'setupComplete' }));
            history.replace('/onboarding-complete');
          }}
        >
          I Don't Have Loans
        </Button>
      </Box>
    </DialogContent>
  );
}

AddLoanAccount.propTypes = {
  setAddLoanAccountDialog: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
