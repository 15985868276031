import { useQuery } from 'react-query';
import moment from 'moment';

import { create, find } from '~/feathersFunctionalWrapper';

export default function useEntitiesMatchQuery({
  organizationId,
  shouldHideSuggestRule,
  transaction,
  setIsAirbnbMessageVisible,
  setIsAlreadyBooked,
  setIsVRBOMessageVisible,
}) {
  const {
    account: { type2: accountType2 },
    accountId,
    amount: transactionAmount,
    date,
    description,
    id: transactionId,
    journalLineId,
    yodleeType,
  } = transaction;
  return useQuery({
    queryKey: ['entitiesMatch', date, description, journalLineId, transactionAmount, transactionId],
    queryFn: async () => {
      if (journalLineId) {
        setIsAlreadyBooked(true);
        return;
      }

      const amount = {};
      if (yodleeType === 'DEBIT') {
        amount.credit = transactionAmount;
      } else {
        amount.debit = transactionAmount;
      }

      // find potential matching transactions
      const journalLinesMatchQuery = {
        organizationId,
        accountId,
        yodleeTransactionId: null,
        ...amount,
        startDate: moment(date).subtract(21, 'days').format('YYYY-MM-DD'),
        endDate: moment(date).add(5, 'days').format('YYYY-MM-DD'),
        includeFullJournals: true,
      };
      const journalLinesMatchPromise = find('journal-lines', { query: journalLinesMatchQuery });

      // find potential owner fund transactions
      const ownerFund = await find('accounts', { query: { organizationId, default: 'ownerFunds', $limit: 1 } });
      const ownerFundsMatchQuery = {
        organizationId,
        accountId: ownerFund.data[0].id,
        yodleeTransactionId: null,
        ...amount,
        startDate: moment(date).subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment(date).add(7, 'days').format('YYYY-MM-DD'),
        includeFullJournals: true,
      };
      const ownerFundsMatchPromise = find('journal-lines', { query: ownerFundsMatchQuery });

      let loanTemplateMatchPromise = Promise.resolve({ data: [] });
      if (['Bank', 'Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(accountType2)) {
        // find potential matching loan templates
        const loanTemplateMatchQuery = {
          organizationId,
          mortgagePaymentAmount: transactionAmount,
          type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        };

        if (accountType2 === 'Bank') {
          loanTemplateMatchQuery.mortgagePaymentAccountId = accountId;
        } else {
          loanTemplateMatchQuery.id = accountId;
        }

        // search if not positive bank account flow
        if (!(accountType2 === 'Bank' && yodleeType === 'CREDIT')) {
          loanTemplateMatchPromise = find('accounts', { query: loanTemplateMatchQuery });
        }
      }

      let importedTransactionRuleMatchPromise = Promise.resolve([]);
      if (['Bank', 'Credit Card'].includes(accountType2) && yodleeType === 'DEBIT') {
        // find potential matching templates
        const importedTransactionRuleMatchCriteria = {
          reportName: 'importedTransactionRuleMatch',
          organizationId,
          description,
          type: ['Expense', 'Fixed Asset Purchase', 'Transfer To'],
          transactionAmount: transactionAmount,
          analysisAccountId: accountId,
        };

        importedTransactionRuleMatchPromise = create('reports', importedTransactionRuleMatchCriteria);
      }

      const incomeTemplateMatch = [];
      if (accountType2 === 'Bank' && yodleeType === 'CREDIT') {
        // TODO: We should probably move both Airbnb and VRBO to a separate hook bc they have very relation with this hook
        // check for Airbnb
        if (description && description.toLowerCase().includes('airbnb')) {
          incomeTemplateMatch.push({
            name: 'Airbnb',
            type: 'Net Income',
            matched: false,
          });
          const airbnbAccountResult = await find('accounts', {
            query: {
              organizationId,
              default: 'airbnb',
            },
          });
          setIsAirbnbMessageVisible(airbnbAccountResult.data[0].inactive);
        }
        // check for VRBO
        if (description && description.toLowerCase().includes('vrbo')) {
          incomeTemplateMatch.push({
            name: 'VRBO',
            type: 'Net Income',
            matched: false,
          });
          const vrboAccountResult = await find('accounts', {
            query: {
              organizationId,
              default: 'vrbo',
            },
          });
          setIsVRBOMessageVisible(vrboAccountResult.data[0].inactive);
        }
        // find potential matching templates
        const importedTransactionRuleMatchCriteria = {
          reportName: 'importedTransactionRuleMatch',
          organizationId,
          description,
          type: ['Revenue', 'Transfer From', 'Fixed Asset Purchase'],
          transactionAmount: transactionAmount,
          analysisAccountId: accountId,
        };

        importedTransactionRuleMatchPromise = create('reports', importedTransactionRuleMatchCriteria);
      }

      let descriptionMatchesPromise = Promise.resolve({ data: [] });
      if (!shouldHideSuggestRule) {
        let descriptionUpdated = description;
        for (let i = descriptionUpdated.length - 1; i > 0; i--) {
          if (descriptionUpdated[i] === '\\') {
            descriptionUpdated = descriptionUpdated.slice(0, i);
          } else {
            break;
          }
        }

        const descriptionMatchQuery = {
          organizationId,
          description: { $iLike: `${descriptionUpdated}` },
          yodleeType,
          journalId: null,
          $limit: 100,
          id: { $ne: transactionId },
          split: { $or: [null, false] },
          ignored: { $or: [null, false] },
          deleted: { $or: [null, false] },
        };
        descriptionMatchesPromise = find('yodlee-transactions', { query: descriptionMatchQuery });
      }

      const [descriptionMatches, importedTransactionRuleMatch, journalLinesMatch, loanTemplateMatch, ownerFundsMatch] =
        await Promise.all([
          descriptionMatchesPromise,
          importedTransactionRuleMatchPromise,
          journalLinesMatchPromise,
          loanTemplateMatchPromise,
          ownerFundsMatchPromise,
        ]);

      return {
        descriptionMatches: descriptionMatches.data,
        importedTransactionRuleMatch,
        incomeTemplateMatch,
        journalLinesMatch: journalLinesMatch.data,
        loanTemplateMatch: loanTemplateMatch.data,
        ownerFundsMatch: ownerFundsMatch.data,
      };
    },
  });
}
