import { get } from '../feathersWrapper';

export function getJournalScopeProperties(journal) {
  const journalScopeProperties = {
    unitId: null,
    propertyId: null,
    entityId: null,
  };

  if (journal.journalScopeSelect.id === 'Portfolio') {
    journalScopeProperties.unitId = null;
    journalScopeProperties.propertyId = null;
    journalScopeProperties.entityId = null;
  } else if (journal.journalScopeSelect.id === 'Sub-Portfolio') {
    journalScopeProperties.unitId = null;
    journalScopeProperties.propertyId = null;
    journalScopeProperties.entityId = journal.entityIdSelect.id;
  } else if (journal.journalScopeSelect.id === 'Property/Unit') {
    journalScopeProperties.propertyId = journal.propertyIdSelect.id;
    journalScopeProperties.entityId = journal.propertyIdSelect.entityId;
    if (journal.propertyIdSelect.unitSelection === 'multi' && journal.unitIdSelect.id !== 'All') {
      journalScopeProperties.unitId = journal.unitIdSelect.id;
    } else {
      journalScopeProperties.unitId = null;
    }
  }

  return journalScopeProperties;
}

export const setJournalScope = (journal) => {
  if (journal.journalScopeSelect.id === 'Portfolio') {
    journal.unitId = null;
    journal.propertyId = null;
    journal.entityId = null;
  } else if (journal.journalScopeSelect.id === 'Sub-Portfolio') {
    journal.unitId = null;
    journal.propertyId = null;
    journal.entityId = journal.entityIdSelect.id;
  } else if (journal.journalScopeSelect.id === 'Property/Unit') {
    journal.propertyId = journal.propertyIdSelect.id;
    journal.entityId = journal.propertyIdSelect.entityId;
    if (
      journal.propertyIdSelect.unitSelection === 'multi' &&
      journal.unitIdSelect.id !== 'All' &&
      journal.unitIdSelect.id !== 'All Units'
    ) {
      journal.unitId = journal.unitIdSelect.id;
    } else {
      journal.unitId = null;
    }
  }
};

export const setInitialJournalScope = async (journal, component) => {
  const { organizationName } = component.context;

  if (journal.unitId) {
    const unit = await get(this, 'units', journal.unitId);
    journal.propertyIdSelect = unit.property;
    journal.unitIdSelect = unit;
    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
  } else if (journal.propertyId) {
    const property = await get(this, 'properties', journal.propertyId);
    if (property.unitSelection === 'multi') {
      journal.unitIdSelect = { name: 'All Units', id: 'All' };
    }
    journal.propertyIdSelect = property;
    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
  } else if (journal.entityId) {
    const entity = await get(this, 'entities', journal.entityId);
    journal.entityIdSelect = entity;
    journal.journalScopeSelect = { name: 'Sub-Portfolio', id: 'Sub-Portfolio' };
  } else {
    journal.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };
  }
};
