import React, { useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, Dialog, DialogActions, DialogContent, makeStyles, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import PropTypes from 'prop-types';

import ExpertSupportSubscribeDialog from '~/components/ExpertSupportSubscribeDialog';
import IconButton from '~/components/IconButton';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { checkIsNegativeAmount } from '~/functions/AmountFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

import Button from '../Button';

const SEGMENT_LOCATION = 'Get Help Modal - Book Transaction';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  dialogTitle: {
    padding: 0,
    fontFamily: theme.typography.fontFamily.primary,
  },
  formLabel: {
    position: 'relative',
  },
  red: {
    color: theme.palette.error.main,
  },
  upgradeBox: {
    backgroundColor: 'rgba(68, 28, 93, 0.08)',
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  upgradeText: {
    flex: 2,
  },
  upgradeBtn: {
    flex: 1,
    fontSize: '16px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  descLabel: {
    marginBottom: theme.spacing(1),
  },
  dialogActions: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  actionBtn: {
    lineHeight: '30px',
  },
}));

export default function GetHelpDialog({ closeDialog, transaction }) {
  const [helpMessage, setHelpMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isExpertSupportDialogOpen, setIsExpertSupportDialogOpen] = useState(false);
  const context = useContext(PersonContext);
  const { organizationId, expertSupportSubscription } = context;
  const classes = useStyles();
  const tracking = useSegmentTrack();

  const submit = async (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    setSubmitting(true);

    const message = `${helpMessage}
      (Transaction Details:
      ${moment(transaction.date).format('M/D/YYYY')}, ${transaction.amount}, ${transaction.account.name},
      ${transaction.description})`;

    await create('contact-us', {
      organizationId,
      personContext: context,
      subject: 'Transaction Help',
      message,
    });
    closeDialog();
  };

  return (
    <>
      <Dialog fullWidth open onClose={closeDialog} data-testid="quickbookrow-gethelpdialog">
        <form onSubmit={submit}>
          <div className={classes.headerWrapper}>
            <Typography variant="subtitle1" className={classes.dialogTitle} id="alert-dialog-title">
              Get Help
            </Typography>
            <IconButton
              color="primary"
              disabled={submitting}
              onClick={() => {
                tracking('close clicked', { location: SEGMENT_LOCATION });
                closeDialog();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.formControl}>
              <div>
                <Typography variant="caption" className={classes.formLabel}>
                  {moment(transaction.date).format('M/D/YYYY')}
                  <Box px={1} component="span">
                    •
                  </Box>
                  {transaction.account.name}
                </Typography>
                <Typography variant="h6" color="colorPrimary">
                  {transaction.description}
                </Typography>
              </div>
              <Typography variant="h6">
                <NumberFormat
                  displayType="text"
                  value={transaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={() => {
                    const isNegative = checkIsNegativeAmount(transaction.account.type, transaction.yodleeType);
                    return (
                      <span className={isNegative ? classes.red : ''}>
                        {isNegative
                          ? `($${Number(transaction.amount).toFixed(2)})`
                          : `$${Number(transaction.amount).toFixed(2)}`}
                      </span>
                    );
                  }}
                />
              </Typography>
            </div>
            <Typography className={classes.descLabel} variant="subtitle2">
              How can we help?
            </Typography>
            <TextField
              helperText="We typically respond within 1 business day"
              placeholder="Description of the issue..."
              fullWidth
              multiline
              required
              rows="3"
              variant="outlined"
              color="primary"
              margin="dense"
              InputProps={{
                value: helpMessage,
                onChange: (event) => {
                  setHelpMessage(event.target.value);
                },
              }}
            />
            <div className={classes.upgradeBox}>
              <Typography variant="subtitle2" className={classes.upgradeText}>
                {!expertSupportSubscription
                  ? 'Need more than tech support? Get expert bookkeeping guidance.'
                  : 'Congratulations! As an expert support subscriber, you can get bookkeeping advice on how to book this transaction.'}
              </Typography>
              {!expertSupportSubscription && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.upgradeBtn}
                  onClick={() => {
                    tracking('upgrade_my_support clicked', { location: SEGMENT_LOCATION });
                    setIsExpertSupportDialogOpen(true);
                  }}
                >
                  Upgrade My Support
                </Button>
              )}
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() => {
                tracking('cancel clicked', { location: SEGMENT_LOCATION });
                closeDialog();
              }}
              color="primary"
              variant="outlined"
              className={classes.actionBtn}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.actionBtn}
              onClick={() => {
                tracking('submit clicked', { location: SEGMENT_LOCATION });
              }}
            >
              Submit Question
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isExpertSupportDialogOpen && (
        <ExpertSupportSubscribeDialog
          location="GET_HELP"
          closeDialog={() => {
            setIsExpertSupportDialogOpen(false);
          }}
        />
      )}
    </>
  );
}

GetHelpDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
