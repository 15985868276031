import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/components/LoadingSpinner/styles';

export default function LoadingSpinner({ size, hasHeader }) {
  const classes = useStyles({ size });
  return (
    <div className={classes.loadingRoot}>
      {hasHeader ? <div className={classes.loadingHeader} /> : null}
      <img src="/REI_Hub_Loading_Animation_Final_2.gif" alt="loading" className={classes.loadingImage} />
    </div>
  );
}

LoadingSpinner.defaultProps = {
  size: 'medium',
  hasHeader: false,
};

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  hasHeader: PropTypes.bool,
};
