import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

import { Box } from '@material-ui/core';
import history from '../history';

import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import FilterDialog from '../components/FilterDialog';

import DownloadDialog from '../components/DownloadDialog';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class MileageByProperty extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityId: null,
        entityIdSelect: null,
      },
      additionalCriteria: [],
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const query = Object.assign(buildQuery(this, true), {
      reportName: 'mileageTotals',
    });
    delete query.entityId;

    const propertiesQuery = {
      organizationId,
      $limit: 200,
      $sort: {
        address1: 1,
        id: 1,
      },
    };
    const entityId = filter.entityId || null;

    if (entityId) {
      propertiesQuery.entityId = entityId;
    }

    const properties = await find(this, 'properties', { query: propertiesQuery });

    const mileagePromises = [];

    const additionalCriteriaArray = [{}];

    properties.data.forEach((property) => {
      mileagePromises.push(
        create(this, 'reports', {
          ...query,
          propertyId: property.id,
        }),
      );

      const criteriaProperty = {
        propertyId: property.id,
      };
      additionalCriteriaArray.push(criteriaProperty);
    });

    additionalCriteriaArray.push({
      propertyId: 'None',
    });

    if (!entityId) {
      mileagePromises.push(
        create(this, 'reports', {
          ...query,
          propertyId: null,
        }),
      );
    } else {
      mileagePromises.push(
        create(this, 'reports', {
          ...query,
          propertyId: null,
          entityId,
        }),
      );
    }

    const mileage = await Promise.all(mileagePromises);

    const mileageObject = {
      miles: [],
      deductions: [],
      trips: [],
    };

    // Variables to hold the totals for miles, deductions, and trips.
    let totalMiles = 0;
    let totalDeduction = 0;
    let total = 0;

    mileage.forEach((item) => {
      mileageObject.miles.push(item.totalMiles);
      mileageObject.deductions.push(item.totalDeduction);
      mileageObject.trips.push(item.total);

      // Add current item's totals to the running totals.
      totalMiles += item.totalMiles;
      totalDeduction += item.totalDeduction;
      total += item.total;
    });

    // Add the totals for miles, deductions, and trips to the beginning of their respective arrays.
    mileageObject.miles.unshift(totalMiles);
    mileageObject.deductions.unshift(totalDeduction);
    mileageObject.trips.unshift(total);

    const headers = properties.data.map((property) => property.address1);
    headers.unshift('Total');
    headers.push('None Assigned');

    await asyncSetState(
      {
        mileageObject,
        headers,
        loading: false,
        additionalCriteria: additionalCriteriaArray,
      },
      this,
    );
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, mileageObject, headers, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        mileageObject,
        headers,
        exportOptions,
        reportName: 'Mileage By Property',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'mileage-by-property.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { mileageObject, headers, loading, filterDialog, filter, downloadDialog, additionalCriteria } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Mileage By Property"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({ downloadDialog: false })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine2 values={headers} bold />
                  <FinancialAccountLine2
                    values={mileageObject.trips}
                    label="Total Trips"
                    indent={1}
                    displayType="text"
                    marginBottom
                  />
                  <FinancialAccountLine2
                    values={mileageObject.miles}
                    label="Total Miles"
                    indent={1}
                    displayType="text"
                  />
                  <FinancialAccountLine2
                    values={mileageObject.deductions}
                    label="Total Deductions"
                    indent={1}
                    filter={filter}
                    additionalCriteria={additionalCriteria}
                    mileage
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

MileageByProperty.contextType = PersonContext;

MileageByProperty.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MileageByProperty;
