import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    'backgroundColor': theme.palette.grey[200],
    '&.MuiTableRow-root': {
      border: 0,
    },
  },
  tableCellRoot: {
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      display: 'table-cell',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
  infoAlert: {
    border: `1px solid ${theme.palette.info._states.outlinedBorder}`,
  },
  cancelButton: {
    marginTop: theme.spacing(-1),
  },
}));

export default useStyles;
