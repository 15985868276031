import React, { useState } from 'react';
import { Dialog, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import { Close as CloseIcon, Event as EventIcon } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import { patch } from '~/feathersFunctionalWrapper';
import { checkIsNegativeAmount, parseTransactionAmount } from '~/functions/AmountFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const SEGMENT_LOCATION = 'Edit Transaction Modal - Import Feed';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(8),
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  bodyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  dateAndAmountFieldsContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    'gap': theme.spacing(2),
    'marginBlock': theme.spacing(2),

    '& > *': {
      flex: 1,
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  eventIcon: {
    color: theme.palette.action.active,
  },
  error: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  cancelButton: {
    order: 2,

    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  editButton: {
    order: 1,

    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
}));

export default function EditImportedTransactionDialog({ closeDialog, onEdit, transaction }) {
  const classes = useStyles();
  const tracking = useSegmentTrack();

  const [editFields, setEditFields] = useState({
    description: transaction.description,
    date: moment(transaction.date).format('YYYY-MM-DD'),
    amount: transaction.amount,
    yodleeType: transaction.yodleeType,
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isNegativeTransactionAmount = checkIsNegativeAmount(
    transaction.account.type,
    transaction.yodleeType,
    transaction.amount === 0,
  );
  const parsedTransactionAmount = isNegativeTransactionAmount
    ? `(${parseTransactionAmount(transaction.amount)})`
    : parseTransactionAmount(transaction.amount);

  const editTransaction = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    const fields = { ...editFields };
    fields.date = moment(fields.date).format('MM-DD-YYYY');
    if (Number(fields.amount) < 0) {
      fields.amount = Math.abs(Number(fields.amount));
      if (fields.yodleeType === 'DEBIT') {
        fields.yodleeType = 'CREDIT';
      } else {
        fields.yodleeType = 'DEBIT';
      }
    }

    setIsSubmitting(true);

    try {
      await patch('yodlee-transactions', transaction.id, fields);
      onEdit();
      closeDialog();
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
    >
      <form onSubmit={editTransaction}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography variant="subtitle1">Edit Transaction</Typography>
            <IconButton aria-label="Close" color="primary" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className={classes.body}>
            <Typography variant="caption">{`${moment(transaction.date).format('M/D/YYYY')} • ${transaction.account.name}`}</Typography>
            <div className={classes.bodyHeader}>
              <Typography variant="h6">{transaction.description}</Typography>
              <Typography color={isNegativeTransactionAmount ? 'error' : 'textPrimary'} variant="h6">
                {parsedTransactionAmount}
              </Typography>
            </div>

            <TextField
              label="Transaction Description"
              variant="outlined"
              fullWidth
              value={editFields.description}
              size="small"
              onChange={(event) => setEditFields({ ...editFields, description: event.target.value })}
            />
            <div className={classes.dateAndAmountFieldsContainer}>
              <DatePicker
                inputVariant="outlined"
                size="small"
                label="Date"
                type="text"
                format="M/D/YYYY"
                InputProps={{
                  endAdornment: <EventIcon className={classes.eventIcon} />,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                value={editFields.date}
                onChange={(date) => setEditFields({ ...editFields, date: moment(date).format('YYYY-MM-DD') })}
              />
              {transaction.splitParentId ? null : (
                <CurrencyField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  placeholder="$0.00"
                  InputLabelProps={{ shrink: true }}
                  value={editFields.amount || ''}
                  onChange={(event) => setEditFields({ ...editFields, amount: Number(event.target.value) })}
                  required
                />
              )}
            </div>
            <Alert severity="error" variant="filled">
              <AlertTitle>Editing may cause future reconciliation issues.</AlertTitle>
              <Typography variant="body2">Changes will overwrite the imported data and cannot be undone.</Typography>
            </Alert>

            {error?.message && (
              <Alert className={classes.error} severity="error">
                {error.message}
              </Alert>
            )}
          </div>

          <div className={classes.footer}>
            <Button
              className={classes.cancelButton}
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => {
                tracking('cancel clicked', { location: SEGMENT_LOCATION });
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.editButton}
              color="primary"
              size="medium"
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: SEGMENT_LOCATION });
                onEdit(editFields);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

EditImportedTransactionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
