import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getEscrowExpenseJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import { PersonContext } from '~/contexts/PersonContext';
import { create, get } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

export default function QuickBookRowEscrowExpenseTemplate({
  transaction,
  onTransactionAction,
  onTemplateLoad,
  onGetHelp,
}) {
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const classes = useStyles();
  const { organizationId } = useContext(PersonContext);
  const [error, setError] = useState(null);
  const [journal, setJournal] = useState(() => {
    const journalObject = getEscrowExpenseJournal(transaction);
    journalObject.organizationId = organizationId;
    journalObject.type = 'Escrow Expense';
    journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    journalObject.description = transaction.description;
    journalObject.date = transaction.date;
    journalObject.amount = transaction.amount;
    return journalObject;
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const expenseAccountOptions = await getAccountOptions(organizationId, { type: 'Expense' });
      setExpenseAccountOptions(expenseAccountOptions);

      const account = await get('accounts', transaction.accountId);
      setJournal((prevJournal) => {
        const journalObject = { ...prevJournal };
        journalObject.journalLines[1].accountId = account.mortgageEscrowAccountId;
        return journalObject;
      });

      setIsLoading(false);
      onTemplateLoad?.();
    }
    getData();
  }, [organizationId, onTemplateLoad, transaction.accountId]);

  const addEscrowExpense = async (event) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const journalSubmit = { ...journal };
    setJournalScope(journalSubmit);
    journalSubmit.journalLines.forEach((line) => {
      if (!line.accountId) {
        line.accountId = line.accountIdSelect.id;
      }
    });

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction();
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <form onSubmit={addEscrowExpense}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <AutocompleteField
            className={classes.autoCompleteField}
            label="Expense Account"
            options={expenseAccountOptions}
            required
            value={journal.journalLines[0].accountIdSelect}
            onChange={(value) => {
              setJournal((prevJournal) => {
                const journalObject = { ...prevJournal };
                journalObject.journalLines[0].accountIdSelect = value;
                return journalObject;
              });
            }}
          />
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.helpButton}
            onClick={() => {
              tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
              onGetHelp();
            }}
          >
            <HelpOutline className={classes.helpIcon} />
            Get Help
          </Button>
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              fullWidth={false}
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowEscrowExpenseTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onTemplateLoad: PropTypes.func.isRequired,
  onGetHelp: PropTypes.func.isRequired,
};
