import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { get, patch, remove } from '../feathersWrapper';
import {
  filterSearchSelectOptions,
  handleCheckboxChange,
  handleSearchSelectChange,
  handleTextFieldChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import { stateCodeOptions } from './SearchSelect/StateCodeOptions';
import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
  stateSelect: {
    marginTop: '4px',
  },
});

class EditVendorDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getVendor = async () => {
    const { vendorId } = this.props;
    const oldVendor = await get(this, 'vendors', vendorId);
    const vendor = {};
    const properties = [
      'organizationId',
      'name',
      'email',
      'phone',
      'address1',
      'address2',
      'city',
      'state',
      'zip',
      'tin',
      'is1099',
    ];

    Object.keys(oldVendor).forEach((prop) => {
      if (properties.includes(prop)) {
        vendor[prop] = oldVendor[prop];
      }
    });

    vendor.stateSelect = vendor.state ? { label: vendor.state, value: vendor.state } : null;

    const addAddress = !!vendor.address1;

    this.setState({
      vendor,
      addAddress,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
    });
  };

  editVendor = async (event) => {
    event.preventDefault();
    const { submitting, vendor, addAddress } = this.state;

    const { onEditVendor, vendorId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const submitVendor = { ...vendor };
    if (!addAddress) {
      submitVendor.address1 = '';
      submitVendor.address2 = '';
      submitVendor.city = '';
      submitVendor.state = '';
      submitVendor.zip = '';
    }

    if (!submitVendor.is1099) {
      submitVendor.tin = '';
    }

    patch(this, 'vendors', vendorId, submitVendor)
      .then(() => {
        onEditVendor();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteVendor = () => {
    const { submitting } = this.state;
    const { onEditVendor, vendorId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    remove(this, 'vendors', vendorId)
      .then(() => {
        onEditVendor();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { loading, error, vendor, addAddress, confirmDelete } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={this.getVendor}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editVendor}>
            <DialogTitle id="alert-dialog-title">
              Edit Vendor
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                required
                InputProps={{
                  value: vendor.name,
                  name: 'nested_vendor_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Email (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: vendor.email,
                  type: 'email',
                  name: 'nested_vendor_email',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Phone Number (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: vendor.phone,
                  type: 'tel',
                  name: 'nested_vendor_phone',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={addAddress} name="addAddress" onChange={handleCheckboxChange(this)} />}
                label="Add Address"
              />
              {addAddress && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <TextField
                    label="Street Address"
                    fullWidth
                    required
                    type="text"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      value: vendor.address1,
                      name: 'nested_vendor_address1',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                  <TextField
                    label="Ste, Fl, Unit, Etc. (optional)"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    InputProps={{
                      value: vendor.address2,
                      name: 'nested_vendor_address2',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                  <FormGridContainer>
                    <FormGridItem xs={12} sm={6}>
                      <TextField
                        label="City"
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        margin="dense"
                        InputProps={{
                          value: vendor.city,
                          name: 'nested_vendor_city',
                          onChange: handleTextFieldChange(this),
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem xs={12} sm={3}>
                      <SearchSelect
                        searchFunction={filterSearchSelectOptions(stateCodeOptions)}
                        changeFunction={handleSearchSelectChange(this)}
                        className={classes.stateSelect}
                        label="State"
                        autoComplete="off"
                        name="nested_vendor_state"
                        value={vendor.stateSelect}
                        required
                        isClearable
                      />
                    </FormGridItem>
                    <FormGridItem xs={12} sm={3}>
                      <TextField
                        label="Zip"
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        margin="dense"
                        InputProps={{
                          value: vendor.zip,
                          name: 'nested_vendor_zip',
                          onChange: handleTextFieldChange(this),
                        }}
                      />
                    </FormGridItem>
                  </FormGridContainer>
                </Box>
              )}
              <FormControlLabel
                control={
                  <Checkbox checked={vendor.is1099} name="nested_vendor_is1099" onChange={handleCheckboxChange(this)} />
                }
                label="Track payments for 1099"
              />
              {vendor.is1099 && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <TextField
                    label="Tax Identification Number"
                    fullWidth
                    type="text"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      value: vendor.tin,
                      name: 'nested_vendor_tin',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                </Box>
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Vendor
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Vendor?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">This vendor will be removed.</DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteVendor} className={classes.deleteConfirmationButton}>
                Delete Vendor
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditVendorDialog.contextType = PersonContext;

EditVendorDialog.defaultProps = {
  vendorId: null,
};

EditVendorDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditVendor: PropTypes.func.isRequired,
  vendorId: PropTypes.node,
};

export default withStyles(styles)(EditVendorDialog);
