import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const POST_PRODUCT_TOUR_LOCATION = 'Post Product Tour';
const BUY_NOW_PROMO_MODAL_LOCATION = '2 Months Free Buy Now Modal';

const useStyles = makeStyles((theme) => ({
  container: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'column',
    'padding': theme.spacing(4),
    // override custom MUI padding
    '&:first-child': {
      paddingTop: theme.spacing(4),
    },
  },
  chip: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  title: {
    marginBottom: theme.spacing(-1),
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  description: {
    marginBottom: theme.spacing(3),
    fontStyle: 'italic',
    textAlign: 'center',
  },
  buyNowButton: {
    marginBottom: theme.spacing(1),
  },
  tryForFreeButton: {
    color: theme.palette.primary.main,
  },
}));

export default function BuyNowPromoModal({ onBuyNow, onTryForFree }) {
  const track = useSegmentTrack();

  useEffect(() => {
    track('2_months_free_modal loaded', {
      location: POST_PRODUCT_TOUR_LOCATION,
    });
  }, [track]);

  const handleBuyNow = () => {
    track('claim_2_months_free clicked', {
      location: BUY_NOW_PROMO_MODAL_LOCATION,
    });
    onBuyNow();
  };

  const handleTryForFree = () => {
    track('try_14_days_free_instead clicked', {
      location: BUY_NOW_PROMO_MODAL_LOCATION,
    });
    onTryForFree();
  };

  const classes = useStyles();

  return (
    <DialogContent className={classes.container}>
      <Chip label="Limited Time Offer" className={classes.chip} />
      <Typography variant="h3" className={classes.title}>
        2 Months Free
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        with an annual plan.
      </Typography>
      <Typography variant="h6" className={classes.description}>
        Buy now and save on accounting built for real estate investors
      </Typography>

      <Box>
        <Button hasFullWidth variant="contained" onClick={handleBuyNow} className={classes.buyNowButton}>
          Claim 2 Months Free
        </Button>
        <MuiButton
          fullWidth
          size="large"
          variant="text"
          className={classes.tryForFreeButton}
          onClick={handleTryForFree}
        >
          Try 14 Days Free Instead
        </MuiButton>
      </Box>
    </DialogContent>
  );
}
BuyNowPromoModal.propTypes = {
  onBuyNow: PropTypes.func.isRequired,
  onTryForFree: PropTypes.func.isRequired,
};
