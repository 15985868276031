import React from 'react';
import NumberFormat from 'react-number-format';
import { Box, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';
import PropTypes from 'prop-types';

import LoadingSpinner from '~/components/LoadingSpinner';

import BatchChangeImportedTransactionDialog from '../components/BatchChangeImportedTransactionDialog';
import CardBase from '../components/CardBase';
import EditAccountDialog from '../components/EditAccountDialog';
import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import ViewBookedTransactionDialog from '../components/ViewBookedTransactionDialog';
import ViewImportedTransactionDialog from '../components/ViewImportedTransactionDialog';
import { PersonContext } from '../contexts/PersonContext';
import { create, find, get } from '../feathersWrapper';
import { buildQuery, parseQuery } from '../functions/FilterFunctions';
import { asyncHandleChange, handleChange } from '../functions/InputHandlers';
import { TablePaginationButtons } from '../functions/TableFunctions';
import history from '../history';

import BatchChangeCard from './cards/BatchChangeCard';
import FilterCard from './cards/FilterCard';
import PMAccountGraphCard from './cards/PMAccountGraphCard';
import PMAccountQuicklinksCard from './cards/PMAccountQuicklinksCard';

const styles = (theme) => ({
  headerContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  red: {
    color: theme.palette.error.main,
  },
  tagButton: {
    padding: 0,
    color: theme.palette.action.active,
    minWidth: '32px',
  },
  tagSpacer: {
    minWidth: '32px',
    display: 'inline-block',
  },
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class PropertyManager extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterJournalsDialog: false,
      selectedJournalId: null,
      editAccount: false,
      viewImportedTransactionDialog: false,
      yodleeTransactionId: null,
      account: null,
      showBalance: false,
      startingPageBalance: {},
      stale: {
        journals: false,
        accountGraph: false,
      },
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        unitId: null,
        transactionType: null,
        description: '',
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: { address1: 'All Properties', id: 'All' },
        unitIdSelect: null,
        transactionTypeSelect: { name: 'All Transactions', id: 'All' },
        page: 0,
        pageSize: 50,
      },
      batchChangeImportedTransactionDialog: false,
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
      batchPages: [],
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { accountId } = match.params;

    await parseQuery(this);

    const account = await get(this, 'accounts', accountId);
    let isAirbnbAccount = false;
    let isVRBOAccount = false;
    if (account.default === 'airbnb') {
      isAirbnbAccount = true;
    }
    if (account.default === 'vrbo') {
      isVRBOAccount = true;
    }
    handleChange('account', account, this);

    await this.getJournals();

    this.setState({
      loading: false,
      isAirbnbAccount,
      isVRBOAccount,
    });
  }

  componentDidUpdate() {
    const { stale } = this.state;
    if (stale.journals) {
      this.getJournals();
      this.removeStale('journals');
    }
  }

  handleTransactionModified = () => {
    this.markStale('accountGraph');
    this.getJournals();
  };

  getJournals = async () => {
    const { organizationId, basis } = this.context;
    const { filter } = this.state;
    const { match } = this.props;
    let { accountId } = match.params;
    accountId = Number.parseInt(accountId, 10);

    const query = Object.assign(buildQuery(this), {
      basis,
      accountId,
      includeFullJournals: true,
      $sort: {
        date: -1,
        amount: -1,
      },
    });

    let showBalance = true;

    if (filter.propertyId || filter.unitId || filter.transactionType || filter.description) {
      showBalance = false;
    }

    const journalLinesResponse = await find(this, 'journal-lines', { query });
    const journalLines = journalLinesResponse.data;
    const journals = [];
    journalLines.forEach((journalLine) => {
      const { journal } = journalLine;

      journal.creditLines = [];

      journal.debitLines = [];
      journal.journalLines.forEach((line) => {
        if (line.id === journalLine.id) {
          journal.accountLine = line;
        }

        if (line.type === 'credit') {
          journal.creditLines.push(line);
        } else {
          journal.debitLines.push(line);
        }
      });
      journals.push(journal);
    });

    const { startingPageBalance } = this.state;
    if (showBalance) {
      let startingBalance;
      if (filter.page === 0) {
        const balanceReportQuery = {
          organizationId,
          basis,
          accountId,
          reportName: 'accountJournalTotals',
        };
        if (filter.endDate) {
          balanceReportQuery.endDate = filter.endDate;
        }
        const balanceReport = await create(this, 'reports', { ...balanceReportQuery });
        startingPageBalance[filter.page] = -balanceReport[0].netCredits;
      }

      startingBalance = startingPageBalance[filter.page];
      for (let i = 0; i < journals.length; i += 1) {
        journals[i].accountLine.balance = startingBalance;
        if (journals[i].accountLine.type === 'debit') {
          startingBalance -= journals[i].accountLine.debit;
        } else {
          startingBalance += journals[i].accountLine.credit;
        }
      }
      startingPageBalance[filter.page + 1] = startingBalance;
    }
    const totalJournals = journalLinesResponse.total;
    this.setState({
      journals,
      totalJournals,
      showBalance,
      startingPageBalance,
    });
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateJournalsFilter(newFilter);
  };

  handleChangePage = async (_event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getJournals();
  };

  closeTransactionDialogs = () => {
    this.setState({
      selectedJournalId: null,
      viewImportedTransactionDialog: false,
      filterJournalsDialog: false,
    });
  };

  updateJournalsFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.markStale('accountGraph');
    this.getJournals();
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  splitAccount = (journal) => {
    if (journal.accountLine.type === 'credit') {
      if (journal.debitLines.length === 1) {
        return journal.debitLines[0].account.name;
      }
      return 'Split';
    }
    if (journal.creditLines.length === 1) {
      return journal.creditLines[0].account.name;
    }
    return 'Split';
  };

  formatNegative = (value, accountLine) => {
    const { classes } = this.props;
    if (accountLine.type === 'credit') {
      return <span className={classes.red}>{`(${value})`}</span>;
    }
    return value;
  };

  formatNegativeBalance = (value) => {
    const { classes } = this.props;
    if (value[0] === '-') {
      return <span className={classes.red}>{`(${value.substring(1)})`}</span>;
    }
    return value;
  };

  setBatchEditState = () => {
    const { batchChange } = this.state;
    if (batchChange === true) {
      this.setState({
        batchChange: false,
        batchChangeTargets: [],
        fullBatchChangeTargets: [],
        batchPages: [],
        batchType: null,
      });
    } else {
      this.setState({ batchChange: true });
    }
  };

  cancelBatchChange = () => {
    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
    });
  };

  addAllPageTransactionsToBatch = (_event, value) => {
    const { journals, batchChangeTargets, filter, batchPages, fullBatchChangeTargets } = this.state;

    const batchArray = [];
    const fullBatchArray = [];

    journals.forEach((journal) => {
      batchArray.push(journal.id);
      fullBatchArray.push(journal);
    });

    if (value) {
      const newBatchTargets = [...new Set([...batchChangeTargets, ...batchArray])];
      const newFullBatchTargets = [...new Set([...fullBatchChangeTargets, ...fullBatchArray])];
      const newBatchPage = [...batchPages, filter.page];

      this.setState({
        batchChangeTargets: newBatchTargets,
        fullBatchChangeTargets: newFullBatchTargets,
        batchPages: newBatchPage,
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter((target) => !batchArray.includes(target)),
        fullBatchChangeTargets: fullBatchChangeTargets.filter((target) => !fullBatchArray.includes(target)),
        batchPages: batchPages.filter((page) => page !== filter.page),
      });
    }
  };

  getBatchTransactions = (_event, value, transaction) => {
    const { batchChangeTargets, fullBatchChangeTargets } = this.state;
    if (value) {
      this.setState({
        batchChangeTargets: [...batchChangeTargets, transaction.id],
        fullBatchChangeTargets: [...fullBatchChangeTargets, transaction],
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter((target) => target !== transaction.id),
        fullBatchChangeTargets: fullBatchChangeTargets.filter((target) => target.id !== transaction.id),
      });
    }
  };

  closeBatchChangeImportedTransactionDialog = () => {
    this.setState({
      batchChangeImportedTransactionDialog: false,
    });
  };

  onBatchChangeTransactionsDialog = () => {
    this.getJournals();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  openBatchChangeImportedTransactionDialog = () => {
    this.setState({ batchChangeImportedTransactionDialog: true });
  };

  actionButtons = () => {
    const { match } = this.props;
    const buttons = [
      { text: 'Edit', action: () => this.setState({ editAccount: true }), class: 'edit' },
      { text: 'Rules', link: `${match.url}/rules`, class: 'edit' },
      { text: 'Batch Edit', action: () => this.setBatchEditState(), class: 'edit' },
    ];
    return buttons;
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      account,
      journals,
      totalJournals,
      filter,
      selectedJournalId,
      filterJournalsDialog,
      viewImportedTransactionDialog,
      yodleeTransactionId,
      stale,
      showBalance,
      editAccount,
      isAirbnbAccount,
      isVRBOAccount,
      batchChange,
      batchChangeTargets,
      fullBatchChangeTargets,
      batchChangeImportedTransactionDialog,
      batchType,
      batchPages,
    } = this.state;

    if (loading) {
      return <LoadingSpinner size="medium" />;
    }

    return (
      <>
        <Box className={classes.headerContainer}>
          <PageHeader
            match={match}
            title={account.name}
            // appliedFilter={appliedFilter(this.updateJournalsFilter, this)}
            actionButtons={this.actionButtons()}
          />
        </Box>
        <PageGrid isMultiCard>
          {editAccount && (
            <EditAccountDialog
              accountId={account.id}
              isOpen
              closeDialog={() => this.setState({ editAccount: false })}
              onEditAccount={() => history.replace('/accounts/property-managers')}
            />
          )}
          {selectedJournalId && (
            <ViewBookedTransactionDialog
              closeDialog={this.closeTransactionDialogs}
              onTransactionModified={this.handleTransactionModified}
              journalId={selectedJournalId}
            />
          )}
          <ViewImportedTransactionDialog
            yodleeTransactionId={yodleeTransactionId}
            isOpen={viewImportedTransactionDialog}
            closeDialog={this.closeTransactionDialogs}
            onUnmatchTransaction={this.getJournals}
          />
          <FilterDialog
            filter={filter}
            isOpen={filterJournalsDialog}
            closeDialog={this.closeTransactionDialogs}
            updateFilter={this.updateJournalsFilter}
            dateRange
            transactionType
            property
            unit
            description
          />
          <PMAccountGraphCard accountId={account.id} stale={stale.accountGraph} removeStale={this.removeStale} />
          <PMAccountQuicklinksCard
            markStale={this.markStale}
            accountId={account.id}
            isAirbnbAccount={isAirbnbAccount}
            isVRBOAccount={isVRBOAccount}
          />
          <FilterCard
            openFilter={() => this.setState({ filterJournalsDialog: true })}
            updateFilter={this.updateJournalsFilter}
            filter={filter}
            fields={['transactionType', 'property']}
          />
          {batchChange && (
            <BatchChangeCard
              openBatchChangeImportedTransactionDialog={() =>
                this.setState({ batchChangeImportedTransactionDialog: true })
              }
              transactions={batchChangeTargets}
              cancelBatchChange={this.cancelBatchChange}
              yodleeTransactions={journals}
              screen="Property Manager"
              updateBatchType={(type) => this.setState({ batchType: type })}
            />
          )}
          {batchChangeImportedTransactionDialog && (
            <BatchChangeImportedTransactionDialog
              closeDialog={() => this.closeBatchChangeImportedTransactionDialog()}
              transactions={batchChangeTargets}
              fullTransactions={fullBatchChangeTargets}
              onBatchChangeTransactions={this.onBatchChangeTransactionsDialog}
              batchType={batchType}
              screen="Property Manager"
            />
          )}
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      {batchChange && (
                        <TableCell>
                          <Checkbox
                            onChange={(event, value) => {
                              this.addAllPageTransactionsToBatch(event, value);
                            }}
                            checked={!!batchPages.includes(filter.page) && batchChangeTargets.length > 0}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="subtitle2">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Description</Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell align="right">
                        <Typography variant="subtitle2">Amount</Typography>
                      </TableCell>
                      {!batchChange && (
                        <TableCell align="right">
                          <Typography variant="subtitle2">Action</Typography>
                        </TableCell>
                      )}
                      {showBalance && (
                        <TableCell align="right">
                          <Typography variant="subtitle2">Balance</Typography>
                        </TableCell>
                      )}
                    </TableRow>
                    {journals.map((journal) => (
                      <TableRow key={journal.accountLine.id} className="noprintbreak">
                        {batchChange && (
                          <TableCell>
                            <Checkbox
                              onChange={(event, value) => this.getBatchTransactions(event, value, journal)}
                              checked={!!batchChangeTargets.find((target) => target === journal.id)}
                            />
                          </TableCell>
                        )}
                        <TableCell>
                          <Typography variant="body2" gutterBottom>
                            {moment(journal.date).format('M/D/YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                            {journal.type}
                          </Typography>
                          <Typography className={classes.truncateOneLine} variant="caption" component="div">
                            {this.splitAccount(journal)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                            {journal.description ? journal.description : '---'}
                          </Typography>
                          {journal.entityId !== null && journal.propertyId === null && (
                            <Typography variant="caption" component="div">
                              {journal.entity.name}
                            </Typography>
                          )}
                          {journal.propertyId !== null && (
                            <Typography variant="caption" component="div">
                              {journal.property.address1}
                            </Typography>
                          )}
                          {journal.unitId !== null && (
                            <Typography variant="caption" component="div">
                              {journal.unit.name}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {journal.accountLine.yodleeTransactionId ? (
                            <Button
                              className={classes.tagButton}
                              aria-label="imported"
                              onClick={() =>
                                this.setState({
                                  viewImportedTransactionDialog: true,
                                  yodleeTransactionId: journal.accountLine.yodleeTransactionId,
                                })
                              }
                            >
                              <LinkIcon fontSize="small" />
                            </Button>
                          ) : (
                            <div className={classes.tagSpacer} />
                          )}
                          {journal.attachmentURL ? (
                            <Button
                              className={classes.tagButton}
                              aria-label="attachments"
                              href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                              target="_blank"
                            >
                              <AttachFileIcon fontSize="small" />
                            </Button>
                          ) : (
                            <div className={classes.tagSpacer} />
                          )}
                          {journal.accountLine.reconciled ? (
                            <Button className={classes.tagButton} aria-label="imported" onClick={() => {}}>
                              R
                            </Button>
                          ) : (
                            <div className={classes.tagSpacer} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={
                              journal.accountLine.type === 'credit'
                                ? journal.accountLine.credit
                                : journal.accountLine.debit
                            }
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                            renderText={(value) => this.formatNegative(value, journal.accountLine)}
                          />
                        </TableCell>
                        {!batchChange && (
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                this.setState({
                                  selectedJournalId: journal.id,
                                });
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                        )}
                        {showBalance && (
                          <TableCell align="right">
                            <NumberFormat
                              displayType="text"
                              value={journal.accountLine.balance}
                              thousandSeparator
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale
                              renderText={(value) => this.formatNegativeBalance(value)}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter className="noprint">
                    <TableRow>
                      <TablePagination
                        variant="footer"
                        count={totalJournals}
                        page={filter.page}
                        rowsPerPage={50}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[50]}
                        ActionsComponent={TablePaginationButtons}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </CardContent>
            </CardBase>
          </Grid>
        </PageGrid>
      </>
    );
  }
}

PropertyManager.contextType = PersonContext;

PropertyManager.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(PropertyManager);
