import { useMemo } from 'react';

export default function useMatchState({
  descriptionMatches = [],
  importedTransactionRuleMatch = [],
  incomeTemplateMatch = [],
  journalLinesMatch = [],
  loanTemplateMatch = [],
  ownerFundsMatch = [],
}) {
  return useMemo(() => {
    const updateMatchedProp = (match) => ({ ...match, matched: false });
    const importedTransactionRuleMatchUpdated = importedTransactionRuleMatch.map(updateMatchedProp);
    const incomeTemplateMatchUpdated = incomeTemplateMatch.map(updateMatchedProp);
    const journalLinesMatchUpdated = journalLinesMatch.map(updateMatchedProp);
    const loanTemplateMatchUpdated = loanTemplateMatch.map(updateMatchedProp);
    const ownerFundsMatchUpdated = ownerFundsMatch.map(updateMatchedProp);

    let hasLoanMatch = false;
    let hasTransactionMatch = false;
    if (journalLinesMatchUpdated.length) {
      hasTransactionMatch = true;
      if (journalLinesMatchUpdated.length === 1) {
        journalLinesMatchUpdated[0].matched = true;
      }
    } else if (ownerFundsMatchUpdated.length) {
      hasTransactionMatch = true;
      if (ownerFundsMatchUpdated.length === 1) {
        ownerFundsMatchUpdated[0].matched = true;
      }
    } else if (loanTemplateMatchUpdated.length === 1) {
      loanTemplateMatchUpdated[0].matched = true;
      hasLoanMatch = true;
    } else if (importedTransactionRuleMatchUpdated.length === 1) {
      importedTransactionRuleMatchUpdated[0].matched = true;
    } else if (incomeTemplateMatchUpdated.length === 1) {
      incomeTemplateMatchUpdated[0].matched = true;
    }

    return {
      descriptionMatches: descriptionMatches,
      hasLoanMatch,
      hasTransactionMatch,
      importedTransactionRuleMatch: importedTransactionRuleMatchUpdated,
      incomeTemplateMatch: incomeTemplateMatchUpdated,
      journalMatch: journalLinesMatchUpdated,
      loanTemplateMatch: loanTemplateMatchUpdated,
      ownerFundsMatch: ownerFundsMatchUpdated,
    };
  }, [
    descriptionMatches,
    importedTransactionRuleMatch,
    incomeTemplateMatch,
    journalLinesMatch,
    loanTemplateMatch,
    ownerFundsMatch,
  ]);
}
