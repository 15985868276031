import React from 'react';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import TransactionScope from '~/components/TransactionScope';

export default function PlaidSuccess({ addedAccounts, error, plaidItem, handleSaveAndClose, setState }) {
  return (
    <DialogContent>
      <form onSubmit={handleSaveAndClose}>
        <Box mx="auto" mb={2}>
          <Typography variant="h6" gutterBottom>
            Success
          </Typography>
        </Box>
        <Typography variant="body1">
          {`Plaid successfully connected to ${plaidItem.institutionName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
        </Typography>
        {addedAccounts.map((item, index) => (
          <Box key={item.plaidAccountId} mb={2}>
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <Typography variant="h6" gutterBottom>
                {`xxxx${item.mask}`}
              </Typography>
              <TextField
                fullWidth
                label="Account Name"
                InputProps={{
                  value: item.name,
                  name: `nested_addedAccounts_${index}_name`,
                  onChange: (_event, value) => {
                    const newAddedAccounts = [...addedAccounts];
                    newAddedAccounts[index].name = value;
                    setState((prevState) => ({ ...prevState, addedAccounts: newAddedAccounts }));
                  },
                }}
              />
              <TransactionScope
                journal={addedAccounts[index].journal}
                transactionScopeChange={(values) => {
                  const newAddedAccounts = [...addedAccounts];
                  const newJournal = {
                    ...newAddedAccounts[index].journal,
                    ...values,
                  };
                  newAddedAccounts[index].journal = newJournal;
                  setState((prevState) => ({ ...prevState, addedAccounts: newAddedAccounts }));
                }}
                label="Account"
              />
            </Box>
          </Box>
        ))}
        <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
          {!error && (
            <Box marginBottom={2}>
              <Typography variant="body1" color="error">
                {error}
              </Typography>
            </Box>
          )}
          <Button hasFullWidth type="submit" variant="contained">
            {error ? 'Retry' : 'Save and Close'}
          </Button>
        </Box>
      </form>
    </DialogContent>
  );
}

PlaidSuccess.propTypes = {
  addedAccounts: PropTypes.array.isRequired,
  error: PropTypes.string,
  plaidItem: PropTypes.object.isRequired,
  handleSaveAndClose: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
