export const checkIsNegativeAmount = (accountType, yodleeType, amountIsZero) => {
  if (amountIsZero) {
    return false;
  }

  return (
    (accountType === 'Asset' && (yodleeType === 'DEBIT' || yodleeType === 'debit')) ||
    (accountType === 'Liability' && (yodleeType === 'CREDIT' || yodleeType === 'credit'))
  );
};

export function parseTransactionAmount(transactionAmount) {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transactionAmount);
}
