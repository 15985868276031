import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import TransactionTypeSelect from '~/components/TransactionTypeSelect';

import { useQuickBookRowContext } from './context';

const useStyles = makeStyles((theme) => ({
  transactionTypeSelect: {
    marginBottom: theme.spacing(1),
  },
}));

export default function QuickBookRowTransactionTypeSelect() {
  const { selectedTransactionType, transactionTypes, onTransactionTypeChange } = useQuickBookRowContext();
  const classes = useStyles();

  return (
    <TransactionTypeSelect
      className={classes.transactionTypeSelect}
      selectedTransactionType={selectedTransactionType}
      transactionTypes={transactionTypes}
      onTransactionTypeChange={onTransactionTypeChange}
      data-testid="quickbookrow-transaction-type-select"
    />
  );
}
