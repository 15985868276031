import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import ButtonBase from '@material-ui/core/ButtonBase';

import { asyncHandleChange } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import FilterDialog from '../components/FilterDialog';

import AddMileageDialog from '../components/AddMileageDialog';
import EditMileageDialog from '../components/EditMileageDialog';
import DownloadDialog from '../components/DownloadDialog';

const styles = (theme) => ({
  textButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  attachmentButton: {
    marginLeft: theme.spacing(1.5),
  },
});

class Mileage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addDialog: false,
      editDialog: false,
      downloadDialog: false,
      filterDialog: false,
      filter: {
        page: 0,
        startDate: null,
        endDate: null,
        propertyId: null,
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getData();
  }

  getData = async () => {
    const { filter } = this.state;

    const query = Object.assign(buildQuery(this), {
      $sort: {
        date: -1,
        miles: -1,
      },
    });

    let response = await find(this, 'mileage', { query });

    let records = response.data;
    const totalRecords = response.total;

    if (filter.print) {
      while (records.length < totalRecords) {
        query.$skip += 500;
        // eslint-disable-next-line no-await-in-loop
        response = await find(this, 'mileage', { query });
        records = records.concat(response.data);
      }
    }

    this.setState({
      totalRecords,
      mileages: records,
      loading: false,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getData();
  };

  handleChangePage = async (_event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getData();
  };

  closeDialogs = () => {
    this.setState({
      filterDialog: false,
      addDialog: false,
      editDialog: false,
    });
  };

  onDialogs = async () => {
    await this.getData();
    this.closeDialogs();
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, mileages, exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        mileages,
        exportOptions,
        reportName: 'Mileage Log',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'mileage-log.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => {
    const { match } = this.props;
    const buttons = [
      { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
      { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
      { text: 'Add Mileage', action: () => this.setState({ addDialog: true }), class: 'add' },
      { text: 'Vehicles', link: `${match.path}/vehicles`, class: 'edit' },
    ];
    return buttons;
  };

  render() {
    const { classes, match } = this.props;
    const { loading, mileages, totalRecords, filter, filterDialog, addDialog, editDialog, downloadDialog, mileageId } =
      this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Mileage Log"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <AddMileageDialog isOpen={addDialog} closeDialog={this.closeDialogs} onAdd={this.onDialogs} />
        <EditMileageDialog
          isOpen={editDialog}
          closeDialog={this.closeDialogs}
          onEdit={this.onDialogs}
          onDelete={this.onDialogs}
          mileageId={mileageId}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property']}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Trip</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Description</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mileages.map((mileage) => (
                    <TableRow key={mileage.id}>
                      <TableCell>
                        <ButtonBase
                          className={classes.textButton}
                          onClick={() => {
                            this.setState({ mileageId: mileage.id, editDialog: true });
                          }}
                        >
                          <Typography variant="body2" component="div" gutterBottom>
                            {moment(mileage.date).format('M/D/YYYY')}
                          </Typography>
                        </ButtonBase>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" component="div" gutterBottom>
                          {'Miles: '}
                          <NumberFormat displayType="text" value={mileage.miles} thousandSeparator decimalScale={2} />
                        </Typography>
                        <Typography variant="caption" component="div">
                          {'Deduction: '}
                          <NumberFormat
                            displayType="text"
                            value={mileage.deduction}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {mileage.description}
                        </Typography>
                        {mileage.property && (
                          <Typography variant="caption" component="div">
                            {`Property: ${mileage.property.address1}`}
                          </Typography>
                        )}
                        {mileage.unit && (
                          <Typography variant="caption" component="div">
                            {`Unit: ${mileage.unit.name}`}
                          </Typography>
                        )}
                        {!mileage.property && mileage.entity && (
                          <Typography variant="caption" component="div">
                            {`Sub-Portfolio: ${mileage.entity.name}`}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalRecords}
                      page={filter.page}
                      rowsPerPage={20}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[20]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Mileage.contextType = PersonContext;

Mileage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Mileage);
