import { createContext, useContext } from 'react';

export const QuickBookRowContext = createContext();

export function useQuickBookRowContext() {
  const context = useContext(QuickBookRowContext);
  if (!context) {
    throw new Error('The QuickBookRow context must be used within a QuickBookRow provider');
  }
  return context;
}
