export function getBookedTransactionDescription(transaction) {
  const nonTransactionLines = transaction.journal.journalLines.filter(
    (line) => line.yodleeTransactionId !== transaction.id,
  );
  return `${transaction.journal.type}${
    nonTransactionLines.length !== 1 ? '' : `: ${nonTransactionLines[0].account.name}`
  }`;
}

export const getCheckboxTooltip = (quickBookLinesLength, transaction) => {
  if (quickBookLinesLength) {
    return 'Batch selection is unavailable while booking transactions';
  } else if (transaction?.split) {
    return 'Split transactions cannot be selected';
  }
  return 'This transaction cannot be selected based on the batch action selected';
};
