import React, { useContext, useEffect } from 'react';
import { ButtonGroup, Card, makeStyles, TextField } from '@material-ui/core';
import { FilterList as FilterListIcon, Link as LinkIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import {
  getImportAccountOptions,
  getImportedTransactionStatusOptions,
  nameLabel,
} from '~/components/Autocomplete/Library';
import Button from '~/components/Button';
import { PersonContext } from '~/contexts/PersonContext';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  autocomplete: {
    flex: 1,
  },
  buttonGroup: ({ isViewRegisterButtonVisible }) => ({
    'display': 'inline-flex',
    'marginInline': isViewRegisterButtonVisible ? '0' : 'auto',
    'width': isViewRegisterButtonVisible ? '100%' : 'fit-content',

    '& > *': {
      width: isViewRegisterButtonVisible ? '100%' : '136px',
    },

    [theme.breakpoints.up('lg')]: {
      'marginInline': '0',
      'width': 'fit-content',

      '& > *': {
        width: '180px',
      },
    },
  }),
}));

export default function ImportFeedFilterCard({ filter, openFilter, updateFilter }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [importAccountOptions, setImportAccountOptions] = React.useState([]);

  const isViewRegisterButtonVisible = filter.accountIdSelect && filter.accountIdSelect.id !== 'All';

  const { organizationId } = useContext(PersonContext);
  const classes = useStyles({ isViewRegisterButtonVisible });

  function handleAutocompleteChange(name) {
    return (_event, value) => {
      const newFilter = { ...filter };
      newFilter[name] = value;
      newFilter.page = 0;
      updateFilter(newFilter);
    };
  }

  function goToRegister() {
    if (filter.accountIdSelect.type2 === 'Bank') {
      history.push(`/accounts/banking/bank/${filter.accountIdSelect.id}`);
      return;
    }
    history.push(`/accounts/banking/card/${filter.accountIdSelect.id}`);
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const importAccountOptions = await getImportAccountOptions({ context: { organizationId } });
      setImportAccountOptions(importAccountOptions);

      setIsLoading(false);
    }

    fetchData();
  }, [organizationId]);

  if (isLoading) {
    return null;
  }

  return (
    <Card className={classes.container}>
      <Autocomplete
        className={classes.autocomplete}
        options={importAccountOptions}
        getOptionLabel={nameLabel}
        value={filter.accountIdSelect}
        onChange={handleAutocompleteChange('accountIdSelect')}
        disableClearable
        blurOnSelect
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Import Account" placeholder="Type to Search" required />}
      />

      <Autocomplete
        className={classes.autocomplete}
        options={getImportedTransactionStatusOptions}
        getOptionLabel={nameLabel}
        value={filter.transactionStatusSelect}
        onChange={handleAutocompleteChange('transactionStatusSelect')}
        disableClearable
        blurOnSelect
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="Transaction Status" placeholder="Type to Search" required />
        )}
      />

      <ButtonGroup className={classes.buttonGroup}>
        <Button color="primary" startIcon={<FilterListIcon />} variant="outlined" onClick={openFilter}>
          Filter
        </Button>
        {isViewRegisterButtonVisible && (
          <Button color="primary" startIcon={<LinkIcon />} variant="outlined" onClick={goToRegister}>
            View Register
          </Button>
        )}
      </ButtonGroup>
    </Card>
  );
}

ImportFeedFilterCard.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  openFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

// import React from 'react';
// import PropTypes from 'prop-types';
// import Grid from '@material-ui/core/Grid';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import LinkIcon from '@material-ui/icons/Link';

// import { ButtonGroup } from '@material-ui/core';
// import history from '../../history';
// import { PersonContext } from '../../contexts/PersonContext';

// import {
//   nameLabel,
//   getImportAccountOptions,
//   getImportedTransactionStatusOptions,
// } from '../../components/Autocomplete/Library';

// import { asyncHandleChange } from '../../functions/InputHandlers';

// import CardBase from '../../components/CardBase';

// class ImportFeedFilterCard extends React.PureComponent {
//   constructor(props) {
//     super(props);

//     this.state = {
//       loading: true,
//     };
//   }

//   async componentDidMount() {
//     const { filter } = this.props;
//     const initialState = { loading: false };

//     if (filter) {
//       initialState.filter = filter;
//     }

//     initialState.importAccountOptions = await getImportAccountOptions(this);

//     this.setState(initialState);
//   }

//   handleAutocompleteChange = (name) => async (event, value) => {
//     const { updateFilter } = this.props;
//     await asyncHandleChange(name, value, this);
//     await asyncHandleChange('nested_filter_page', 0, this);
//     const { filter } = this.state;
//     console.log('filter', filter);
//     updateFilter(filter);
//   };

//   goToRegister = () => {
//     const { filter } = this.props;

//     if (filter.accountIdSelect.type2 === 'Bank') {
//       history.push(`/accounts/banking/bank/${filter.accountIdSelect.id}`);
//       return;
//     }
//     history.push(`/accounts/banking/card/${filter.accountIdSelect.id}`);
//   };

//   render() {
//     const { openFilter, filter } = this.props;
//     const { loading, importAccountOptions } = this.state;

//     if (loading) {
//       return null;
//     }

//     return (
//       <Grid item xs={12}>
//         <CardBase>
//           <CardContent>
//             <Grid container spacing={4}>
//               <Grid item xs={filter.accountIdSelect.id !== 'All' ? 4 : 5}>
//                 <Autocomplete
//                   options={importAccountOptions}
//                   getOptionLabel={nameLabel}
//                   value={filter.accountIdSelect}
//                   onChange={this.handleAutocompleteChange('nested_filter_accountIdSelect')}
//                   disableClearable
//                   blurOnSelect
//                   getOptionSelected={(option, value) => option.id === value.id}
//                   renderInput={(params) => (
//                     <TextField
//                       {
//                         ...params /* eslint-disable-line react/jsx-props-no-spreading */
//                       }
//                       label="Import Account"
//                       placeholder="Type to Search"
//                       required
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={filter.accountIdSelect.id !== 'All' ? 4 : 5}>
//                 <Autocomplete
//                   options={getImportedTransactionStatusOptions}
//                   getOptionLabel={nameLabel}
//                   value={filter.transactionStatusSelect}
//                   onChange={this.handleAutocompleteChange('nested_filter_transactionStatusSelect')}
//                   disableClearable
//                   blurOnSelect
//                   getOptionSelected={(option, value) => option.id === value.id}
//                   renderInput={(params) => (
//                     <TextField
//                       {
//                         ...params /* eslint-disable-line react/jsx-props-no-spreading */
//                       }
//                       label="Transaction Status"
//                       placeholder="Type to Search"
//                       required
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item container xs={filter.accountIdSelect.id !== 'All' ? 4 : 2} alignItems="flex-end">
//                 <ButtonGroup fullWidth>
//                   <Button
//                     variant="outlined"
//                     color="primary"
//                     fullWidth
//                     onClick={openFilter}
//                     startIcon={<FilterListIcon />}
//                   >
//                     Filter
//                   </Button>
//                   {filter.accountIdSelect && filter.accountIdSelect.id !== 'All' && (
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       fullWidth
//                       onClick={this.goToRegister}
//                       startIcon={<LinkIcon />}
//                     >
//                       View Register
//                     </Button>
//                   )}
//                 </ButtonGroup>
//               </Grid>
//             </Grid>
//           </CardContent>
//         </CardBase>
//       </Grid>
//     );
//   }
// }

// ImportFeedFilterCard.contextType = PersonContext;

// ImportFeedFilterCard.propTypes = {
//   openFilter: PropTypes.func.isRequired,
//   updateFilter: PropTypes.func.isRequired,
//   filter: PropTypes.objectOf(PropTypes.any).isRequired,
// };

// export default ImportFeedFilterCard;
