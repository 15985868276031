import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { create, find, get, patch, patchMultiple, remove } from '../feathersWrapper';
import {
  handleAutocompleteChange,
  handleCheckboxChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleTextFieldChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';
import { setInitialJournalScope, setJournalScope } from '../functions/JournalFunctions';

import {
  getAccountOptions,
  getLoanAccountTypeOptions,
  getPaymentAccountOptions,
  nameLabel,
} from './Autocomplete/Library';
import TransactionScope from './TransactionScope';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
  labelContainer: {
    [theme.breakpoints.down('md')]: {
      // overflow: 'hidden',
      // width: '100%',
      // display: 'block',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
    },
  },
  label: {
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      width: '100%',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
});

class EditLoanDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getAccount = async () => {
    const { accountId } = this.props;
    const { organizationId } = this.context;
    const account = await get(this, 'accounts', accountId);

    account.mortgageInterestRatePercent =
      account.mortgageInterestRate !== null ? account.mortgageInterestRate * 100 : null;
    account.mortgagePaymentAccountIdSelect = account.mortgagePaymentAccountId
      ? {
          name: account.mortgagePaymentAccount.name,
          value: account.mortgagePaymentAccountId,
        }
      : null;

    account.hasEscrow = account.mortgageEscrowAccountId !== null;
    account.setUpPaymentTemplate = true;

    const journal = {
      propertyId: account.propertyId,
      unitId: account.unitId,
      entityId: account.entityId,
    };
    await setInitialJournalScope(journal, this);

    if (
      account.mortgagePaymentAmount === null ||
      account.lastCurrentBalance === null ||
      account.mortgageInterestRate === null
    ) {
      account.setUpPaymentTemplate = false;
    }
    if (account.mortgageEscrowAccountId && account.mortgageEscrowTransferAmount === null) {
      account.setUpPaymentTemplate = false;
    }

    const query = { organizationId, accountId, $limit: 0 };
    const journalLinesResponse = await find(this, 'journal-lines', { query });

    account.loanType = getLoanAccountTypeOptions.find((type) => type.id === account.type2);

    const paymentAccountOptions = await getPaymentAccountOptions(this);
    const mergeAccountOptions = await getAccountOptions(this, {
      id: { $ne: account.id },
      type: ['Liability', 'Equity'],
    });

    this.setState({
      account,
      lastCurrentBalance: null,
      lastCurrentBalanceDate: moment(),
      updateCurrentBalance: account.lastCurrentBalance === null,
      error: null,
      loading: false,
      step: 'editLoan',
      submitting: false,
      totalTransactions: journalLinesResponse.total,
      newAccountIdSelect: null,
      journal,
      paymentAccountOptions,
      mergeAccountOptions,
    });
  };

  editAccount = async (event) => {
    event.preventDefault();
    const { submitting, account, lastCurrentBalance, lastCurrentBalanceDate, updateCurrentBalance, journal } =
      this.state;

    const { onEditAccount, accountId } = this.props;

    const { organizationId } = this.context;

    if (account.setUpPaymentTemplate) {
      if (account.mortgageEscrowTransferAmount >= account.mortgagePaymentAmount) {
        this.setState({
          error: { message: 'The escrow portion of the mortgage payment should be less than the total payment amount' },
        });
        return;
      }
    }

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    setJournalScope(journal);

    const accountSubmit = { ...account };
    accountSubmit.propertyId = journal.propertyId;
    accountSubmit.entityId = journal.entityId;
    accountSubmit.unitId = journal.unitId;

    accountSubmit.type2 = account.loanType.id;
    if (updateCurrentBalance) {
      accountSubmit.lastCurrentBalance = lastCurrentBalance;
      accountSubmit.lastCurrentBalanceDate = lastCurrentBalanceDate;
    } else {
      delete accountSubmit.lastCurrentBalance;
      delete accountSubmit.lastCurrentBalanceDate;
    }

    if (!account.setUpPaymentTemplate) {
      delete accountSubmit.mortgageInterestRatePecent;
      accountSubmit.mortgagePaymentAmount = null;
      accountSubmit.mortgagePaymentAccountId = null;
      accountSubmit.mortgageEscrowTransferAmount = null;
      accountSubmit.mortgagePaymentAccountId = null;
    } else {
      accountSubmit.mortgageInterestRate = account.mortgageInterestRatePercent / 100;
      accountSubmit.mortgageEscrowTransferAmount = account.hasEscrow ? account.mortgageEscrowTransferAmount : null;
      accountSubmit.mortgagePaymentAccountId = account.mortgagePaymentAccountIdSelect
        ? account.mortgagePaymentAccountIdSelect.id
        : null;
    }

    if (account.hasEscrow && !account.mortgageEscrowAccountId) {
      await create(
        this,
        'accounts',
        {
          organizationId,
          name: `Escrow for ${accountSubmit.name}`,
          type: 'Asset',
          type2: 'Escrow',
          propertyId: accountSubmit.propertyId,
          unitId: accountSubmit.unitId,
          entityId: accountSubmit.entityId,
        },
        true,
      )
        .then((result) => {
          accountSubmit.mortgageEscrowAccountId = result.id;
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    } else if (!account.hasEscrow && account.mortgageEscrowAccountId) {
      const escrowTransactionQuery = {
        organizationId: account.organizationId,
        accountId: account.mortgageEscrowAccountId,
      };
      const escrowJournalLinesResponse = await find(this, 'journal-lines', { query: escrowTransactionQuery });

      if (escrowJournalLinesResponse.total > 0) {
        await patch(this, 'accounts', account.mortgageEscrowAccountId, { type2: null });
      } else {
        await remove(this, 'accounts', account.mortgageEscrowAccountId);
      }
      accountSubmit.mortgageEscrowAccountId = null;
    }

    if (accountSubmit.inactive) {
      if (accountSubmit.yodleeAccountId) {
        await remove(this, 'yodlee-accounts', accountSubmit.yodleeAccountId);
        accountSubmit.yodleeAccountId = null;
      }
      if (accountSubmit.plaidAccountId) {
        await remove(this, 'plaid-accounts', accountSubmit.plaidAccountId);
        accountSubmit.plaidAccountId = null;
      }
    }

    await patch(this, 'accounts', accountId, accountSubmit, true)
      .then(async () => {
        onEditAccount();
        if (account.setUpPaymentTemplate && account.autobookMatchingTransactions) {
          const automationResult = await create(this, 'automations', {
            organizationId,
            automationName: 'bookLoanPayments',
            loanAccountId: accountId,
          });
          if (automationResult.newTransactions) {
            this.setState({
              step: 'autobook',
              autobookedTransactionCount: automationResult.newTransactions,
            });
          } else {
            this.closeDialog();
          }
        } else {
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  unlinkAccount = async (event) => {
    event.preventDefault();
    const { account, submitting } = this.state;
    const { onEditAccount, accountId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    await patch(
      this,
      'accounts',
      accountId,
      {
        yodleeAccountId: null,
        plaidAccountId: null,
      },
      true,
    ).catch((error) => {
      this.setState({ error });
      this.setState({ submitting: false });
    });

    if (account.yodleeAccountId) {
      await remove(this, 'yodlee-accounts', account.yodleeAccountId);
    }

    if (account.plaidAccountId) {
      await remove(this, 'plaid-accounts', account.plaidAccountId);
    }

    onEditAccount();
    this.closeDialog();
  };

  deleteAccount = async (event) => {
    event.preventDefault();
    const { account, totalTransactions, newAccountIdSelect, submitting } = this.state;
    const { onEditAccount, accountId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    if (account.yodleeAccountId) {
      await remove(this, 'yodlee-accounts', account.yodleeAccountId);
    }

    if (account.plaidAccountId) {
      await remove(this, 'plaid-accounts', account.plaidAccountId);
    }

    if (totalTransactions > 0) {
      await patchMultiple(
        this,
        'journal-lines',
        {
          query: {
            accountId,
            organizationId: account.organizationId,
          },
        },
        { accountId: newAccountIdSelect.id },
      );
    }

    if (account.mortgageEscrowAccountId) {
      const escrowTransactionQuery = {
        organizationId: account.organizationId,
        accountId: account.mortgageEscrowAccountId,
      };
      const escrowJournalLinesResponse = await find(this, 'journal-lines', { query: escrowTransactionQuery });

      if (escrowJournalLinesResponse.total === 0) {
        await remove(this, 'accounts', account.mortgageEscrowAccountId);
      }
    }

    remove(this, 'accounts', accountId, true)
      .then(() => {
        onEditAccount();
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  getDialogStepContent = () => {
    const {
      loading,
      step,
      account,
      totalTransactions,
      newAccountIdSelect,
      lastCurrentBalance,
      lastCurrentBalanceDate,
      updateCurrentBalance,
      autobookedTransactionCount,
      error,
      journal,
      mergeAccountOptions,
      paymentAccountOptions,
    } = this.state;
    const { classes } = this.props;

    if (loading) {
      // return <DialogTitle id="alert-dialog-title">Edit Loan</DialogTitle>;
      return null;
    }
    if (step === 'confirmDelete') {
      return (
        <form onSubmit={this.deleteAccount}>
          <DialogTitle id="alert-dialog-title">Really Delete Account?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">This account will be removed.</DialogContentText>
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" className={classes.deleteConfirmationButton}>
              Delete Account
            </Button>
          </DialogActions>
        </form>
      );
    }
    if (step === 'confirmMerge') {
      return (
        <form onSubmit={this.deleteAccount}>
          <DialogTitle id="alert-dialog-title">Really Merge and Delete Account?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Merging and deleting this account will modify ${totalTransactions}
              transactions currently booked to the account.
              To proceed, please select an account.
              Existing booked transaction lines assigned to the deleted account will be merged to the selected account.`}
            </DialogContentText>
            <Autocomplete
              options={mergeAccountOptions}
              getOptionLabel={nameLabel}
              value={newAccountIdSelect}
              onChange={handleAutocompleteChange('newAccountIdSelect', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Select Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" className={classes.deleteConfirmationButton}>
              Merge and Delete Account
            </Button>
          </DialogActions>
        </form>
      );
    }
    if (step === 'confirmUnlink') {
      return (
        <form onSubmit={this.unlinkAccount}>
          <DialogTitle id="alert-dialog-title">Really Unlink Account?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`This account will no longer sync balance information or transactions
                from your financial institution.`}
            </DialogContentText>
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" className={classes.deleteConfirmationButton}>
              Unlink Account
            </Button>
          </DialogActions>
        </form>
      );
    }
    if (step === 'autobook') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Loan Edited</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Your loan was updated. ${autobookedTransactionCount} transactions matched the payment template and were booked automatically.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
              Close
            </Button>
          </DialogActions>
        </>
      );
    }
    if (step === 'editLoan') {
      return (
        <form onSubmit={this.editAccount}>
          <DialogTitle id="alert-dialog-title">
            Edit Loan
            {(account.plaidAccountId || account.yodleeAccountId) && (
              <IconButton
                className={classes.deleteIconButton}
                aria-label="unlink"
                onClick={() => this.setState({ step: 'confirmUnlink' })}
              >
                <LinkOffIcon />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Loan Name"
              fullWidth
              required
              margin="dense"
              InputProps={{
                value: account.name,
                name: 'nested_account_name',
                onChange: handleTextFieldChange(this),
              }}
            />
            <Autocomplete
              options={getLoanAccountTypeOptions}
              getOptionLabel={nameLabel}
              value={account.loanType}
              onChange={handleAutocompleteChange('nested_account_loanType', this)}
              disableClearable
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan Type"
                  margin="dense"
                  required
                  helperText={
                    account.loanType && account.loanType.id === 'HELOC'
                      ? 'Set the loan scope (below) to indicate where the HELOC funds will be utilized, not the property used as collateral.'
                      : ''
                  }
                />
              )}
            />
            <TransactionScope
              journal={journal}
              transactionScopeChange={(newScopeValues) => {
                handleTransactionScopeChange(journal, newScopeValues, this);
              }}
              label="Loan"
            />
            <FormControl margin="none" fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={account.hasEscrow}
                    onChange={handleCheckboxChange(this)}
                    name="nested_account_hasEscrow"
                  />
                }
                label="This loan includes an escrow account"
              />
            </FormControl>
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <Typography variant="subtitle2" gutterBottom>
                Payment Template
              </Typography>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={account.setUpPaymentTemplate}
                      onChange={handleCheckboxChange(this)}
                      name="nested_account_setUpPaymentTemplate"
                    />
                  }
                  label={
                    account.loanType && account.loanType.id === 'Mortgage'
                      ? 'Set up loan payment template (strongly recommended)'
                      : 'Set up loan payment template'
                  }
                />
              </FormControl>
              {account.setUpPaymentTemplate && (
                <>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel required>Monthly Payment Amount</InputLabel>
                    <NumberFormat
                      value={account.mortgagePaymentAmount}
                      required
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('nested_account_mortgagePaymentAmount', this)}
                      customInput={Input}
                    />
                  </FormControl>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel required>Interest Rate</InputLabel>
                    <NumberFormat
                      value={account.mortgageInterestRatePercent}
                      required
                      suffix="%"
                      decimalScale={3}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('nested_account_mortgageInterestRatePercent', this)}
                      customInput={Input}
                    />
                  </FormControl>
                  {account.hasEscrow && (
                    <FormControl margin="dense" fullWidth className={classes.labelContainer}>
                      <InputLabel required className={classes.label}>
                        Monthly Escrow Transfer Amount
                      </InputLabel>
                      <NumberFormat
                        value={account.mortgageEscrowTransferAmount}
                        required
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={handleNumberFormatChange('nested_account_mortgageEscrowTransferAmount', this)}
                        customInput={Input}
                      />
                    </FormControl>
                  )}
                  <Autocomplete
                    options={paymentAccountOptions}
                    getOptionLabel={nameLabel}
                    value={account.mortgagePaymentAccountIdSelect}
                    onChange={handleAutocompleteChange('nested_account_mortgagePaymentAccountIdSelect', this)}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        label="Payment Account (Optional)"
                        placeholder="Type to Search"
                        fullWidth
                      />
                    )}
                  />
                  <FormControl margin="none" fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={account.autobookMatchingTransactions}
                          onChange={handleCheckboxChange(this)}
                          name="nested_account_autobookMatchingTransactions"
                        />
                      }
                      label="Automatically book matching loan payments"
                    />
                  </FormControl>
                </>
              )}
              <Box marginTop={1}>
                <Typography variant="body2">
                  {'About the Payment Template: Completing the payment template allows REI Hub to recognize loan payments in your transaction import feed ' +
                    'and automatically split payments into their interest, principal, and escrow components each month.'}
                </Typography>
              </Box>
            </Box>
            <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
              <Typography variant="subtitle2" gutterBottom>
                Loan Balance
              </Typography>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updateCurrentBalance}
                      onChange={handleCheckboxChange(this)}
                      name="updateCurrentBalance"
                    />
                  }
                  label="Update current loan balance"
                />
              </FormControl>
              {updateCurrentBalance && (
                <>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel required>Current Loan Balance</InputLabel>
                    <NumberFormat
                      value={lastCurrentBalance}
                      required
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('lastCurrentBalance', this)}
                      customInput={Input}
                    />
                  </FormControl>
                  <KeyboardDatePicker
                    label="Date of Current Loan Balance"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={lastCurrentBalanceDate}
                    onChange={handleKeyboardDatePickerChange('lastCurrentBalanceDate', this)}
                    margin="dense"
                    fullWidth
                    clearable
                    required
                  />
                </>
              )}
              <Box marginTop={1}>
                <Typography variant="body2">
                  {'Last Verified Balance: '}
                  <NumberFormat
                    displayType="text"
                    value={account.lastCurrentBalance}
                    thousandSeparator
                    prefix="$"
                    suffix={` on ${moment(account.lastCurrentBalanceDate).format('M/D/YYYY')}`}
                    decimalScale={0}
                    fixedDecimalScale
                  />
                </Typography>
              </Box>
            </Box>
            <FormControl margin="none" fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={account.inactive}
                    onChange={handleCheckboxChange(this)}
                    name="nested_account_inactive"
                  />
                }
                label="This account is inactive"
              />
              {account.inactive && (account.yodleeAccountId || account.plaidAccountId) && (
                <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                  <Typography variant="body2">This account will be unlinked before being marked inactive</Typography>
                </Box>
              )}
            </FormControl>
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained" disableElevation>
              Save Account
            </Button>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            {totalTransactions === 0 && (
              <Button
                onClick={() => this.setState({ step: 'confirmDelete' })}
                className={classes.deleteConfirmationButton}
                color="primary"
              >
                Delete
              </Button>
            )}
            {totalTransactions > 0 && (
              <Button
                onClick={() => this.setState({ step: 'confirmMerge' })}
                className={classes.deleteConfirmationButton}
                color="primary"
              >
                Merge
              </Button>
            )}
          </DialogActions>
        </form>
      );
    }
    return null;
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.getAccount}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editAccountDialog"
      >
        {this.getDialogStepContent()}
      </Dialog>
    );
  }
}

EditLoanDialog.contextType = PersonContext;

EditLoanDialog.defaultProps = {
  accountId: null,
};

EditLoanDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditAccount: PropTypes.func.isRequired,
  accountId: PropTypes.node,
};

export default withStyles(styles)(EditLoanDialog);
