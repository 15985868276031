import authentication from '@feathersjs/authentication-client';
import { feathers } from '@feathersjs/feathers';
import feathersSocketClient from '@feathersjs/socketio-client';
import socketClient from 'socket.io-client';

const client = feathers();

const socket = socketClient(import.meta.env.VITE_FEATHERS_SOCKET, {
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

client.configure(
  feathersSocketClient(socket, {
    timeout: 10000,
  }),
);

client.configure(
  authentication({
    storage: window.localStorage,
  }),
);

client.service('yodlee-accounts').timeout = 30000;
client.service('authManagement').timeout = 20000;

export default client;
