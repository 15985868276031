import React from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { find, get, patch, patchMultiple, remove } from '../feathersWrapper';
import { handleCheckboxChange, handleSearchSelectChange, handleTextFieldChange } from '../functions/InputHandlers';

import { searchAccounts } from './SearchSelect/SearchFunctions';
import SearchSelect from './SearchSelect/SearchSelect';
import DialogTemplate from './DialogTemplate';
import EditBankingAccountDialog from './EditBankingAccountDialog';
import EditLoanDialog from './EditLoanDialog';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
});

class EditAccountDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.getAccount();
  }

  getAccount = async () => {
    const { accountId } = this.props;
    const { organizationId } = this.context;
    const account = await get(this, 'accounts', accountId);

    const query = { organizationId, accountId, $limit: 0 };
    const journalLinesResponse = await find(this, 'journal-lines', { query });

    this.setState({
      account,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
      confirmMerge: false,
      totalTransactions: journalLinesResponse.total,
      newAccountId: null,
      newAccountIdSelect: null,
    });
  };

  editAccount = async (event) => {
    event.preventDefault();
    const { submitting, account } = this.state;

    const { onEditAccount, closeDialog, accountId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const accountSubmit = {
      name: account.name,
      inactive: account.inactive,
    };

    patch(this, 'accounts', accountId, accountSubmit)
      .then(() => {
        onEditAccount();
        closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteAccount = async (event) => {
    event.preventDefault();
    const { account, totalTransactions, newAccountId, submitting } = this.state;
    const { onEditAccount, closeDialog, accountId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    if (account.yodleeAccountId) {
      await remove(this, 'yodlee-accounts', account.yodleeAccountId);
    }

    if (account.plaidAccountId) {
      await remove(this, 'plaid-accounts', account.plaidAccountId);
    }

    if (totalTransactions > 0) {
      await patchMultiple(
        this,
        'journal-lines',
        {
          query: {
            accountId,
            organizationId: account.organizationId,
          },
        },
        { accountId: newAccountId },
      );
    }

    remove(this, 'accounts', accountId)
      .then(() => {
        onEditAccount();
        closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, closeDialog, onEditAccount } = this.props;
    const { loading, error, account, confirmDelete, confirmMerge, totalTransactions, newAccountIdSelect } = this.state;

    if (loading) {
      return null;
    }

    if (['Bank', 'Credit Card'].includes(account.type2)) {
      return (
        <EditBankingAccountDialog
          accountId={account.id}
          isOpen
          closeDialog={closeDialog}
          onEditAccount={onEditAccount}
        />
      );
    }

    if (['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(account.type2)) {
      return <EditLoanDialog accountId={account.id} isOpen closeDialog={closeDialog} onEditAccount={onEditAccount} />;
    }

    return (
      <DialogTemplate
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editAccountDialog"
      >
        {!loading && !confirmDelete && !confirmMerge && (
          <form onSubmit={this.editAccount}>
            <DialogTitle id="alert-dialog-title">Edit Account</DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                required
                margin="dense"
                disabled={account.default}
                InputProps={{
                  value: account.name,
                  name: 'nested_account_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
              <FormControl fullWidth margin="none">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={account.inactive}
                      onChange={handleCheckboxChange(this)}
                      name="nested_account_inactive"
                      color="primary"
                    />
                  }
                  label="Account is Inactive"
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Account
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              {totalTransactions === 0 && !account.default && (
                <Button
                  onClick={() => this.setState({ confirmDelete: true })}
                  className={classes.deleteConfirmationButton}
                  color="primary"
                >
                  Delete
                </Button>
              )}
              {totalTransactions > 0 && !account.default && (
                <Button
                  onClick={() => this.setState({ confirmMerge: true })}
                  className={classes.deleteConfirmationButton}
                  color="primary"
                >
                  Merge
                </Button>
              )}
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <form onSubmit={this.deleteAccount}>
            <DialogTitle id="alert-dialog-title">Really Delete Account?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">This account will be removed.</DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" className={classes.deleteConfirmationButton}>
                Delete Account
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmMerge && (
          <form onSubmit={this.deleteAccount}>
            <DialogTitle id="alert-dialog-title">Really Merge and Delete Account?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Merging and deleting this account will modify ${totalTransactions}
                transactions currently booked to the account.
                To proceed, please select an account.
                Existing booked transaction lines assigned to the deleted account will be merged to the selected account.`}
              </DialogContentText>
              <SearchSelect
                searchFunction={searchAccounts(this, { id: { $ne: account.id } })}
                changeFunction={handleSearchSelectChange(this)}
                label="Select Account"
                name="newAccountId"
                isMulti={false}
                required
                value={newAccountIdSelect}
                margin="dense"
                target={document.getElementById('editAccountDialog')}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" className={classes.deleteConfirmationButton}>
                Merge and Delete Account
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogTemplate>
    );
  }
}

EditAccountDialog.contextType = PersonContext;

EditAccountDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditAccount: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
};

export default withStyles(styles)(EditAccountDialog);
