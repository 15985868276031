import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import cx from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  autocompleteField: {
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function AutocompleteField({
  className,
  label,
  options,
  required,
  value,
  onChange,
  getOptionLabel,
  placeholder,
  textFieldVariant,
  disableShrink,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Autocomplete
      {...rest}
      className={cx(classes.autocompleteField, className)}
      disableClearable
      options={options}
      size="small"
      value={value}
      variant="outlined"
      getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(_event, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          placeholder={placeholder || 'Select one'}
          required={required}
          variant={textFieldVariant || 'outlined'}
          {...(!disableShrink && {
            InputLabelProps: {
              shrink: true,
            },
          })}
        />
      )}
    />
  );
}

export const autocompleteFieldProps = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  value: PropTypes.object,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  textFieldVariant: PropTypes.string,
  disableShrink: PropTypes.bool,
};

AutocompleteField.propTypes = autocompleteFieldProps;
