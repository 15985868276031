import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';

import { Autocomplete } from '@material-ui/lab';
import { ButtonBase } from '@material-ui/core';
import { find, get, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
  asyncHandleChange,
  handleNumberFormatChange,
  handleKeyboardDatePickerChange,
  handleChange,
  handleUploaderChange,
  handleTransactionScopeChange,
  handleAutocompleteChange,
} from '../../functions/InputHandlers';

import { sumProperty } from '../../functions/SumFunctions';

import {
  getManualBasicJournal,
  getCreditJournalLine,
  getDebitJournalLine,
} from '../SearchSelect/TransactionTypeOptions';

import { setJournalScope, setInitialJournalScope } from '../../functions/JournalFunctions';

import TransactionScope from '../TransactionScope';
import {
  getAccountOptions,
  getFixedAssetOptions,
  nameLabel,
  nameAndAddressLabel,
  padSubaccounts,
  getGroupedAccountOptions,
  filterSubaccounts,
} from '../Autocomplete/Library';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddTransactionDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { organizationId, organizationName } = this.context;
    const { transactionType, assetId, unitId, propertyId } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      journal: null,
      error: null,
      displayNotesSection: false,
    };

    initialState.fixedAssetOptions = await getFixedAssetOptions(this);

    const accounts = await getAccountOptions(this);
    const groupedAccounts = await getGroupedAccountOptions(this);

    initialState.accountOptions = accounts;
    initialState.groupedAccountOptions = groupedAccounts;
    initialState.expenseAccountOptions = accounts.filter((account) => account.type === 'Expense');
    initialState.revenueAccountOptions = accounts.filter((account) => account.type === 'Revenue');
    initialState.transferAccountOptions = accounts.filter(
      (account) => account.type === 'Asset' || account.type === 'Liability' || account.type === 'Equity',
    );
    initialState.paymentAccountOptions = accounts.filter(
      (account) =>
        account.type2 === 'Bank' ||
        account.type2 === 'Credit Card' ||
        account.type2 === 'Property Management' ||
        account.type2 === 'Owner Funds',
    );
    initialState.creditCardAccountOptions = accounts.filter((account) => account.type2 === 'Credit Card');
    initialState.creditCardPaymentAccountOptions = accounts.filter(
      (account) => account.type2 === 'Bank' || account.type2 === 'Owner Funds',
    );
    initialState.depositAccountOptions = accounts.filter(
      (account) =>
        account.type2 === 'Bank' || account.type2 === 'Property Management' || account.type2 === 'Owner Funds',
    );
    initialState.equityAccountOptions = accounts.filter(
      (account) => account.type === 'Equity' && account.default !== 'autoBalance',
    );
    initialState.depositAccountOptions = accounts.filter(
      (account) => account.type2 === 'Bank' || account.type2 === 'Property Management',
    );
    initialState.escrowAccountOptions = accounts.filter((account) => account.type2 === 'Escrow');

    const journal = getManualBasicJournal();
    if (
      [
        'Distribution',
        'Contribution',
        'Escrow Expense',
        'Refund Issued',
        'Transfer',
        'Manual Journal',
        'Credit Card Payment',
      ].includes(transactionType)
    ) {
      journal.type = transactionType;
    }
    if (transactionType === 'securityDepositReceived') {
      journal.type = 'Security Deposit';
      const securityDepositAccountData = await find(this, 'accounts', {
        query: { organizationId, default: 'securityDeposits' },
      });
      // eslint-disable-next-line prefer-destructuring
      journal.creditLines[0].accountIdSelect = securityDepositAccountData.data[0];
    }
    if (transactionType === 'securityDepositRefunded') {
      journal.type = 'Security Deposit Out';
      const securityDepositAccountData = await find(this, 'accounts', {
        query: { organizationId, default: 'securityDeposits' },
      });
      // eslint-disable-next-line prefer-destructuring
      journal.debitLines[0].accountIdSelect = securityDepositAccountData.data[0];
    }
    if (transactionType === 'securityDepositRetained') {
      journal.type = 'Security Deposit Out';
      const securityDepositAccountData = await find(this, 'accounts', {
        query: { organizationId, default: 'securityDeposits' },
      });
      const securityDepositRevenueAccountData = await find(this, 'accounts', {
        query: { organizationId, default: 'securityDepositsRetainedRevenue' },
      });
      // eslint-disable-next-line prefer-destructuring
      journal.debitLines[0].accountIdSelect = securityDepositAccountData.data[0];
      // eslint-disable-next-line prefer-destructuring
      journal.creditLines[0].accountIdSelect = securityDepositRevenueAccountData.data[0];
    }

    if (
      ['Distribution', 'Contribution', 'Transfer', 'Manual Journal', 'Credit Card Payment'].includes(transactionType)
    ) {
      journal.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };
    }

    if (
      [
        'Escrow Expense',
        'Refund Issued',
        'securityDepositReceived',
        'securityDepositRetained',
        'securityDepositRefunded',
      ].includes(transactionType)
    ) {
      journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    }

    if (assetId) {
      const asset = await get(this, 'fixed-assets', assetId);
      journal.fixedAssetId = asset.id;
      journal.fixedAssetIdSelect = asset;
      // journal.unitId = asset.unitId;
      journal.propertyId = asset.propertyId;
      journal.entityId = asset.entityId;
      await setInitialJournalScope(journal, this);
    }

    if (unitId) {
      const unit = await get(this, 'units', unitId);
      journal.propertyIdSelect = unit.property;
      journal.unitIdSelect = unit;
    } else if (propertyId) {
      const property = await get(this, 'properties', propertyId);
      journal.propertyIdSelect = property;
    }

    initialState.journal = journal;

    this.setState(initialState);
  };

  addDebitLine = () => {
    const { journal } = this.state;
    handleChange('nested_journal_debitLines', journal.debitLines.concat([getDebitJournalLine()]), this);
  };

  addCreditLine = () => {
    const { journal } = this.state;
    handleChange('nested_journal_creditLines', journal.creditLines.concat([getCreditJournalLine()]), this);
  };

  deleteDebitLine = (index) => {
    const { journal } = this.state;
    journal.debitLines.splice(index, 1);
    handleChange('nested_journal_debitLines', journal.debitLines, this);
  };

  deleteCreditLine = (index) => {
    const { journal } = this.state;
    journal.creditLines.splice(index, 1);
    handleChange('nested_journal_creditLines', journal.creditLines, this);
  };

  handleAmountChange = async (values) => {
    if (typeof values.floatValue === 'undefined') {
      await asyncHandleChange('nested_journal_debitLines_0_debit', null, this);
      await asyncHandleChange('nested_journal_creditLines_0_credit', null, this);
    } else {
      await asyncHandleChange('nested_journal_debitLines_0_debit', values.floatValue, this);
      await asyncHandleChange('nested_journal_creditLines_0_credit', values.floatValue, this);
    }
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const { submitting, journal } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'The credit and debit amounts for a journal must be equal' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    setJournalScope(journal);

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    journal.journalLines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.accountId = line.accountIdSelect.id;
    });

    journal.fixedAssetId = journal.fixedAssetIdSelect ? journal.fixedAssetIdSelect.id : null;

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, transactionType, transactionTypeLabel } = this.props;
    const {
      loading,
      error,
      journal,
      displayNotesSection,
      revenueAccountOptions,
      expenseAccountOptions,
      paymentAccountOptions,
      transferAccountOptions,
      creditCardAccountOptions,
      creditCardPaymentAccountOptions,
      equityAccountOptions,
      depositAccountOptions,
      escrowAccountOptions,
      fixedAssetOptions,
      groupedAccountOptions,
    } = this.state;

    if (loading || journal === null) {
      return null;
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">{`Add ${transactionTypeLabel}`}</DialogTitle>
        <DialogContent>
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          {[
            'Distribution',
            'Contribution',
            'Escrow Expense',
            'Security Deposit',
            'Security Deposit Out',
            'Refund Issued',
            'Transfer',
            'Credit Card Payment',
          ].includes(journal.type) && (
            <FormControl margin="dense" fullWidth>
              <InputLabel required>Amount</InputLabel>
              <NumberFormat
                value={journal.debitLines[0].debit}
                required
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                onValueChange={this.handleAmountChange}
                customInput={Input}
              />
            </FormControl>
          )}
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          {journal.type === 'Refund Issued' && (
            <>
              <Autocomplete
                options={revenueAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Revenue Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
              <Autocomplete
                options={paymentAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Payment Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
            </>
          )}
          {journal.type === 'Transfer' && (
            <>
              <Autocomplete
                options={transferAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Transferred From Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
              <Autocomplete
                options={transferAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Transferred To Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
            </>
          )}
          {journal.type === 'Credit Card Payment' && (
            <>
              <Autocomplete
                options={creditCardAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Credit Card Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
              <Autocomplete
                options={creditCardPaymentAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Paid From Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
            </>
          )}
          {journal.type === 'Distribution' && (
            <>
              <Autocomplete
                options={equityAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Equity Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
              <Autocomplete
                options={depositAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Distributed From Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
            </>
          )}
          {journal.type === 'Contribution' && (
            <>
              <Autocomplete
                options={equityAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Equity Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
              <Autocomplete
                options={depositAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Contributed To Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
            </>
          )}
          {journal.type === 'Security Deposit' && (
            <Autocomplete
              options={depositAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.debitLines[0].accountIdSelect}
              onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {
                    ...params /* eslint-disable-line react/jsx-props-no-spreading */
                  }
                  label="Deposited To Account"
                  margin="dense"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          )}
          {journal.type === 'Security Deposit Out' && transactionType === 'securityDepositRefunded' && (
            <Autocomplete
              options={paymentAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.creditLines[0].accountIdSelect}
              onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {
                    ...params /* eslint-disable-line react/jsx-props-no-spreading */
                  }
                  label="Payment Account"
                  margin="dense"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          )}
          {journal.type === 'Escrow Expense' && (
            <>
              <Autocomplete
                options={expenseAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Expense Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
              <Autocomplete
                options={escrowAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Escrow Account"
                    margin="dense"
                    placeholder="Type to Search"
                    required
                  />
                )}
              />
            </>
          )}
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          {journal.type === 'Manual Journal' && (
            <>
              <Autocomplete
                options={fixedAssetOptions}
                getOptionLabel={nameAndAddressLabel}
                value={journal.fixedAssetIdSelect}
                onChange={handleAutocompleteChange('nested_journal_fixedAssetIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Fixed Asset (optional)"
                    margin="dense"
                    placeholder="Type to Search"
                  />
                )}
              />
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                // bgcolor="common.white"
                padding={2}
                marginY={2}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">Debits</Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {journal.debitLines.map((journalLine, index) => (
                      <TableRow key={journalLine.key}>
                        <TableCell>
                          <Autocomplete
                            groupBy={(option) => option.type}
                            options={groupedAccountOptions}
                            getOptionLabel={nameLabel}
                            renderOption={padSubaccounts}
                            filterOptions={filterSubaccounts}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={journalLine.accountIdSelect}
                            onChange={handleAutocompleteChange(
                              `nested_journal_debitLines_${index}_accountIdSelect`,
                              this,
                            )}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                margin="dense"
                                label="Account"
                                placeholder="Type to search"
                                fullWidth
                                required
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell className={classes.amountCell}>
                          <FormControl margin="dense" fullWidth>
                            <InputLabel required>Amount</InputLabel>
                            <NumberFormat
                              value={journalLine.debit}
                              thousandSeparator
                              required
                              prefix="$"
                              decimalScale={2}
                              onValueChange={handleNumberFormatChange(`nested_journal_debitLines_${index}_debit`, this)}
                              customInput={Input}
                              endAdornment={
                                journal.debitLines.length > 1 && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      color="primary"
                                      size="small"
                                      onClick={() => {
                                        this.deleteDebitLine(index);
                                      }}
                                    >
                                      <ClearIcon style={{ fontSize: 18 }} />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <Button onClick={this.addDebitLine} color="primary">
                          Add Line
                        </Button>
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          displayType="text"
                          value={sumProperty(journal.debitLines, 'debit')}
                          thousandSeparator
                          prefix="Total: $"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                // bgcolor="common.white"
                padding={2}
                marginY={2}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">Credits</Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {journal.creditLines.map((journalLine, index) => (
                      <TableRow key={journalLine.key}>
                        <TableCell>
                          <Autocomplete
                            groupBy={(option) => option.type}
                            options={groupedAccountOptions}
                            getOptionLabel={nameLabel}
                            renderOption={padSubaccounts}
                            filterOptions={filterSubaccounts}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={journalLine.accountIdSelect}
                            onChange={handleAutocompleteChange(
                              `nested_journal_creditLines_${index}_accountIdSelect`,
                              this,
                            )}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                margin="dense"
                                label="Account"
                                placeholder="Type to search"
                                fullWidth
                                required
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell className={classes.amountCell}>
                          <FormControl margin="dense" fullWidth>
                            <InputLabel required>Amount</InputLabel>
                            <NumberFormat
                              value={journalLine.credit}
                              thousandSeparator
                              required
                              prefix="$"
                              decimalScale={2}
                              onValueChange={handleNumberFormatChange(
                                `nested_journal_creditLines_${index}_credit`,
                                this,
                              )}
                              customInput={Input}
                              endAdornment={
                                journal.creditLines.length > 1 && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      color="primary"
                                      size="small"
                                      onClick={() => {
                                        this.deleteCreditLine(index);
                                      }}
                                    >
                                      <ClearIcon style={{ fontSize: 18 }} />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <Button onClick={this.addCreditLine} color="primary">
                          Add Line
                        </Button>
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          displayType="text"
                          value={sumProperty(journal.creditLines, 'credit')}
                          thousandSeparator
                          prefix="Total: $"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            </>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Transaction
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddTransactionDialogContent.contextType = PersonContext;

AddTransactionDialogContent.defaultProps = {
  unitId: null,
  propertyId: null,
  assetId: null,
};

AddTransactionDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  transactionType: PropTypes.string.isRequired,
  transactionTypeLabel: PropTypes.string.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  assetId: PropTypes.node,
};

export default withStyles(styles)(AddTransactionDialogContent);
