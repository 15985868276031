import React, { useContext, useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { nameLabel } from '~/components/Autocomplete/Library';
import Button from '~/components/Button';
import DialogTemplate from '~/components/DialogTemplate';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import TransactionScope from '~/components/TransactionScope';
import { PersonContext } from '~/contexts/PersonContext';
import { patchMultiple, removeMultiple } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

export default function BatchChangeImportedTransactionDialog({
  closeDialog,
  transactions,
  fullTransactions,
  onBatchChangeTransactions,
  batchType,
  screen,
}) {
  const TRANSACTION_COUNT = transactions.length;
  const ONE_TRANSACTION = TRANSACTION_COUNT === 1;
  const { organizationId } = useContext(PersonContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [journal, setJournal] = useState({
    journalScopeSelect: null,
    entityIdSelect: null,
    propertyId: null,
    propertyIdSelect: null,
    unitId: null,
    unitIdSelect: null,
  });
  const [category, setCategory] = useState({});
  const [accountOptions, setAccountOptions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if (screen === 'Expense') {
        setAccountOptions(
          await getAccountOptions(organizationId, { $or: [{ type: 'Expense' }, { type2: 'Sales and Use Tax' }] }),
        );
      } else if (screen === 'Revenue') {
        setAccountOptions(
          await getAccountOptions(organizationId, { $or: [{ type: 'Revenue' }, { type2: 'Deferred Revenue' }] }),
        );
      }
      setIsLoading(false);
    };
    getData();
  }, [organizationId, screen]);

  const handleIgnoreUnignoreDelete = async () => {
    let data = {};
    if (batchType.id === 'Ignore') {
      data = { ignored: true };
    }
    if (batchType.id === 'Unignore') {
      data = { ignored: false };
    }
    if (batchType.id === 'Delete') {
      data = { deleted: true };
    }
    return patchMultiple('yodlee-transactions', { query: { organizationId, id: transactions } }, data);
  };

  const handleUndo = async () => {
    await removeMultiple('journals', {
      query: { organizationId, id: fullTransactions.map((transaction) => transaction.journalId) },
    });
  };

  const handleJournalDelete = async () => {
    await removeMultiple('journals', { query: { organizationId, id: transactions } });
  };

  const handleEditScope = async () => {
    setJournalScope(journal);
    const data = {
      entityId: journal.entityId,
      propertyId: journal.propertyId,
      unitId: journal.unitId,
    };

    await patchMultiple('journals', { query: { organizationId, id: transactions } }, data);
  };

  const handleEditAccount = async () => {
    const batchChangeIds = fullTransactions.map((transaction) => transaction.accountLine.id);
    await patchMultiple(
      'journal-lines',
      { query: { organizationId, id: batchChangeIds } },
      { accountId: category.accountIdSelect.id },
    );
  };

  const batchChangeTransactions = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    let batchFunction;

    switch (batchType.id) {
      case 'Ignore':
      case 'Unignore':
      case 'Delete':
        batchFunction = handleIgnoreUnignoreDelete;
        break;
      case 'Undo':
      case 'Undo Bookings':
        batchFunction = handleUndo;
        break;
      case 'Journal Delete':
        batchFunction = handleJournalDelete;
        break;
      case 'Edit Scope':
        batchFunction = handleEditScope;
        break;
      case 'Edit Account':
        batchFunction = handleEditAccount;
        break;
      default:
        break;
    }

    try {
      await batchFunction();
      setIsSubmitting(false);
      closeDialog();
      onBatchChangeTransactions();
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
    }
  };

  const getDialogContent = () => {
    switch (batchType.id) {
      case 'Ignore':
      case 'Unignore':
      case 'Delete':
      case 'Journal Delete':
        return (
          <>
            <DialogTitle id="form-dialog-title">{`${batchType.id} Transactions`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Are you sure you want to ${batchType.id.toLowerCase()}
                ${ONE_TRANSACTION ? 'this' : 'these'}
                ${pluralize('transaction', TRANSACTION_COUNT, ONE_TRANSACTION ? null : true)}?
                ${batchType.id === 'Delete' || batchType.id === 'Journal Delete' ? 'This action cannot be undone.' : ''}`}
              </DialogContentText>
            </DialogContent>
          </>
        );
      case 'Undo':
      case 'Undo Bookings':
        return (
          <>
            <DialogTitle id="form-dialog-title">Really Undo Bookings?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Undoing ${ONE_TRANSACTION ? 'this' : 'these'} ${pluralize('transaction', TRANSACTION_COUNT, ONE_TRANSACTION ? null : true)}
                will delete the booked transaction and restore the imported transaction to unbooked status in your import feed.
                Are you sure you want to undo?`}
              </DialogContentText>
            </DialogContent>
          </>
        );
      case 'Edit Account':
        return (
          <>
            <DialogTitle id="form-dialog-title">Edit Account</DialogTitle>
            <DialogContent>
              <AutocompleteField
                options={accountOptions}
                getOptionLabel={nameLabel}
                value={category.accountIdSelect}
                onChange={(value) => setCategory({ ...category, accountIdSelect: value })}
                label={`${screen} Account`}
                placeholder="Type to Search"
                required
                textFieldVariant="standard"
                disableShrink
              />
            </DialogContent>
          </>
        );
      case 'Edit Scope':
        return (
          <>
            <DialogTitle id="form-dialog-title">Edit Scope</DialogTitle>
            <DialogContent>
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValue) => setJournal({ ...journal, ...newScopeValue })}
              />
            </DialogContent>
          </>
        );
    }
  };

  const getButtonLabel = () => {
    const pluralizedTransaction = pluralize('Transaction', TRANSACTION_COUNT);
    switch (batchType.id) {
      case 'Ignore':
      case 'Unignore':
      case 'Delete':
      case 'Undo':
        return `${batchType.id} ${pluralizedTransaction}`;
      case 'Edit Scope':
        return 'Confirm Scope Change';
      case 'Edit Account':
      default:
        return 'Confirm';
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <DialogTemplate open aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={batchChangeTransactions}>
        {getDialogContent()}
        {error && <Typography color="error">{error}</Typography>}
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            color={batchType.id === 'Delete' || batchType.id === 'Undo' ? 'error' : 'primary'}
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            {getButtonLabel()}
          </Button>
        </DialogActions>
      </form>
    </DialogTemplate>
  );
}

BatchChangeImportedTransactionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  fullTransactions: PropTypes.array.isRequired,
  onBatchChangeTransactions: PropTypes.func.isRequired,
  batchType: PropTypes.objectOf(PropTypes.string).isRequired,
  screen: PropTypes.string,
};
