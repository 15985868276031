import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { create } from '../feathersWrapper';
import { handleAutocompleteChange, handleCheckboxChange, handleTextFieldChange } from '../functions/InputHandlers';

import {
  getAccountOptions,
  getAccountTypeOptions,
  getAssetType2Options,
  getFilteredAccountOptions,
  getLiabilityType2Options,
  getRevenueType2Options,
  nameLabel,
} from './Autocomplete/Library';
import LinkBase from './LinkBase';

class AddAccountDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true, error: null };
  }

  setInitialState = async () => {
    const initialState = {
      loading: false,
      submitting: false,
      account: {
        name: '',
        typeSelect: null,
        isSubaccount: true,
        assetType2Select: null,
        liabilityType2Select: null,
        revenueType2Select: null,
        parentAccountIdSelect: null,
      },
      error: null,
    };

    initialState.accountOptions = await getAccountOptions(this, { parentAccountId: null });
    this.setState(initialState);
  };

  saveAccount = async (event) => {
    event.preventDefault();
    const { submitting, account } = this.state;
    const { onAddAccount, closeDialog } = this.props;
    const { organizationId, basis, demo } = this.context;

    if (demo) {
      this.setState({ error: { message: 'You cannot save changes in the demo portfolio' } });
      return;
    }

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });
    const accountSubmit = { ...account };

    if (accountSubmit.parentAccountIdSelect) {
      accountSubmit.parentAccountId = accountSubmit.parentAccountIdSelect.id;
      accountSubmit.type = accountSubmit.parentAccountIdSelect.type;
      accountSubmit.type2 = accountSubmit.parentAccountIdSelect.type2;
      accountSubmit.nonDepreciable = accountSubmit.parentAccountIdSelect.nonDepreciable;
    } else {
      accountSubmit.type = accountSubmit.typeSelect.id;
      if (accountSubmit.type === 'Asset' && accountSubmit.assetType2Select.id !== 'Other') {
        accountSubmit.type2 = accountSubmit.assetType2Select.id;
        if (basis === 'cash' && accountSubmit.assetType2Select.cashType) {
          accountSubmit.type = accountSubmit.assetType2Select.cashType;
        }
      } else if (accountSubmit.type === 'Liability' && accountSubmit.liabilityType2Select.id !== 'Other') {
        accountSubmit.type2 = accountSubmit.liabilityType2Select.id;
        if (basis === 'cash' && accountSubmit.liabilityType2Select.cashType) {
          accountSubmit.type = accountSubmit.liabilityType2Select.cashType;
        }
      } else if (accountSubmit.type === 'Revenue') {
        accountSubmit.type2 = accountSubmit.revenueType2Select.id;
      }
    }

    create(this, 'accounts', {
      organizationId,
      ...accountSubmit,
    })
      .then(() => {
        onAddAccount();
        this.setState({ loading: true });
        closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const { loading, error, account, accountOptions } = this.state;
    const { basis } = this.context;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addAccountDialog"
      >
        {!loading && (
          <form onSubmit={this.saveAccount}>
            <DialogTitle id="alert-dialog-title">Add Account</DialogTitle>
            <DialogContent>
              <TextField
                label="Account Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: account.name,
                  name: 'nested_account_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Autocomplete
                options={getAccountTypeOptions}
                getOptionLabel={nameLabel}
                value={account.typeSelect}
                onChange={handleAutocompleteChange('nested_account_typeSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Account Type"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={account.isSubaccount}
                      onChange={handleCheckboxChange(this)}
                      name="nested_account_isSubaccount"
                    />
                  }
                  label="This account is a subaccount"
                />
              </FormControl>
              {account.typeSelect && account.isSubaccount && (
                <Autocomplete
                  options={getFilteredAccountOptions(accountOptions, account.typeSelect.id)}
                  getOptionLabel={nameLabel}
                  value={account.parentAccountIdSelect}
                  onChange={handleAutocompleteChange('nested_account_parentAccountIdSelect', this)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Parent Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {account.typeSelect && !account.isSubaccount && account.typeSelect.id === 'Asset' && (
                <>
                  <Autocomplete
                    options={getAssetType2Options}
                    getOptionLabel={nameLabel}
                    value={account.assetType2Select}
                    onChange={handleAutocompleteChange('nested_account_assetType2Select', this)}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        label="Account Subtype"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                  {basis === 'cash' && account.assetType2Select && account.assetType2Select.cashType && (
                    <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                      <Typography variant="body2">
                        {`Your portfolio uses a cash basis. This account will be created as a ${account.assetType2Select.cashType.toLowerCase()} account. `}
                        <LinkBase to="/settings">Click here</LinkBase>
                        {' to update your portfolio settings if you would like to switch to an accrual basis.'}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              {account.typeSelect && !account.isSubaccount && account.typeSelect.id === 'Liability' && (
                <Autocomplete
                  options={getLiabilityType2Options}
                  getOptionLabel={nameLabel}
                  value={account.liabilityType2Select}
                  onChange={handleAutocompleteChange('nested_account_liabilityType2Select', this)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Account Subtype"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {account.typeSelect && !account.isSubaccount && account.typeSelect.id === 'Revenue' && (
                <Autocomplete
                  options={getRevenueType2Options}
                  getOptionLabel={nameLabel}
                  value={account.revenueType2Select}
                  onChange={handleAutocompleteChange('nested_account_revenueType2Select', this)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Account Subtype"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Account
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddAccountDialog.contextType = PersonContext;

AddAccountDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddAccount: PropTypes.func.isRequired,
};

export default AddAccountDialog;
