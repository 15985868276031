import React from 'react';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import AddAccountDialog from '../components/AddAccountDialog';
import CardBase from '../components/CardBase';
import DownloadDialog from '../components/DownloadDialog';
import EditAccountDialog from '../components/EditAccountDialog';
import FilterChartOfAccountsDialog from '../components/FilterChartOfAccountsDialog';
import LinkBase from '../components/LinkBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import { PersonContext } from '../contexts/PersonContext';
import { find } from '../feathersWrapper';
import { asyncHandleChange } from '../functions/InputHandlers';

import FilterCard from './cards/FilterCard';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
  subAccount: {
    paddingLeft: theme.spacing(4),
  },
});

class ChartOfAccounts extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addAccountDialog: false,
      downloadDialog: false,
      editAccountId: null,
      filter: {
        hideInactive: false,
        hideDefaults: false,
        accountType: null,
        accountName: '',
      },
    };
  }

  async componentDidMount() {
    await this.getAccounts();
  }

  accountPriority = (account) => {
    if (account.type === 'Asset') {
      return 1;
    }
    if (account.type === 'Liability') {
      return 2;
    }
    if (account.type === 'Equity') {
      return 3;
    }
    if (account.type === 'Revenue') {
      return 4;
    }
    if (account.type === 'Expense') {
      return 5;
    }
    return null;
  };

  sortFn = (a, b) => this.accountPriority(a) - this.accountPriority(b);

  getAccounts = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;
    // launch async calls

    let displayFlatlist = false;
    const accountsQuery = {
      organizationId,
      includeSubAccounts: true,
      parentAccountId: null,
      $sort: {
        name: 1,
      },
      $skip: 0,
      $limit: 500,
    };

    if (filter.accountName) {
      accountsQuery.name = { $iLike: `%${filter.accountName}%` };
      delete accountsQuery.parentAccountId;
      delete accountsQuery.includeSubAccounts;
      displayFlatlist = true;
    }

    if (filter.accountType) {
      accountsQuery.type = filter.accountType.id;
      if (accountsQuery.type === 'All') {
        delete accountsQuery.type;
      }
    }

    if (filter.hideInactive) {
      accountsQuery.inactive = { $or: [null, false] };
      delete accountsQuery.parentAccountId;
      delete accountsQuery.includeSubAccounts;
      displayFlatlist = true;
    }

    if (filter.hideDefaults) {
      accountsQuery.default = null;
      delete accountsQuery.parentAccountId;
      delete accountsQuery.includeSubAccounts;
      displayFlatlist = true;
    }

    let accounts = [];
    let accountsResult;
    do {
      accountsResult = await find(this, 'accounts', { query: accountsQuery });
      accounts = accounts.concat(accountsResult.data);
      accountsQuery.$skip += accountsQuery.$limit;
    } while (accountsResult.total > accounts.length);

    accounts.sort(this.sortFn);

    const collator = new Intl.Collator('en', { numeric: true });
    accounts.forEach((account) => {
      if (account.subAccounts) {
        account.subAccounts.sort((a, b) => collator.compare(a.name, b.name));
      }
    });

    this.setState({
      loading: false,
      accounts,
      displayFlatlist,
    });
  };

  openAddAccountDialog = () => {
    this.setState({
      addAccountDialog: true,
    });
  };

  closeAccountDialogs = () => {
    this.setState({
      addAccountDialog: false,
      editAccountId: null,
    });
  };

  appliedFilter = () => {
    const { filter } = this.state;
    const filterArray = [];

    if (filter.hideInactive) {
      filterArray.push({
        label: 'Hide Inactive',
        value: 'True',
      });
    }
    if (filter.hideDefaults) {
      filterArray.push({
        label: 'Hide System Defaults',
        value: 'True',
      });
    }
    return filterArray;
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getAccounts();
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { exportOptions } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        exportOptions,
        reportName: 'Chart of Accounts',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'chart-of-accounts.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Add Account', action: this.openAddAccountDialog, class: 'add' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { classes, match } = this.props;
    const {
      accounts,
      addAccountDialog,
      editAccountId,
      downloadDialog,
      loading,
      filter,
      filterChartOfAccountsDialog,
      displayFlatlist,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          actionButtons={this.actionButtons()}
          title="Chart of Accounts"
          appliedFilter={this.appliedFilter()}
        />
        <AddAccountDialog
          isOpen={addAccountDialog}
          closeDialog={this.closeAccountDialogs}
          onAddAccount={this.getAccounts}
        />
        {editAccountId && (
          <EditAccountDialog
            accountId={editAccountId}
            closeDialog={this.closeAccountDialogs}
            onEditAccount={this.getAccounts}
          />
        )}
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        {filterChartOfAccountsDialog && (
          <FilterChartOfAccountsDialog
            isOpen={filterChartOfAccountsDialog}
            closeDialog={() =>
              this.setState({
                filterChartOfAccountsDialog: false,
              })
            }
            filter={filter}
            updateFilter={this.updateFilter}
          />
        )}
        <FilterCard
          openFilter={() => this.setState({ filterChartOfAccountsDialog: true })}
          updateFilter={this.updateFilter}
          filter={filter}
          fields={['accountName', 'accountType']}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Account Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Subtype</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts.map((account) => (
                    <>
                      <TableRow key={account.id}>
                        <TableCell>
                          <LinkBase to={`/transactions/booked?accountId=${account.id}`}>
                            {account.inactive ? `${account.name} (Inactive)` : account.name}
                          </LinkBase>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{account.type}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{account.type2 ? account.type2 : '---'}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              this.setState({
                                editAccountId: account.id,
                              })
                            }
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                      {!displayFlatlist && account.subAccounts.length > 0 && (
                        <>
                          {account.subAccounts.map((subAccount) => (
                            <TableRow key={subAccount.id}>
                              <TableCell>
                                <LinkBase
                                  to={`/transactions/booked?accountId=${subAccount.id}`}
                                  className={classes.subAccount}
                                >
                                  {subAccount.inactive ? `${subAccount.name} (Inactive)` : subAccount.name}
                                </LinkBase>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">{account.type}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">{account.type2 ? account.type2 : '---'}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    this.setState({
                                      editAccountId: subAccount.id,
                                    })
                                  }
                                >
                                  Edit
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

ChartOfAccounts.contextType = PersonContext;

ChartOfAccounts.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ChartOfAccounts);
