import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import { setUpJournalForSubmit } from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getPartnerLink } from '~/helpers/utils/getPartnerLink';

import QuickBookRowLoading from '../QuickBookRowLoading';

export default function QuickBookRowPartnerDepositTemplate({ transaction, onTransactionAction, onGetHelp }) {
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const { partnerName, organizationId } = useContext(PersonContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [journal, setJournal] = useState(null);
  const [partnerAccountOptions, setPartnerAccountOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const partnerAccountData = await find('accounts', {
        query: {
          organizationId,
          type2: 'Partner',
        },
      });

      const journalObject = getManualBasicJournal();
      const { account, id, amount, date, description } = transaction;
      journalObject.debitLines[0].accountIdSelect = account;
      journalObject.debitLines[0].yodleeTransactionId = id;
      journalObject.amount = amount;
      journalObject.description = description;
      journalObject.date = date;

      const turboTenantDescription = description.toLowerCase().includes('turbotenant');
      const proPayDescription = description.toLowerCase().includes('propay');

      if (journalObject.description === null || !(turboTenantDescription || proPayDescription)) {
        const manualAccount = partnerAccountData.data.find((partnerAccount) =>
          partnerAccount.default.includes('Manual'),
        );
        if (manualAccount) {
          journalObject.creditLines[0].accountIdSelect = manualAccount;
        }
      } else {
        const electronicAccount = partnerAccountData.data.find(
          (partnerAccount) => !partnerAccount.default.includes('Manual'),
        );
        if (electronicAccount) {
          journalObject.creditLines[0].accountIdSelect = electronicAccount;
        }
      }

      setPartnerAccountOptions(partnerAccountData.data);
      setJournal(journalObject);
      setIsLoading(false);
    };
    getData();
  }, [organizationId, transaction]);

  const addPartnerDeposit = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const journalData = {
      journal,
      organizationId,
      direction: 'cash in',
      transactionId: transaction.id,
      type: 'Transfer',
      bookAsRefund: false,
    };

    const journalSubmit = setUpJournalForSubmit(journalData);

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction();
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
      return;
    }
  };

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <form onSubmit={addPartnerDeposit}>
      <Box className={classes.rootContainer}>
        <Alert severity="info" className={classes.infoAlert}>
          Use this to record a deposit from {partnerName}. This will not be recorded as revenue. Payments through{' '}
          {partnerName} are automatically recorded as revenue to the correct rental.{' '}
          <Box
            component="span"
            className={classes.link}
            onClick={() => window.open(getPartnerLink(partnerName), '_blank')}
          >
            Learn more about partner payments.
          </Box>
        </Alert>
        <Box className={classes.container}>
          <QuickBookRowAutocomplete
            className={classes.autoCompleteField}
            options={partnerAccountOptions}
            onChange={(value) => {
              setJournal({ ...journal, creditLines: [{ ...journal.creditLines[0], accountIdSelect: value }] });
            }}
            label="Partner Payment Account"
            value={journal.creditLines[0].accountIdSelect}
            required
          />
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.helpButton}
            onClick={() => {
              tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
              onGetHelp();
            }}
          >
            <HelpOutline className={classes.helpIcon} />
            Get Help
          </Button>
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowPartnerDepositTemplate.propTypes = {
  onTransactionAction: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  onGetHelp: PropTypes.func.isRequired,
};
