import { BATCH_TYPES } from '~/constants/batch-booking';

export const getBatchOptionButtonLabel = (batchTypeId) => {
  switch (batchTypeId) {
    case BATCH_TYPES.IGNORE:
      return 'Ignore';
    case BATCH_TYPES.UNIGNORE:
      return 'Unignore';
    case BATCH_TYPES.DELETE:
      return 'Delete';
    case BATCH_TYPES.UNDO:
      return 'Undo';
    case BATCH_TYPES.EXPENSE:
    case BATCH_TYPES.REVENUE:
    case BATCH_TYPES.TRANSFER:
    default:
      return 'Book';
  }
};
