import React from 'react';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Clear as ClearIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles(() => ({
  closeIcon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    color: 'white',
  },
}));

export default function PlaidExitDialog({
  plaidExitDialogVisible,
  setAddAccountDialog,
  setPlaidExitDialogVisible,
  setState,
  setYodleeAddLink,
}) {
  const tracking = useSegmentTrack();
  const classes = useStyles();

  return (
    <Dialog
      open={plaidExitDialogVisible}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setPlaidExitDialogVisible(false);
        }
      }}
      onLoad={() => {
        tracking('not_finding_account_modal loaded', {
          location: 'Onboarding - Linked Account: Not Finding Account Modal',
        });
      }}
      maxWidth="sm"
      disableEscapeKeyDown
      id="plaid-exit-dialog"
      aria-describedby="plaid-exit-dialog"
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ClearIcon
            className={classes.closeIcon}
            onClick={() => {
              tracking('close clicked', { location: 'Onboarding - Linked Account: Not Finding Account Modal' });
              setPlaidExitDialogVisible(false);
              setState((prevState) => ({
                ...prevState,
                key: prevState.accountCount === 0 ? 'accountsOverview' : 'addAccountsSuccess',
              }));
            }}
          />
        </Box>
        <Box
          style={{
            backgroundColor: 'white',
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <DialogContent>
            <Box marginX="auto" marginBottom={2} textAlign="left">
              <Typography variant="h6" gutterBottom>
                Not finding the account you're looking for?
              </Typography>
              <Box marginBottom={1} textAlign="left">
                <Typography variant="body1">
                  We can try with our alternative data provider Yodlee to link to your account. Or you could import the
                  account information manually.
                </Typography>
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" marginBottom={1} marginTop={5}>
                <Button
                  hasFullWidth
                  variant="contained"
                  onClick={() => {
                    tracking('try_linking_via_yodlee clicked', {
                      location: 'Onboarding - Linked Account: Not Finding Account Modal',
                    });
                    setPlaidExitDialogVisible(false);
                    setYodleeAddLink(true);
                  }}
                >
                  Try Linking Via Yodlee
                </Button>
                <Box marginTop={1}>
                  <MuiButton
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => {
                      tracking('import_manually clicked', {
                        location: 'Onboarding - Linked Account: Not Finding Account Modal',
                      });
                      setState((prevState) => ({ ...prevState, accountType: 'manual' }));
                      setPlaidExitDialogVisible(false);
                      setAddAccountDialog(true);
                    }}
                  >
                    Add Manually
                  </MuiButton>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </>
    </Dialog>
  );
}

PlaidExitDialog.propTypes = {
  plaidExitDialogVisible: PropTypes.bool.isRequired,
  setAddAccountDialog: PropTypes.func.isRequired,
  setPlaidExitDialogVisible: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  setYodleeAddLink: PropTypes.func.isRequired,
};
