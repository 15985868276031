import { useMemo } from 'react';

import {
  bankCreditTransactionTypes,
  bankDebitTransactionTypes,
  cardCreditTransactionTypes,
  cardDebitTransactionTypes,
  loanCreditTransactionTypes,
  loanDebitTransactionTypes,
} from '~/components/SearchSelect/TransactionTypeOptions';

export default function useTransactionTypesState({ accountType, accountType2, partnerName, yodleeType }) {
  return useMemo(() => {
    if (accountType === 'Asset') {
      if (yodleeType === 'CREDIT') {
        const types = getUpdatedTransactionTypesLabels(bankCreditTransactionTypes, {
          'Transfer Between Accounts': 'Transfer',
          'Owner Contribution': 'Contribution',
        });
        if (partnerName) {
          return [{ label: `${partnerName} Deposit`, value: 'Partner Deposit' }, ...bankCreditTransactionTypes];
        }
        return types;
      }

      const types = getUpdatedTransactionTypesLabels(bankDebitTransactionTypes, {
        'Owner Distribution': 'Distribution',
        'Refund Issued': 'Refund',
        'Security Deposit Refund': 'Security Deposit Return',
        'Transfer Between Accounts': 'Transfer',
      });
      return types;
    }

    if (accountType === 'Liability' && ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(accountType2)) {
      if (yodleeType === 'CREDIT') {
        return loanCreditTransactionTypes;
      }
      return loanDebitTransactionTypes;
    }

    if (accountType === 'Liability' && accountType2 === 'Credit Card') {
      if (yodleeType === 'CREDIT') {
        return cardCreditTransactionTypes;
      }
      const types = getUpdatedTransactionTypesLabels(cardDebitTransactionTypes, {
        'Transfer Between Accounts': 'Transfer',
      });
      return types;
    }

    return [];
  }, [accountType, accountType2, partnerName, yodleeType]);
}

function getUpdatedTransactionTypesLabels(types, labels) {
  return types.map((transactionType) => {
    if (Object.keys(labels).includes(transactionType.label)) {
      return { ...transactionType, label: labels[transactionType.label] };
    }
    return transactionType;
  });
}
