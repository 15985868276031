import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import { patch } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';

import AddAccountSuccess from '../AddAccountSuccess';
import AddAnotherLoanAccount from '../AddAnotherLoanAccount';
import AddLoanAccount from '../AddLoanAccount';
import AddManualAccount from '../AddManualAccount';
import Overview from '../Overview';
import PlaidSuccess from '../PlaidSuccess';
import SetupComplete from '../SetupComplete';
import YodleeSuccess from '../YodleeSuccess';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '600px',
    width: '100%',
  },
}));

export default function Content({
  allAccounts = [],
  state,
  onAddAccount,
  setAddAccountDialog,
  setAddLoanAccountDialog,
  setPlaidAddNew,
  setSkipForNowDialogVisible,
  setState,
}) {
  const { accountCount, addedAccounts, error, key, loanAccountCount, plaidItem, yodleeProvider } = state;

  const classes = useStyles();

  const handleSaveAndClose = useCallback(
    async (event) => {
      event.preventDefault();
      const patchPromises = [];

      let account;

      addedAccounts.forEach((accountItem) => {
        if (plaidItem) {
          account = allAccounts.find((item) => item.plaidAccountId === accountItem.plaidAccountId);
        }
        if (yodleeProvider) {
          account = allAccounts.find((item) => item.yodlee_account.accountData.id === accountItem.yodleeAccountId);
        }

        if (account) {
          setJournalScope(accountItem.journal);
          const data = {
            name: accountItem.name,
            propertyId: accountItem.journal.propertyId,
            entityId: accountItem.journal.entityId,
            unitId: accountItem.journal.unitId,
          };
          patchPromises.push(patch('accounts', account.id, data));
        }
      });

      const results = await Promise.allSettled(patchPromises);
      let error;
      results.forEach((result) => {
        if (result.status === 'rejected') {
          error = result.reason;
        }
      });

      if (error) {
        setState((prevState) => ({ ...prevState, error }));
      } else {
        onAddAccount();
        setState((prevState) => ({
          ...prevState,
          error: null,
          plaidItem: null,
          yodleeProvider: null,
          key: 'addAccountsSuccess',
        }));
      }
    },
    [addedAccounts, allAccounts, plaidItem, yodleeProvider, onAddAccount, setState],
  );

  switch (key) {
    case 'accountsOverview':
      return (
        <Overview
          setAddAccountDialog={setAddAccountDialog}
          setPlaidAddNew={setPlaidAddNew}
          setSkipForNowDialogVisible={setSkipForNowDialogVisible}
          setState={setState}
        />
      );
    case 'addManualAccounts':
      return (
        <Card scroll="body" className={classes.card}>
          <AddManualAccount setAddAccountDialog={setAddAccountDialog} setState={setState} />
        </Card>
      );
    case 'plaidSuccess':
      return (
        <Card scroll="body" className={classes.card}>
          <PlaidSuccess
            addedAccounts={addedAccounts}
            error={error}
            plaidItem={plaidItem}
            handleSaveAndClose={handleSaveAndClose}
            setState={setState}
          />
        </Card>
      );
    case 'yodleeSuccess':
      return (
        <Card scroll="body" className={classes.card}>
          <YodleeSuccess
            addedAccounts={addedAccounts}
            error={error}
            yodleeProvider={yodleeProvider}
            handleSaveAndClose={handleSaveAndClose}
            setState={setState}
          />
        </Card>
      );
    case 'addAccountsSuccess':
      return (
        <Card scroll="body" className={classes.card}>
          <AddAccountSuccess
            accountCount={accountCount}
            setAddAccountDialog={setAddAccountDialog}
            setPlaidAddNew={setPlaidAddNew}
            setState={setState}
          />
        </Card>
      );
    case 'addLoanAccounts':
      return (
        <Card scroll="body" className={classes.card}>
          <AddLoanAccount setAddLoanAccountDialog={setAddLoanAccountDialog} setState={setState} />
        </Card>
      );
    case 'addAnotherLoanAccount':
      return (
        <Card scroll="body" className={classes.card}>
          <AddAnotherLoanAccount
            loanAccountCount={loanAccountCount}
            setAddLoanAccountDialog={setAddLoanAccountDialog}
            setState={setState}
          />
        </Card>
      );
    case 'setupComplete':
      return (
        <Card scroll="body" className={classes.card}>
          <SetupComplete />
        </Card>
      );
    default:
      return null;
  }
}

Content.propTypes = {
  allAccounts: PropTypes.array,
  state: PropTypes.object.isRequired,
  onAddAccount: PropTypes.func.isRequired,
  setAddAccountDialog: PropTypes.func.isRequired,
  setAddLoanAccountDialog: PropTypes.func.isRequired,
  setPlaidAddNew: PropTypes.func.isRequired,
  setSkipForNowDialogVisible: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
