import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import PropTypes from 'prop-types';

import AddPropertyManagerDialog from '../components/AddPropertyManagerDialog';
import CardBase from '../components/CardBase';
import FormGridContainer from '../components/FormGridContainer';
import FormGridItem from '../components/FormGridItem';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import { searchEntities } from '../components/SearchSelect/SearchFunctions';
import SearchSelect from '../components/SearchSelect/SearchSelect';
import { stateCodeOptions } from '../components/SearchSelect/StateCodeOptions';
import TransactionScope from '../components/TransactionScope';
import { PersonContext } from '../contexts/PersonContext';
import { find, get, patch, patchMultiple, remove } from '../feathersWrapper';
import {
  asyncHandleChange,
  filterSearchSelectOptions,
  handleChange,
  handleCheckboxChange,
  handleNumberFormatChange,
  handleSearchSelectChange,
  handleTextFieldChange,
  handleToggleButtonChange,
  handleTransactionScopeChange,
  handleUploaderChange,
} from '../functions/InputHandlers';
import { setJournalScope } from '../functions/JournalFunctions';
import history from '../history';

const styles = (theme) => ({
  stateSelect: {
    marginTop: '4px',
  },
  hideWidget: {
    display: 'none',
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
});

class EditProperty extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true, confirmDelete: false };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { organizationId } = this.context;
    const { propertyId } = match.params;
    const property = {};

    const propertiesPromise = get(this, 'properties', propertyId);

    const oldProperty = await propertiesPromise;

    property.id = oldProperty.id;
    property.entityId = oldProperty.entityId;
    property.entityIdSelect = null;
    property.address1 = oldProperty.address1;
    property.address2 = oldProperty.address2;
    property.city = oldProperty.city;
    property.state = oldProperty.state;
    property.stateSelect = { label: oldProperty.state, value: oldProperty.state };
    property.zip = oldProperty.zip;
    property.unitSelection = oldProperty.unitSelection;
    property.marketValue = oldProperty.marketValue;
    property.image = oldProperty.image;
    property.propertyManagerId = oldProperty.propertyManagerId;
    property.propertyManagerIdSelect = null;
    property.inactive = oldProperty.inactive;

    let propertyManagementSelection = 'self';
    if (property.propertyManagerId) {
      property.propertyManagerIdSelect = {
        label: oldProperty.property_manager.name,
        value: property.propertyManagerId,
      };
      propertyManagementSelection = 'professional';
    }

    if (property.entityId) {
      property.entityIdSelect = {
        label: oldProperty.entity.name,
        value: property.entityId,
      };
    }

    const query = {
      organizationId,
      propertyId: property.id,
      $limit: 0,
    };
    const journalsResponse = await find(this, 'journals', { query });

    this.setState({
      property,
      oldProperty,
      propertyManagementSelection,
      journal: {
        journalScopeSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
      totalTransactions: journalsResponse.total,
      loading: false,
      submitting: false,
      newPropertyManager: false,
    });
  }

  getPropertyManagers = async (search) => {
    const { organizationId } = this.context;
    const query = { organizationId, name: { $iLike: `${search}%` } };
    const propertyManagersResult = await find(this, 'property-managers', { query });
    const propertyManagers = propertyManagersResult.data;
    return Promise.resolve(propertyManagers.map((manager) => ({ label: manager.name, value: manager.id })));
  };

  closeAddPropertyManagerDialog = () => {
    this.setState({
      newPropertyManager: false,
    });
  };

  onAddPropertyManagerDialog = (propertyManager) => {
    const { property } = this.state;
    handleChange(
      'property',
      {
        ...property,
        propertyManagerId: propertyManager.id,
        propertyManagerIdSelect: {
          label: propertyManager.name,
          value: propertyManager.id,
        },
      },
      this,
    );

    this.closeAddPropertyManagerDialog();
  };

  editProperty = async (event) => {
    event.preventDefault();
    const { submitting, property, oldProperty, propertyManagementSelection } = this.state;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    if (propertyManagementSelection === 'self') {
      property.propertyManagerId = null;
      property.propertyManagerIdSelect = null;
    }

    await patch(this, 'properties', property.id, property, true)
      .then(async () => {
        if (property.entityId !== oldProperty.entityId) {
          await patchMultiple(
            this,
            'journals',
            {
              query: {
                propertyId: property.id,
              },
            },
            {
              entityId: property.entityId,
            },
            true,
          );
          await patchMultiple(
            this,
            'accounts',
            {
              query: {
                propertyId: property.id,
              },
            },
            {
              entityId: property.entityId,
            },
            true,
          );
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });

    history.push(`/properties/property/${property.id}`);
  };

  openDelete = () => {
    this.setState({ confirmDelete: true });
  };

  deleteProperty = async () => {
    const { property, submitting, totalTransactions, journal } = this.state;
    const { id } = property;

    if (submitting) {
      return;
    }

    if (totalTransactions) {
      setJournalScope(journal);

      if (journal.propertyId === property.id) {
        this.setState({ error: { message: 'You cannot reassign transactions to the property being deleted.' } });
        return;
      }
    }

    this.setState({ submitting: true });

    if (totalTransactions) {
      await patchMultiple(
        this,
        'journals',
        {
          query: {
            propertyId: property.id,
          },
        },
        {
          ...journal,
        },
      );
      await patchMultiple(
        this,
        'fixed-assets',
        {
          query: {
            propertyId: property.id,
          },
        },
        {
          entityId: journal.entityId,
          propertyId: journal.propertyId,
        },
      );
      await patchMultiple(
        this,
        'accounts',
        {
          query: {
            propertyId: property.id,
          },
        },
        {
          entityId: journal.entityId,
          propertyId: journal.propertyId,
        },
      );
      await patchMultiple(
        this,
        'leases',
        {
          query: {
            propertyId: property.id,
          },
        },
        {
          propertyId: journal.propertyId,
          unitId: journal.unitId,
        },
      );
    }
    remove(this, 'properties', id, true)
      .then(() => {
        history.push('/properties');
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  actionButtons = () => {
    const { match } = this.props;
    const { propertyId } = match.params;
    const returnArray = [
      { text: 'Delete', action: this.openDelete, class: 'delete' },
      { text: 'Cancel', link: `/properties/property/${propertyId}`, class: 'cancel' },
    ];
    return returnArray;
  };

  render() {
    const { classes, match } = this.props;
    const { multiEntity } = this.context;
    const {
      loading,
      error,
      submitting,
      newPropertyManager,
      property,
      propertyManagementSelection,
      confirmDelete,
      totalTransactions,
      journal,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader match={match} actionButtons={this.actionButtons()} title="Edit Property" />
        <Dialog
          open={confirmDelete}
          maxWidth="sm"
          fullWidth
          onClose={() => this.setState({ confirmDelete: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Really Delete Property?</DialogTitle>
          <DialogContent>
            {totalTransactions > 0 && (
              <>
                <DialogContentText id="alert-dialog-description">
                  {`Deleting this property will modify ${totalTransactions}
                    transactions currently booked to the property.
                    Click cancel and mark the property inactive instead to preserve its financial records.
                    To proceed, please select a scope.
                    Existing booked transactions assigned to the deleted property will be assigned to the selected scope.`}
                </DialogContentText>
                <TransactionScope
                  journal={journal}
                  transactionScopeChange={(newScopeValues) => {
                    handleTransactionScopeChange(journal, newScopeValues, this);
                  }}
                />
              </>
            )}
            {totalTransactions === 0 && (
              <DialogContentText id="alert-dialog-description">This property will be removed.</DialogContentText>
            )}
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ confirmDelete: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteProperty} color="primary" className={classes.deleteConfirmationButton}>
              Delete Property
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <form onSubmit={this.editProperty} autoComplete="off">
                <TextField
                  label="Street Address"
                  fullWidth
                  type="text"
                  autoComplete="off"
                  margin="dense"
                  InputProps={{
                    value: property.address1,
                    name: 'nested_property_address1',
                    onChange: handleTextFieldChange(this),
                    required: true,
                  }}
                />
                {property.unitSelection === 'single' && (
                  <TextField
                    label="Apt, Fl, Ste, Etc. (Optional)"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    InputProps={{
                      value: property.address2,
                      name: 'nested_property_address2',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                )}
                <FormGridContainer>
                  <FormGridItem xs={12} sm={6}>
                    <TextField
                      label="City"
                      fullWidth
                      type="text"
                      autoComplete="off"
                      margin="dense"
                      InputProps={{
                        value: property.city,
                        name: 'nested_property_city',
                        onChange: handleTextFieldChange(this),
                        required: true,
                      }}
                    />
                  </FormGridItem>
                  <FormGridItem xs={12} sm={3}>
                    <SearchSelect
                      searchFunction={filterSearchSelectOptions(stateCodeOptions)}
                      changeFunction={handleSearchSelectChange(this)}
                      className={classes.stateSelect}
                      label="State"
                      autoComplete="off"
                      name="nested_property_state"
                      value={property.stateSelect}
                      required
                      isClearable
                    />
                  </FormGridItem>
                  <FormGridItem xs={12} sm={3}>
                    <TextField
                      label="Zip"
                      fullWidth
                      type="text"
                      autoComplete="off"
                      margin="dense"
                      InputProps={{
                        value: property.zip,
                        name: 'nested_property_zip',
                        onChange: handleTextFieldChange(this),
                        required: true,
                      }}
                    />
                  </FormGridItem>
                </FormGridContainer>
                <FormControl margin="dense">
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Property Image
                  </FormLabel>
                  <div className={property.image ? null : classes.hideWidget}>
                    <Widget
                      ref={this.uploadcareWidget}
                      imagesOnly
                      crop="7:3"
                      previewStep
                      tabs="file url camera"
                      value={property.image}
                      onChange={handleUploaderChange('nested_property_image', this)}
                    />
                  </div>
                  {!property.image && (
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.editImageButton}
                      onClick={() => {
                        this.uploadcareWidget.current.openDialog();
                      }}
                    >
                      Click to Add A Property Image
                    </Button>
                  )}
                  {property.image && (
                    <Button
                      color="primary"
                      className={classes.editImageButton}
                      onClick={async () => {
                        await asyncHandleChange('nested_property_image', '', this);
                        this.uploadcareWidget.current.reloadInfo();
                      }}
                    >
                      Remove Image
                    </Button>
                  )}
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <InputLabel>Estimated Current Market Value</InputLabel>
                  <NumberFormat
                    value={property.marketValue}
                    required
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    onValueChange={handleNumberFormatChange('nested_property_marketValue', this)}
                    customInput={Input}
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      return formattedValue === '' || floatValue <= 99999999;
                    }}
                  />
                </FormControl>
                {multiEntity === true && (
                  <SearchSelect
                    searchFunction={searchEntities(this)}
                    changeFunction={handleSearchSelectChange(this)}
                    label="Sub-Portfolio"
                    name="nested_property_entityId"
                    isMulti={false}
                    value={property.entityIdSelect}
                    margin="dense"
                  />
                )}
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Unit Selection
                  </FormLabel>
                  <ToggleButtonGroup
                    value={property.unitSelection}
                    onChange={handleToggleButtonChange('nested_property_unitSelection', this)}
                    exclusive
                  >
                    <ToggleButton value="single">Single Unit</ToggleButton>
                    <ToggleButton value="multi">Multiple Units</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Property Management Selection
                  </FormLabel>
                  <ToggleButtonGroup
                    value={propertyManagementSelection}
                    onChange={handleToggleButtonChange('propertyManagementSelection', this)}
                    exclusive
                  >
                    <ToggleButton value="professional">Professionally Managed</ToggleButton>
                    <ToggleButton value="self">Self Managed</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                {propertyManagementSelection === 'professional' && (
                  <>
                    <SearchSelect
                      searchFunction={this.getPropertyManagers}
                      changeFunction={handleSearchSelectChange(this)}
                      label="Property Management Account"
                      name="nested_property_propertyManagerId"
                      isMulti={false}
                      required
                      value={property.propertyManagerIdSelect}
                      margin="dense"
                      noOptionsMessage={() => (
                        <Button onClick={() => this.setState({ newPropertyManager: true })}>
                          No Property Managers Found - Click to create a new account
                        </Button>
                      )}
                    />
                    <Box marginY={1}>
                      <Button
                        onClick={() => {
                          this.setState({ newPropertyManager: true });
                        }}
                        color="primary"
                      >
                        Add A New Property Management Account
                      </Button>
                    </Box>
                  </>
                )}
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={property.inactive}
                        onChange={handleCheckboxChange(this)}
                        name="nested_property_inactive"
                      />
                    }
                    label="This property is inactive"
                  />
                </FormControl>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting || property.address1 === ''}
                >
                  Save
                </Button>
                <Typography color="error">{error && error.message}</Typography>
              </form>
              <AddPropertyManagerDialog
                isOpen={newPropertyManager}
                closeDialog={this.closeAddPropertyManagerDialog}
                onAddPropertyManager={this.onAddPropertyManagerDialog}
              />
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

EditProperty.contextType = PersonContext;

EditProperty.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(EditProperty);
