import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import AddVendorDialog from '~/components/AddVendorDialog';
import { nameLabel } from '~/components/Autocomplete/Library';
import Button from '~/components/Button';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import TransactionScope from '~/components/TransactionScope';
import { BATCH_TYPES } from '~/constants/batch-booking';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { getAccountOptions, getVendorOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function BatchBookTransactionsDialog({
  closeDialog,
  fullTransactions,
  onBatchBookTransactions,
  batchType,
}) {
  const { organizationId, vendorTracking } = useContext(PersonContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [addVendorDialogOpen, setAddVendorDialogOpen] = useState(false);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [revenueAccountOptions, setRevenueAccountOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [journal, setJournal] = useState({
    ...getManualBasicJournal(),
    journalScopeSelect: { name: 'Property/Unit', id: 'Property/Unit' },
  });

  useEffect(() => {
    const getData = async () => {
      if (batchType.id === BATCH_TYPES.REVENUE) {
        const revenueAccounts = await getAccountOptions(organizationId, {
          $or: [{ type: 'Revenue' }, { type2: 'Deferred Revenue' }],
        });
        setRevenueAccountOptions(revenueAccounts);
      }

      if (batchType.id === BATCH_TYPES.EXPENSE) {
        const expenseAccounts = await getAccountOptions(organizationId, {
          $or: [{ type: 'Expense' }, { type2: 'Sales and Use Tax' }],
        });
        setExpenseAccountOptions(expenseAccounts);

        const vendors = await getVendorOptions(organizationId);
        setVendorOptions(vendors);
      }

      setIsLoading(false);
    };
    getData();
  }, [batchType, organizationId]);

  const addVendor = async (newVendor) => {
    const newVendorOptions = await getVendorOptions(organizationId, true);
    setVendorOptions(newVendorOptions);

    setJournal((prevJournal) => ({ ...prevJournal, vendorIdSelect: newVendor }));
    setAddVendorDialogOpen(false);
  };

  /**
   * Sets up batch journals depending on selected type, adds them to an array, and then
   * submits/creates the journals for each item added to the array.
   */
  const batchBookTransactions = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setJournalScope(journal);

    const batchJournalsArray = [];

    fullTransactions.forEach((transaction) => {
      const journalTemplate = getManualBasicJournal();

      // Set all journal attributes that are not transaction type specific
      journalTemplate.organizationId = organizationId;
      journalTemplate.description = transaction.description;
      journalTemplate.date = transaction.date;
      journalTemplate.amount = transaction.amount;

      journalTemplate.journalScopeSelect = journal.journalScopeSelect;
      journalTemplate.propertyIdSelect = journal.propertyIdSelect;
      journalTemplate.propertyId = journal.propertyId;
      journalTemplate.unitId = journal.unitId;
      journalTemplate.unitIdSelect = journal.unitIdSelect;
      journalTemplate.entityIdSelect = journal.entityIdSelect;
      journalTemplate.entityId = journal.entityId;

      journalTemplate.vendorIdSelect = journal.vendorIdSelect;
      if (journalTemplate.vendorIdSelect) {
        journalTemplate.vendorId = journalTemplate.vendorIdSelect.id;
      }

      journalTemplate.debitLines[0].debit = transaction.amount;
      journalTemplate.creditLines[0].credit = transaction.amount;

      if (batchType.id === BATCH_TYPES.EXPENSE) {
        // Set journal type for expenses
        journalTemplate.type = 'Expense';
        // Set debit and credit line data for expenses
        journalTemplate.debitLines[0].accountIdSelect = journal.debitLines[0].accountIdSelect;
        journalTemplate.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

        journalTemplate.creditLines[0].yodleeTransactionId = transaction.id;

        journalTemplate.creditLines[0].accountId = transaction.account.id;
        journalTemplate.creditLines[0].accountIdSelect = transaction.account;
      } else if (batchType.id === BATCH_TYPES.REVENUE) {
        // Set journal type for revenues
        journalTemplate.type = 'Revenue';
        // Set debit and credit line data for revenues
        journalTemplate.debitLines[0].yodleeTransactionId = transaction.id;

        journalTemplate.debitLines[0].accountIdSelect = transaction.account;
        journalTemplate.debitLines[0].accountId = transaction.account.id;

        journalTemplate.creditLines[0].accountIdSelect = journal.creditLines[0].accountIdSelect;
        journalTemplate.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
      }

      journalTemplate.journalLines = journalTemplate.debitLines.concat(journalTemplate.creditLines);

      batchJournalsArray.push(journalTemplate);
    });

    setIsSubmitting(true);

    const createPromises = batchJournalsArray.map((batchJournalsArrayItem) =>
      create('journals', batchJournalsArrayItem),
    );

    try {
      await Promise.allSettled(createPromises);
      setIsSubmitting(false);
      closeDialog();
      onBatchBookTransactions();
    } catch (error) {
      setIsSubmitting(false);
      setError(error);
    }
  };

  if (isLoading) {
    return null;
  }

  if (addVendorDialogOpen) {
    return (
      <AddVendorDialog
        closeDialog={() => setAddVendorDialogOpen(false)}
        open={addVendorDialogOpen}
        onAddVendor={addVendor}
      />
    );
  }

  return (
    <Dialog
      open
      scroll="body"
      fullWidth
      disableBackdropClick
      disableEnforceFocus
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="addTransactionDialog"
    >
      <DialogTitle>{batchType.id === BATCH_TYPES.EXPENSE ? 'Book Expenses' : 'Book Revenues'}</DialogTitle>
      <form onSubmit={batchBookTransactions}>
        <DialogContent>
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValue) => setJournal({ ...journal, ...newScopeValue })}
          />
          <div className={classes.autocompleteContainer}>
            {batchType.id === BATCH_TYPES.EXPENSE && (
              <AutocompleteField
                options={expenseAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={(value) =>
                  setJournal({ ...journal, debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }] })
                }
                label="Expense Account"
                placeholder="Type to Search"
                required
                textFieldVariant="standard"
                disableShrink
              />
            )}
            {batchType.id === BATCH_TYPES.REVENUE && (
              <AutocompleteField
                options={revenueAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={(value) =>
                  setJournal({ ...journal, creditLines: [{ ...journal.creditLines[0], accountIdSelect: value }] })
                }
                label="Revenue Account"
                placeholder="Type to Search"
                required
                textFieldVariant="standard"
                disableShrink
              />
            )}
            {vendorTracking && batchType.id === BATCH_TYPES.EXPENSE && (
              <AutocompleteField
                options={vendorOptions}
                getOptionLabel={nameLabel}
                value={journal.vendorIdSelect}
                onChange={(value) => setJournal({ ...journal, vendorIdSelect: value })}
                label="Vendor (optional)"
                placeholder="Type to Search"
                textFieldVariant="standard"
                disableShrink
              />
            )}
          </div>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined" size="medium">
            Cancel
          </Button>
          <Button color="primary" variant="contained" size="medium" type="submit" disabled={isSubmitting}>
            {batchType.id === BATCH_TYPES.EXPENSE
              ? `Book ${pluralize('Expense', fullTransactions.length, true)}`
              : `Book ${pluralize('Revenue', fullTransactions.length, true)}`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

BatchBookTransactionsDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fullTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBatchBookTransactions: PropTypes.func.isRequired,
  batchType: PropTypes.objectOf(PropTypes.any).isRequired,
};
