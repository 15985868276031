import * as colors from '@material-ui/core/colors';

export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const COMPONENTS = {
  icon: {
    size: {
      xxsm: 16,
      xsm: 18,
      sm: 20,
      md: 24,
      xmd: 28,
      lg: 36,
      xlg: 44,
      xxlg: 56,
    },
  },
};

export const MATERIAL_COLORS = {
  ...colors,
  reiHubBlue: {
    50: '#E3F3FD',
    100: '#BBE0FB',
    200: '#91CDF9',
    300: '#65B9F6',
    400: '#41AAF5',
    500: '#1A9CF4',
    600: '#138EE6',
    700: '#097CD3',
    800: '#026BC1',
    900: '#004DA3',
    A100: '#CACCFD',
    A200: '#A6ACFC',
    A400: '#606DF9',
    A700: '#323BDC',
  },
  reiHubPurple: {
    50: '#F0E4F0',
    100: '#DABDDA',
    200: '#C392C2',
    300: '#AB69AA',
    400: '#994C98',
    500: '#883388',
    600: '#7C2F82',
    700: '#6C2979',
    800: '#5E246F',
    900: '#441C5D',
    950: '#2E1341',
    A100: '#E3B9DA',
    A200: '#D48CC1',
    A400: '#C24294',
    A700: '#992170',
  },
};

export const PALETTE = {
  action: {
    active: '#0000008F',
    disabled: '#00000061',
    disabledBackground: '#0000001F',
    focus: '#0000001F',
    hover: '#0000000A',
    selected: '#00000014',
  },
  alert: {
    error: {
      backgroundColor: '#FDEDED',
      color: '#5F2120',
    },
    info: {
      backgroundColor: '#E5F6FD',
      color: '#014361',
    },
    success: {
      backgroundColor: '#EDF7ED',
      color: '#1E4620',
    },
    warning: {
      backgroundColor: '#FFF4E5',
      color: '#663C00',
    },
  },
  avatar: {
    fill: MATERIAL_COLORS.grey[400],
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
    secondary: MATERIAL_COLORS.grey[50],
  },
  border: {
    dark: '#000000',
    default: '#0000003A',
  },
  common: {
    whiteStates: {
      focus: '#FFFFFF1F',
      hover: '#FFFFFF0A',
      main: '#FFFFFF',
    },
  },
  divider: '#0000001F',
  elevation: {
    outlined: '#E0E0E0',
  },
  error: {
    contrastText: '#FFFFFF',
    dark: MATERIAL_COLORS.red[800],
    light: MATERIAL_COLORS.red[400],
    main: MATERIAL_COLORS.red[700],
    _states: {
      focusVisible: '#D32F2F4D',
      hover: '#D32F2F0A',
      outlinedBorder: '#D32F2F80',
      selected: '#D32F2F14',
    },
  },
  info: {
    contrastText: '#FFFFFF',
    dark: MATERIAL_COLORS.lightBlue[900],
    light: MATERIAL_COLORS.lightBlue[500],
    main: MATERIAL_COLORS.lightBlue[700],
    _states: {
      focusVisible: '#0288D14D',
      hover: '#0288D10A',
      outlinedBorder: '#0288D180',
      selected: '#0288D114',
    },
  },
  primary: {
    contrastText: MATERIAL_COLORS.reiHubPurple[50],
    dark: MATERIAL_COLORS.reiHubPurple[950],
    light: MATERIAL_COLORS.reiHubPurple[800],
    main: MATERIAL_COLORS.reiHubPurple[900],
    _states: {
      focus: '#441C5D1F',
      focusVisible: '#441C5D4D',
      hover: '#441C5D0A',
      outlinedBorder: '#441C5D80',
      selected: '#441C5D14',
    },
  },
  secondary: {
    contrastText: '#FFFFFF',
    contrastBackground: '#f7fcff',
    dark: MATERIAL_COLORS.reiHubBlue[900],
    light: MATERIAL_COLORS.reiHubBlue[500],
    main: MATERIAL_COLORS.reiHubBlue[800],
    _states: {
      focus: '#41AAF51F',
      focusVisible: '#41AAF54D',
      hover: '#41AAF50A',
      outlinedBorder: '#41AAF580',
      selected: '#41AAF514',
    },
  },
  success: {
    contrastText: '#FFFFFF',
    dark: MATERIAL_COLORS.green[900],
    light: MATERIAL_COLORS.green[500],
    main: MATERIAL_COLORS.green[800],
    _states: {
      focusVisible: '#2E7D324D',
      hover: '#2E7D320A',
      outlinedBorder: '#2E7D3280',
      selected: '#2E7D3214',
    },
  },
  text: {
    disabled: '#00000061',
    primary: '#000000DE',
    secondary: '#00000099',
    _states: {
      focus: '#0000001F',
      focusVisible: '#0000004D',
      hover: '#0000000A',
      selected: '#00000014',
    },
  },
  warning: {
    contrastText: '#FFFFFF',
    dark: MATERIAL_COLORS.orange[900],
    light: MATERIAL_COLORS.orange[500],
    main: MATERIAL_COLORS.orange[800],
    _states: {
      focusVisible: '#EF6C004D',
      hover: '#EF6C000A',
      outlinedBorder: '#EF6C0080',
      selected: '#EF6C0014',
    },
  },
};

export const SHAPE = {
  borderRadius: {
    sm: 4,
    md: 8,
    lg: 12,
  },
};

export const SPACING = 8;

export const TYPOGRAPHY = {
  fontFamily: {
    headers: '"Poppins", sans-serif',
    primary: '"Open Sans", sans-serif',
  },
  fontSize: {
    body1: 18,
    body2: 16,
    button: 14.9,
    caption: 14,
    h1: 96,
    h2: 64,
    h3: 48,
    h4: 32,
    h5: 24,
    h6: 20,
    overline: 14,
    subtitle1: 18,
    subtitle2: 16,
  },
  fontWeight: {
    bold: 700,
    light: 300,
    regular: 400,
    semiBold: 600,
  },
  lineHeight: {
    body1: 1.52381,
    body2: 1.43,
    button: 1.47651,
    caption: 1.62612,
    h1: 1.33372,
    h2: 1.28571,
    h3: 1.33371,
    h4: 1.49964,
    h5: 1.52457,
    h6: 1.82857,
    overline: 2.60571,
    subtitle1: 1.77778,
    subtitle2: 1.43,
  },
};
