import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { setUpJournalForSubmit } from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowLoading from '../QuickBookRowLoading';

export default function QuickBookRowRefundReceivedTemplate({ transaction, onTransactionAction, onGetHelp }) {
  const context = useContext(PersonContext);
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const { organizationId } = context;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [journal, setJournal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      if (!isLoading) {
        return;
      }

      const expenseAccounts = await getAccountOptions(organizationId, {
        $or: [{ type: 'Expense' }, { type2: ['Sales and Use Tax', 'Fixed Asset'] }],
      });
      setExpenseAccountOptions(expenseAccounts);

      const journalObject = getManualBasicJournal();
      const { account, id, description, date, amount } = transaction;
      journalObject.debitLines[0].accountIdSelect = account;
      journalObject.debitLines[0].yodleeTransactionId = id;
      journalObject.description = description;
      journalObject.date = date;
      journalObject.amount = amount;
      journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

      setJournal(journalObject);
      setIsLoading(false);
    };
    getData();
  }, [isLoading, organizationId, transaction]);

  const addRefundReceived = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    const journalData = {
      journal,
      organizationId,
      direction: 'cash in',
      transactionId: transaction.id,
      type: 'Refund',
    };

    const journalSubmit = setUpJournalForSubmit(journalData);

    setIsSubmitting(true);

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction();
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
      return;
    }
  };

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <form onSubmit={addRefundReceived}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <QuickBookRowAutocomplete
            className={classes.autoCompleteField}
            options={expenseAccountOptions}
            onChange={(value) => {
              setJournal({ ...journal, creditLines: [{ ...journal.creditLines[0], accountIdSelect: value }] });
            }}
            label="Expense Account"
            value={journal.creditLines[0].accountIdSelect}
            required
          />
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.helpButton}
            onClick={() => {
              tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
              onGetHelp();
            }}
          >
            <HelpOutline className={classes.helpIcon} />
            Get Help
          </Button>
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              fullWidth={false}
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowRefundReceivedTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onGetHelp: PropTypes.func.isRequired,
};
