import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';

import AddContactDialog from '../components/AddContactDialog';
import CardBase from '../components/CardBase';
import FormGridContainer from '../components/FormGridContainer';
import FormGridItem from '../components/FormGridItem';
import PageHeader from '../components/PageHeader';
import { searchContacts, searchProperties, searchUnits } from '../components/SearchSelect/SearchFunctions';
import SearchSelect from '../components/SearchSelect/SearchSelect';
import { PersonContext } from '../contexts/PersonContext';
import { get, patch, remove } from '../feathersWrapper';
import {
  asyncHandleChange,
  handleChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleSearchSelectChange,
  handleTextFieldChange,
  handleUploaderChange,
} from '../functions/InputHandlers';
import { sumProperty } from '../functions/SumFunctions';
import history from '../history';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
  hideWidget: {
    display: 'none',
  },
});

class EditLease extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      confirmDelete: false,
      addPersonDialog: false,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { leaseId } = match.params;
    const lease = {};
    let monthToMonth = false;

    try {
      const oldLease = await get(this, 'leases', leaseId);

      lease.id = oldLease.id;

      if (oldLease.startDate) {
        lease.startDate = oldLease.startDate;
        lease.startDateSelect = moment(oldLease.startDate);
      } else {
        lease.startDate = null;
        lease.startDateSelect = null;
      }

      if (oldLease.endDate) {
        lease.endDate = oldLease.endDate;
        lease.endDateSelect = moment(oldLease.endDate);
      } else {
        lease.endDate = null;
        lease.endDateSelect = null;
        monthToMonth = true;
      }

      lease.recurringRents = oldLease.recurringRents;
      lease.attachmentURL = oldLease.attachmentURL;

      lease.propertyId = oldLease.propertyId;
      lease.propertyIdSelect = oldLease.propertyId
        ? {
            value: oldLease.propertyId,
            label: oldLease.property.address1,
          }
        : null;
      const selectedProperty = oldLease.propertyId
        ? {
            id: oldLease.propertyId,
            address1: oldLease.property.address1,
            unitSelection: oldLease.property.unitSelection,
          }
        : null;
      lease.unitId = oldLease.unitId;
      lease.unitIdSelect = oldLease.unitId
        ? {
            value: oldLease.unitId,
            label: oldLease.unit.name,
          }
        : null;

      lease.recurringRents = oldLease.recurringRents;
      lease.securityDepositAmount = oldLease.securityDepositAmount;
      lease.tenants = oldLease.people.map((person) => person.id);
      lease.tenantsSelect = oldLease.people.map((person) => ({
        value: person.id,
        label: `${person.firstName} ${person.lastName}`,
      }));

      this.setState({
        lease,
        selectedProperty,
        monthToMonth,
        loading: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  handlePropertyChange = async (name, value, isMulti) => {
    const { lease } = this.state;
    if (value !== null) {
      const property = await get(this, 'properties', value.value);
      handleChange('selectedProperty', property, this);
    } else {
      handleChange('selectedProperty', null, this);
    }
    lease.unitId = null;
    lease.unitIdSelect = null;
    await asyncHandleChange('lease', { ...lease }, this);

    handleSearchSelectChange(this)(name, value, isMulti);
  };

  clickMonthToMonth = () => {
    const { monthToMonth, lease } = this.state;
    lease.endDate = null;
    lease.endDateSelect = null;
    this.setState({
      monthToMonth: !monthToMonth,
      lease: { ...lease },
    });
  };

  editLease = async (event) => {
    event.preventDefault();
    const { submitting, lease } = this.state;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    await patch(
      this,
      'leases',
      lease.id,
      {
        organizationId,
        ...lease,
      },
      true,
    )
      .then(() => {
        history.push('/organization/leases');
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteLease = () => {
    const { submitting, lease } = this.state;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    remove(this, 'leases', lease.id, true)
      .then(() => {
        history.push('/organization/leases');
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  addRecurringRent = () => {
    const newRecurringRent = {
      key: generateHash(),
      description: '',
      amount: '',
    };

    const { lease } = this.state;
    lease.recurringRents.push({ ...newRecurringRent });

    handleChange('nested_lease_recurringRents', [...lease.recurringRents], this);
  };

  removeRecurringRent = (index) => {
    const { lease } = this.state;
    lease.recurringRents.splice(index, 1);
    handleChange('nested_lease_recurringRents', [...lease.recurringRents], this);
  };

  closeAddPersonDialog = () => {
    this.setState({
      addPersonDialog: false,
    });
  };

  onAddPersonDialog = (contact) => {
    const { lease } = this.state;
    if (!Array.isArray(lease.tenantsSelect)) {
      lease.tenantsSelect = [];
    }
    lease.tenantsSelect.push({
      label: `${contact.person.firstName} ${contact.person.lastName}`,
      value: contact.personId,
    });
    handleSearchSelectChange(this)('nested_lease_tenants', [...lease.tenantsSelect], true);
    this.closeAddPersonDialog();
  };

  actionButtons = () => [
    { text: 'Delete', action: () => this.setState({ confirmDelete: true }), class: 'delete' },
    { text: 'Cancel', link: '/organization/leases', class: 'cancel' },
  ];

  render() {
    const { classes, match } = this.props;
    const { loading, submitting, error, confirmDelete, lease, selectedProperty, monthToMonth, addPersonDialog } =
      this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid spacing={3} alignItems="center" justify="center" container className={classes.grid}>
        <PageHeader match={match} actionButtons={this.actionButtons()} title="Edit Lease" />
        <AddContactDialog
          isOpen={addPersonDialog}
          closeDialog={this.closeAddPersonDialog}
          onAddContact={this.onAddPersonDialog}
        />
        <Dialog
          open={confirmDelete}
          onClose={() => this.setState({ confirmDelete: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Really Delete Lease?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">This lease will be removed</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ confirmDelete: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteLease} className={classes.deleteConfirmationButton}>
              Delete Lease
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <form onSubmit={this.editLease}>
                <SearchSelect
                  searchFunction={searchProperties(this)}
                  changeFunction={this.handlePropertyChange}
                  label="Property"
                  name="nested_lease_propertyId"
                  isMulti={false}
                  value={lease.propertyIdSelect}
                  margin="dense"
                  required
                />
                {selectedProperty && selectedProperty.unitSelection === 'multi' && (
                  <SearchSelect
                    searchFunction={searchUnits(this, { propertyId: selectedProperty.id })}
                    changeFunction={handleSearchSelectChange(this)}
                    label="Unit"
                    name="nested_lease_unitId"
                    isMulti={false}
                    value={lease.unitIdSelect}
                    margin="dense"
                    required
                  />
                )}
                <FormGridContainer>
                  <FormGridItem xs={12} sm={6}>
                    <KeyboardDatePicker
                      className={classes.datetime}
                      fullWidth
                      margin="dense"
                      label="Start Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={lease.startDateSelect}
                      onChange={handleKeyboardDatePickerChange('nested_lease_startDate', this)}
                      clearable
                      required
                    />
                  </FormGridItem>
                  <FormGridItem xs={12} sm={6}>
                    <KeyboardDatePicker
                      className={classes.datetime}
                      fullWidth
                      margin="dense"
                      label={monthToMonth ? 'No End Date' : 'End Date'}
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={lease.endDateSelect}
                      onChange={handleKeyboardDatePickerChange('nested_lease_endDate', this)}
                      clearable
                      disabled={monthToMonth}
                      required={!monthToMonth}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={monthToMonth} onChange={this.clickMonthToMonth} />}
                      label="Month to Month (no end date)"
                    />
                  </FormGridItem>
                </FormGridContainer>
                <SearchSelect
                  searchFunction={searchContacts(this)}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Tenants (Optional)"
                  name="nested_lease_tenants"
                  isMulti
                  value={lease.tenantsSelect}
                />
                <div>
                  <Button onClick={() => this.setState({ addPersonDialog: true })} color="primary">
                    Add Person
                  </Button>
                </div>
                <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">Rent Items</Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lease.recurringRents.map((recurringRent, index) => (
                        <TableRow key={recurringRent.id ? recurringRent.id : recurringRent.key}>
                          <TableCell>
                            <TextField
                              label="Description"
                              fullWidth
                              required
                              margin="dense"
                              InputProps={{
                                value: recurringRent.description,
                                name: `nested_lease_recurringRents_${index}_description`,
                                onChange: handleTextFieldChange(this),
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.amountCell}>
                            <FormControl margin="dense" fullWidth>
                              <InputLabel>Amount</InputLabel>
                              <NumberFormat
                                value={recurringRent.amount}
                                required
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                                onValueChange={handleNumberFormatChange(
                                  `nested_lease_recurringRents_${index}_amount`,
                                  this,
                                )}
                                customInput={Input}
                                endAdornment={
                                  index > 0 && (
                                    <InputAdornment position="end">
                                      <Button
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                          this.removeRecurringRent(index);
                                        }}
                                      >
                                        X
                                      </Button>
                                    </InputAdornment>
                                  )
                                }
                              />
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>
                          <Button onClick={this.addRecurringRent} color="primary">
                            Add Rent Item
                          </Button>
                        </TableCell>
                        <TableCell>
                          <NumberFormat
                            displayType="text"
                            value={sumProperty(lease.recurringRents, 'amount')}
                            thousandSeparator
                            prefix="Total: $"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
                <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel>Security Deposit (Optional)</InputLabel>
                    <NumberFormat
                      value={lease.securityDepositAmount}
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('nested_lease_securityDepositAmount', this)}
                      customInput={Input}
                    />
                  </FormControl>
                </Box>
                <Box marginBottom={2}>
                  <FormControl margin="dense">
                    <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                      Attachments
                    </FormLabel>
                    <div className={lease.attachmentURL ? null : classes.hideWidget}>
                      <Widget
                        ref={this.uploadcareWidget}
                        multiple
                        tabs="file url camera"
                        value={lease.attachmentURL}
                        onChange={handleUploaderChange('nested_lease_attachmentURL', this)}
                      />
                    </div>
                    {!lease.attachmentURL && (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          this.uploadcareWidget.current.openDialog();
                        }}
                      >
                        Click to Add Pictures or Files
                      </Button>
                    )}
                    {lease.attachmentURL && (
                      <Button
                        color="primary"
                        onClick={async () => {
                          await asyncHandleChange('nested_lease_attachmentURL', '', this);
                          this.uploadcareWidget.current.reloadInfo();
                        }}
                      >
                        Remove All Attachments
                      </Button>
                    )}
                  </FormControl>
                </Box>
                <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                  Save Lease
                </Button>
                <Typography color="error">{error && error.message}</Typography>
              </form>
            </CardContent>
          </CardBase>
        </Grid>
      </Grid>
    );
  }
}

EditLease.contextType = PersonContext;

EditLease.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(EditLease);
