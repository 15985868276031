import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import AddVendorDialog from '~/components/AddVendorDialog';
import Button from '~/components/Button';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import {
  setUpJournalForSubmit,
  setUpRuleJournalScope,
} from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getAccountOptions, getVendorOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowLoading from '../QuickBookRowLoading';

const DEPRECIATION_ERROR_MESSAGE = 'Depreciation is not a cash expense and should not be entered from the import feed.';

export default function QuickBookRowExpenseTemplate({
  transaction,
  onTransactionAction,
  onTransactionTypeChange,
  onGetHelp,
  matchState,
}) {
  const [vendorOptions, setVendorOptions] = useState([]);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldAddRule, setShouldAddRule] = useState(false);

  const { organizationId, organizationName, vendorTracking } = useContext(PersonContext);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const expenseAccountOptions = await getAccountOptions(organizationId, { type: 'Expense' });
      setExpenseAccountOptions(expenseAccountOptions);

      if (vendorTracking) {
        const vendorOptions = await getVendorOptions(organizationId, true);
        setVendorOptions(vendorOptions);
      }

      setIsLoading(false);
    }
    getData();
  }, [organizationId, vendorTracking]);

  const [isAddVendorDialogOpen, setIsAddVendorDialogOpen] = useState(false);

  const hasRuleMatch =
    matchState.importedTransactionRuleMatch.length === 1 &&
    matchState.importedTransactionRuleMatch[0].type === 'Expense';

  const [journal, setJournal] = useState(() => {
    const journalObject = getManualBasicJournal();
    if (!hasRuleMatch) {
      journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    }
    return journalObject;
  });

  useEffect(() => {
    setJournal((prevJournal) => {
      const newJournal = { ...prevJournal };
      newJournal.creditLines[0].accountIdSelect = transaction.account;
      newJournal.creditLines[0].transactionId = transaction.id;
      newJournal.description = transaction.description;
      newJournal.date = transaction.date;
      newJournal.amount = transaction.amount;
      return newJournal;
    });
  }, [transaction.account, transaction.id, transaction.description, transaction.date, transaction.amount]);

  useEffect(() => {
    if (isLoading || journal.debitLines[0].accountIdSelect) {
      return;
    }
    const journalObject = { ...journal };
    if (hasRuleMatch) {
      const setUpRuleJournal = async () => {
        const match = matchState.importedTransactionRuleMatch[0];
        journalObject.debitLines[0].accountIdSelect = expenseAccountOptions.find(
          (account) => account.id === match.destinationAccountId,
        );
        journalObject.vendorIdSelect = match.vendorId
          ? vendorOptions.find((vendor) => vendor.id === match.vendorId)
          : null;
        await setUpRuleJournalScope(match, journalObject, organizationName);

        setJournal(journalObject);
      };
      setUpRuleJournal();
    } else if (transaction.recommendedTransactionType === 'Expense' && transaction.recommendedAccount) {
      const defaultAccount = expenseAccountOptions.find(
        (account) => account.default === transaction.recommendedAccount,
      );
      if (defaultAccount) {
        journalObject.debitLines[0].accountIdSelect = defaultAccount;
        setJournal(journalObject);
      }
    }
  }, [
    expenseAccountOptions,
    hasRuleMatch,
    isLoading,
    journal,
    matchState,
    organizationName,
    vendorOptions,
    transaction.recommendedTransactionType,
    transaction.recommendedAccount,
  ]);

  async function addVendor(newVendor) {
    const newVendorOptions = await getVendorOptions(organizationId, true);
    setVendorOptions(newVendorOptions);

    setJournal((prevJournal) => ({ ...prevJournal, vendorIdSelect: newVendor }));
    setIsAddVendorDialogOpen(false);
  }

  const [isCapitalizationBoxDisplayed, setIsCapitalizationBoxDisplayed] = useState(false);

  function handleSwitchToFixedAssetPurchase() {
    const fixedAssetPurchaseTransactionType = { label: 'Fixed Asset Purchase', value: 'Fixed Asset Purchase' };
    onTransactionTypeChange(fixedAssetPurchaseTransactionType);
  }

  const [error, setError] = useState(null);

  useEffect(() => {
    if (
      error?.message === DEPRECIATION_ERROR_MESSAGE &&
      journal.debitLines[0].accountIdSelect.type2 !== 'Depreciation Expense'
    ) {
      setError(null);
    }
  }, [error, journal.debitLines]);

  const validateExpense = () => {
    if (journal.debitLines[0].accountIdSelect.type2 === 'Depreciation Expense') {
      setError({ message: DEPRECIATION_ERROR_MESSAGE });
      return false;
    }

    setError(null);
    return true;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const addExpense = async () => {
    event.preventDefault();
    const validation = validateExpense();

    if (isSubmitting || !validation) {
      return;
    }

    setIsSubmitting(true);

    const journalData = {
      transactionId: transaction.id,
      journal,
      organizationId,
      type: 'Expense',
      direction: 'cash out',
      bookAsRefund: true,
    };
    const journalSubmit = setUpJournalForSubmit(journalData);

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction(shouldAddRule, journalSubmit, matchState.descriptionMatches);
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
      return;
    }
  };

  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const classes = useStyles();

  const getDescriptionFieldClass = () => {
    if (isMobile) {
      return classes.mobileDescriptionField;
    }

    return !vendorTracking
      ? classes.descriptionFieldThirtyThreePercentAdjusted
      : classes.descriptionFieldThirtyThreePercent;
  };

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <>
      <AddVendorDialog
        isOpen={isAddVendorDialogOpen}
        closeDialog={() => {
          setIsAddVendorDialogOpen(false);
        }}
        onAddVendor={addVendor}
      />

      <form onSubmit={addExpense}>
        <Box className={classes.rootContainer}>
          {journal.amount > 2500 && !isCapitalizationBoxDisplayed && (
            <Alert severity="info" className={classes.infoAlert}>
              You may need to capitalize expenses over $2,500.{' '}
              <Box component="span" className={classes.link} onClick={() => setIsCapitalizationBoxDisplayed(true)}>
                Learn More
              </Box>
            </Alert>
          )}
          {isCapitalizationBoxDisplayed && (
            <Alert severity="info" className={classes.infoAlert}>
              The IRS generally requires you to capitalize items over $2,500 which improve or restore your property.
              This includes renovations and new or replacement tangible property, such as appliances.{` `}
              <Box
                component="span"
                className={classes.link}
                onClick={() => {
                  handleSwitchToFixedAssetPurchase();
                }}
              >
                Use a fixed asset purchase
              </Box>{' '}
              to record these transactions in REI Hub.
            </Alert>
          )}
          {journal.debitLines[0].accountIdSelect?.type2 === 'Depreciation Expense' && (
            <Alert severity="info" className={classes.infoAlert}>
              Depreciation is not a cash expense and should not be entered from the import feed. If you made capital
              expenditures that will be depreciated in the future, use the{' '}
              <Box
                component="span"
                className={classes.link}
                onClick={() => {
                  handleSwitchToFixedAssetPurchase();
                }}
              >
                Fixed Asset Purchase transaction type.
              </Box>
            </Alert>
          )}

          <Box className={classes.container}>
            <AutocompleteField
              className={classes.autoCompleteField}
              label="Expense Account"
              options={expenseAccountOptions}
              required
              value={journal.debitLines[0].accountIdSelect}
              onChange={(value) => {
                setJournal({ ...journal, debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }] });
              }}
            />

            {vendorOptions.length > 0 && (
              <AutocompleteField
                className={classes.autoCompleteField}
                label="Vendor (optional)"
                options={vendorOptions}
                value={journal.vendorIdSelect}
                onChange={(value) => {
                  if (value?.id === 'New Instance') {
                    setIsAddVendorDialogOpen(true);
                    return;
                  }
                  setJournal({ ...journal, vendorIdSelect: value });
                }}
              />
            )}

            <TextField
              className={getDescriptionFieldClass()}
              label="Description"
              placeholder="Description"
              variant="outlined"
              size="small"
              value={journal.description}
              onChange={(event) => {
                setJournal({ ...journal, description: event.target.value });
              }}
            />
          </Box>
          <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
          <QuickBookRowNotesAndAttachment
            journal={journal}
            onChange={(newJournal) => {
              setJournal(newJournal);
            }}
          />
          {error && error.message ? (
            <Alert severity="error" className={classes.errorAlert}>
              {error.message}
            </Alert>
          ) : null}
          <Box className={classes.actionsContainer}>
            <Button
              variant="outlined"
              size="small"
              className={classes.helpButton}
              onClick={() => {
                tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
                onGetHelp();
              }}
            >
              <HelpOutline className={classes.helpIcon} />
              Get Help
            </Button>
            <Box className={classes.rowActionsSecondaryContainer}>
              <Box className={classes.checkboxContainer}>
                <Checkbox
                  checked={shouldAddRule}
                  color="primary"
                  onClick={() => {
                    tracking('create_rule clicked', { location: 'Inline Booking - Import Feed' });
                    setShouldAddRule(!shouldAddRule);
                  }}
                />
                <Typography variant="body2">Create rule after saving</Typography>
              </Box>
              <Button
                variant="contained"
                className={classes.saveButton}
                size="small"
                fullWidth={false}
                type="submit"
                onClick={() => {
                  tracking('save clicked', { location: 'Inline Booking - Import Feed' });
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

QuickBookRowExpenseTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
  onGetHelp: PropTypes.func.isRequired,
  matchState: PropTypes.object.isRequired,
};
