import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';
import moment from 'moment';

import { create, find, get } from '~/feathersFunctionalWrapper';
import history from '~/history';
import AuthenticateLayout from '~/layouts/Authenticate/Layout';
import AUTHENTICATE_ROUTES_PATHS from '~/routes/constants';

import AddPortfolioDialog from '../../components/AddPortfolioDialog';
import RemovePortfolioDialog from '../../components/RemovePortfolioDialog';
import { PersonContext } from '../../contexts/PersonContext';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginCard: {
    maxWidth: '500px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  accountantContainer: {
    width: '100%',
    marginBottom: '20px',
  },
}));

export default function Organizations() {
  const classes = useStyles();
  const theme = useTheme();
  const {
    adminLogin,
    adminUserSelect,
    autoSelectOrganization,
    setContextState,
    setOrganizationId,
    userId,
    viewDemoPortfolio,
  } = useContext(PersonContext);
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [firms, setFirms] = useState([]);
  const [addPortfolio, setAddPortfolio] = useState(false);
  const [removeOrganizationId, setRemoveOrganizationId] = useState(null);

  const selectOrganization = useCallback(
    (organizationId) => {
      if (!adminUserSelect) {
        create('logins', { userId, organizationId });
      }
      localStorage.setItem('organizationId', organizationId);
      setOrganizationId(organizationId);
    },
    [adminUserSelect, setOrganizationId, userId],
  );

  const getData = useCallback(async () => {
    if (!userId) {
      await history.push('/sign-in');
      return;
    }

    let userIdLookup = userId;
    if (adminUserSelect && adminUserSelect.id) {
      userIdLookup = adminUserSelect.id;
    }

    const administrators = await find('administrators', {
      query: {
        userId: userIdLookup,
        $limit: 50,
      },
    });

    const user = await get('users', userIdLookup);

    if (administrators.total === 1 && autoSelectOrganization && !user.isAccountant) {
      selectOrganization(administrators.data[0].organizationId);
    } else {
      const organizationsResults = await find('organizations', {
        query: {
          id: administrators.data.map((admin) => admin.organizationId),
          $limit: 50,
        },
      });

      let firms = null;
      if (user.isAccountant) {
        const accountingAdminResults = await find('accounting-administrators', {
          query: { userId: userIdLookup },
        });
        firms = accountingAdminResults.data;
      }

      setOrganizations(organizationsResults.data);
      setFirms(firms);
      setLoading(false);
    }
  }, [adminUserSelect, autoSelectOrganization, selectOrganization, userId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getSubscriptionMessage = (organization) => {
    if (organization.partnerSubscribed) {
      return `Subscribed via ${organization.partnerName}`;
    }
    if (organization.stripeSubscription) {
      return 'Subscribed';
    }
    if (organization.partnerName === 'Innago') {
      return `Innago Trial Account: ${
        moment().diff(moment(organization.freeTrialExpires).endOf('day')) < 0
          ? `${moment(organization.freeTrialExpires).diff(moment(), 'days') + 1} days remaining`
          : 'Expired'
      }`;
    }
    return `Trial Account: ${
      moment().diff(moment(organization.freeTrialExpires).endOf('day')) < 0
        ? `${moment(organization.freeTrialExpires).diff(moment(), 'days') + 1} days remaining`
        : 'Expired'
    }`;
  };

  if (loading) {
    return null;
  }

  return (
    <AuthenticateLayout>
      <Grid container justify="center" className={classes.root}>
        <AddPortfolioDialog
          isOpen={addPortfolio}
          closeDialog={() => setAddPortfolio(false)}
          selectOrganization={selectOrganization}
        />
        {removeOrganizationId && (
          <RemovePortfolioDialog
            closeDialog={() => setRemoveOrganizationId(null)}
            organizationId={removeOrganizationId}
            onModified={() => getData()}
          />
        )}
        {firms && (
          <Box className={classes.accountantContainer}>
            <Grid item xs={12} className={classes.centerContent}>
              <Card className={classes.loginCard}>
                <Box display="flex" flexDirection="column" width={1} px={2} py={2}>
                  <Typography variant="h6" align="center" gutterBottom>
                    Choose an Accountant
                  </Typography>
                  {firms.map((firm) => (
                    <Box
                      marginTop={1}
                      paddingY={1}
                      borderBottom={`1px solid ${theme.palette.divider}`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      key={firm.id}
                    >
                      <Box>
                        <Typography variant="subtitle1">{firm.accounting_firm.name}</Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        size="large"
                        startIcon={<LaunchIcon />}
                        onClick={() => {
                          setContextState({
                            accountingFirmId: firm.accountingFirmId,
                            accountantLogin: 1,
                          });
                          localStorage.setItem('accountingFirmId', firm.accountingFirmId);
                        }}
                      >
                        View
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Card>
            </Grid>
          </Box>
        )}
        <Grid item xs={12} className={classes.centerContent}>
          <Card className={classes.loginCard}>
            <Box display="flex" flexDirection="column" width={1} px={2} py={2}>
              <Typography variant="h6" align="center" gutterBottom>
                Choose a Portfolio
              </Typography>
              {organizations.map((organization) => (
                <Box
                  marginTop={1}
                  paddingY={1}
                  borderBottom={`1px solid ${theme.palette.divider}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  key={organization.id}
                >
                  <Box>
                    <Typography variant="subtitle1">{organization.name}</Typography>
                    <Typography variant="caption">{getSubscriptionMessage(organization)}</Typography>
                    {!organization.partnerSubscribed && !organization.stripeSubscription && (
                      <Box component="div" color="error.main">
                        <Button
                          variant="text"
                          color="inherit"
                          size="small"
                          disableElevation
                          onClick={() => setRemoveOrganizationId(organization.id)}
                        >
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                    startIcon={<LaunchIcon />}
                    onClick={() => selectOrganization(organization.id)}
                  >
                    {getSubscriptionMessage(organization) !== 'Trial Account: Expired' ? 'View' : 'Subscribe'}
                  </Button>
                </Box>
              ))}
              <Box marginTop="30px">
                <Button variant="outlined" color="primary" size="large" fullWidth onClick={() => setAddPortfolio(true)}>
                  Add a portfolio
                </Button>
                <Box marginTop={1.5}>
                  <Button variant="outlined" color="primary" size="large" fullWidth onClick={viewDemoPortfolio}>
                    View demo portfolio
                  </Button>
                </Box>
                {adminLogin && (
                  <Box marginTop={1.5}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => history.push(AUTHENTICATE_ROUTES_PATHS.ADMIN_PASSWORD_RESET)}
                    >
                      Reset Password
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </AuthenticateLayout>
  );
}
