import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import { PersonContext } from '~/contexts/PersonContext';

import CurrentOverview from './CurrentOverview';
import ExperimentalOverview from './ExperimentalOverview';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '600px',
    width: '100%',
  },
}));

export default function Overview({ setAddAccountDialog, setPlaidAddNew, setSkipForNowDialogVisible, setState }) {
  const { settings } = useContext(PersonContext);
  const classes = useStyles();

  if (settings?.['2_variant_test'] === 'test') {
    return (
      <ExperimentalOverview
        setAddAccountDialog={setAddAccountDialog}
        setPlaidAddNew={setPlaidAddNew}
        setState={setState}
      />
    );
  }

  return (
    <Card scroll="body" className={classes.card}>
      <CurrentOverview
        setPlaidAddNew={setPlaidAddNew}
        setSkipForNowDialogVisible={setSkipForNowDialogVisible}
        setState={setState}
      />
    </Card>
  );
}

Overview.propTypes = {
  setAddAccountDialog: PropTypes.func.isRequired,
  setPlaidAddNew: PropTypes.func.isRequired,
  setSkipForNowDialogVisible: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
