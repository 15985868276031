import React from 'react';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { get, patch } from '../feathersWrapper';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
  red: {
    color: theme.palette.error.main,
  },
});

class ViewImportedTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { yodleeTransactionId } = this.props;

    const yodleeTransaction = await get(this, 'yodlee-transactions', yodleeTransactionId);

    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      yodleeTransaction,
      key: 'viewImportedTransaction',
    };

    this.setState(initialState);
  };

  getDialogContent = (key) => {
    const { classes } = this.props;
    const { error, yodleeTransaction } = this.state;

    switch (key) {
      case 'viewImportedTransaction':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Matched Imported Transaction</DialogTitle>
            <DialogContent>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Date
                </FormLabel>
                <Typography>{moment(yodleeTransaction.date).format('M/D/YYYY')}</Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Amount
                </FormLabel>
                <Typography>
                  <NumberFormat
                    displayType="text"
                    value={yodleeTransaction.amount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                  />
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Account
                </FormLabel>
                <Typography>{yodleeTransaction.account.name}</Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Statement Description
                </FormLabel>
                <Typography>
                  {yodleeTransaction.merchant ? `${yodleeTransaction.merchant} - ` : ''}
                  {yodleeTransaction.description}
                  {yodleeTransaction.splitParentId ? ' (split)' : ''}
                </Typography>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
              <Button
                onClick={() => this.setState({ key: 'confirmUnmatch' })}
                className={classes.deleteConfirmationButton}
              >
                Unmatch Transaction
              </Button>
            </DialogActions>
          </>
        );
      case 'confirmUnmatch':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Really Unmatch Transaction?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`This transaction will be reverted to unbooked status
                  in your import feed.`}
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.unmatchYodleeTransaction} className={classes.deleteConfirmationButton}>
                Unmatch Transaction
              </Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  unmatchYodleeTransaction = async () => {
    const { onUnmatchTransaction } = this.props;
    const { submitting, yodleeTransaction } = this.state;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    await patch(this, 'journal-lines', yodleeTransaction.journalLineId, { yodleeTransactionId: null });
    await patch(this, 'yodlee-transactions', yodleeTransaction.id, { journalId: null, journalLineId: null });
    this.closeDialog();
    onUnmatchTransaction();
  };

  render() {
    const { isOpen } = this.props;
    const { loading, key } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={this.closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addTransactionDialog"
      >
        {!loading && this.getDialogContent(key)}
      </Dialog>
    );
  }
}

ViewImportedTransactionDialog.contextType = PersonContext;

ViewImportedTransactionDialog.defaultProps = {
  yodleeTransactionId: null,
};

ViewImportedTransactionDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onUnmatchTransaction: PropTypes.func.isRequired,
  yodleeTransactionId: PropTypes.node,
};

export default withStyles(styles)(ViewImportedTransactionDialog);
