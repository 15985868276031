import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { find, get, patch, remove } from '../feathersWrapper';
import { handleCheckboxChange, handleTextFieldChange } from '../functions/InputHandlers';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  deleteConfirmationButton: {
    color: theme.palette.error.main,
  },
});

class EditVehicleDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  setInitialState = async () => {
    const { vehicleToEdit } = this.props;
    const { organizationId } = this.context;

    const vehicle = await get(this, 'vehicles', vehicleToEdit.id);

    const query = { organizationId, vehicleId: vehicleToEdit.id, $limit: 0 };
    const mileageResponse = await find(this, 'mileage', { query });

    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      vehicle,
      mileageTransactions: mileageResponse.total,
      confirmDelete: false,
    };

    this.setState(initialState);
  };

  editVehicle = async (event) => {
    event.preventDefault();
    const { submitting, vehicle } = this.state;

    const { onEdit, closeDialog } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    await patch(this, 'vehicles', vehicle.id, {
      name: vehicle.name,
      inactive: vehicle.inactive,
    })
      .then(() => {
        onEdit();
        closeDialog();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteVehicle = async () => {
    const { vehicle } = this.state;
    const { onDelete, closeDialog } = this.props;

    await remove(this, 'vehicles', vehicle.id)
      .then(() => {
        onDelete();
        closeDialog();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { loading, error, vehicle, mileageTransactions, confirmDelete } = this.state;

    return (
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editVehicle}>
            <DialogTitle id="alert-dialog-title">Edit Vehicle</DialogTitle>
            <DialogContent>
              <TextField
                label="Vehicle Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: vehicle.name,
                  name: 'nested_vehicle_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControl fullWidth margin="none">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={vehicle.inactive}
                      onChange={handleCheckboxChange(this)}
                      name="nested_vehicle_inactive"
                      color="primary"
                    />
                  }
                  label="Vehicle is Inactive"
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Vehicle
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              {mileageTransactions === 0 && (
                <Button
                  onClick={() => this.setState({ confirmDelete: true })}
                  className={classes.deleteConfirmationButton}
                  color="primary"
                >
                  Delete
                </Button>
              )}
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Vehicle?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">This vehicle will be removed.</DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteVehicle} className={classes.deleteConfirmationButton}>
                Delete Vehicle
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditVehicleDialog.contextType = PersonContext;

EditVehicleDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  vehicleToEdit: PropTypes.objectOf(PropTypes.node).isRequired,
};

export default withStyles(styles)(EditVehicleDialog);
