import { makeStyles } from '@material-ui/core';

const SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const sizesMap = {
  [SIZE.LARGE]: {
    height: '300px',
  },
  [SIZE.MEDIUM]: {
    height: '150px',
  },
  [SIZE.SMALL]: {
    height: '75px',
  },
};

export const useStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  loadingHeader: {
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      height: `${theme.spacing(6)}px`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `${theme.spacing(9)}px`,
    },
    width: '100vw',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  loadingImage: {
    margin: 'auto',
    height: (props) => sizesMap[props.size].height,
  },
}));
