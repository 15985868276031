import React, { useContext, useEffect, useMemo, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import IconButton from '~/components/IconButton';
import SplitLines, { generateSplitLine } from '~/components/SplitLines';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { sumProperty } from '~/functions/SumFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import Book from './Book';
import { ADD_RULE_SEGMENT_LOCATION, AUTOMATION_NAMES_MAP, RULE_TYPES, TRANSACTION_TYPES } from './constants';
import Ignore from './Ignore';
import RuleTypeSelect from './RuleTypeSelect';
import useStyles from './styles';
import { getInitialRuleTransactionType, getInitialRuleType, getRuleBody } from './utils';

export default function AddRuleDrawer({ transaction, transactionJournal, onAdd, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [accountOptions, setAccountOptions] = useState([]);
  const [ruleData, setRuleData] = useState({
    descriptionContains: '',
    selectedAccount: null,
    selectedDestinationAccount: null,
    selectedFixedAsset: null,
    selectedRuleType: getInitialRuleType(transaction?.ruleType),
    selectedTransactionType: getInitialRuleTransactionType(transactionJournal),
    selectedVendor: null,
    splitLines: transaction?.splitLines || [generateSplitLine(), generateSplitLine()],
    transactionAmount: 0,
  });
  const [accountInputValue, setAccountInputValue] = useState('');
  const [isAmountEnabled, setIsAmountEnabled] = useState(false);
  const [journal, setJournal] = useState(
    transactionJournal || { journalScopeSelect: { name: 'Property/Unit', id: 'Property/Unit' } },
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organizationId, triggerSnackbar } = useContext(PersonContext);
  const tracking = useSegmentTrack();
  const classes = useStyles({ selectedAccount: ruleData.selectedAccount });

  const isValidForm = useMemo(() => {
    if (ruleData.descriptionContains.length === 0) {
      return false;
    }

    if (ruleData.selectedRuleType === RULE_TYPES.BOOK) {
      if (!ruleData.selectedTransactionType) return false;
      if (!ruleData.selectedDestinationAccount) return false;
      if (
        ruleData.selectedTransactionType.value === TRANSACTION_TYPES.FIXED_ASSET_PURCHASE &&
        !ruleData.selectedFixedAsset
      ) {
        return false;
      }
    }

    return true;
  }, [ruleData]);

  function handleRuleDataChange(key, value) {
    setRuleData((prevRuleData) => ({ ...prevRuleData, [key]: value }));
  }

  function handleChangeAccount(_event, newValue) {
    handleRuleDataChange('selectedAccount', newValue || accountOptions[0]);
  }

  function handleClose() {
    tracking('close clicked', { location: ADD_RULE_SEGMENT_LOCATION });
    onClose();
  }

  function handleCancel() {
    tracking('cancel clicked', { location: ADD_RULE_SEGMENT_LOCATION });
    onClose();
  }

  function areTransactionAmountAndSplitLinesEqual() {
    if (
      isAmountEnabled &&
      ruleData.selectedRuleType === RULE_TYPES.SPLIT &&
      parseFloat(sumProperty(ruleData.splitLines, 'amount')) !== ruleData.transactionAmount
    ) {
      triggerSnackbar({ message: 'The total of the Split Lines must equal the Transaction Amount', type: 'warning' });
      return false;
    }

    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    tracking('save_rule clicked', { location: ADD_RULE_SEGMENT_LOCATION });

    if (isSubmitting || !areTransactionAmountAndSplitLinesEqual()) {
      return;
    }

    setIsSubmitting(true);

    const ruleBody = getRuleBody({ isAmountEnabled, journal, organizationId, ruleData });

    try {
      const importedTransactionRule = await create('imported-transaction-rules', ruleBody);
      const automationResult = await create('automations', {
        automationName: AUTOMATION_NAMES_MAP[ruleBody.type],
        organizationId,
        ruleId: importedTransactionRule.id,
      });

      triggerSnackbar({
        autoHideDuration: automationResult.newTransactions ? 6000 : 3000,
        horizontal: 'center',
        message: `Rule added${automationResult.newTransactions ? `. ${automationResult.newTransactions} transactions matched the template and were booked automatically.` : ''}`,
        type: 'success',
        vertical: 'top',
      });

      onAdd();
      setIsSubmitting(false);
      onClose();
    } catch (error) {
      console.error(error);
      triggerSnackbar({ message: 'An error occurred while creating the rule.', type: 'error' });

      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      const options = await getAccountOptions(organizationId, { type2: ['Bank', 'Credit Card'] });
      const finalOptions = [{ id: 'none', name: 'Any account' }, ...options];
      setAccountOptions(finalOptions);
      if (!transactionJournal && !transaction) {
        handleRuleDataChange('selectedAccount', finalOptions[0]);
      }
      setIsLoading(false);
    }

    loadData();
  }, [organizationId, transactionJournal, transaction]);

  // Clear the selected destination account whenever we change the transaction type because the autocomplete options change
  useEffect(() => {
    handleRuleDataChange('selectedDestinationAccount', null);
  }, [ruleData.selectedTransactionType]);

  useEffect(() => {
    if (ruleData.selectedRuleType === RULE_TYPES.SPLIT) {
      setIsAmountEnabled(true);
    }
  }, [ruleData.selectedRuleType]);

  // Hide the intercom launcher when the drawer is open because it overlaps with the Create button
  useEffect(() => {
    const intercomLauncher =
      document.querySelector('.intercom-app') || document.querySelector('.intercom-lightweight-app');
    if (intercomLauncher) {
      intercomLauncher.style.display = 'none';
    }

    return () => {
      if (intercomLauncher) {
        intercomLauncher.style.display = 'block';
      }
    };
  }, []);

  useEffect(() => {
    setRuleData((prevRuleData) => {
      const newRuleData = { ...prevRuleData };
      if (transactionJournal) {
        newRuleData.descriptionContains = transactionJournal.description;
        newRuleData.selectedAccount = transactionJournal.creditLines[0].yodleeTransactionId
          ? transactionJournal.creditLines[0].accountIdSelect
          : transactionJournal.debitLines[0].accountIdSelect;
        newRuleData.selectedDestinationAccount = transactionJournal.debitLines[0].yodleeTransactionId
          ? transactionJournal.creditLines[0].accountIdSelect
          : transactionJournal.debitLines[0].accountIdSelect;
        newRuleData.selectedVendor = transactionJournal.vendorId ? transactionJournal.vendorIdSelect : null;
        newRuleData.transactionAmount = transactionJournal.amount;
        newRuleData.selectedFixedAsset = transactionJournal.fixedAssetId ? transactionJournal.fixedAssetIdSelect : null;
      }
      return newRuleData;
    });
  }, [transactionJournal]);

  useEffect(() => {
    setRuleData((prevRuleData) => {
      const newRuleData = { ...prevRuleData };
      if (transaction) {
        newRuleData.selectedAccount = transaction.account;
        newRuleData.descriptionContains = transaction.description;
        newRuleData.transactionAmount = transaction.amount;
      }
      return newRuleData;
    });
  }, [transaction]);

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.container,
      }}
      open
      onClose={handleClose}
    >
      <form className={classes.form} onSubmit={handleSubmit}>
        <header className={classes.header}>
          <Typography variant="subtitle1">Add Rule</Typography>
          <IconButton aria-label="Close drawer" color="primary" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </header>

        <main className={classes.main}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Typography className={classes.label} variant="h6">
                When a transaction from
              </Typography>
              <Autocomplete
                classes={{
                  clearIndicator: classes.autocompleteClearIndicator,
                  endAdornment: classes.autocompleteEndAdornment,
                  option: classes.autocompleteOption,
                  root: classes.textField,
                }}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                inputValue={accountInputValue}
                options={accountOptions}
                size="small"
                value={ruleData.selectedAccount}
                onChange={handleChangeAccount}
                onInputChange={(_event, newValue) => setAccountInputValue(newValue)}
                renderInput={(params) => (
                  <TextField label="Account" placeholder="Select an account" variant="outlined" {...params} />
                )}
              />

              <Typography className={classes.label} variant="h6">
                includes the following
              </Typography>
              <TextField
                className={classes.textField}
                fullWidth
                label="Description containing"
                required
                size="small"
                value={ruleData.descriptionContains}
                variant="outlined"
                onChange={(event) => handleRuleDataChange('descriptionContains', event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <div className={classes.textField}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAmountEnabled}
                      disabled={ruleData.selectedRuleType === RULE_TYPES.SPLIT}
                      size="small"
                      onChange={() => setIsAmountEnabled(!isAmountEnabled)}
                    />
                  }
                  label="and the"
                />
                <CurrencyField
                  className={classes.currencyField}
                  disabled={!isAmountEnabled}
                  label="Amount"
                  placeholder="$0.00"
                  size="small"
                  value={ruleData.transactionAmount || ''}
                  variant="outlined"
                  onChange={(event) => handleRuleDataChange('transactionAmount', Number(event.target.value || 0))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className={classes.ruleTypeContainer}>
                <Typography variant="h6">then</Typography>
                <RuleTypeSelect
                  ruleType={ruleData.selectedRuleType}
                  setRuleType={(value) => handleRuleDataChange('selectedRuleType', value)}
                />
              </div>

              {ruleData.selectedRuleType === RULE_TYPES.BOOK && (
                <Book
                  journal={journal}
                  selectedDestinationAccount={ruleData.selectedDestinationAccount}
                  selectedFixedAsset={ruleData.selectedFixedAsset}
                  selectedTransactionType={ruleData.selectedTransactionType}
                  selectedVendor={ruleData.selectedVendor}
                  setJournal={setJournal}
                  setSelectedDestinationAccount={(value) => handleRuleDataChange('selectedDestinationAccount', value)}
                  setSelectedFixedAsset={(value) => handleRuleDataChange('selectedFixedAsset', value)}
                  setSelectedTransactionType={(value) => handleRuleDataChange('selectedTransactionType', value)}
                  setSelectedVendor={(value) => handleRuleDataChange('selectedVendor', value)}
                />
              )}
              {ruleData.selectedRuleType === RULE_TYPES.IGNORE && <Ignore />}
              {ruleData.selectedRuleType === RULE_TYPES.SPLIT && (
                <SplitLines
                  splitLines={ruleData.splitLines}
                  setSplitLines={(value) => handleRuleDataChange('splitLines', value)}
                />
              )}
            </>
          )}
        </main>

        <footer className={classes.footer}>
          <Button color="primary" disabled={isSubmitting} size="large" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" disabled={isSubmitting || !isValidForm} size="large" type="submit">
            Create
          </Button>
        </footer>
      </form>
    </Drawer>
  );
}

AddRuleDrawer.propTypes = {
  transaction: PropTypes.object,
  transactionJournal: PropTypes.object,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
