import React, { useContext, useEffect, useRef, useState } from 'react';
// both named and default are exported. The default MUST be used
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { Chip, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Check } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CardElement } from '@stripe/react-stripe-js';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { IMPACT_COOKIE_KEY } from '~/constants';
import { PersonContext } from '~/contexts/PersonContext';
import client from '~/feathers';
import { create, find, patch } from '~/feathersFunctionalWrapper';
import { getDiscount, getPlans } from '~/functions/BuyNowFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  cardsContainer: {
    width: '100%',
    maxWidth: '900px',
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingTop: '24px',
    },
  },
  firstRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  card: {
    width: '100%',
    height: 'fit-content',
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '442px',
    },
  },
  elementFocus: {
    borderBottomColor: theme.palette.secondary.main,
    borderBottomWidth: '2px',
    paddingBottom: '4px',
  },
  stripeContainer: {
    position: 'relative',
  },
  cardElementWrapper: {
    position: 'absolute',
    top: 0,
    padding: '30.5px 14px 18.5px 14px',
    width: '100%',
    zIndex: 2,
  },
  captionContainer: {
    marginTop: 3,
    marginLeft: '14px',
    color: theme.palette.text.secondary,
  },
  savingsCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: theme.spacing(2.5),
  },
  savingsCardTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowTwoCaptionContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    marginTop: 15,
    gap: 10,
  },
  rowTwoCaptionContainerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  billedMonthlyTextContainer: {
    display: 'flex',
    marginRight: 10,
  },
  fieldWrapper: {
    marginTop: '12px',
    width: '100%',
  },
  errorMessage: {
    marginTop: '20px',
    lineHeight: 1.2,
  },
  linkWrapper: {
    width: '100%',
  },
  ctaButton: {
    marginTop: '20px',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    textTransform: 'none',
  },
  boldText: {
    fontWeight: 'bold',
  },
  limitedTimeOfferChipContainer: {
    alignSelf: 'flex-start',
    marginTop: '-33px',
    position: 'absolute',
  },
  limitedTimeOfferChip: {
    'borderRadius': '4px',
    'backgroundColor': '#FFCD00',
    'fontWeight': 700,
    'fontSize': '12px',
    'marginBottom': '10px',
    'maxHeight': '24px',
    '& span': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}));

function getImpactClickId() {
  const impactCookie = Cookies.get(IMPACT_COOKIE_KEY);
  if (!impactCookie) return null;

  const impactCookieDecoded = decodeURIComponent(impactCookie);
  if (impactCookieDecoded.split('|').length <= 4) return null;

  return impactCookieDecoded.split('|')[3];
}

export default function View({ stripe, elements, isLimitedTimeOffer }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const recaptchaRef = useRef();
  const { setPerson, setContextState, id, isProduction } = useContext(PersonContext);

  const discount = getDiscount(isLimitedTimeOffer);
  const plans = getPlans(discount);

  const [unitSelection, setUnitSelection] = useState(plans[0]);
  const [hasElementFocus, setHasElementFocus] = useState(false);
  const [isElementComplete, setIsElementComplete] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tracking = useSegmentTrack();
  useEffect(() => {
    if (id) return;
    tracking('checkout loaded', { location: 'Buy Now - Checkout' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parseQueryString = async () => {
      const parsedQuery = queryString.parse(location.search);

      const registrationResponse = await find('registrations', {
        query: {
          lookupHash: parsedQuery.registration,
        },
      });
      if (registrationResponse.total !== 1) {
        history.push(`/register-profile?registration=${parsedQuery.registration}`);
      }
      const registration = registrationResponse.data[0];

      const plan = plans.find((option) => option.unitsIncluded === registration.unitsIncluded);
      if (plan) {
        setUnitSelection(plan);
      } else {
        history.push(`/register-profile?registration=${parsedQuery.registration}`);
      }
    };
    parseQueryString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkout = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      if (!isElementComplete) {
        throw new Error('Please complete your card information.');
      }
      setIsSubmitting(true);

      const cardElement = elements.getElement(CardElement);
      // Use your card Element with other Stripe.js APIs
      const { stripeError, source } = await stripe.createSource(cardElement, {
        type: 'card',
      });

      if (error) {
        throw stripeError;
      }
      if (!source) {
        throw new Error('Unable to process card. Please refresh the page and try again.');
      }

      const parsedQuery = queryString.parse(location.search);
      const recaptchaToken = recaptchaRef.current ? await recaptchaRef.current.executeAsync() : null;
      const buyNowResponse = await create('buy-now', {
        lookupHash: parsedQuery.registration,
        stripeSource: source.id,
        plan: unitSelection.id,
        maxUnits: unitSelection.maxUnits,
        discount: discount.id,
        recaptchaToken,
      });

      // Track registration
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newRegistrationComplete',
        organizationId: buyNowResponse.organizationId,
        userId: buyNowResponse.id,
      });

      tracking('confirm_purchase clicked', { location: 'Buy Now - Checkout' });

      await client.authentication.setAccessToken(buyNowResponse.accessToken);
      client.reAuthenticate().then(async (response) => {
        await setPerson(response);
        setContextState({ sessionSource: 'buy now' });
      });

      const impactClickId = getImpactClickId();

      if (impactClickId) {
        const { organizationId } = buyNowResponse;
        await patch('organizations', organizationId, { analyticsTracking: { impactClickId } });
      }
    } catch (err) {
      setError(err);
      recaptchaRef.current.reset();
      setIsSubmitting(false);
    }
  };

  const elementOnChange = async (event) => {
    if (event.complete) {
      setError(null);
    }
    setIsElementComplete(event.complete);
  };

  return (
    <Box className={classes.container}>
      <form className={classes.form} onSubmit={checkout}>
        <Box className={classes.cardsContainer}>
          <Box className={classes.cards}>
            <Box className={classes.firstRowContainer}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography className={classes.boldText} variant="h6" align="center">
                    Review & Pay
                  </Typography>
                  <Box className={classes.stripeContainer}>
                    <TextField
                      fullWidth
                      label="Payment"
                      focused={hasElementFocus}
                      variant="outlined"
                      className={classes.fieldWrapper}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Box className={classes.cardElementWrapper}>
                      <CardElement
                        className={`${classes.bottomBorder} ${hasElementFocus ? classes.elementFocus : ''}`}
                        onFocus={() => setHasElementFocus(true)}
                        onBlur={() => setHasElementFocus(false)}
                        onChange={elementOnChange}
                        onReady={(e) => e.focus()}
                        options={{
                          style: {
                            base: {
                              fontSize: '18.2857px',
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: theme.typography.fontWeight,
                              color: 'rgba(0, 0, 0, 0.8)',
                            },
                            invalid: {
                              color: theme.palette.error.main,
                            },
                          },
                          disableLink: true,
                        }}
                      />
                    </Box>
                  </Box>
                  {isProduction ? (
                    <ReCAPTCHA
                      sitekey="6LdCnJscAAAAANpuCS4G9OWfER1Sphen8uXne-bD"
                      ref={recaptchaRef}
                      size="invisible"
                      badge="bottomright"
                    />
                  ) : null}
                  {error && (
                    <Typography color="error" gutterBottom>
                      {error.message}
                    </Typography>
                  )}

                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className={classes.ctaButton}
                  >
                    Confirm Purchase
                  </Button>
                </CardContent>
              </Card>
            </Box>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.boldText} variant="h6" align="left" gutterBottom>
                  Your Plan
                </Typography>
                <Autocomplete
                  options={plans}
                  getOptionLabel={(option) => option.units}
                  value={unitSelection}
                  onChange={(_event, value) => {
                    setUnitSelection(value);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Units Included"
                      fullWidth
                      required
                      variant="outlined"
                      onClick={() => {
                        tracking('units_included_dropdown clicked', { location: 'Buy Now - Checkout' });
                      }}
                    />
                  )}
                />
                <Box className={classes.captionContainer}>
                  <Typography variant="caption">The number of units you'll add to your portfolio.</Typography>
                </Box>
                <Card className={classes.savingsCard}>
                  {isLimitedTimeOffer ? (
                    <Box className={classes.limitedTimeOfferChipContainer}>
                      <Chip label="Discount Applied" className={classes.limitedTimeOfferChip} />
                    </Box>
                  ) : null}
                  <Box className={classes.savingsCardTextContainer}>
                    <Typography className={classes.boldText} variant="body1">
                      Plan
                    </Typography>
                    <Typography variant="body1">{`$${unitSelection.price.toFixed(2)}/mo`}</Typography>
                  </Box>
                  <Box className={classes.savingsCardTextContainer}>
                    <Typography className={classes.boldText} variant="body1">
                      {`${discount.discountPercent}% Discount (${discount.duration} months)`}
                    </Typography>
                    <Typography variant="body1">
                      {`-$${(unitSelection.price - unitSelection.discountPrice).toFixed(2)}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Divider />
                  </Box>
                  <Box className={classes.savingsCardTextContainer}>
                    <Typography className={classes.boldText} variant="body1">
                      Your Total
                    </Typography>
                    <Typography color="primary" variant="body1" className={classes.boldText}>
                      {`$${unitSelection.discountPrice.toFixed(2)}/mo`}
                    </Typography>
                  </Box>
                  <Box className={classes.rowTwoCaptionContainer}>
                    <Box className={classes.rowTwoCaptionContainerText}>
                      <Box>
                        <Check fontSize="small" />
                      </Box>
                      <Box>
                        <Typography variant="body1">Billed monthly</Typography>
                      </Box>
                    </Box>
                    <Box className={classes.rowTwoCaptionContainerText}>
                      <Box>
                        <Check fontSize="small" />
                      </Box>
                      <Box>
                        <Typography variant="body1">Cancel anytime</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

View.defaultProps = {
  stripe: null,
  elements: null,
  isLimitedTimeOffer: false,
};

View.propTypes = {
  stripe: PropTypes.objectOf(PropTypes.any),
  elements: PropTypes.objectOf(PropTypes.any),
  isLimitedTimeOffer: PropTypes.bool,
};
