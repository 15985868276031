import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AddBankingAccountDialog from '~/components/AddBankingAccountDialog';
import AddLoanDialog from '~/components/AddLoanDialog';
import PlaidLinkAdd from '~/components/Plaid/PlaidLinkAdd';
import SkipForNowAccountsDialog from '~/components/SkipForNowAccountsDialog';
import YodleeLinkAdd from '~/components/Yodlee/YodleeLinkAdd';
import { PersonContext } from '~/contexts/PersonContext';
import { find } from '~/feathersWrapper';

import Content from './Content';
import PlaidExitDialog from './PlaidExitDialog';

export default function AccountsSetup({ allAccounts = [], onAddAccount }) {
  const [loading, setLoading] = useState(true);
  const [addAccountDialog, setAddAccountDialog] = useState(false);
  const [plaidAddNew, setPlaidAddNew] = useState(false);
  const [yodleeAddLink, setYodleeAddLink] = useState(false);
  const [addLoanAccountDialog, setAddLoanAccountDialog] = useState(false);
  const [skipForNowDialogVisible, setSkipForNowDialogVisible] = useState(false);
  const [plaidExitDialogVisible, setPlaidExitDialogVisible] = useState(false);
  const [state, setState] = useState({
    key: 'accountsOverview',
  });

  const { organizationId, organizationName, bookkeepingStartDate } = useContext(PersonContext);

  const addAccount = useCallback(async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onboardingAddAccount',
      organizationId,
    });

    await onAddAccount();

    const query = {
      organizationId,
      type2: ['Bank', 'Credit Card'],
    };

    let key;
    if (state.plaidItem) {
      key = 'plaidSuccess';
    } else if (state.yodleeProvider) {
      key = 'yodleeSuccess';
    } else {
      key = 'addAccountsSuccess';
    }

    const accounts = await find(this, 'accounts', { query });
    setState((prevState) => ({
      ...prevState,
      key,
      accountCount: accounts.total,
    }));
  }, [organizationId, state.plaidItem, state.yodleeProvider, onAddAccount]);

  const addLoanAccount = useCallback(async () => {
    const query = {
      organizationId,
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
    };
    const loanAccounts = await find(this, 'accounts', { query });

    setState((prevState) => ({
      ...prevState,
      loanAccountCount: loanAccounts.total,
      key: 'addAnotherLoanAccount',
    }));
  }, [organizationId]);

  const onPlaidSuccess = useCallback(
    (plaidItem) => {
      const addedAccounts = [];
      plaidItem.accounts.forEach((account) => {
        addedAccounts.push({
          plaidAccountId: account.id,
          mask: account.mask,
          name: account.name,
          journal: {
            entityId: null,
            propertyId: null,
            unitId: null,
            journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
          },
        });
      });
      setPlaidAddNew(false);
      setState({
        plaidItem,
        key: 'plaidSuccess',
        addedAccounts,
        accountCount: state.accountCount + addedAccounts.length,
      });
    },
    [organizationName, state.accountCount],
  );

  const onYodleeSuccess = useCallback(
    async (yodleeProvider) => {
      const { providerAccountId } = yodleeProvider;
      const query = {
        organizationId,
        providerAccountId,
      };
      const yodleeAccounts = await find(this, 'yodlee-accounts', { query });

      const addedAccounts = [];
      yodleeAccounts.data.forEach((account) => {
        addedAccounts.push({
          yodleeAccountId: account.accountId,
          mask: account.mask,
          name: account.name,
          journal: {
            entityId: null,
            propertyId: null,
            unitId: null,
            journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
          },
        });
      });
      setState({
        yodleeProvider,
        key: 'yodleeSuccess',
        addedAccounts,
        accountCount: state.accountCount + addedAccounts.length,
      });
    },
    [organizationId, organizationName, state.accountCount],
  );

  useEffect(() => {
    async function getData() {
      const bankingQuery = {
        organizationId,
        type2: ['Bank', 'Credit Card'],
        default: null,
      };
      const bankingAccounts = await find(this, 'accounts', { query: bankingQuery });
      const accountCount = bankingAccounts.total;
      if (window.location.pathname === '/onboarding-complete') {
        setState({
          key: 'setupComplete',
        });
      } else if (accountCount === 0) {
        setState({
          key: 'accountsOverview',
          accountCount,
        });
      } else {
        setState({
          key: 'addAccountsSuccess',
          accountCount,
        });
      }
      setLoading(false);
    }
    getData();
  }, [organizationId]);

  if (loading) {
    return null;
  }

  if (addAccountDialog) {
    return (
      <AddBankingAccountDialog
        isOpen
        closeDialog={() => {
          setState({ ...state, accountType: null });
          setAddAccountDialog(false);
        }}
        onAddAccount={addAccount}
        accountType={state.accountType}
        allAccounts={allAccounts}
      />
    );
  }

  if (plaidExitDialogVisible) {
    return (
      <PlaidExitDialog
        plaidExitDialogVisible={plaidExitDialogVisible}
        setAddAccountDialog={setAddAccountDialog}
        setPlaidExitDialogVisible={setPlaidExitDialogVisible}
        setState={setState}
        setYodleeAddLink={setYodleeAddLink}
      />
    );
  }

  if (plaidAddNew) {
    return (
      <PlaidLinkAdd
        onSuccess={onPlaidSuccess}
        closeDialog={() => {
          setPlaidAddNew(false);
          setPlaidExitDialogVisible(true);
        }}
        importStartDate={bookkeepingStartDate}
      />
    );
  }

  if (yodleeAddLink) {
    return (
      <YodleeLinkAdd
        onSuccess={onYodleeSuccess}
        closeDialog={() => {
          setYodleeAddLink(false);
        }}
        importStartDate={bookkeepingStartDate}
      />
    );
  }

  if (addLoanAccountDialog) {
    return <AddLoanDialog isOpen closeDialog={() => setAddLoanAccountDialog(false)} onAddAccount={addLoanAccount} />;
  }

  return (
    <>
      <SkipForNowAccountsDialog
        isOpen={skipForNowDialogVisible}
        closeDialog={() => setSkipForNowDialogVisible(false)}
        onConfirm={() => setState({ ...state, accountType: null, key: 'addLoanAccounts' })}
      />
      <Content
        allAccounts={allAccounts}
        state={state}
        onAddAccount={onAddAccount}
        setAddAccountDialog={setAddAccountDialog}
        setAddLoanAccountDialog={setAddLoanAccountDialog}
        setPlaidAddNew={setPlaidAddNew}
        setSkipForNowDialogVisible={setSkipForNowDialogVisible}
        setState={setState}
      />
    </>
  );
}

AccountsSetup.propTypes = {
  allAccounts: PropTypes.arrayOf(PropTypes.object),
  onAddAccount: PropTypes.func.isRequired,
};
