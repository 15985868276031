import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getDebitJournalLine, getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

import QuickBookRowLoading from '../QuickBookRowLoading';

const AMOUNT_MISMATCH_ERROR =
  'Amount Mismatch: Please double check the net amount entered equals the net calculated amount shown below the components.';

export default function QuickBookRowIncomeMatchTemplate({ transaction, onTransactionAction, matchState, onGetHelp }) {
  const isAirbnb = matchState.incomeTemplateMatch[0].name === 'Airbnb';
  const isVRBO = matchState.incomeTemplateMatch[0].name === 'VRBO';

  const classes = useStyles();
  const tracking = useSegmentTrack();
  const { organizationId } = useContext(PersonContext);
  const isMobile = checkIsSmallScreen();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [journal, setJournal] = useState(getManualBasicJournal());

  const [grossEarnings, setGrossEarnings] = useState(0);
  const [hostFee, setHostFee] = useState(0);
  const [adjustments, setAdjustments] = useState(null);

  const [grossRevenue, setGrossRevenue] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [paymentProcessingFee, setPaymentProcessingFee] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const journalObject = { ...journal };
    journalObject.organizationId = organizationId;
    journalObject.date = transaction.date;
    journalObject.amount = transaction.amount;
    journalObject.description = transaction.description;
    journalObject.type = 'Net Income';
    journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    journal.debitLines[0].accountIdSelect = transaction.account;
    journal.debitLines[0].yodleeTransactionId = transaction.id;
    journal.debitLines[0].debit = transaction.amount;

    if (isAirbnb) {
      const grossEarnings = parseFloat((((transaction.amount / 0.97) * 100) / 100).toFixed(2));
      const hostFee = parseFloat((((transaction.amount / 0.97) * 3) / 100).toFixed(2));
      setGrossEarnings(grossEarnings);
      setHostFee(hostFee);
      journalObject.debitLines = journalObject.debitLines.concat([getDebitJournalLine()]);
    }
    if (isVRBO) {
      const serviceFee = parseFloat((((transaction.amount / 0.92) * 5) / 100).toFixed(2));
      const paymentProcessingFee = parseFloat((((transaction.amount / 0.92) * 3) / 100).toFixed(2));
      const grossRevenue = parseFloat((transaction.amount + serviceFee + paymentProcessingFee).toFixed(2));
      setServiceFee(serviceFee);
      setPaymentProcessingFee(paymentProcessingFee);
      setGrossRevenue(grossRevenue);
      journalObject.debitLines = journal.debitLines.concat([getDebitJournalLine(), getDebitJournalLine()]);
    }

    setJournal(journalObject);
    setIsLoading(false);
    // NOTE: we only need this effect to run one time to set up the initial journal state and
    // initial values of the airbnb/VRBO specific fields which is why I'm using an empty dependency
    // array here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error && error.message === AMOUNT_MISMATCH_ERROR) {
      const airbnbTotal = parseFloat((grossEarnings - hostFee - adjustments).toFixed(2));
      const vrboTotal = parseFloat((grossRevenue - serviceFee - paymentProcessingFee).toFixed(2));

      if (
        (isAirbnb && journal.debitLines[0].debit === airbnbTotal) ||
        (isVRBO && journal.debitLines[0].debit === vrboTotal)
      ) {
        setError(null);
      }
    }
  }, [
    adjustments,
    error,
    grossEarnings,
    grossRevenue,
    hostFee,
    isAirbnb,
    isVRBO,
    journal.debitLines,
    paymentProcessingFee,
    serviceFee,
  ]);

  const bookIncomeMatch = async (event) => {
    event.preventDefault();

    const airbnbTotal = parseFloat((grossEarnings - hostFee - adjustments).toFixed(2));
    const vrboTotal = parseFloat((grossRevenue - serviceFee - paymentProcessingFee).toFixed(2));

    if (
      (isAirbnb && journal.debitLines[0].debit !== airbnbTotal) ||
      (isVRBO && journal.debitLines[0].debit !== vrboTotal)
    ) {
      setError({ message: AMOUNT_MISMATCH_ERROR });
      return;
    }

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const journalSubmit = { ...journal };

    const commissionsAccountData = await find('accounts', {
      query: {
        organizationId,
        default: 'commissionsExpense',
      },
    });

    const rentAccountData = await find('accounts', {
      query: {
        organizationId,
        default: 'rentRevenue',
      },
    });

    if (isAirbnb) {
      journalSubmit.creditLines[0].credit = grossEarnings - adjustments;
      journalSubmit.creditLines[0].accountId = rentAccountData.data[0].id;
      journalSubmit.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;
      journalSubmit.debitLines[1].debit = hostFee;
      journalSubmit.debitLines[1].accountId = commissionsAccountData.data[0].id;
    } else if (isVRBO) {
      journalSubmit.creditLines[0].credit = grossRevenue;
      journalSubmit.creditLines[0].accountId = rentAccountData.data[0].id;
      journalSubmit.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;
      journalSubmit.debitLines[1].debit = serviceFee;
      journalSubmit.debitLines[2].debit = paymentProcessingFee;
      journalSubmit.debitLines[1].accountId = commissionsAccountData.data[0].id;
      const managementFeesAccountData = await find('accounts', {
        query: {
          organizationId,
          default: 'managementFeesExpense',
        },
      });
      journalSubmit.debitLines[2].accountId = managementFeesAccountData.data[0].id;
    }

    journalSubmit.journalLines = journalSubmit.debitLines.concat(journalSubmit.creditLines);

    setJournalScope(journalSubmit);

    try {
      await create('journals', journalSubmit);
      setIsSubmitting(false);
      onTransactionAction();
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <QuickBookRowLoading />;
  }

  return (
    <form onSubmit={bookIncomeMatch}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionFieldSixtySixPercent}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        {isAirbnb && (
          <Box className={classes.loanBox}>
            <Typography variant="subtitle2">Components</Typography>
            <Box className={classes.loanAmountsContainer}>
              <Box className={classes.loanAmountsRow}>
                <Box className={classes.incomeTemplateMatchContainer}>
                  <CurrencyField
                    label="Gross Earnings"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="$0.00"
                    inputLabelProps={{ shrink: true }}
                    value={grossEarnings || ''}
                    onChange={(event) => {
                      setGrossEarnings(event.target.value);
                    }}
                    className={classes.netIncomeAmountField}
                  />
                  <Box />
                </Box>
                <Box className={classes.incomeTemplateMatchContainer}>
                  <CurrencyField
                    label="Host Fee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="$0.00"
                    inputLabelProps={{ shrink: true }}
                    value={hostFee || ''}
                    onChange={(event) => {
                      setHostFee(event.target.value);
                    }}
                    className={classes.netIncomeAmountField}
                  />
                  <Typography variant="caption">Airbnb's host fee is typically 3%</Typography>
                </Box>
                <Box className={classes.incomeTemplateMatchContainer}>
                  <CurrencyField
                    label="Adjustments"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="$0.00"
                    InputLabelProps={{ shrink: true }}
                    value={adjustments || ''}
                    onChange={(event) => {
                      setAdjustments(event.target.value);
                    }}
                    className={classes.netIncomeAmountField}
                  />
                  <Box />
                </Box>
              </Box>
              <Box className={classes.loanAmountsTextContainer}>
                {journal.debitLines[0].debit !== parseFloat((grossEarnings - hostFee - adjustments).toFixed(2)) ? (
                  <>
                    <Typography variant="body2" className={classes.expectedLoanAmountText}>
                      {`Expected $${Number(journal.amount).toFixed(2)}`}
                    </Typography>
                    <Typography variant="body2">{' | '}</Typography>
                  </>
                ) : null}
                <Typography variant="body2">{`Total $${Number(grossEarnings - hostFee - adjustments).toFixed(2)}`}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {isVRBO && (
          <Box className={classes.loanBox}>
            <Box className={classes.loanAmountsContainer}>
              <Typography variant="subtitle2">Components</Typography>
              <Box className={classes.loanAmountsContainer}>
                <Box className={classes.loanAmountsRow}>
                  <Box className={classes.incomeTemplateMatchContainer}>
                    <CurrencyField
                      label="Gross Revenue"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="$0.00"
                      isRequired
                      inputLabelProps={{ shrink: true }}
                      value={grossRevenue || ''}
                      onChange={(event) => {
                        setGrossEarnings(event.target.value);
                      }}
                      className={classes.netIncomeAmountField}
                    />
                    <Box />
                  </Box>
                  <Box className={classes.incomeTemplateMatchContainer}>
                    <CurrencyField
                      label="Service Fee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="$0.00"
                      isRequired
                      inputLabelProps={{ shrink: true }}
                      value={serviceFee || ''}
                      onChange={(event) => {
                        setServiceFee(event.target.value);
                      }}
                      className={classes.netIncomeAmountField}
                    />
                    <Typography variant="caption">VRBO's service fee is typically 5%</Typography>
                  </Box>
                  <Box className={classes.incomeTemplateMatchContainer}>
                    <CurrencyField
                      label="Payment Processing Fee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="$0.00"
                      isRequired
                      inputLabelProps={{ shrink: true }}
                      value={paymentProcessingFee || ''}
                      onChange={(event) => {
                        setPaymentProcessingFee(event.target.value);
                      }}
                      className={classes.netIncomeAmountField}
                    />
                    <Typography variant="caption">VRBO's payment processing fee is typically 3%</Typography>
                  </Box>
                </Box>
                <Box className={classes.loanAmountsTextContainer}>
                  {journal.debitLines[0].debit !==
                  parseFloat((grossRevenue - serviceFee - paymentProcessingFee).toFixed(2)) ? (
                    <>
                      <Typography variant="body2" className={classes.expectedLoanAmountText}>
                        {`Expected $${Number(journal.amount).toFixed(2)}`}
                      </Typography>
                      <Typography variant="body2">{' | '}</Typography>
                    </>
                  ) : null}
                  <Typography variant="body2">{`Total $${Number(grossRevenue - serviceFee - paymentProcessingFee).toFixed(2)}`}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.helpButton}
            onClick={() => {
              tracking('get_help clicked', { location: 'Inline Booking - Import Feed' });
              onGetHelp();
            }}
          >
            <HelpOutline className={classes.helpIcon} />
            Get Help
          </Button>
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              fullWidth={false}
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              {`Save ${isAirbnb ? 'Airbnb Income' : 'VRBO Income'}`}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowIncomeMatchTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  matchState: PropTypes.object.isRequired,
  onGetHelp: PropTypes.func.isRequired,
};
