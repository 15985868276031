import React from 'react';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

export default function AddManualAccount({ setAddAccountDialog, setState }) {
  const tracking = useSegmentTrack();

  return (
    <DialogContent>
      <Box mx="auto" mb={2}>
        <Typography variant="h6" gutterBottom>
          Would you like to add any manual bank or credit card accounts?
        </Typography>
      </Box>
      <Box mb={1}>
        <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
          <Typography variant="body2">
            {`Use the import feed with manual accounts by uploading a transaction file from
                  your financial institution. Transactions can also be created individually.`}
          </Typography>
        </Box>
      </Box>
      <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
        <Button
          hasFullWidth
          variant="contained"
          onClick={() => {
            tracking('add_manual_account clicked', { location: 'Onboarding - Manual Accounts: Intro' });
            setAddAccountDialog(true);
          }}
        >
          Add A Manual Account
        </Button>
        <Box mt={1}>
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            hasFullWidth
            onClick={() => {
              tracking('no_manual_accounts clicked', { location: 'Onboarding - Manual Accounts: Intro' });
              setState((prevState) => ({ ...prevState, key: 'addLoanAccounts' }));
            }}
          >
            No Manual Accounts
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
}

AddManualAccount.propTypes = {
  setAddAccountDialog: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};
