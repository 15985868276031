import React from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import animationPathJson from '~/lottie/confetti.json';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
}));

function Confetti({ className }) {
  const classes = useStyles();

  return <Lottie animationData={animationPathJson} autoPlay className={cx(classes.container, className)} loop />;
}

Confetti.propTypes = {
  className: PropTypes.string,
};

export default Confetti;
