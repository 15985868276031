import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import history from '../history';
import { find, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import { asyncHandleChange, asyncSetState } from '../functions/InputHandlers';
import { parseQuery, buildQuery, appliedFilter } from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';

class FixedAssetsSchedule extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
        entityId: null,
        entityIdSelect: null,
        propertyId: null,
        propertyIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment().subtract(1, 'years').endOf('year');
      newFilter.startDateSelect = moment().subtract(1, 'years').startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const assetQuery = {
      organizationId,
      $limit: 100,
      $skip: 0,
      $sort: {
        name: 1,
        id: 1,
      },
    };

    if (filter.propertyId) {
      assetQuery.propertyId = filter.propertyId;
    } else if (filter.entityId) {
      const properties = await find(this, 'properties', {
        query: {
          organizationId,
          entityId: filter.entityId,
          inactive: false,
          $limit: 100,
        },
      });
      assetQuery.propertyId = properties.data.map((property) => property.id);
    }

    let assets = [];
    let assetsResult;

    do {
      // eslint-disable-next-line no-await-in-loop
      assetsResult = await find(this, 'fixed-assets', { query: assetQuery });
      assets = assets.concat(assetsResult.data);
      assetQuery.$skip += 100;
    } while (assets.length < assetsResult.total);

    const periodFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Fixed Asset', 'Accumulated Depreciation'],
    });
    const reportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Fixed Asset', 'Accumulated Depreciation'],
    });
    delete reportFilter.date.$gte;

    const accountsObject = {
      depreciable: {
        asset: {
          accounts: [],
        },
        depreciation: {
          accounts: [],
        },
        accounts: [],
      },
      nonDepreciable: {
        accounts: [],
      },
      all: {
        accounts: [],
      },
      byId: {},
    };
    // launch async calls
    // get totals and prep value array and display toggle
    const accountsPromises = [
      create(this, 'reports', reportFilter).then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.values = [];
          account.display = false;
          account.subaccounts = [];
          if (account.type2 === 'Fixed Asset') {
            if (account.nonDepreciable) {
              accountsObject.nonDepreciable.accounts.push(account);
            } else {
              accountsObject.depreciable.asset.accounts.push(account);
              accountsObject.depreciable.accounts.push(account);
            }
          } else {
            accountsObject.depreciable.depreciation.accounts.push(account);
            accountsObject.depreciable.accounts.push(account);
          }
          accountsObject.all.accounts.push(account);
          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      }),
    ];
    // get data for each asset
    assets.forEach((asset) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...reportFilter,
          fixedAssetId: asset.id,
        }),
      );
    });

    // get unassigned data
    accountsPromises.push(
      create(this, 'reports', {
        ...reportFilter,
        fixedAssetId: null,
      }),
    );

    const periodAccountsObject = {
      depreciable: {
        asset: {
          accounts: [],
        },
        depreciation: {
          accounts: [],
        },
        accounts: [],
      },
      nonDepreciable: {
        accounts: [],
      },
      all: {
        accounts: [],
      },
      byId: {},
    };

    // get period totals and prep value array and display toggle
    const periodAccountsPromises = [
      create(this, 'reports', periodFilter).then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.values = [];
          account.display = false;
          account.subaccounts = [];
          if (account.type2 === 'Fixed Asset') {
            if (account.nonDepreciable) {
              periodAccountsObject.nonDepreciable.accounts.push(account);
            } else {
              periodAccountsObject.depreciable.asset.accounts.push(account);
              periodAccountsObject.depreciable.accounts.push(account);
            }
          } else {
            periodAccountsObject.depreciable.depreciation.accounts.push(account);
            periodAccountsObject.depreciable.accounts.push(account);
          }
          periodAccountsObject.all.accounts.push(account);
          periodAccountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      }),
    ];
    // get data for each asset
    assets.forEach((asset) => {
      periodAccountsPromises.push(
        create(this, 'reports', {
          ...periodFilter,
          fixedAssetId: asset.id,
        }),
      );
    });

    // get unassigned data
    periodAccountsPromises.push(
      create(this, 'reports', {
        ...periodFilter,
        fixedAssetId: null,
      }),
    );

    // resolve promises
    const accountsResults = await Promise.all(accountsPromises);
    const periodAccountsResults = await Promise.all(periodAccountsPromises);

    // assign parent accounts
    accountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    // populate values array
    for (let i = 0; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        accountsResults[0][index].values.push(-account.netCredits);
        if (account.netCredits !== 0) {
          accountsResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    // assign parent accounts
    periodAccountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        periodAccountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    // populate values array
    for (let i = 0; i < periodAccountsResults.length; i += 1) {
      periodAccountsResults[i].forEach((account, index) => {
        periodAccountsResults[0][index].values.push(-account.netCredits);
        if (account.netCredits !== 0) {
          periodAccountsResults[0][index].display = true;
          if (account.parentAccountId) {
            periodAccountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const headers = assets.map((asset) => asset.name);
    headers.unshift('Total');
    headers.push('None Assigned');

    const propertyLine = assets.map((asset) => {
      if (asset.property) {
        return asset.property.address1;
      }
      return 'Not Set';
    });
    propertyLine.unshift('');
    propertyLine.push('');

    const lifespanLine = assets.map((asset) => {
      if (asset.lifespan !== null) {
        return `${asset.lifespan} years`;
      }
      return 'Not Set';
    });
    lifespanLine.unshift('');
    lifespanLine.push('');

    const depreciationLine = assets.map((asset, index) => {
      if (asset.lifespan !== null) {
        let recommendedDepreciation =
          accountsObject.depreciable.asset.accounts.reduce((total, account) => total + account.values[index + 1], 0) /
          asset.lifespan;
        let start = moment(filter.startDate).year() * 12 + moment(filter.startDate).month();
        const end = moment(filter.endDate).year() * 12 + moment(filter.endDate).month() + 1;
        if (
          asset.placedInServiceDate !== null &&
          moment(asset.placedInServiceDate).isSameOrAfter(moment(filter.startDate))
        ) {
          start = moment(asset.placedInServiceDate).year() * 12 + moment(asset.placedInServiceDate).month() + 0.5;
        }
        recommendedDepreciation *= (end - start) / 12;

        if (asset.placedInServiceDate !== null && moment(asset.placedInServiceDate).isAfter(moment(filter.endDate))) {
          recommendedDepreciation = 0;
        }

        const remainingDepreciation = accountsObject.depreciable.accounts.reduce(
          (total, account) => total + account.values[index + 1],
          0,
        );
        const maxDepreciation = Math.max(0, remainingDepreciation);
        return Math.min(maxDepreciation, recommendedDepreciation);
      }
      return 'Unknown';
    });
    depreciationLine.unshift('');
    depreciationLine.push('');

    const inServiceLine = assets.map((asset) => {
      if (asset.placedInServiceDate !== null) {
        return moment(asset.placedInServiceDate).format('M/D/YYYY');
      }
      return 'Not Set';
    });
    inServiceLine.unshift('');
    inServiceLine.push('');

    await asyncSetState(
      {
        loading: false,
        headers,
        propertyLine,
        lifespanLine,
        inServiceLine,
        depreciationLine,
        accountsObject,
        periodAccountsObject,
      },
      this,
    );
    return { success: true };
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter,
      propertyLine,
      lifespanLine,
      inServiceLine,
      accountsObject,
      depreciationLine,
      periodAccountsObject,
      headers,
      exportOptions,
    } = this.state;
    return fetch(`${import.meta.env.VITE_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        propertyLine,
        lifespanLine,
        inServiceLine,
        accountsObject,
        depreciationLine,
        periodAccountsObject,
        headers,
        exportOptions,
        reportName: 'Fixed Asset Schedule',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'fixed-assets-schedule.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const {
      accountsObject,
      periodAccountsObject,
      headers,
      propertyLine,
      lifespanLine,
      inServiceLine,
      depreciationLine,
      filter,
      filterDialog,
      loading,
      downloadDialog,
    } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Fixed Assets Schedule"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <>
                  <Box mx="auto" my="50px" width="fit-content" paddingX={2}>
                    <FinancialAccountLine label="Asset Name" values={headers} bold />
                    <FinancialAccountLine label="Property" values={propertyLine} />
                    <FinancialAccountLine label="Lifespan" values={lifespanLine} />
                    <FinancialAccountLine label="Placed in Service" values={inServiceLine} marginBottom />
                    <FinancialAccountLine label="Depreciable Basis" overline bold />
                    {accountsObject.depreciable.asset.accounts.map((account) => (
                      <FinancialAccountLine
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        entityId={filter.entityId}
                        propertyId={filter.propertyId}
                        unitId={filter.unitId}
                        endDate={filter.endDate}
                      />
                    ))}
                    <FinancialAccountLine
                      label="Total Depreciable Basis"
                      values={this.sumColumnValues(accountsObject.depreciable.asset.accounts)}
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine label="Non-Depreciable Basis" overline bold />
                    {accountsObject.nonDepreciable.accounts.map((account) => (
                      <FinancialAccountLine
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        entityId={filter.entityId}
                        propertyId={filter.propertyId}
                        unitId={filter.unitId}
                        endDate={filter.endDate}
                      />
                    ))}
                    <FinancialAccountLine
                      label="Total Non-Depreciable Basis"
                      values={this.sumColumnValues(accountsObject.nonDepreciable.accounts)}
                      bold
                      overline
                      marginBottom
                    />
                    <FinancialAccountLine label="Depreciation" overline bold />
                    {accountsObject.depreciable.depreciation.accounts.map((account) => (
                      <FinancialAccountLine
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        entityId={filter.entityId}
                        propertyId={filter.propertyId}
                        unitId={filter.unitId}
                        endDate={filter.endDate}
                      />
                    ))}
                    <FinancialAccountLine
                      label="Total Depreciation"
                      values={this.sumColumnValues(accountsObject.depreciable.depreciation.accounts)}
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine
                      label="Book Value"
                      values={this.sumColumnValues(accountsObject.all.accounts)}
                      bold
                      overline
                    />
                    <FinancialAccountLine
                      label="Remaining Depreciable Basis"
                      values={this.sumColumnValues(accountsObject.depreciable.accounts)}
                    />
                    <FinancialAccountLine
                      label="Recommended Depreciation*"
                      values={depreciationLine}
                      underline
                      marginBottom
                    />
                    <FinancialAccountLine label="Reporting Period Change" overline bold />
                    {periodAccountsObject.all.accounts.map((account) => (
                      <FinancialAccountLine
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        entityId={filter.entityId}
                        propertyId={filter.propertyId}
                        unitId={filter.unitId}
                        startDate={filter.startDate}
                        endDate={filter.endDate}
                      />
                    ))}
                    <FinancialAccountLine
                      label="Total Reporting Period Change"
                      values={this.sumColumnValues(periodAccountsObject.all.accounts)}
                      overline
                      bold
                    />
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    // bgcolor="common.white"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      {`* Recommended depreciation is calculated using the straight line method and mid-month convention.
                        Some assets may be eligible for bonus or accelerated depreciation. Please consult your tax advisor.`}
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

FixedAssetsSchedule.contextType = PersonContext;

FixedAssetsSchedule.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FixedAssetsSchedule;
