import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as atatus from 'atatus-spa';

import theme from './theme/theme';
import App from './App';
import history from './history';
import metaPrivate from './metaPrivate.json';

// sets the app version variable to be used in cache invalidation
global.appVersion = metaPrivate.version;

// logging package
if (import.meta.env.PROD) {
  atatus.config('945eb4b6d1d74e36bbfaefddc6e0ebc8').install();
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <App />
      </Router>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
