import React from 'react';
import NumberFormat from 'react-number-format';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import IconButton from '~/components/IconButton';
import { sumProperty } from '~/functions/SumFunctions';

import { generateSplitLine } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },
  item: {
    'display': 'flex',
    'alignItems': 'center',
    'gap': theme.spacing(1),
    'justifyContent': 'space-between',
    'borderBottom': `1px solid ${theme.palette.divider}`,
    'paddingBlock': theme.spacing(2),

    '&:last-of-type': {
      borderBottom: 'none',
      paddingBlock: theme.spacing(1),
    },

    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(2),
    },
  },
  label: {
    flex: 1,
  },
  amountField: {
    width: '120px',

    [theme.breakpoints.up('sm')]: {
      width: '180px',
    },
  },
}));

export default function SplitLines({
  splitLines,
  onSplitLineAdded,
  onSplitLineRemoved,
  onSplitLineUpdated,
  setSplitLines,
}) {
  const classes = useStyles();

  function addSplitLine() {
    setSplitLines([...splitLines, generateSplitLine()]);
    onSplitLineAdded?.();
  }

  function updateSplitLine(key, amount) {
    setSplitLines(splitLines.map((line) => (line.key === key ? { ...line, amount } : line)));
    onSplitLineUpdated?.();
  }

  function removeSplitLine(key) {
    setSplitLines(splitLines.filter((line) => line.key !== key));
    onSplitLineRemoved?.();
  }

  return (
    <ul className={classes.container}>
      {splitLines.map((splitLine, index) => (
        <li className={classes.item} key={splitLine.key}>
          <Typography className={classes.label} variant="body2">
            {`Transaction ${index + 1}`}
          </Typography>
          {splitLines.length > 2 && (
            <IconButton aria-label="Delete split line" onClick={() => removeSplitLine(splitLine.key)}>
              <DeleteIcon />
            </IconButton>
          )}
          <CurrencyField
            align="right"
            className={classes.amountField}
            InputLabelProps={{ shrink: true }}
            label="Amount"
            placeholder="$0.00"
            required
            size="small"
            value={splitLine.amount || null}
            variant="outlined"
            onChange={(event) => updateSplitLine(splitLine.key, Number(event.target.value))}
          />
        </li>
      ))}
      <li className={classes.item}>
        <Button color="primary" startIcon={<ControlPointOutlinedIcon />} variant="text" onClick={addSplitLine}>
          Add Line
        </Button>
        <NumberFormat
          displayType="text"
          value={sumProperty(splitLines, 'amount')}
          thousandSeparator
          prefix="Total Assigned: $"
          decimalScale={2}
          fixedDecimalScale
        />
      </li>
    </ul>
  );
}

SplitLines.propTypes = {
  splitLines: PropTypes.array.isRequired,
  onSplitLineAdded: PropTypes.func,
  onSplitLineRemoved: PropTypes.func,
  onSplitLineUpdated: PropTypes.func,
  setSplitLines: PropTypes.func.isRequired,
};
