export const IMPACT_COOKIE_KEY = 'IR_27775';

export const REIHUB_FREEMIUM_COOKIE_KEY = 'rei_hub_core';
export const REIHUB_FREEMIUM_RELEASE_DATE = '2025-02-05';
export const REIHUB_FREEMIUM_VARIANTS = {
  ESSENTIALS_A: 'ESSENTIALS_A',
  ESSENTIALS_B: 'ESSENTIALS_B',
  EXISTING: 'EXISTING',
};

export const ORGANIZATION_CELEBRATION_TYPES = {
  FIRST: 'FIRST',
  MORE_THAN_25: 'MORE_THAN_25',
  MORE_THAN_50: 'MORE_THAN_50',
  MORE_THAN_100: 'MORE_THAN_100',
};

export const ORGANIZATION_CELEBRATION_SETTINGS = {
  [ORGANIZATION_CELEBRATION_TYPES.FIRST]: '1_booked_transaction_celebration_seen',
  [ORGANIZATION_CELEBRATION_TYPES.MORE_THAN_25]: '25_booked_transactions_celebration_seen',
  [ORGANIZATION_CELEBRATION_TYPES.MORE_THAN_50]: '50_booked_transactions_celebration_seen',
  [ORGANIZATION_CELEBRATION_TYPES.MORE_THAN_100]: '100_booked_transactions_celebration_seen',
};
