import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';

import IconButton from '~/components/IconButton';

import ActionButtons from './ActionButtons';
import ActionsMenu from './ActionsMenu';
import NavBreadcrumbs from './NavBreadcrumbs';

const useStyles = makeStyles((theme) => ({
  applied: {
    textDecoration: 'underline',
  },
  flexGrow: {
    flexGrow: '1',
  },
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
    verticalAlign: 'text-bottom',
  },
  reverseMargins: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  moreActionsButton: {
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  actionButtons: {
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
}));

export default function PageHeader({ title, appliedFilter = [], children, actionButtons }) {
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const hasActionButtons = actionButtons?.length > 0;

  const actionsMenuButtonRef = useRef(null);
  const match = useRouteMatch();
  const classes = useStyles();

  const displayFilterLine = (filterElement) => {
    if (typeof filterElement === 'object') {
      return (
        <Box marginLeft={2} key={generateHash()}>
          <Typography variant="caption">{`${filterElement.label}:`}</Typography>
          <Box marginLeft={1} component="span">
            <Typography variant="caption">{filterElement.value}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box marginLeft={2} key={generateHash()}>
        <Typography variant="caption">{filterElement}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" flexWrap="wrap" marginTop={-1} marginLeft={-1}>
          <div className={classes.reverseMargins}>
            {match && <NavBreadcrumbs match={match} />}
            {title && (
              <div className={classes.header}>
                <Typography variant="h5">{title}</Typography>

                {hasActionButtons && (
                  <IconButton
                    aria-label="More transactions actions"
                    className={classes.moreActionsButton}
                    color="primary"
                    ref={actionsMenuButtonRef}
                    onClick={() => setIsActionsMenuOpen(true)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </div>
            )}
            {appliedFilter.length > 0 && (
              <>
                <Typography variant="caption" component="div" className={classes.applied}>
                  Applied Filter
                </Typography>
                {appliedFilter.map((filterElement) => displayFilterLine(filterElement))}
              </>
            )}
            {children}
          </div>
          <div className={classes.flexGrow} />
          {hasActionButtons && <ActionButtons actionButtons={actionButtons} className={classes.actionButtons} />}
        </Box>
      </Grid>

      {isActionsMenuOpen && hasActionButtons && (
        <ActionsMenu
          actions={actionButtons}
          anchorEl={actionsMenuButtonRef.current}
          onClose={() => setIsActionsMenuOpen(false)}
        />
      )}
    </>
  );
}

PageHeader.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.object),
  appliedFilter: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
  children: PropTypes.node,
};
