const AUTHENTICATE_ROUTES_PATHS = {
  REGISTER: '/register',
  SELECT_PRICING: '/select-pricing',
  CHECKOUT: '/checkout',
  REGISTER_PROFILE: '/register-profile',
  SELECT_PRICING_LIMITED: '/select-pricing-limited',
  REGISTER_PROFILE_LIMITED: '/register-profile-limited',
  CHECKOUT_LIMITED: '/checkout-limited',
  FURNISHED_FINDER: '/furnished-finder',
  RESET_PASSWORD: '/resetpassword',
  SET_PASSWORD: '/setpassword',
  VERIFY_EMAIL: '/verifyemail',
  SIGN_IN: '/sign-in',
  SSO: '/sso',
  PORTFOLIOS: '/portfolios',
  INVITE: '/invite',
  DEMO: '/demo',
  TURBOTENANT: '/turbotenant',
  RENTREDI: '/rentredi',
  ACCOUNTANT_SIGN_IN: '/accountant/sign-in',
  ACCOUNTANT_REGISTER: '/accountant/register',
  ACCOUNTANT_FIRMS: '/accountant/firms',
  ADMIN_SIGN_IN: '/admin/sign-in',
  ADMIN_PASSWORD_RESET: '/admin-password-reset',
};

export default AUTHENTICATE_ROUTES_PATHS;
